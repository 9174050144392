<template>
  <div class="input-modal-wrapper" @click.self="dropdown = false">
    <div class="input-modal" @click.self="dropdown = false">
      <span class="tms close" @click.prevent="close()">F</span>
      <form>
        <template v-if="type === 'activity'">
          <h2 v-if="!this.obj">{{ $t("new-activity") }}</h2>
          <h2 v-else>{{ $t("edit") }} {{ $t("activity") }}</h2>
        </template>
        <template v-else-if="type === 'material'">
          <h2 v-if="!this.obj">{{ $t("new-material") }}</h2>
          <h2 v-else>{{ $t("edit") }} {{ $t("material") }}</h2>
        </template>
        <template v-else-if="type === 'mechanization'">
          <h2 v-if="!this.obj">{{ $t("new-mechanization") }}</h2>
          <h2 v-else>{{ $t("edit") }} {{ $t("mechanization") }}</h2>
        </template>
        <label for="inputActivityField" class="input-label">{{
          $t("name")
        }}</label>
        <!-- INPUT ACTIVITY -->
        <div class="input-wrapper textarea-wrapper" :title="$t('name')">
          <span class="tms">U</span>
          <textarea
            id="inputActivityField"
            type="text"
            :placeholder="$t('name')"
            v-model="inputName"
            @focus="inputNameFocused = true"
            @blur="inputNameFocused = false"
            @keydown.enter.prevent="save()"
          />
          <div
            class="input-wrapper__dropdown"
            v-if="nameList && inputNameFocused"
          >
            <p
              v-for="(n, i) in filteredNameList"
              :key="`n-${i}`"
              @mousedown="inputName = n"
            >
              {{ n }}
            </p>
          </div>
        </div>
        <div class="inputs inputs--single" :title="$t('metric-unit')">
          <div class="input-wrapper">
            <span class="tms">S</span>
            <input
              id="inputUnitField"
              type="text"
              :placeholder="$t('metric-unit')"
              v-model="inputUnit"
              @keydown.enter.prevent="save()"
            />
            <!-- :disabled="type === 'mechanization'" -->
          </div>
        </div>
        <div class="inputs inputs--single" :title="$t('quantity')">
          <!-- v-if="type === 'activity'" -->
          <div class="input-wrapper">
            <span class="tms2">a</span>
            <input
              id="inputQuantityField"
              type="number"
              :placeholder="$t('quantity')"
              v-model="inputQuantity"
              @keydown.enter.prevent="save()"
            />
          </div>
        </div>
        <div class="inputs inputs--single" :title="$t('price-one')">
          <div class="input-wrapper">
            <span class="tms2">a</span>
            <input
              id="inputPriceField"
              type="number"
              :placeholder="$t('price-one')"
              v-model="inputPrice"
              @keydown.enter.prevent="save()"
            />
          </div>
        </div>
        <div class="input-modal-actions">
          <div>
            <!-- JUST AND EMPTY DIV FOR THE SPACE-BETWEEN RULE TO WORK -->
          </div>
          <div class="btns">
            <button class="btn btn-close" @click.prevent="close()">
              <span class="tms">F</span>
              {{ $t("cancel") }}
            </button>
            <!-- BUTTON ACCEPT FOR MATERIAL/ACTIVITY-->
            <button
              class="btn btn-new"
              type="submit"
              value="Submit"
              @click.prevent="save()"
            >
              <span class="tms">Y</span>
              {{ $t("save") }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: ["obj", "parts", "type", "nameList"],
  data() {
    return {
      inputName: "",
      inputNameFocused: false,
      inputUnit: "",
      inputPrice: undefined,
      inputQuantity: undefined,
      dropdown: false,
      newPart: false,
    };
  },
  computed: {
    filteredNameList() {
      return this.nameList.filter((n) =>
        n.toLowerCase().includes(this.inputName.toLowerCase())
      );
    },
  },
  methods: {
    save() {
      if (this.isValid()) {
        const item = {
          name: this.inputName,
          unit: this.inputUnit,
          price: this.inputPrice || 0,
        };

        // if (this.type === "activity") {
        item.quantity = this.inputQuantity;
        // }

        if (!this.obj) {
          this.$emit("create", { ...item, isAdditional: true });
        } else {
          this.$emit("edit", item);
        }
        this.close();
      }
    },
    // VALIDATE INPUTS
    isValid() {
      if (!this.inputName) {
        this.$snotify.error(this.$t("enter-name"));
        return false;
      }
      if (this.nameList) {
        const filtered = this.nameList.filter((n) => n === this.inputName);

        if (
          filtered.length > 0 &&
          (this.obj ? this.obj.name !== this.inputName : true)
        ) {
          this.$snotify.error(this.$t("name-is-already-used"));
          return false;
        }
      }
      if (!this.inputUnit) {
        this.$snotify.error(this.$t("enter-metric-unit"));
        return false;
      }
      // if (this.type === "activity" && !this.inputQuantity) {
      if (!this.inputQuantity) {
        this.$snotify.error(this.$t("enter-quantity"));
        return false;
      }
      return true;
    },
    close() {
      this.$emit("close");
    },
    escape(e) {
      if (e.code === "Escape") {
        this.close();
      }
    },
  },
  mounted() {
    if (this.obj) {
      this.inputName = this.obj.name;
      this.inputUnit = this.obj.unit;
      this.inputPrice = this.obj.price;
      this.inputQuantity = this.obj.quantity;
    } else {
      // if (this.type === "mechanization") {
      //   this.inputUnit = "мсм";
      // }
    }

    window.addEventListener("keydown", this.escape);
  },
  destroyed() {
    window.removeEventListener("keydown", this.escape);
  },
};
</script>

<style scoped>
.input-modal-wrapper {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}
.input-modal {
  background-color: #fff;
  border-radius: 4px;
  /* border-top: 10px solid #e65100; */
  display: flex;
  flex-direction: column;
  /* height: 60vh; */
  /* overflow: auto; */
  padding: 15px 20px 0;
  position: relative;
  /* width: 600px; */
}
.close {
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  padding: 6px;
  position: absolute;
  right: 14px;
  top: 9px;
  transition: all 0.3s;
}
.close:hover {
  background-color: rgba(0, 0, 0, 0.1);
}
.input-modal-permissions {
  background-color: #f4f4f4;
  border-radius: 4px;
  display: flex;
  justify-content: center;
}
.input-modal-actions {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}
.textarea-wrapper {
  width: 100%;
  margin-bottom: 0;
  max-width: 100%;
  position: relative;
  overflow: initial;
}
.textarea-wrapper .tms {
  top: 15px;
}
.textarea-wrapper textarea {
  background-color: #deecf9;
  color: #005a9e;
  font-family: "Google Sans", Helvetica, Arial, sans-serif;
  font-weight: 700;
  min-height: 60px;
  padding-top: 6px;
  resize: vertical;
  width: 100%;
}
.input-wrapper .tms,
.input-wrapper .tms2,
.users-list .tms {
  color: #005a9e;
}
.input-wrapper input {
  color: #005a9e;
  font-weight: 700;
}

.input-wrapper textarea::placeholder,
.input-wrapper input::placeholder {
  font-weight: 400;
}
.input-wrapper input,
.users-list {
  background-color: #deecf9;
}
.invalid .tms {
  color: #d91313;
}
.invalid input {
  background-color: #f8dcdc;
  color: #d91313;
}
.invalid input::placeholder {
  color: #d91313;
}
.inputs {
  display: flex;
  margin-top: 7px;
}
.inputs.inputs--single .input-wrapper {
  width: 100%;
}
.input-wrapper {
  display: flex;
  margin-right: 1em;
  /* margin-bottom: 1em; */
}
.input-wrapper:last-child {
  margin-right: 0px;
  /* margin-bottom: 0px; */
}
form > h2 {
  font-size: 13px;
  font-weight: 700;
  text-align: center;
  text-transform: uppercase;
}

.input-wrapper__dropdown {
  background: #fff;
  border: 1px solid #104d8033;
  border-radius: 4px;
  left: 0;
  max-height: 120px;
  overflow: auto;
  position: absolute;
  top: calc(100% + 5px);
  width: 100%;
  z-index: 1;
}
.input-wrapper__dropdown p {
  cursor: pointer;
  padding: 2px 5px;
}

.input-wrapper__dropdown p:hover {
  background: rgba(222, 236, 249, 0.5);
}

.hide {
  display: none;
}

/* Responsive */
@media screen and (max-width: 1000px) {
  .btn.btn-close {
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 650px) {
  .input-modal {
    width: 90%;
  }
}
@media screen and (max-width: 450px) {
  .inputs {
    flex-direction: column;
  }
  .input-wrapper {
    margin-right: 0px;
  }
  .btns {
    display: flex;
    flex-direction: column;
  }
  .btn.btn-close {
    margin-bottom: 10px;
  }
}
</style>
