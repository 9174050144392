<template>
  <div class="input-modal-wrapper">
    <div class="input-modal">
      <form>
        <!-- INPUT -->
        <div>
          <label for="editInputField" class="input-label">{{ val.name }} ({{ val.unit }})</label>
          <div class="input-wrapper last-item">
            <span class="tms">Z</span>
            <input id="editInputField" type="text" :placeholder="$t('quantity')" v-model="input" />
          </div>
        </div>

        <div class="input-modal-actions">
          <div>
            <!-- JUST AND EMPTY DIV FOR THE SPACE-BETWEEN RULE TO WORK -->
          </div>
          <div class="btns">
            <!-- BUTTON ACCEPT FOR MATERIAL/ACTIVITY-->
            <button class="btn btn-new" type="submit" value="Submit" @click.prevent="save()">
              <span class="tms">Y</span>
              {{ $t('save') }}
            </button>
            <button class="btn btn-close" @click.prevent="close()">
              <span class="tms">F</span>
              {{ $t('cancel') }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: ['val'],
  data() {
    return {
      input: ''
    };
  },
  methods: {
    // SAVE METHODS
    save() {
      if (isNaN(+this.input)) {
        this.$snotify.error(this.$t('no-quantity'));
      } else {
        const output = this.val;
        output.quantity = +this.input || 0;
        this.$parent.$emit('addQuantity', {
          value: output
        });
      }
    },
    close() {
      this.$parent.$emit('closeAddQuantityModal');
    }
  },
  mounted() {
    this.$eventBus.$on('closeModalFromSpeechRecognition', () => {
      this.close();
    });
  },
  destroyed() {
    this.$eventBus.$off('closeModalFromSpeechRecognition');
  }
};
</script>

<style scoped>
.input-modal-wrapper {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}
.input-modal {
  background-color: #fff;
  border-radius: 4px;
  border-top: 10px solid #3377b1;
  display: flex;
  flex-direction: column;
  /* height: 60vh; */
  overflow: auto;
  padding: 10px 20px 0;
  width: 600px;
}
.input-modal-permissions {
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
}
.input-modal-actions {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}
.invalid .tms {
  color: #d91313;
}
.invalid input {
  background-color: #f8dcdc;
  color: #d91313;
}
.invalid input::placeholder {
  color: #d91313;
}
.invalid input::placeholder {
  color: #d91313;
}
.invalid input::placeholder {
  color: #d91313;
}
.invalid input::placeholder {
  color: #d91313;
}
.inputs {
  display: flex;
  margin-top: 20px;
}
.input-and-label-wrapper {
  display: flex;
  flex-direction: column;
  margin-right: 1em;
  margin-bottom: 1em;
  width: 100%;
}
.input-and-label-wrapper:last-child {
  margin-right: 0;
}
.input-label {
  font-size: 12px;
}

.input-wrapper {
  width: 100%;
}
.last-item {
  margin-right: 0px;
  /* margin-bottom: 0px; */
}
.hide {
  display: none;
}

/* Responsive */
@media screen and (max-width: 1000px) {
  .btn.btn-close {
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 650px) {
  .input-modal {
    width: 90%;
  }
}
@media screen and (max-width: 450px) {
  .inputs {
    flex-direction: column;
  }
  .input-wrapper {
    margin-right: 0px;
  }
  .btns {
    display: flex;
    flex-direction: column;
  }
  .btn.btn-close {
    margin-bottom: 10px;
  }
}
</style>
