<template>
  <div class="log">
    <div class="log-header">
      <h2>{{ $t('system-activities') }}</h2>
      <div class="header-controlls" ref="top">
        <div class="datepicker">
          <div class="new-input-wrapper">
            <div class="new-input">
              <span class="tms">D</span>
              <input
                type="text"
                :placeholder="$t('date-from')"
                @click="openDropdown(0)"
                @focus="openDropdown(0)"
                @blur="closeDropdown(0)"
                v-model="formatedFromDate"
                readonly
              >
            </div>
          </div>
          <div class="new-input-wrapper">
            <div class="new-input">
              <span class="tms">D</span>
              <input
                type="text"
                :placeholder="$t('date-to')"
                @click="openDropdown(1)"
                @focus="openDropdown(1)"
                @blur="closeDropdown(1)"
                v-model="formatedToDate"
                readonly
              >
            </div>
          </div>
          <div
            class="new-input-dropdown datepicker-dropdown"
            :class="{ 'datepicker-dropdown-active' : openedDropdown[0] }"
          >
            <datepicker
              :language="this[$i18n.locale]"
              :inline="true"
              :monday-first="true"
              :wrapper-class="'custom-datepicker'"
              :calendar-class="'custom-calendar'"
              v-model="fromDate"
              @selected="closeDropdown(0)"
            ></datepicker>
          </div>
          <div
            class="new-input-dropdown datepicker-dropdown"
            :class="{ 'datepicker-dropdown-active' : openedDropdown[1] }"
          >
            <datepicker
              :language="this[$i18n.locale]"
              :inline="true"
              :monday-first="true"
              :wrapper-class="'custom-datepicker'"
              :calendar-class="'custom-calendar'"
              v-model="toDate"
              @selected="closeDropdown(1)"
            ></datepicker>
          </div>
        </div>
        <!-- DESKTOP BUTTONS -->
        <div class="btns desktop">
          <button class="btn btn-excel" @click="exportToExcelFile()">
            <span class="tms">E</span>
            {{ $t('export-to-excel') }}
          </button>
        </div>
      </div>
    </div>
    <div class="table-wrapper">
      <table v-if="logs.length" class="table" ref="logTable">
        <thead>
          <tr>
            <th>{{ $t('date') | capitalize }}</th>
            <th>{{ $t('user') }}</th>
            <th>{{ $t('activity') }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="log in logs" :key="`log-${log._id}`">
            <td>
              <span class="no-wrap">
                <strong>{{log.createdAt | formatFullDate}}</strong>
              </span>
            </td>
            <td>{{log.createdBy.name}}</td>
            <td>{{log.activity}}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- MOBILE BUTTONS -->
    <footer class="modal-footer mobile" ref="footer">
      <div class="btns mobile">
        <!-- EXPORT IN EXCEL -->
        <span class="new-content-data-item-toggle-circle mobile excel" @click="exportToExcelFile()">
          <span class="tms">E</span>
        </span>
      </div>
    </footer>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { bg, en } from "vuejs-datepicker/dist/locale";
import XLSX from "xlsx";
export default {
  components: {
    Datepicker
  },
  data() {
    return {
      bg: bg,
      en: en,
      openedDropdown: [false, false],
      fromDate: undefined,
      toDate: undefined,
      formatedFromDate: undefined,
      formatedToDate: undefined,
      logs: []
    };
  },
  watch: {
    fromDate(to, from) {
      this.fromDate.setHours(0, 0, 0, 0);
      // this.queryObject.fromDate = this.fromDate.toISOString();
      this.formatedFromDate = this.$moment(
        String(this.fromDate.toISOString())
        ).format("DD/MM/YYYY");
      // eslint-disable-next-line
      if (!!from) {
        this.getLogs();
      }
    },
    toDate(to, from) {
      this.toDate.setHours(23, 59, 59, 999);
      // this.queryObject.toDate = this.toDate.toISOString();
      this.formatedToDate = this.$moment(
        String(this.toDate.toISOString())
      ).format("DD/MM/YYYY");
      // eslint-disable-next-line
      if (!!from) {
        this.getLogs();
      }
    }
  },
  methods: {
    // Open dropdown. Depends on index (hard coded in template)
    openDropdown(i) {
      this.$set(this.openedDropdown, i, true);
    },
    // Close dropdown. Depends on index (hard coded in template)
    closeDropdown(i) {
      setTimeout(() => {
        this.$set(this.openedDropdown, i, false);
      }, 100);
    },
    setDates() {
      const date = new Date();
      // set first day of previous month
      this.fromDate = new Date(date.getFullYear(), date.getMonth(), 1);
      this.fromDate.setHours(0, 0, 0, 0);
      // set last day of previous month
      this.toDate = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      this.toDate.setHours(23, 59, 59, 999);
    },
    getLogs() {
      this.$store.dispatch("startLoading");
      let url = `${
        process.env.VUE_APP_BASE_URI
      }/log/${this.fromDate.toISOString()}/${this.toDate.toISOString()}`;
      this.$http
        .get(url)
        .then(response => {
          this.logs = response.data;
          this.$store.dispatch("stopLoading");
        })
        .catch(error => console.log(error));
    },
    exportToExcelFile() {
      const wscols = [{ wpx: 100 }, { wpx: 100 }, { wpx: 500 }];

      const config = { dateNF: "dd/mm/yyyy" };

      // Convert Table to Worksheet
      const workersWorksheet = XLSX.utils.table_to_sheet(
        this.$refs["logTable"],
        config
      );
      // Define New Workbook
      const new_workbook = XLSX.utils.book_new();

      workersWorksheet["!cols"] = wscols;

      // Append Worksheet
      XLSX.utils.book_append_sheet(
        new_workbook,
        workersWorksheet,
        this.$t('system-activities')
      );
      // Save File

      XLSX.writeFile(
        new_workbook,
        `${this.$t('system-activities')}-${this.formatedFromDate}-${
          this.formatedToDate
        }.xlsx`
      );
    }
  },
  mounted() {
    this.setDates();
    this.getLogs();
  }
};
</script>

<style scoped>
.log {
  padding: 10px 40px;
}
.log-header {
  align-items: center;
  display: flex;
  height: 36px;
  justify-content: space-between;
}
.header-controlls {
  align-items: center;
  display: flex;
}
.pre-datepicker-text {
  color: #373737;
  display: inline-block;
  font-size: 11px;
  font-weight: 700;
  margin-right: 20px;
}
.datepicker {
  border-radius: 4px;
  display: flex;
  position: relative;
  width: 330px;
  padding: 10px 0;
}
.datepicker .new-input-wrapper {
  background-color: #d3e0f5;
  margin: 0;
  width: 50%;
}
.datepicker .new-input-wrapper:first-child {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.datepicker .new-input-wrapper:nth-child(2) {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.datepicker .new-input {
  background-color: transparent;
  margin: 0;
}
.datepicker .new-input input {
  background-color: transparent;
  border: 1px solid #d3e0f5;
  height: 36px;
  cursor: pointer;
}
.datepicker .new-input input:focus {
  background-color: #fff;
  /* border: 1px solid #0099ff; */
}
/* .datepicker .datepicker-dropdown { */
  /* background-color: #ccd1e3; */
/* } */
.table-wrapper {
  overflow: auto;
}
.desktop {
  display: flex;
}
.mobile {
  display: none;
}

/* Responsive */
@media screen and (max-width: 1000px) {
  .mobile {
    display: flex;
  }
  .table-wrapper {
    overflow: auto;
  }
  .log {
    padding: 10px 15px;
  }
  .log-header h2,
  .log-header .btn {
    display: none;
  }
  .log-header {
    justify-content: flex-end;
  }
  .new-content-data-item-toggle-circle {
    height: 50px;
    width: 50px;
  }
  .new-content-data-item-toggle-circle.mobile {
    bottom: 20px;
    cursor: pointer;
    position: fixed;
    right: 20px;
  }
  .new-content-data-item-toggle-circle.mobile.excel {
    background-color: green;
    bottom: 25px;
    cursor: pointer;
    position: fixed;
    right: 20px;
  }
  .new-content-data-item-toggle-circle.mobile.top {
    background-color: #083f75;
    margin-top: 50px;
    position: inherit;
  }
  .new-content-data-item-toggle-circle .tms {
    border: 0px;
    font-size: 20px;
  }
  .modal-footer {
    background-color: #fff;
    height: 0;
    padding: 0px;
  }
}
@media screen and (max-width: 600px){
  .datepicker {
    width: 100%;
  }
}
</style>
