<template>
  <div class="modal-wrapper copy-move-modal">
    <div class="modal">
      <div class="modal-header">
        <h1>{{ title }}</h1>
        <span class="tms modal-close" @click="close()">F</span>
      </div>
      <div class="modal-content">
        <datepicker
          :language="this[$i18n.locale]"
          :inline="true"
          :monday-first="true"
          :minimumView="'day'"
          :maximumView="'day'"
          :full-month-name="true"
          v-model="date"
        ></datepicker>
      </div>
      <div class="modal-footer">
        <button class="btn btn-new" @click="save()">
          <span class="tms">Y</span>
          <template>{{ $t('select') }}</template>
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from 'vuejs-datepicker';
export default {
  components: {
    Datepicker: Datepicker
  },
  props: ['selected', 'title'],
  data() {
    return {
      months: [
        'Януари',
        'Февруари',
        'Март',
        'Април',
        'Май',
        'Юни',
        'Юли',
        'Август',
        'Септември',
        'Октомври',
        'Ноември',
        'Декември'
      ],
      date: undefined
    };
  },
  methods: {
    save() {
      this.$emit('save', this.date);
    },
    close() {
      this.$emit('close');
    },
    handleEvents(e) {
      if (e.code === 'Enter') {
        this.save();
      } else if (e.code === 'Escape') {
        this.close();
      }
    }
  },
  mounted() {
    if (this.selected) {
      this.date = this.selected;
    } else {
      this.date = new Date();
    }
    window.addEventListener('keydown', this.handleEvents);
  },
  destroyed() {
    window.removeEventListener('keydown', this.handleEvents);
  }
};
</script>

<style scoped>
.modal {
  width: initial;
}
.modal-header {
  background-color: #5f5f5f;
  justify-content: flex-start;
  padding: 0 30px;
}
.modal-header h1 {
  color: #fff;
  font-size: 20px;
}
.modal-footer {
  justify-content: center;
}
.btn {
  margin: 0;
}
/* Responsive */
@media screen and (max-width: 1000px) {
  .modal {
    max-height: 80vh;
    width: 90%;
  }
  .modal-header .new-input {
    width: 72%;
  }
}
@media screen and (max-width: 600px) {
  .modal-content-check {
    width: 45%;
  }
  .modal-footer {
    flex-direction: column;
    height: 97px;
  }
  .select-btns {
    margin: 10px 0;
  }
  .btn.btn-new {
    margin: 0 0 10px 0;
  }
}
@media screen and (max-width: 400px) {
  .modal-content-check {
    width: 100%;
  }
}
</style>
