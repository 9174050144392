<template>
  <div
    class="projects-wrapper"
    :class="{ 'projects-wrapper-side-panel': this.$route.name !== 'companies' }"
  >
    <div
      class="projects"
      :class="{ 'projects-mobile-hidden': this.$route.name !== 'companies' }"
    >
      <div class="projects-header">
        <h2 class="desktop">{{ $t("companies") }}</h2>
        <!-- ACTIVE/INACTIVE SWITCH -->
        <div class="switch-wrapper" v-if="this.$route.name === 'companies'">
          <label
            class="switch-label"
            :class="{ 'switch-label-active': !showActiveProjects }"
            @click="showActiveProjects = false"
            >{{ $t("inactive") }}</label
          >
          <div class="switch-container">
            <label class="switch">
              <input type="checkbox" v-model="showActiveProjects" />
              <span class="slider"></span>
            </label>
          </div>
          <label
            class="switch-label"
            :class="{ 'switch-label-active': showActiveProjects }"
            @click="showActiveProjects = true"
            >{{ $t("active") }}</label
          >
        </div>
        <!-- NEW USER BUTTON DESKTOP-->
        <button
          class="btn btn-new desktop"
          @click="newCompany()"
          v-if="this.$route.name === 'companies'"
        >
          <span class="tms">a</span>
          {{ $t("new-company") }}
        </button>
      </div>
      <div
        class="companies-container"
        :class="{ 'no-overflow': this.$route.name === 'companies' }"
      >
        <ul class="companies-ul-wrapper">
          <!-- КОМПАНИИ -->
          <li
            v-for="c in companies"
            :key="c._id"
            :class="{ 'company-li-inactive': !showActiveProjects }"
            class="company-li"
            :ref="c._id"
          >
            <div
              class="company-toggle"
              :class="{ 'company-toggle-inactive': !showActiveProjects }"
              @click="toggleElements(c._id, 'company', 'project')"
            >
              <span class="tms">M</span>
              <span>
                <strong>{{ c.companyName }}</strong>
              </span>
            </div>
            <!-- ПРОЕКТИ -->
            <ul class="projects-ul-wrapper">
              <li
                v-for="p in c.projects"
                :key="p._id"
                class="project-li"
                :ref="p._id"
              >
                <div
                  class="project-toggle"
                  @click="toggleElements(p._id, 'project', 'object')"
                >
                  <span class="tms">M</span>
                  <span>
                    <strong>{{ $t("project").toUpperCase() }}:</strong>
                    {{ p.projectName }}
                  </span>
                </div>
                <!-- ОБЕКТИ -->
                <ul class="objects-ul-wrapper" v-if="p.objects.length">
                  <li
                    v-for="o in p.objects"
                    :key="o._id"
                    class="object-li"
                    :ref="o._id"
                  >
                    <div
                      class="object-toggle"
                      @click="
                        toggleElements(o._id, 'object', 'part'),
                          newPart(c._id, p._id, o._id)
                      "
                    >
                      <span class="tms">M</span>
                      <span
                        :class="{ 'object-selected': selectedObject === o._id }"
                      >
                        <strong>{{ $t("site").toUpperCase() }}:</strong>
                        {{ o.objectName }}
                      </span>
                    </div>
                    <!-- ЧАСТ -->
                    <ul class="objects-ul-wrapper" v-if="p.objects.length">
                      <li
                        v-for="part in o.parts"
                        :key="part._id"
                        class="part-li"
                        :ref="part._id"
                      >
                        <div
                          class="part-toggle"
                          @click="getPart(c._id, p._id, o._id, part._id)"
                        >
                          <span class="tms">M</span>
                          <span>
                            <strong>{{ $t("part").toUpperCase() }}:</strong>
                            {{ part.partName }}
                          </span>
                        </div>
                      </li>
                      <li>
                        <div
                          class="part-toggle"
                          v-if="o.parts ? !o.parts.length : true"
                        >
                          <span> {{ $t("no-parts") }} </span>
                        </div>
                      </li>
                      <li
                        class="part-toggle new-btn-mini-toggle"
                        v-if="
                          getUser.permissions.isSuperAdmin ||
                          getUser.permissions.isAdmin
                        "
                      >
                        <span class="tms">M</span>
                        <a
                          class="new-btn-mini"
                          @click="newPart(c._id, p._id, o._id, true)"
                        >
                          <span class="tms">L</span>
                          {{ $t("create-new-part") }}
                        </a>
                      </li>
                    </ul>
                  </li>
                  <li
                    class="object-toggle new-btn-mini-toggle"
                    v-if="
                      getUser.permissions.isSuperAdmin ||
                      getUser.permissions.isAdmin
                    "
                  >
                    <span class="tms">M</span>
                    <a class="new-btn-mini" @click="newObject(c._id, p._id, true)">
                      <span class="tms">L</span>
                      {{ $t("create-new-site") }}
                    </a>
                  </li>
                </ul>
              </li>
              <li
                class="project-toggle new-btn-mini-toggle"
                v-if="
                  getUser.permissions.isSuperAdmin ||
                  getUser.permissions.isAdmin
                "
              >
                <span class="tms">M</span>
                <a class="new-btn-mini" @click="newProject(c._id)">
                  <span class="tms">L</span>
                  {{ $t("create-new-project") }}
                </a>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <!-- BUTTON NEW COMPANY FOR MOBILE -->
      <footer class="modal-footer mobile">
        <div
          class="new-content-data-item-toggle-circle mobile"
          v-if="this.$route.name === 'companies'"
          @click="newCompany()"
        >
          <span class="tms">L</span>
        </div>
      </footer>
    </div>
    <!-- COMPANY DETAILS SLIDE -->
    <transition name="slideFromRight">
      <Company
        v-if="
          this.$route.name === 'newProject' ||
          this.$route.name === 'newObject' ||
          this.$route.name === 'newPart' ||
          this.$route.name === 'getPart'
        "
        @company-changed="isCompanyChanged = $event"
      />
    </transition>
  </div>
</template>

<script>
import Company from "./Company";
import { mapGetters } from "vuex";
export default {
  components: {
    Company: Company,
  },
  computed: {
    ...mapGetters(["getUser"]),
  },
  watch: {
    "$route.name"(to) {
      if (to === "companies") {
        this.getCompanies();
      }
    },
    "$route.query"() {
      if (this.$route.query.removed) this.getCompanies();
    },
    showActiveProjects() {
      this.getCompanies();
    },
    $route() {
      this.isCompanyChanged = false;
    },
  },
  data() {
    return {
      selectedObject: "",
      showActiveProjects: true,
      companies: [],
      search: "",
      isCompanyChanged: false,
    };
  },
  methods: {
    getCompanies(initial) {
      this.$store.dispatch("startLoading");
      this.companies = [];
      let type = this.showActiveProjects ? "active" : "inactive";
      let requestUrl = `${process.env.VUE_APP_BASE_URI}/company/${type}`;
      if (this.search.length) {
        requestUrl += `/${this.search}`;
      }
      this.$http.get(requestUrl).then((response) => {
        this.companies = response.data;
        this.$store.dispatch("stopLoading");

        if (initial) {
          this.$nextTick(() => {
            if (this.$route.params.companyId) {
              this.toggleElements(
                this.$route.params.companyId,
                "company",
                "project"
              );
            }

            if (this.$route.params.projectId) {
              this.toggleElements(
                this.$route.params.projectId,
                "project",
                "object"
              );
            }

            if (this.$route.params.objectId) {
              this.toggleElements(
                this.$route.params.objectId,
                "object",
                "part"
              );

              this.selectedObject = this.$route.params.objectId;
            }
          });
        }
      });
    },
    // Filter Companies by companyName
    searchFilter() {
      return this.companies.filter((c) => {
        return c.companyName.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    toggleElements(id, parent, child) {
      const parentElement = this.$refs[id][0];
      const selectedChildren = parentElement.getElementsByClassName(
        `${child}-toggle`
      );
      if (selectedChildren) {
        // Close
        if (parentElement.classList.contains(`${parent}-li-active`)) {
          for (let i = 0; i < selectedChildren.length; i++) {
            selectedChildren[i].classList.remove(`${child}-toggle-active`);
          }
          parentElement.classList.remove(`${parent}-li-active`);
          // Close children also
          this.closeEverythingInside(id, parent);
        } else {
          // Open
          for (let i = 0; i < selectedChildren.length; i++) {
            selectedChildren[i].classList.add(`${child}-toggle-active`);
          }
          parentElement.classList.add(`${parent}-li-active`);
        }
      }
    },
    closeEverythingInside(id, parent) {
      const element = this.$refs[id][0];
      const types = ["project", "object", "part"];
      for (let i = types.length; i > types.indexOf(parent); i--) {
        const activeToggleItems = element.getElementsByClassName(
          `${types[i]}-toggle-active`
        );
        const activeLiItems = element.getElementsByClassName(
          `${types[i]}-li-active`
        );
        while (activeLiItems.length > 0) {
          activeLiItems[0].classList.remove(`${types[i]}-li-active`);
        }
        while (activeToggleItems.length > 0) {
          activeToggleItems[0].classList.remove(`${types[i]}-toggle-active`);
        }
      }
    },
    // METHODS FOR ROUTING
    newCompany() {
      this.selectedObject = "";
      this.$router.push("/companies/new").catch(() => {});
    },
    newProject(companyId) {
      this.selectedObject = "";
      this.$router.push(`/companies/${companyId}`).catch(() => {});
    },
    newObject(companyId, projectId, addNew) {
      this.selectedObject = "";
      let route = `/companies/${companyId}/${projectId}`;
      if (addNew) route += "?newObject=true";

      this.$router.push(route).catch(() => {});
    },
    newPart(companyId, projectId, objectId, addNew) {
      this.selectedObject = objectId;
      let route = `/companies/${companyId}/${projectId}/${objectId}`;
      if (addNew) route += "?newPart=true";

      this.$router.push(route).catch(() => {});
    },
    getPart(companyId, projectId, objectId, partId) {
      this.selectedObject = objectId;
      this.$router
        .push(`/companies/${companyId}/${projectId}/${objectId}/${partId}`)
        .catch(() => {});
    },
  },
  mounted() {
    this.getCompanies(true);
    // Handle search input
    this.$root.$on("sendSearchInput", (data) => {
      this.search = data;
      this.getCompanies();
    });
  },
  beforeRouteUpdate(to, from, next) {
    if (this.isCompanyChanged) {
      this.$eventBus.$emit("check-for-unsaved-data", next);
    } else {
      next();
    }
  },
  beforeRouteLeave(to, from, next) {
    if (this.isCompanyChanged) {
      this.$eventBus.$emit("check-for-unsaved-data", next);
    } else {
      next();
    }
  },
  destroyed() {
    this.$root.$off("sendSearchInput");
  },
};
</script>

<style scoped>
.projects-wrapper {
  display: flex;
}
.projects {
  padding: 10px 40px;
  width: 100%;
  transition: all 0.3s;
}
.projects-wrapper-side-panel .projects {
  padding-right: 0;
  width: 30%;
}
.projects-header {
  align-items: center;
  display: flex;
  height: 36px;
  justify-content: space-between;
}
.companies-container {
  overflow: auto;
  max-height: 100vh;
}
.no-overflow {
  overflow: unset;
  max-height: none;
}
.switch-wrapper {
  align-items: center;
  display: flex;
}
.switch {
  margin: 0 10px;
}
.switch-label {
  cursor: pointer;
  font-weight: 700;
  opacity: 0.4;
}
.switch-label-active {
  opacity: 1;
}
/* COMPANY */
.company-li {
  background-color: #f3f7fd;
  border: 1px solid #d3e0f5;
  border-radius: 4px;
  display: block;
  margin-top: 10px;
  overflow: hidden;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  user-select: none;
}
.company-li-inactive {
  background-color: #fff8f8;
}
.company-li-active {
  padding-bottom: 15px;
}
.company-toggle {
  align-items: center;
  background-color: #f3f7fd;
  cursor: pointer;
  display: flex;
  height: 50px;
  padding: 0 10px;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -ms-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.company-toggle-inactive {
  background-color: #fff8f8;
}
.company-toggle:hover {
  color: rgba(0, 0, 0, 0.8);
}
.company-toggle > .tms {
  margin: 0 7px 0 4px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
/* PROJECTS */
.projects-ul-wrapper,
.objects-ul-wrapper,
.part-ul-wrapper {
  padding-left: 34px;
}
.project-toggle,
.object-toggle,
.part-toggle,
.new-btn-mini-toggle {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 0;
  overflow: hidden;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.project-toggle:hover,
.object-toggle:hover,
.part-toggle:hover {
  color: rgba(0, 0, 0, 0.8);
}
.project-toggle-active,
.object-toggle-active,
.part-toggle-active,
.new-btn-mini-toggle-active {
  height: 25px;
}
.project-toggle > .tms,
.object-toggle > .tms,
.part-toggle > .tms {
  margin-right: 7px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.company-li-active .company-toggle .tms,
.project-li-active .project-toggle .tms,
.object-li-active .object-toggle > .tms,
.part-li-active .part-toggle > .tms {
  transform: rotate(90deg);
}
/* FIX TEXTS OVERFLOW */
.company-li span:nth-child(2),
.project-li span:nth-child(2),
.object-li span:nth-child(2),
.part-li span:nth-child(2) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/* NEW MINI BTN */
.new-btn-mini-toggle > .tms {
  color: #0058bc;
}
.new-btn-mini {
  align-items: center;
  background-color: #0058bc;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  font-size: 12px;
  display: inline-flex;
  padding: 3px 10px;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -ms-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.new-btn-mini > .tms {
  margin-right: 7px;
  font-size: 10px;
}
.new-btn-mini:hover {
  background-color: rgb(23, 112, 214);
}
.object-selected {
  /* color: #8d3506; */
  color: #0058bc;
}
.desktop {
  display: flex;
}
.mobile {
  display: none;
}
@media screen and (max-width: 1000px) {
  .desktop,
  .projects-mobile-hidden {
    display: none;
  }
  .mobile {
    display: flex;
  }
  .projects {
    padding: 10px 15px;
    width: 100%;
  }
  .projects-header {
    justify-content: center;
    width: 100%;
  }
  .projects-wrapper.projects-wrapper-side-panel {
    justify-content: center;
  }
  .company-toggle {
    height: 65px;
    width: 100%;
  }
  .project-toggle-active,
  .object-toggle-active,
  .part-toggle-active,
  .new-btn-mini-toggle-active {
    height: 40px;
    width: 100%;
  }
  .new-content-data-item-toggle-circle {
    font-size: 20px;
    height: 50px;
    width: 50px;
  }
  .new-content-data-item-toggle-circle.mobile {
    bottom: 20px;
    cursor: pointer;
    position: fixed;
    right: 20px;
  }
  .modal-footer {
    background-color: #fff;
    height: 75px;
    padding: 0px;
  }
}
</style>
