<template>
  <div class="worktime-tag" :title="start && end ? `${start} - ${end}` : ''">
    <template v-if="calculated.normal">
      <span class="hour">
        {{ calculated.normal }}
        ч.
      </span>
      <span class="worktime-indicator">{{ $t('rwh') }}</span>
    </template>
    <template v-if="calculated.overtime">
      <span class="hour">
        {{ calculated.overtime }}
        ч.
      </span>
      <span class="worktime-indicator overtime-indicator">{{ $t('ot1') }}</span>
    </template>
    <template v-if="calculated.holiday">
      <span class="hour">
        {{ calculated.holiday }}
        ч.
      </span>
      <span class="worktime-indicator holiday-indicator">{{ $t('ot2') }}</span>
    </template>
  </div>
</template>

<script>
import hours from '../mixins/hours.js';
export default {
  props: ['date', 'start', 'end', 'total'],
  mixins: [hours],
  watch: {
    date: 'recalculate',
    start: 'recalculate',
    end: 'recalculate',
  },
  data() {
    return {
      calculated: {},
    };
  },
  methods: {
    recalculate() {
      this.calculated = this.calculateHours(this.date, this.start, this.end);
    },
  },
  mounted() {
    if (this.total) {
      this.calculated = this.total;
    } else {
      this.recalculate();
    }
  },
};
</script>

<style scoped>
.worktime-tag {
  align-items: center;
  display: inline-flex;
}
.hour {
  display: inline-flex;
  justify-content: center;
  width: 50px;
}
</style>
