<template>
  <div class="modal-wrapper">
    <div class="modal">
      <div class="modal-header">
        <div class="new-input">
          <span class="tms">N</span>
          <input
            type="text"
            :placeholder="$t('search')"
            v-model="searchActivities"
          />
        </div>
        <span class="tms modal-close" @click="close()">F</span>
      </div>
      <div class="modal-content" v-if="!isComingFromReport">
        <div
          v-if="!activities.length"
          class="modal-content-template"
          @click="triggerFileInput()"
        >
          <img
            class="modal-content-template-img desktop"
            :src="getImg(false)"
            :alt="$t('table-structure')"
          />
          <img
            class="modal-content-template-img mobile"
            :src="getImg(true)"
            :alt="$t('table-structure')"
          />
        </div>
        <div
          class="modal-content-item with-edit"
          v-for="(activity, index) in filteredActivities"
          :key="`activityModal-${index}-${activity.name}`"
          :class="{ 'modal-content-check-additional': activity.isAdditional }"
          :title="activity.name"
        >
          <span v-if="activity.isAdditional">⚡</span>
          <p>{{ activity.name }}</p>
          <template v-if="getUser.permissions.isSuperAdmin">
            <span class="tms edit" @click="editActivity(index)" title>c</span>
            <span class="tms" @click="deleteActivity(index)" title>b</span>
          </template>
        </div>
        <!-- NO RESULTS -->
        <p
          class="modal-content-no-results"
          v-if="activities.length && !filteredActivities.length"
        >
          {{ $t("no-results-for") }} '
          <strong>{{ this.searchActivities }}</strong
          >'
        </p>
      </div>
      <div class="modal-content" v-else>
        <p
          v-if="!activities.length"
          class="modal-content-no-results text-align-center"
        >
          {{ $t("no-activities") }}
        </p>
        <div
          class="modal-content-check"
          v-for="(a, i) in filteredActivities"
          :key="`addActivities-${i}-${a._id}`"
          :class="{
            'modal-content-check-additional': a.isAdditional,
            'modal-content-check-disabled': getSelectedActivities(a),
          }"
          :title="a.name"
        >
          <label class="checkbox-wrapper">
            <p><span v-if="a.isAdditional">⚡ </span>{{ a.name }}</p>
            <template v-if="!getSelectedActivities(a)">
              <input
                type="checkbox"
                :id="a._id"
                v-model="a.isSelected"
                @change="a.isSelected ? openAddQuantityModal(a) : null"
              />
              <span class="checkmark"></span>
            </template>
          </label>
        </div>
        <!-- NO RESULTS -->
        <p
          class="modal-content-no-results"
          v-if="activities.length && !filteredActivities.length"
        >
          {{ $t("no-results-for") }} '
          <strong>{{ this.searchActivities }}</strong
          >'
        </p>
      </div>
      <div class="modal-footer">
        <div class="btns-select" v-if="isComingFromReport">
          <button class="btn btn-select" @click="isEditItemModalOpened = true">
            <span class="tms">L</span>
            {{ $t("new-activity") }}
          </button>
        </div>
        <div v-else>
          <!-- EMPTY DIV FOR THE SPACE-AROUND RULE TO WORK -->
        </div>
        <div class="btns">
          <button
            class="btn btn-excel"
            @click="triggerFileInput()"
            v-if="!isComingFromReport"
          >
            <span class="tms">E</span>
            {{ $t("new-file") }}
          </button>
          <input
            ref="uploadActivities"
            class="input-hide"
            type="file"
            value="C:\fakepath"
            @change="handleFile"
          />

          <button class="btn btn-new" @click="save()">
            <span class="tms">Y</span>
            {{ $t("save") }}
          </button>
        </div>
      </div>
    </div>
    <transition name="fade">
      <AddQuantityModal v-if="addQuantityTo" :val="addQuantityTo" />
    </transition>
    <EditItemModal
      v-if="isEditItemModalOpened"
      :isComingFromActivities="true"
      type="activity"
      :obj="activities[selectedIndex]"
      :nameList="activities.map((a) => a.name)"
      @create="handleAdd"
      @edit="handleEditActivity"
      @close="closeEditItemModalModal"
    />
  </div>
</template>

<script>
import XLSX from "xlsx";
import EditItemModal from "./EditItemModal";
import AddQuantityModal from "./AddQuantityModal";
import { mapGetters } from "vuex";
export default {
  components: {
    EditItemModal,
    AddQuantityModal,
  },
  props: [
    "selectedActivities",
    "incomingActivities",
    "incomingBudget",
    "isComingFromReport",
    "companyId",
    "projectId",
    "objectId",
    "needsQuantity",
  ],
  watch: {
    incomingActivities() {
      this.initActivities();
    },
  },
  data() {
    return {
      isEditItemModalOpened: false,
      activities: [],
      searchActivities: "",
      totalSumActivities: 0,
      selectedIndex: null,
      addQuantityTo: null,
    };
  },
  computed: {
    ...mapGetters(["getUser"]),
    filteredActivities() {
      return this.filterActivities();
    },
  },
  methods: {
    openAddQuantityModal(m) {
      if (this.needsQuantity) this.addQuantityTo = m;
    },
    getImg() {
      return `${process.env.VUE_APP_STATIC_URI}/table_help_${this.$i18n.locale}.png`;
    },
    triggerFileInput() {
      this.$refs.uploadActivities.click();
    },
    // EXCEL FILE TO JSON
    handleFile(e) {
      let rABS = true; // true: readAsBinaryString ; false: readAsArrayBuffer
      const files = e.target.files;
      const f = files[0];
      if (f.name.match(`.(xls|XLS|xlsx|XLSX)$`)) {
        const reader = new FileReader();
        reader.onload = (e) => {
          let data = e.target.result;
          if (!rABS) data = new Uint8Array(data);
          const workbook = XLSX.read(data, { type: rABS ? "binary" : "array" });

          /* DO SOMETHING WITH workbook HERE */
          const rows = this.workbookToJSON(workbook);
          // SHEET
          if (Object.keys(rows).length > 1) {
            return this.$snotify.error(this.$t("table-one-sheet-only"));
          }
          for (let row in rows) {
            const keys = Object.keys(rows[row][0]);
            const values = Object.values(rows[row]);

            if (keys.length !== 4) {
              return this.$snotify.error(this.$t("table-warning-header"));
            } else {
              for (let i = 0; i < keys.length; i++) {
                if (
                  !(
                    keys[i].toLowerCase().includes(this.$t("table-name")) ||
                    keys[i].toLowerCase().includes(this.$t("table-unit")) ||
                    keys[i].toLowerCase().includes(this.$t("table-quantity")) ||
                    keys[i].toLowerCase().includes(this.$t("table-price"))
                  )
                ) {
                  return this.$snotify.error(this.$t("table-warning-header"));
                }
              }
            }

            for (let v of values) {
              let cols = Object.keys(v);

              if (cols.length !== 4) {
                return this.$snotify.error(this.$t("table-warning-missing"));
              }

              for (let c of cols) {
                if (
                  (c.toLocaleLowerCase() === this.$t("table-quantity") ||
                    c.toLocaleLowerCase() === this.$t("table-price")) &&
                  typeof v[c] !== "number"
                )
                  return this.$snotify.error(
                    this.$t("table-invalid-quantity-or-price")
                  );
              }
            }

            // ROW OBJECT
            let duplicates = 0;
            for (let item in rows[row]) {
              // check for duplicates
              const i = this.activities
                .map((a) => a.name)
                .indexOf(rows[row][item][Object.keys(rows[row][item])[0]]);
              if (i > -1) {
                duplicates++;
                continue;
              }
              // ROW DATA
              if (!isNaN(rows[row][item][Object.keys(rows[row][item])[2]])) {
                this.totalSumActivities +=
                  rows[row][item][Object.keys(rows[row][item])[2]] *
                  rows[row][item][Object.keys(rows[row][item])[3]];

                this.activities.push({
                  name: rows[row][item][Object.keys(rows[row][item])[0]],
                  unit: rows[row][item][Object.keys(rows[row][item])[1]],
                  quantity:
                    Number(
                      rows[row][item][Object.keys(rows[row][item])[2]]
                    ).toFixed(2) || 0,
                  price:
                    Number(
                      rows[row][item][Object.keys(rows[row][item])[3]]
                    ).toFixed(2) || 0,
                });
              }
            }

            if (duplicates) {
              this.$snotify.warning(
                this.$tc("table-duplicated-activities", duplicates)
              );
            }
          }
        };
        if (rABS) reader.readAsBinaryString(f);
        else reader.readAsArrayBuffer(f);
      } else {
        this.$snotify.error(this.$t("table-warning-xls"));
      }

      // input @change was not reacting to new files
      e.target.value = null;
    },
    workbookToJSON(workbook) {
      var result = {};
      workbook.SheetNames.forEach((sheetName) => {
        var roa = XLSX.utils.sheet_to_row_object_array(
          workbook.Sheets[sheetName]
        );
        if (roa.length > 0) {
          result[sheetName] = roa;
        }
      });
      return result;
    },
    filterActivities() {
      return this.activities.filter((a) => {
        return a.name
          .toLowerCase()
          .includes(this.searchActivities.toLowerCase());
      });
    },
    // Handle added activity from EditInput
    handleAdd(newActivity) {
      // this.activities.unshift(newActivity);
      let url = `${process.env.VUE_APP_BASE_URI}/company/${this.companyId}`;
      this.$http
        .get(url)
        .then((response) => {
          const company = response.data;
          // get index of selected project
          const indexOfProject = company.projects.findIndex(
            (p) => p._id === this.projectId
          );
          // get index of selected object
          const indexOfObject = company.projects[
            indexOfProject
          ].objects.findIndex((o) => o._id === this.objectId);
          company.projects[indexOfProject].objects[
            indexOfObject
          ].activities.unshift(newActivity);

          // make the post request
          let url = `${process.env.VUE_APP_BASE_URI}/company/${company._id}`;

          this.$http
            .put(url, company)
            .then(() => {
              this.$snotify.success(this.$t("success-activity"));
              this.$parent.$emit("getActivitiesAgain");
            })
            .catch((error) => {
              console.log(error);
              this.$snotify.error(this.$t("something-went-wrong"));
            });
        })
        .catch((error) => console.log(error));
    },
    editActivity(i) {
      this.selectedIndex = i;
      this.isEditItemModalOpened = true;
    },
    handleEditActivity(a) {
      this.activities[this.selectedIndex].name = a.name;
      this.activities[this.selectedIndex].unit = a.unit;
      this.activities[this.selectedIndex].price = a.price;
      this.activities[this.selectedIndex].quantity = a.quantity;
      this.activities[this.selectedIndex].updateInReports = true;

      this.$set(
        this.activities,
        this.selectedIndex,
        this.activities[this.selectedIndex]
      );
    },
    closeEditItemModalModal() {
      this.isEditItemModalOpened = false;
      this.selectedIndex = null;
    },
    deleteActivity(i) {
      this.totalSumActivities -=
        this.activities[i].quantity * this.activities[i].price;
      this.activities.splice(i, 1);
    },
    getSelectedActivities(a) {
      if (this.isComingFromReport) {
        if (a) {
          let test = this.selectedActivities.filter((x) => {
            return a._id === x._id;
          });
          return test[0];
        } else {
          return this.activities.filter((a) => {
            return a.isSelected;
          });
        }
      }
    },
    close() {
      this.$parent.$emit("closeAddActivitiesModal");
    },
    save() {
      if (this.isComingFromReport) {
        const sa = this.getSelectedActivities();
        this.$parent.$emit("saveAddActivitiesModal", sa);
      } else {
        this.$parent.$emit(
          "saveAddActivitiesModal",
          this.activities,
          this.totalSumActivities
        );
      }
    },
    initActivities() {
      this.activities = this.$_.cloneDeep(this.incomingActivities);

      this.activities.sort((x, y) => {
        return x.isSelected === y.isSelected ? 0 : x.isSelected ? -1 : 1;
      });
    },
    closeEditItemModal() {
      this.isEditItemModalOpened = false;
    },
    removeActivity() {
      // there is no quantity so we set isSelected to false
      this.addQuantityTo.isSelected = false;
      this.closeAddQuantityModal();
    },
    closeAddQuantityModal() {
      this.addQuantityTo = null;
    },
  },
  mounted() {
    if (this.incomingBudget) {
      this.totalSumActivities = this.incomingBudget;
    }
    this.initActivities();

    this.$on("saveMaterialActivityOrMechanization", this.handleAdd);
    this.$on("closeEditItemModal", this.closeEditItemModal);
    this.$on("closeAddQuantityModal", this.removeActivity);
    this.$on("addQuantity", this.closeAddQuantityModal);
    this.$eventBus.$on("closeModalFromSpeechRecognition", () => {
      this.close();
    });
  },
  destroyed() {
    this.$off("saveMaterialActivityOrMechanization");
    this.$off("closeEditItemModal");
    this.$off("closeAddQuantityModal");
    this.$off("addQuantity");
    this.$eventBus.$off("closeModalFromSpeechRecognition");
  },
};
</script>

<style scoped>
.model-wrapper {
  justify-content: center;
}
/* .modal-header {
  background: #17643a;
} */
.desktop {
  display: initial;
}
.mobile {
  display: none;
}

/* Responsive */
@media screen and (max-width: 1000px) {
  .modal {
    overflow: unset;
    width: 90%;
  }
  .modal-content-item {
    height: fit-content;
    min-height: 55px;
    width: 100%;
  }
  .modal-content-check {
    margin-bottom: 5px;
    width: 100%;
  }
}
@media screen and (max-width: 700px) {
  .modal-footer {
    height: 98px;
    flex-direction: column;
  }
  .btn.btn-new {
    margin-bottom: 0.8em;
  }
  .btns-select {
    display: flex;
    justify-content: center;
    margin-top: 1em;
  }
  .btn-select {
    padding: 0 10px;
  }
}
@media screen and (max-width: 600px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: initial;
  }
  .modal-header .new-input {
    width: 60%;
  }
}
@media screen and (max-width: 500px) {
  .btns {
    display: flex;
    flex-direction: column;
  }
  .btn.btn-excel {
    margin-bottom: 0.5em;
  }
}
</style>
