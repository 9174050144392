<template>
  <div class="input-modal-wrapper">
    <div class="input-modal">
      <form>
        <!-- INPUT -->
        <div v-if="isComingFromCompany">
          <label for="editInputField" class="input-label"></label>
          <div class="input-wrapper last-item">
            <span class="tms">Z</span>
            <input
              id="editInputField"
              type="text"
              :placeholder="$t('new-value')"
              v-model="input"
            />
          </div>
        </div>

        <div class="inputs" :class="{ hide: this.isComingFromCompany }">
          <!-- INPUT MATERIAL  -->
          <div class="input-and-label-wrapper" v-if="isComingFromMaterials">
            <label class="input-label" for="inputMaterialField">{{
              $t("material")
            }}</label>
            <div class="input-wrapper">
              <span class="tms">U</span>
              <input
                ref="nameInput"
                id="inputMaterialField"
                type="text"
                :placeholder="$t('material')"
                v-model="inputName"
              />
            </div>
          </div>

          <!-- INPUT ACTIVITY  -->
          <div class="input-and-label-wrapper" v-if="isComingFromActivities">
            <label class="input-label" for="inputActivityField">{{
              $t("activity")
            }}</label>
            <div class="input-wrapper">
              <span class="tms">U</span>
              <input
                ref="nameInput"
                id="inputActivityField"
                type="text"
                :placeholder="$t('activity')"
                v-model="inputName"
              />
            </div>
          </div>

          <!-- INPUT MECHANIZATION  -->
          <div
            class="input-and-label-wrapper"
            v-if="isComingFromMechanizations"
          >
            <label class="input-label" for="inputActivityField">{{
              $t("mechanization")
            }}</label>
            <div class="input-wrapper">
              <span class="tms">U</span>
              <input
                ref="nameInput"
                id="inputMechanizationField"
                type="text"
                :placeholder="$t('mechanization')"
                v-model="inputName"
              />
            </div>
          </div>

          <!-- COMMON INPUTS -->
          <div
            class="input-and-label-wrapper"
            v-if="
              isComingFromMaterials ||
              isComingFromActivities ||
              isComingFromMechanizations
            "
          >
            <label class="input-label" for="inputActivityField"
              >{{ $t("metric") }} {{ $t("unit") }}</label
            >
            <div class="input-wrapper">
              <span class="tms">Z</span>
              <select id="inputUnitField" class="select" v-model="inputUnit">
                <option
                  v-for="(u, i) in $t('units')"
                  :key="`unit-${i}`"
                  :value="u"
                >
                  {{ u }}
                </option>
              </select>
            </div>
          </div>

          <!-- <div
            class="input-and-label-wrapper"
            v-if="isComingFromMaterials || isComingFromActivities || isComingFromMechanizations"
          >
            <label class="input-label" for="inputActivityField"> {{ $t('price') }} </label>
            <div class="input-wrapper">
              <span class="tms">Z</span>
              <input
                id="inputPriceField"
                type="number"
                min="0"
                :placeholder="$t('price')"
                v-model="inputPrice"
              >
            </div>
          </div> -->
        </div>

        <div class="input-modal-actions">
          <div>
            <!-- JUST AND EMPTY DIV FOR THE SPACE-BETWEEN RULE TO WORK -->
          </div>
          <div class="btns">
            <!-- BUTTON ACCEPT FOR COMPANY/PROJECT/OBJECT/PART-->
            <button
              v-if="isComingFromCompany"
              class="btn btn-new"
              type="submit"
              value="Submit"
              @click.prevent="save()"
            >
              <span class="tms">c</span>
              {{ $t("edit") }}
            </button>
            <!-- BUTTON ACCEPT FOR MATERIAL/ACTIVITY-->
            <button
              v-if="
                isComingFromActivities ||
                isComingFromMaterials ||
                isComingFromMechanizations
              "
              class="btn btn-new"
              type="submit"
              value="Submit"
              @click.prevent="saveMaterialActivityOrMechanization()"
            >
              <span class="tms">Y</span>
              {{ $t("save") }}
            </button>
            <button class="btn btn-close" @click.prevent="close()">
              <span class="tms">F</span>
              {{ $t("cancel") }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "inputForEdit",
    "isComingFromActivities",
    "isComingFromMaterials",
    "isComingFromMechanizations",
    "isComingFromCompany",
  ],
  data() {
    return {
      input: "",
      inputName: undefined,
      inputUnit: this.$t("unit-default"),
      inputPrice: 0,
    };
  },
  methods: {
    // SAVE METHODS
    save() {
      if (!this.input) {
        this.$snotify.error(this.$t("no-quantity"));
      } else {
        this.$parent.$emit("saveEditInputModal", {
          value: this.input,
          where: this.inputForEdit.where,
        });
        // this.$snotify.success("Успешно редактиране!");
      }
    },
    saveMaterialActivityOrMechanization() {
      if (this.inputValidatation()) {
        // Check for materials modal
        if (this.isComingFromMaterials) {
          this.$parent.$emit("saveMaterialActivityOrMechanization", {
            name: this.inputName,
            unit: this.inputUnit,
            isAdditional: true,
            price: this.inputPrice,
          });
          this.close();
        }
        // Check for activities modal
        else if (this.isComingFromActivities) {
          this.$parent.$emit("saveMaterialActivityOrMechanization", {
            name: this.inputName,
            unit: this.inputUnit,
            isAdditional: true,
            price: this.inputPrice,
          });
          this.close();
        }
        // Check for mechanizations modal
        else if (this.isComingFromMechanizations) {
          this.$parent.$emit("saveMaterialActivityOrMechanization", {
            name: this.inputName,
            unit: this.inputUnit,
            price: this.inputPrice,
          });
          this.close();
        }
      }
    },
    // VALIDATE INPUTS
    inputValidatation() {
      if (!this.inputName) {
        let output = this.$t("material").toLowerCase();

        if (this.isComingFromActivities) {
          output = this.$t("activity").toLowerCase();
        } else if (this.isComingFromMechanizations) {
          output = this.$t("mechanization").toLowerCase();
        }

        this.$snotify.error(`${this.$t("enter-name-for")} ${output}`);
        return false;
      }
      if (!this.inputUnit) {
        this.$snotify.error(this.$t("enter-unit"));
        return false;
      }
      if (this.inputPrice === undefined) {
        this.$snotify.error(this.$t("enter-price"));
        return false;
      }
      if (this.inputName && this.inputUnit && this.inputPrice !== undefined) {
        return true;
      }
    },
    close() {
      this.$parent.$emit("closeEditInputModal");
    },
  },
  mounted() {
    if (this.isComingFromCompany) {
      this.input = this.inputForEdit.value;
    } else {
      let tryFocus = true;
      while (tryFocus) {
        const input = this.$refs.nameInput;
        if (input) {
          input.focus();
          tryFocus = false;
        }
      }
    }
    this.$eventBus.$on("closeModalFromSpeechRecognition", () => {
      this.close();
    });
  },
  destroyed() {
    this.$eventBus.$off("closeModalFromSpeechRecognition");
  },
};
</script>

<style scoped>
.input-modal-wrapper {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}
.input-modal {
  background-color: #fff;
  border-radius: 4px;
  border-top: 10px solid #3377b1;
  display: flex;
  flex-direction: column;
  /* height: 60vh; */
  overflow: auto;
  padding: 10px 20px 0;
  width: 600px;
}
.input-modal-permissions {
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
}
.input-modal-actions {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}
.invalid .tms {
  color: #d91313;
}
.invalid input {
  background-color: #f8dcdc;
  color: #d91313;
}
.invalid input::placeholder {
  color: #d91313;
}
.invalid input::placeholder {
  color: #d91313;
}
.invalid input::placeholder {
  color: #d91313;
}
.invalid input::placeholder {
  color: #d91313;
}
.inputs {
  display: flex;
  margin-top: 20px;
}
.input-and-label-wrapper {
  display: flex;
  flex-direction: column;
  margin-right: 1em;
  margin-bottom: 1em;
  width: 100%;
}
.input-and-label-wrapper:last-child {
  margin-right: 0;
}
.input-label {
  font-size: 11px;
}

.input-wrapper {
  width: 100%;
}
.last-item {
  margin-right: 0px;
  /* margin-bottom: 0px; */
}
.hide {
  display: none;
}

/* Responsive */
@media screen and (max-width: 1000px) {
  .btn.btn-close {
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 650px) {
  .input-modal {
    width: 90%;
  }
}
@media screen and (max-width: 450px) {
  .inputs {
    flex-direction: column;
  }
  .input-wrapper {
    margin-right: 0px;
  }
  .btns {
    display: flex;
    flex-direction: column;
  }
  .btn.btn-close {
    margin-bottom: 10px;
  }
}
</style>
