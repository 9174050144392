import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    status: '',
    token: localStorage.getItem('token') || '',
    user: JSON.parse(localStorage.getItem('user')) || '',
    isLoading: false
  },
  mutations: {
    loading(state) {
      state.isLoading = true;
    },
    loaded(state) {
      state.isLoading = false;
    },
    auth_request(state) {
      state.status = 'loading'
    },
    auth_success(state, payload) {
      state.status = 'success'
      state.token = payload.token
      state.user = payload.user
    },
    auth_error(state) {
      state.status = 'error'
    },
    logout(state) {
      state.status = ''
      state.token = ''
    },
  },
  actions: {
    login({
      commit
    }, user) {
      return new Promise((resolve, reject) => {
        commit('auth_request')
        axios({
            url: `${process.env.VUE_APP_BASE_URI}/auth/login`,
            data: user,
            method: 'POST'
          })
          .then(resp => {
            const token = resp.data.token
            const user = resp.data.user
            // set user and token in local storage
            localStorage.setItem('token', token)
            localStorage.setItem('user', JSON.stringify(user))

            axios.defaults.headers.common['Authorization'] = token
            commit('auth_success', {
              token: token,
              user: user
            })
            resolve(resp)
          })
          .catch(err => {
            commit('auth_error')
            localStorage.removeItem('token')
            reject(err)
          })
      })
    },
    logout({
      commit
    }) {
      return new Promise((resolve) => {
        commit('logout')
        localStorage.removeItem('token')
        localStorage.removeItem('user')
        delete axios.defaults.headers.common['Authorization']
        resolve()
      })
    },
    checkAuth(context) {
      return new Promise((res) => {
        const token = localStorage.getItem('token')
        if (token) {
          axios.defaults.headers.common['Authorization'] = token
          axios({
              url: `${process.env.VUE_APP_BASE_URI}/auth/validate`,
              data: {
                token: token
              },
              method: 'POST'
            })
            .then((response) => {
              if (response.data.user) {
                context.commit('auth_success', {
                  token: response.data.token,
                  user: response.data.user
                })
              } else {
                context.commit('logout');
              }
              res()
            })
            .catch(() => {
              context.commit('logout');
              res()
            });
        } else {
          context.commit('logout');
          res()
        }
      })
    },
    startLoading({
      commit
    }) {
      commit('loading');
    },
    stopLoading({
      commit
    }) {
      commit('loaded');
    }
  },
  getters: {
    isLoggedIn: state => {
      if (state.token) {
        return true
      }
      return false
    },
    getUser: state => state.user,
    getLoading: state => state.isLoading
  }
})