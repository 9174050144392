<template>
  <div class="modal-wrapper">
    <div class="modal">
      <div class="modal-header">
        <span class="tms modal-close" @click="close()">F</span>
      </div>
      <div class="modal-content">
        <!-- IMPORT TEMPLATE -->
        <div class="modal-content-template" @click="triggerFileInput()">
          <img class="modal-content-template-img desktop" :src="getImg(false)" :alt="$t('table-structure')" />
          <img class="modal-content-template-img mobile" :src="getImg(true)" :alt="$t('table-structure')" />
        </div>
      </div>
      <input ref="uploadBudget" class="input-hide" type="file" @change="handleFile" />
    </div>
  </div>
</template>

<script>
import XLSX from 'xlsx';
export default {
  data() {
    return {
      totalBudget: 0
    };
  },
  methods: {
    getImg(mobile) {
      return `${process.env.VUE_APP_STATIC_URI}/table_help${mobile ? '_mobile' : ''
        }_${this.$i18n.locale}.png`;
    },
    triggerFileInput() {
      this.$refs.uploadBudget.click();
    },
    // EXCEL FILE TO JSON
    handleFile(e) {
      let rABS = true; // true: readAsBinaryString ; false: readAsArrayBuffer
      const files = e.target.files;
      const f = files[0];
      if (f.name.match(`.(xls|XLS|xlsx|XLSX)$`)) {
        const reader = new FileReader();
        reader.onload = e => {
          let data = e.target.result;
          if (!rABS) data = new Uint8Array(data);
          const workbook = XLSX.read(data, { type: rABS ? 'binary' : 'array' });

          /* DO SOMETHING WITH workbook HERE */
          const rows = this.workbookToJSON(workbook);
          // SHEET
          for (let row in rows) {
            const keys = Object.keys(rows[row][0]);
            let valid = false
            for (let i = 0; i < keys.length; i++) {
              if (i === 4 && keys[i].toLowerCase() === this.$t('table-value').toLowerCase()) {
                valid = true
              }
            }
            if (!valid) {
              this.$snotify.error(this.$t('table-budget-warning'));
              return;
            }
            // ROW OBJECT
            this.totalBudget = 0
            for (let item in rows[row]) {
              // ROW DATA
              let num = rows[row][item][Object.keys(rows[row][item])[4]]
              if (!isNaN(num)) this.totalBudget += rows[row][item][Object.keys(rows[row][item])[4]]

            }
            this.save()
          }
        };
        if (rABS) reader.readAsBinaryString(f);
        else reader.readAsArrayBuffer(f);
      } else {
        this.$snotify.error(this.$t('table-warning-xls'));
      }
    },
    workbookToJSON(workbook) {
      var result = {};
      workbook.SheetNames.forEach(sheetName => {
        var roa = XLSX.utils.sheet_to_row_object_array(
          workbook.Sheets[sheetName]
        );
        if (roa.length > 0) {
          result[sheetName] = roa;
        }
      });
      return result;
    },
    close() {
      this.$parent.$emit('closeAddBudgetModal');
    },
    save() {
      this.$parent.$emit('saveAddBudgetModal', this.totalBudget);
      this.$snotify.success(this.$t('successfully-added-new-budget'));
    },
  },
  mounted() {
    this.$eventBus.$on('closeModalFromSpeechRecognition', () => {
      this.close();
    });
  },
  destroyed() {
    this.$off('saveMaterialActivityOrMechanization');
    this.$eventBus.$off('closeModalFromSpeechRecognition');
  }
};
</script>

<style scoped>
.model-wrapper {
  justify-content: center;
}

/* .modal-header {
  background: #17643a;
} */
.desktop {
  display: initial;
}

.mobile {
  display: none;
}

/* Responsive */
@media screen and (max-width: 1000px) {
  .modal {
    overflow: unset;
    width: 90%;
  }

  .modal-content-item {
    height: 55px;
    width: 100%;
  }

  .modal-content-check {
    margin-bottom: 5px;
    width: 100%;
  }
}

@media screen and (max-width: 700px) {
  .modal-footer {
    height: 98px;
    flex-direction: column;
  }

  .btn.btn-new {
    margin-bottom: 0.8em;
  }

  .btns-select {
    display: flex;
    justify-content: center;
    margin-top: 1em;
  }

  .btn-select {
    padding: 0 10px;
  }
}

@media screen and (max-width: 600px) {
  .desktop {
    display: none;
  }

  .mobile {
    display: initial;
  }

  .modal-header .new-input {
    width: 60%;
  }
}

@media screen and (max-width: 500px) {
  .btns {
    display: flex;
    flex-direction: column;
  }

  .btn.btn-excel {
    margin-bottom: 0.5em;
  }
}</style>
