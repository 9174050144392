<template>
  <div class="login">
    <div class="login-container">
      <img class="login-logo" src="../assets/img/login/logo.svg" alt="TMS" />
      <form class="login-form" @submit.prevent="login">
        <div class="login-input">
          <span class="tms">B</span>
          <input
            v-model="user.username"
            type="text"
            :placeholder="$t('username')"
            @keyup="getUsernames()"
            autocomplete="off"
          />
          <div
            class="usernames-wrapper"
            v-if="usernames.length && !isUsernameChosen"
          >
            <div
              v-for="(u, index) in usernames"
              :key="index"
              class="username"
              @click="selectUsername(u.username)"
            >
              {{ u.username }}
            </div>
          </div>
        </div>
        <div class="login-input">
          <span class="tms">A</span>
          <input
            v-model="user.password"
            type="password"
            :placeholder="$t('password')"
          />
        </div>
        <button class="login-submit" type="submit">{{ $t("enter") }}</button>
        <div
          v-if="error"
          class="login-error"
          :class="{ 'login-error-active': error }"
        >
          <p>{{ error }}</p>
        </div>
        <div class="language-toggle">
          <span @click="changeLang()">
            {{ $i18n.locale === "bg" ? "English" : "Български" }}
          </span>
        </div>
        <a class="cdots" href="https://cdots.bg/" target="_blank"
          >SOLUTION DELIVERED BY CDOTS LTD.</a
        >
      </form>
    </div>
  </div>
</template>

<script>
export default {
  name: "Login",
  data() {
    return {
      usernames: [],
      isUsernameChosen: false,
      user: {
        username: "",
        password: "",
      },
      error: "",
    };
  },
  watch: {
    "user.username"() {
      if (this.usernames.length === 1) {
        if (this.usernames[0].username === this.user.username) {
          this.isUsernameChosen = true;
        } else {
          this.isUsernameChosen = false;
        }
      }
    },
  },
  methods: {
    getUsernames() {
      if (this.user.username) {
        let url = `${process.env.VUE_APP_BASE_URI}/auth/users/usernames/${this.user.username}`;
        this.$http
          .get(url)
          .then((response) => {
            this.usernames = response.data;
          })
          .catch((error) => console.log(error));
      } else {
        this.usernames = [];
      }
    },
    selectUsername(username) {
      this.user.username = username;
    },
    changeLang() {
      if (this.$i18n.locale === "bg") {
        this.$i18n.locale = "en";
      } else {
        this.$i18n.locale = "bg";
      }
      localStorage.setItem("lang", this.$i18n.locale);
    },
    login() {
      const username = this.user.username.toLowerCase();
      const password = this.user.password;
      if (username.length && password.length) {
        this.$store
          .dispatch("login", { username, password })
          .then(() =>
            this.$router.push(
              this.$route.query.from ? this.$route.query.from : "/"
            )
          )
          .catch((err) => {
            this.error = this.$t(err.response.data.failed);
          });
      } else {
        this.error = this.$t("enter-user-or-pass");
      }
    },
  },
};
</script>

<style scoped>
.login {
  background-image: url("../assets/img/login/login_bg.jpg");
  background-position: right;
  background-size: contain;
  height: 100vh;
  width: 100%;
}
.login-container {
  align-items: center;
  background-image: url("../assets/img/login/login_container.jpg");
  background-position: right;
  background-size: cover;
  border-left: 15px solid #3377b1;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100vh;
  position: relative;
  width: 350px;
}
.login-logo {
  height: auto;
  width: 125px;
}
.login-form {
  display: flex;
  flex-direction: column;
  width: 300px;
}
.login-error {
  align-items: center;
  background-color: #f2dede;
  border-radius: 4px;
  display: flex;
  height: 50px;
  justify-content: center;
  margin-bottom: 10px;
  padding: 5px 15px;
  opacity: 0;
}
.login-error p {
  color: #a94442;
  text-align: center;
}
.login-error-active {
  opacity: 1;
}
.login-input {
  align-items: center;
  display: flex;
  margin-bottom: 15px;
  position: relative;
}
.login-input .tms {
  color: #fff;
  font-size: 20px;
  margin-right: 10px;
}
.login-input input {
  background-color: rgba(0, 0, 0, 0);
  border: 0;
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  color: #fff;
  font-size: 14px;
}
.login-input input::placeholder {
  color: #fff;
}
.login-input input:-moz-placeholder {
  color: #fff;
}
.login-input input::-moz-placeholder {
  color: #fff;
}
.login-input input:-ms-input-placeholder {
  color: #fff;
}
.login-input input::-webkit-input-placeholder {
  color: #fff;
}
.usernames-wrapper {
  border-radius: 4px;
  max-height: 15vh;
  overflow: auto;
  position: absolute;
  top: -10px;
  transform: translateY(-100%);
  width: 100%;
}
.usernames-wrapper div {
  background-color: rgba(0, 0, 0, 0.1);
  color: #fff;
  cursor: pointer;
  margin: 1px 0;
  padding: 10px;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -ms-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.usernames-wrapper div:hover {
  background-color: rgba(0, 0, 0, 0.2);
}
.login-submit {
  background-color: #9c9c9c;
  border: none;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  font-size: 14px;
  height: 50px;
  margin: 15px 0;
  padding: 5px 15px;
  text-transform: uppercase;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -ms-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.login-submit:hover {
  background-color: #b8b8b8;
}
.language-toggle {
  text-align: center;
}
.language-toggle span {
  color: #fff;
  cursor: pointer;
  text-decoration: underline;
}
.language-toggle span:hover {
  opacity: 0.8;
}
.cdots {
  bottom: 20px;
  color: #989898;
  display: block;
  font-size: 10px;
  left: 50%;
  margin-top: 15px;
  position: absolute;
  text-align: center;
  text-decoration: none;
  transform: translateX(-50%);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.cdots:hover {
  color: #b8b8b8;
}

@media screen and (max-width: 1000px) {
  .login-container {
    border-left: 0px;
    border-top: 10px solid #3377b1;
  }
}
@media screen and (max-width: 600px) {
  .login-container {
    width: 100%;
  }
}
@media screen and (max-width: 400px) {
  .login-form {
    width: 280px;
  }
}
</style>
