<template>
  <div class="unsaved-changes-modal-wrapper">
    <div class="unsaved-changes-modal">
      <div class="unsaved-changes-header">
        <h6>{{ $t('unsaved-changes') }}</h6>
      </div>
      <h6 class="you-want">{{ $t('want-to-save') }}</h6>
      <div class="unsaved-changes-modal-actions">
        <div class="btns">
          <button class="btn btn-new" @click.prevent="confirm()">
            <span class="tms">Y</span>
            {{ $t('yes') }}
          </button>
          <button class="btn btn-close" @click.prevent="decline()">
            <span class="tms">F</span>
            {{ $t('no') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    decline() {
      this.$parent.$emit("closeUnsavedChangesModal", false);
    },
    confirm() {
      this.$parent.$emit("closeUnsavedChangesModal", true);
    }
  }
};
</script>

<style scoped>
.unsaved-changes-modal-wrapper {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}
.unsaved-changes-modal {
  background-color: #fff;
  border-radius: 4px;
  /* border-top: 10px solid #083f75; */
  display: flex;
  flex-direction: column;
  /* height: 60vh; */
  overflow: auto;
  width: 600px;
}
.unsaved-changes-modal-actions {
  display: flex;
  justify-content: center;
  padding: 0 20px 20px;
}
.unsaved-changes-header {
  align-items: center;
  background-color: #3377b1;
  display: flex;
  height: 60px;
  justify-content: center;
  padding: 20px;
}
.unsaved-changes-header h6 {
  color: #fff;
  font-weight: 400;
  font-size: 20px;
  text-align: center;
}
.you-want {
  font-weight: 400;
  font-size: 20px;
  padding: 50px 20px;
  text-align: center;
  width: 100%;
}
.unsaved-changes-modal .btn:first-child {
  margin-left: 0;
}
@media screen and (max-width: 600px) {
  .unsaved-changes-modal {
    width: calc(100% - 30px);
  }
  .unsaved-changes-header h6,
  .you-want {
    font-size: 16px;
  }
}
@media screen and (max-width: 400px) {
  .unsaved-changes-modal .btns {
    flex-direction: column;
  }
  .unsaved-changes-modal .btn {
    margin: 0;
  }
  .unsaved-changes-modal .btn:first-child {
    margin-bottom: 10px;
  }
}
</style>
