<template>
  <div class="modal-wrapper">
    <div class="modal">
      <div class="modal-header">
        <div class="new-input">
          <span class="tms">N</span>
          <input
            type="text"
            :placeholder="$t('search')"
            v-model="searchUsers"
          />
        </div>
        <span class="tms modal-close" @click="close()">F</span>
      </div>
      <div class="modal-content">
        <div
          class="modal-content-check"
          :class="{ 'modal-content-check-disabled': u.isDisabled }"
          v-for="u in filteredUsers"
          :key="`addUsersModal-${u._id}`"
          :title="
            selectedUsersType === 'groups' ? u.users.map((x) => x.name) : ''
          "
        >
          <label class="checkbox-wrapper">
            {{ u.name }}
            <input
              type="checkbox"
              :id="u._id"
              v-model="u.isSelected"
              :disabled="u.isDisabled"
              @change="
                isCommingFromReport
                  ? u.useIncomingWage
                    ? (u.useIncomingWage = false)
                    : null
                  : null,
                  setSelectedUsersOrGroups(u)
              "
            />
            <span class="checkmark"></span>
          </label>
        </div>
        <!-- NO RESULTS -->
        <p
          class="modal-content-no-results"
          v-if="(groups.length || users.length) && !filteredUsers.length"
        >
          {{ $t("no-results-for") }}
          <strong>{{ this.searchUsers }}</strong
          >'
        </p>
      </div>
      <div class="modal-footer">
        <div class="select-btns" v-if="usersType !== 'noPermissions'">
          <template v-if="!isCommingFromCompany">
          <button
            class="btn btn-select"
            :class="{ 'btn--selected': selectedUsersType === 'employees' }"
            @click="getUsers('employees')"
          >
            <span class="tms">W</span>
            {{ $t("employees") }}
          </button>
            <button
              class="btn btn-select"
              :class="{ 'btn--selected': selectedUsersType === 'groups' }"
              @click="getUsers('groups')"
            >
              <span class="tms">W</span>
              {{ $t("users-groups") }}
            </button>
            <button
              class="btn btn-select"
              :class="{ 'btn--selected': selectedUsersType === 'workers' }"
              @click="getUsers('workers')"
            >
              <span class="tms">W</span>
              {{ $t("Workers") }}
            </button>
          </template>
          <!-- <button class="btn btn-select" @click="toggleUsers(true)">
            <span class="tms">W</span>
            {{ $t("all") }}
          </button>
          <button class="btn btn-select" @click="toggleUsers(false)">
            <span class="tms">X</span>
            {{ $t("none") }}
          </button> -->
        </div>
        <div v-else></div>
        <button class="btn btn-new" @click="save()">
          <span class="tms">Y</span>
          {{ $t("save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: [
    "usersType",
    "incomingUsers",
    "date",
    "isCommingFromReport",
    "isCommingFromCompany",
  ],
  data() {
    return {
      users: [],
      groups: [],
      workers: [],
      searchUsers: "",
      selectedUsersType: "reporters",
    };
  },
  computed: {
    filteredUsers() {
      return this.filterUsers();
    },
  },
  methods: {
    getAllUsers() {
       let url = `${process.env.VUE_APP_BASE_URI}/auth/users`; // gets all users except isWorkers permision

       if (this.isCommingFromCompany) url += "/reporters"
       else url += '?noWorkers=true'

      this.$http.get(url).then((response) => {
        this.users = response.data;
        this.setSelectedProps(this.users)
        // if (this.date) {
        //   this.getAbsentWorkers();
        // }
      });
    },
    getAllGropus() {
      this.$http
        .get(`${process.env.VUE_APP_BASE_URI}/group?active=true&notEmpty=true`)
        .then((response) => {
          this.groups = response.data;
          this.groups.map((g) => {
            this.$http
              .get(`${process.env.VUE_APP_BASE_URI}/group/users/${g._id}`)
              .then((res) => {
                this.$set(g, "users", res.data);

                if (this.incomingUsers) {
                  let count = 0;
                  this.incomingUsers.forEach((x) => {
                    res.data.forEach((r) => {
                      if (x._id === r._id) count++;
                    });
                  });

                  if (res.data.length === count)
                    this.$set(g, "isSelected", true);
                }
              });
          });
        });
    },
    getAllWorkers() {
      this.$http
        .get(`${process.env.VUE_APP_BASE_URI}/auth/users/workers`)
        .then((response) => {
          this.workers = response.data;
          this.setSelectedProps(this.workers)
        });
    },
    setSelectedProps(array) {
      if (this.incomingUsers) {
        this.incomingUsers.forEach((iu) => {
          array.forEach((u) => {
            if (u._id === iu._id) {
              u.isSelected = true;

              if (iu.startHour) {
                u.startHour = iu.startHour;
              }
              if (iu.startHourDate) {
                u.startHourDate = iu.startHourDate;
              }
              if (iu.endHour) {
                u.endHour = iu.endHour;
              }
              if (iu.endHourDate) {
                u.endHourDate = iu.endHourDate;
              }
              if (iu.new) {
                u.new = iu.new;
              }
              if (iu.isFromGroup) {
                u.isFromGroup = iu.isFromGroup;
                console.log(u);
              }
              if (iu.elsewhereConfirmed) {
                u.elsewhereConfirmed = iu.elsewhereConfirmed;
              }

              // when manualy change the isSelected state this should become false and his current wage should be assigned to the user
              if (this.isCommingFromReport) {
                u.useIncomingWage = true;
                u.incomingWage = iu.wage;
              }
            }
          });
        });
      }
    },
    getAbsentWorkers() {
      this.$http
        .get(
          `${
            process.env.VUE_APP_BASE_URI
          }/otpusk/absent?date=${this.date.toISOString()}`
        )
        .then((response) => {
          const absent = response.data;
          absent.forEach((a) => {
            const i = this.users.map((u) => u._id).indexOf(a.worker);
            this.users[i].isDisabled = true;
            this.$set(this.users, i, this.users[i]);
          });
        });
    },
    filterUsers() {
      let array = this.users;
      if (this.selectedUsersType === "groups") array = this.groups;
      if (this.selectedUsersType === "workers") array = this.workers;
      return array.filter((u) => {
        return u.name.toLowerCase().includes(this.searchUsers.toLowerCase());
      });
    },
    getUsers(type) {
      this.selectedUsersType = type;
    },
    // toggleUsers(toggle) {
    //   // If searching select only results
    //   if (this.searchUsers.length) {
    //     const tempFilteredUsers = this.filterUsers();
    //     this.users.forEach((u, index) => {
    //       tempFilteredUsers.forEach((fu) => {
    //         if (fu._id === u._id) {
    //           if (!u.isDisabled) {
    //             let user = u;
    //             user.isSelected = toggle;
    //             if (this.isCommingFromReport) {
    //               user.useIncomingWage ? (user.useIncomingWage = false) : null;
    //             }
    //             this.$set(this.users, index, user);
    //           }
    //         }
    //       });
    //     });
    //   }
    //   // Otherwise select/deselect all
    //   else {
    //     this.users.forEach((u, index) => {
    //       if (!u.isDisabled) {
    //         let user = u;
    //         user.isSelected = toggle;
    //         if (this.isCommingFromReport) {
    //           user.useIncomingWage ? (user.useIncomingWage = false) : null;
    //         }
    //         this.$set(this.users, index, user);
    //       }
    //     });
    //   }
    // },
    setSelectedUsersOrGroups(v) {
    if (this.selectedUsersType === "groups") {
        // selects/deselects all users in group
        if (v.users && v.users.length) {
          for (let x of v.users) {
            for (let u of this.users) {
              if (x._id.toString() === u._id.toString()) {
                u.isSelected = v.isSelected, u.isFromGroup = true;
              }
              if (u.isDisabled) u.isSelected = false;
            }
          }
        }
      } else if (this.selectedUsersType === "reporters") {
        if (v.group) {
          let userGroups = this.groups.filter((g) => g._id === v.group._id);
          for (let g of userGroups) {
            let count = 0;

            this.users.forEach((u) => {
              g.users.forEach((gu) => {
                if (gu._id === u._id && u.isSelected) count++;
              });
            });

            if (count === g.users.length) g.isSelected = true;
            else g.isSelected = false;
          }
        }
      }
       if (v.isDisabled) v.isSelected = false;
      // if (this.selectedUsersType === "groups") {
      //   let selected = [];

      //   this.groups.forEach((g) => {
      //     if (g.isSelected) {
      //       for (let u of g.users) {
      //         selected.push(u._id);
      //       }
      //     }
      //   });
      //   this.users.map((u) => {
      //     if (selected.includes(u._id)) u.isSelected = true;
      //     else u.isSelected = false;
      //   });
      // } 
    },
    getSelectedUsers() {
      let selectedUsers = this.users.filter((u) => {
        if (u.isSelected && u.useIncomingWage && this.isCommingFromReport) {
          u.wage = u.incomingWage;
        }
        return u.isSelected;
      });
      let selectedWorkers = this.workers.filter((u) => {
        if (u.isSelected && u.useIncomingWage && this.isCommingFromReport) {
          u.wage = u.incomingWage;
        }
        return u.isSelected;
      });

      return [...selectedUsers.concat(selectedWorkers)];
    },
    close() {
      this.$parent.$emit("closeAddUsersModal");
    },
    save() {
      this.$parent.$emit("saveAddUsersModal", this.getSelectedUsers());
    },
  },
  mounted() {
    this.getAllUsers();
    this.getAllGropus();
    this.getAllWorkers();
    this.$eventBus.$on("closeModalFromSpeechRecognition", () => {
      this.close();
    });
  },
  destroyed() {
    this.$eventBus.$off("closeModalFromSpeechRecognition");
  },
};
</script>

<style scoped>
/* Responsive */
.modal-content-check-disabled {
  background-color: #f3eae6;
  opacity: 0.5;
}

.btn--selected {
  background: rgb(54, 53, 53);
  color: white;
}

@media screen and (max-width: 1000px) {
  .modal {
    max-height: 80vh;
    width: 90%;
  }
  .modal-header .new-input {
    width: 72%;
  }
}
@media screen and (max-width: 600px) {
  .modal-content-check {
    width: 45%;
  }
  .modal-footer {
    flex-direction: column;
    height: 97px;
  }
  .select-btns {
    display: flex;
    margin: 10px 0;
    overflow: auto;
    width: 100%;
    white-space: nowrap;
  }
  .btn.btn-new {
    margin: 0 0 10px 0;
  }
}
@media screen and (max-width: 400px) {
  .modal-content-check {
    width: 100%;
  }
}
</style>
