<template>
  <div class="users-companies-wrapper">
    <!-- HEADER WITH SWITCH AND NEW USER BUTTON -->
    <header>
      <div class="user-types">
        <span
          class="user-type"
          :class="{ 'user-type-active': userType === '' }"
          @click="userType = ''"
          >{{ $t("users-all") }}</span
        >
        <span
          class="user-type"
          :class="{ 'user-type-active': userType === 'isReporter' }"
          @click="userType = 'isReporter'"
          >{{ $t("users-reporters") }}</span
        >
        <!-- <span
          class="user-type"
          :class="{ 'user-type-active': userType === 'isReporter'}"
          @click="userType = 'isReporter'"
        >{{ $t('users-reporters') }}</span> -->
        <!-- <span
          class="user-type"
          :class="{ 'user-type-active': userType === 'isTechLead'}"
          @click="userType = 'isTechLead'"
        >{{ $t('users-tech-lead') }}</span> -->
        <!-- <span
          class="user-type"
          :class="{ 'user-type-active': userType === 'isHR'}"
          @click="userType = 'isHR'"
        >{{ $t('users-hr') }}</span> -->
        <!-- <span
          class="user-type"
          :class="{ 'user-type-active': userType === 'allAdmins'}"
          @click="userType = 'allAdmins'"
        >{{ $t('users-admins') }}</span> -->
        <span
          class="user-type"
          :class="{ 'user-type-active': userType === 'groups' }"
          @click="userType = 'groups'"
          >{{ $t("users-groups") }}</span
        >
        <span
          class="user-type"
          :class="{ 'user-type-active': userType === 'isWorker' }"
          @click="userType = 'isWorker'"
          >{{ $t("users-workers") }}</span
        >
        <span
          class="user-type"
          :class="{ 'user-type-active': userType === 'isEmployee' }"
          @click="userType = 'isEmployee'"
          >{{ $t("users-employees") }}</span
        >
      </div>
      <!-- <h2 class="desktop">Потребители</h2> -->
      <!-- ACTIVE/INACTIVE SWITCH -->
      <!-- <div class="switch-wrapper">
        <label
          class="switch-label"
          :class="{ 'switch-label-active': !showUsers }"
          @click="showUsers = false"
        >Подизпълнители</label>
        <div class="switch-container">
          <label class="switch">
            <input type="checkbox" v-model="showUsers" />
            <span class="slider"></span>
          </label>
        </div>
        <label
          class="switch-label"
          :class="{ 'switch-label-active': showUsers }"
          @click="showUsers = true"
        >Потребители</label>
      </div>-->
      <!-- NEW USER BUTTON -->
      <section class="add-new-users-wrapper" v-click-outside="hideIfOpen">
        <button
          class="btn btn-new desktop"
          @click="isAddUsersMenuOpen = !isAddUsersMenuOpen"
          v-if="getUser.permissions.isSuperAdmin || getUser.permissions.isAdmin || getUser.permissions.isReporter"
        >
          <span class="tms">V</span>
          {{ $t("add") }}
        </button>
        <transition name="fade">
          <div class="add-options" v-if="isAddUsersMenuOpen">
            <p
              @click="openUserModal('employee')"
              v-if="
                getUser.permissions.isSuperAdmin || getUser.permissions.isAdmin
              "
            >
              {{ $t("new-worker") }}
            </p>
            <p @click="openUserModal('worker')">
              {{ $t("new-user") }}
            </p>
            <p
              @click="openUserModal()"
              v-if="
                getUser.permissions.isSuperAdmin || getUser.permissions.isAdmin
              "
            >
              {{ $t("new-group") }}
            </p>
          </div>
        </transition>
      </section>
    </header>
    <template v-if="userType !== 'groups'">
      <!-- DESKTOP TABLE WITH USERS (ACTIVE AND INACTIVE) -->
      <div class="table-wrapper desktop">
        <table class="table">
          <tbody>
            <tr
              v-for="user of searchFilter()"
              :key="user._id"
              :class="{ 'inactive-row': !user.isActive }"
              @click="!user.users ? editUser(user) : editGroup(user._id)"
            >
              <td class="td-user-name">
                <span
                  v-if="user.permissions && user.permissions.isWorker"
                  class="tms2"
                  >G</span
                >
                <span v-else-if="user.users" class="tms2">D</span>
                <span v-else class="tms2">H</span>
                <span>
                  <strong>{{ user.name }}</strong>
                </span>
              </td>
              <template v-if="user.permissions">
                <td>
                  <template v-if="!user.permissions.isWorker">{{
                    user.username
                  }}</template>
                  <template v-else></template>
                </td>
                <td>{{ user.email }}</td>
                <td>{{ user.phone }}</td>
                <td>
                  <span
                    v-if="user.permissions.isSuperAdmin"
                    class="permission-indicator superadmin-indicator"
                    >J</span
                  >
                  <span
                    v-if="user.permissions.isAdmin"
                    class="permission-indicator admin-indicator"
                    >{{ $t("users-admin") }}</span
                  >
                  <span
                    v-if="user.permissions.isReporter"
                    class="permission-indicator reporter-indicator"
                    >{{ $t("reporter") }}</span
                  >
                  <span
                    v-if="user.permissions.isEmployee"
                    class="permission-indicator employee-indicator"
                    >{{ $t("employee") }}</span
                  >
                  <span
                    v-if="user.permissions.isWorker"
                    class="permission-indicator worker-indicator"
                    >{{ $t("worker") }}</span
                  >
                  <!-- <span
                v-if="user.permissions.isCEO"
                class="permission-indicator ceo-indicator"
              >{{ $t('users-ceo') }}</span>
              <span
                v-if="user.permissions.isHR"
                class="permission-indicator hr-indicator"
              >{{ $t('users-hr') }}</span> -->
                  <!-- <span
                v-if="user.permissions.isTechLead"
                class="permission-indicator tr-indicator"
              >{{ $t('users-tr') }}</span> -->
                </td>
              </template>
              <template v-else>
                <td></td>
                <td></td>
                <td></td>
                <td class="td-transparent td-widthless">
                  <span class="permission-indicator group-indicator">{{
                    $t("group")
                  }}</span>
                </td>
              </template>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- MOBILE TABLE WITH USERS (ACTIVE AND INACTIVE) -->
      <div class="table-wrapper mobile">
        <table class="table">
          <tbody>
            <tr
              v-for="user of searchFilter()"
              :key="user._id"
              :class="{ 'inactive-row': !showActiveUsers }"
              @click="!user.users ? editUser(user) : editGroup(user._id)"
            >
              <td class="td-user-name">
                <div class="user-mobile">
                  <span>
                    <strong>{{ user.name }}</strong>
                  </span>
                </div>
                <div class="user-permissions" v-if="user.permissions">
                  <span
                    v-if="user.permissions.isSuperAdmin"
                    class="permission-indicator superadmin-indicator"
                    >J</span
                  >
                  <span
                    v-if="user.permissions.isAdmin"
                    class="permission-indicator admin-indicator"
                    >{{ $t("users-admin") }}</span
                  >
                  <span
                    v-if="user.permissions.isReporter"
                    class="permission-indicator reporter-indicator"
                    >{{ $t("reporter") }}</span
                  >
                  <span
                    v-if="user.permissions.isWorker"
                    class="permission-indicator worker-indicator"
                    >{{ $t("is-worker") }}</span
                  >
                  <!-- <span
                  v-if="user.permissions.isCEO"
                  class="permission-indicator ceo-indicator"
                >{{ $t('users-ceo') }}</span>
                <span
                  v-if="user.permissions.isHR"
                  class="permission-indicator hr-indicator"
                >{{ $t('users-hr') }}</span> -->
                  <!-- <span
                  v-if="user.permissions.isTechLead"
                  class="permission-indicator tr-indicator"
                >{{ $t('users-tr') }}</span> -->
                </div>
                <div v-else class="user-permissions">
                  <span class="permission-indicator group-indicator">{{
                    $t("group")
                  }}</span>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- BUTTON NEW USER FOR MOBILE -->

      <!--  <footer class="modal-footer mobile">
      <transition name="slide-right-and-fade" mode="out-in">
        <div
          v-if="!showNewBtns"
          class="new-content-data-item-toggle-circle mobile"
          @click="showNewBtns = true"
        >
          <span class="tms">V</span>
        </div>
        <div v-else>
          <div
            class="new-content-data-item-toggle-circle btn-blue mobile"
            @click="isUserModalOpened = 'employee'"
          >
            <span class="tms">V</span>
          </div>
          <div
            class="new-content-data-item-toggle-circle new-content-data-item-toggle-circle-2 btn-green mobile"
            @click="isUserModalOpened = 'worker'"
          >
            <span class="tms">V</span>
          </div>
        </div>
      </transition> 
    </footer> -->
    </template>
    <template v-if="userType === 'groups'">
      <div class="table-wrapper">
        <table class="table">
          <tbody>
            <tr
              v-for="g of filteredGroups"
              :class="{ 'inactive-row': !g.isActive }"
              :key="g._id"
              @click="editGroup(g._id)"
            >
              <td class="td-user-name">
                <span class="tms2">D</span>
                <span>
                  <strong>{{ g.name }}</strong>
                </span>
              </td>
              <td class="td-transparent td-widthless">
                <span class="permission-indicator group-indicator">{{
                  $t("group")
                }}</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- <footer
        class="modal-footer mobile"
        v-if="getUser.permissions.isSuperAdmin"
      >
        <div
          class="new-content-data-item-toggle-circle mobile"
          @click="isGroupModalOpened = true"
        >
          <span class="tms2">D</span>
        </div>
      </footer> -->
    </template>
    <footer class="modal-footer mobile">
      <div
        class="new-content-data-item-toggle-circle mobile"
        @click="isAddUsersMenuOpen = !isAddUsersMenuOpen"
      >
        <span class="tms">V</span>
      </div>
    </footer>
    <!-- USER MODAL -->
    <transition name="fade">
      <appUserModal
        v-if="isUserModalOpened"
        :idToEdit="idToEdit"
        :userMode="isUserModalOpened === 'employee' ? true : false"
      />
    </transition>
    <transition name="fade">
      <UserGroupModal
        v-if="isGroupModalOpened"
        :id="groupToEdit"
        @close="closeUserGroupModal"
      />
    </transition>
  </div>
</template>

<script>
import UserModal from "./shared/UserModal";
import UserGroupModal from "./shared/UserGroupModal";
import ClickOutside from "vue-click-outside";
import { mapGetters } from "vuex";
export default {
  components: {
    appUserModal: UserModal,
    UserGroupModal,
  },
  data() {
    return {
      userType: "",
      showUsers: false,
      showNewBtns: false,
      showActiveUsers: true,
      isUserModalOpened: "",
      isGroupModalOpened: false,
      isAddUsersMenuOpen: false,
      idToEdit: "",
      groupToEdit: "",
      users: [],
      groups: [],
      search: "",
    };
  },
  directives: {
    ClickOutside,
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      "getUser",
      // ...
    ]),
    // Filtered by active
    filteredUsers() {
      if (this.userType === "noPermissions") {
        // return this.users
        return this.users.filter((u) => {
          if (
            !u.permissions.isWorker
            // !u.permissions.isSuperAdmin &&
            // !u.permissions.isAdmin &&
            // !u.permissions.isWorker &&
            // !u.permissions.isReporter &&
            // !u.permissions.isHR &&
            // !u.permissions.isCEO
            // &&
            // !u.permissions.isTechLead
          ) {
            return true;
          } else {
            return false;
          }
        });
      }
      if (this.userType === "allAdmins") {
        return this.users.filter((u) => {
          if (
            u.permissions.isSuperAdmin ||
            u.permissions.isAdmin ||
            u.permissions.isCEO
          ) {
            return true;
          } else {
            return false;
          }
        });
      } else if (this.userType.length) {
        return this.users.filter((u) => {
          return u.permissions[this.userType] === true;
        });
      } else {
        let combined = this.users.concat(this.groups);
        return combined;
        // return combined.filter(u => {
        //   // return u.permissions.isWorker === false
        //   return u.permissions? !u.permissions.isSuperAdmin &&
        //     !u.permissions.isAdmin &&
        //     !u.permissions.isReporter &&
        //     !u.permissions.isHR &&
        //     !u.permissions.isCEO  : true;
        // });
      }
    },
    filteredGroups() {
      return this.groups.filter((g) => {
        return g.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
  },
  watch: {
    userType() {
      this.getUsers();
      this.getGroups();
      // if (to === "users") {
      //   this.getUsers();
      // } else if (to === "groups") {
      //   this.getGroups();
      // }
    },
  },
  methods: {
    // Filter users for searching by name
    searchFilter() {
      let filteredusers = this.filteredUsers;
      return filteredusers.filter((u) => {
        return (
          u.name.toLowerCase().includes(this.search.toLowerCase()) ||
          (u.username &&
            u.username.toLowerCase().includes(this.search.toLowerCase()))
        );
      });
    },
    editUser(u) {
      if (
        !this.getUser.permissions.isSuperAdmin &&
        !this.getUser.permissions.isAdmin &&
        !this.getUser.permissions.isHR &&
        !this.getUser.permissions.isCEO
      ) {
        this.$snotify.error(this.$t("users-no-edit-permissions"));
        return;
      }
      if (
        u.permissions.isSuperAdmin &&
        !this.getUser.permissions.isSuperAdmin
      ) {
        this.$snotify.error(this.$t("users-no-permissions"));
        return;
      }
      this.idToEdit = u._id;
      this.isUserModalOpened = u.permissions.isWorker ? "worker" : "employee";
    },
    getUsers() {
      this.$store.dispatch("startLoading");
      this.$http
        .get(`${process.env.VUE_APP_BASE_URI}/auth/users`)
        .then((response) => {
          this.users = response.data;
          this.$store.dispatch("stopLoading");
        });
    },
    getGroups() {
      this.$store.dispatch("startLoading");
      this.$http
        .get(`${process.env.VUE_APP_BASE_URI}/group`)
        .then((response) => {
          this.groups = response.data;
          // this.groups.map((g) => {
          //   this.$http
          //     .get(`${process.env.VUE_APP_BASE_URI}/group/count-users/${g._id}`)
          //     .then((res) => {
          //       this.$set(g, "users", res.data);
          //     });
          // });
          this.$store.dispatch("stopLoading");
        });
    },
    editGroup(g) {
      if (!this.getUser.permissions.isSuperAdmin) {
        this.$snotify.error(this.$t("groups-no-edit-permissions"));
        return;
      }
      this.groupToEdit = g;
      this.isGroupModalOpened = true;
    },
    closeUserGroupModal(update) {
      this.isGroupModalOpened = false;
      this.groupToEdit = "";
      if (update) {
        this.getGroups();
        this.getUsers();
      }
    },
    openUserModal(type) {
      if (type) this.isUserModalOpened = type;
      else this.isGroupModalOpened = true;
      this.isAddUsersMenuOpen = false;
    },
    hideIfOpen() {
      if (this.isAddUsersMenuOpen) this.isAddUsersMenuOpen = false;
    },
  },
  mounted() {
    this.getUsers();
    this.getGroups();

    this.$on("closeUserModal", (result) => {
      this.isUserModalOpened = "";
      this.showNewBtns = false;
      this.idToEdit = "";
      // if result.isEdited = true, this means something is changed and we get the users again
      if (result.isEdited) {
        this.users = [];
        this.getUsers();
      }
    });
  },
  updated() {
    // Handle search input
    this.$root.$on("sendSearchInput", (data) => {
      this.search = data;
      // console.log(this.search);
    });
  },
  destroyed() {
    this.$off("closeUserModal");
    this.$root.$off("sendSearchInput");
  },
};
</script>

<style scoped>
</style>
