<template>
  <div
    class="new-project-container"
    :class="{
      'new-project-container-side-panel': this.$route.name !== 'newCompany',
    }"
  >
    <div class="new-project-header">
      <div class="title-and-excell">
        <h2 class="title" v-if="this.$route.name === 'newCompany'">
          {{ $t("create-new-company") }}
        </h2>
        <div v-else></div>
        <!-- <button class="btn btn-excel" @click="isAddBudgetModalOpened = true" v-if="selectedObject !== undefined && selectedProject !== undefined">
          <span class="tms">E</span>
          {{ $t('new-file') }}
        </button> -->
      </div>
      <!-- <h2 class="title" v-if="this.$route.name === 'newProject'">Създаване на нов проект</h2>
      <h2 class="title" v-if="this.$route.name === 'newObject'">Създаване на нов обект</h2>
      <h2 class="title" v-if="this.$route.name === 'newPart'">Създаване на нова част</h2>-->
      <div class="buttons-wrapper">
        <div class="switch-wrapper" v-if="selectedObject !== undefined">
          <label
            class="switch-label"
            :class="{
              'switch-label-active':
                !company.projects[selectedProject].objects[selectedObject]
                  .isActive,
            }"
            @click="
              company.projects[selectedProject].objects[
                selectedObject
              ].isActive = false
            "
            >{{ $t("inactive-site") }}</label
          >
          <div class="switch-container">
            <label class="switch">
              <input
                type="checkbox"
                v-model="
                  company.projects[selectedProject].objects[selectedObject]
                    .isActive
                "
                @change="companyChanged()"
              />
              <span class="slider"></span>
            </label>
          </div>
          <label
            class="switch-label"
            :class="{
              'switch-label-active':
                company.projects[selectedProject].objects[selectedObject]
                  .isActive,
            }"
            @click="
              company.projects[selectedProject].objects[
                selectedObject
              ].isActive = true
            "
            >{{ $t("active-site") }}</label
          >
        </div>
        <div class="switch-wrapper" v-else>
          <label
            class="switch-label"
            :class="{ 'switch-label-active': !newObject.isActive }"
            @click="newObject.isActive = false"
            >{{ $t("inactive-site") }}</label
          >
          <div class="switch-container">
            <label class="switch">
              <input
                type="checkbox"
                v-model="newObject.isActive"
                @change="companyChanged()"
              />
              <span class="slider"></span>
            </label>
          </div>
          <label
            class="switch-label"
            :class="{ 'switch-label-active': newObject.isActive }"
            @click="newObject.isActive = true"
            >{{ $t("active-site") }}</label
          >
        </div>
        <button class="btn btn-close desktop" @click="goBack">
          <span class="tms">F</span>
          {{ $t("cancel") }}
        </button>
        <button
          class="btn btn-new desktop"
          @click="save()"
          v-if="getUser.permissions.isSuperAdmin || getUser.permissions.isAdmin"
        >
          <span class="tms">Y</span>
          {{ $t("save") }}
        </button>
      </div>
    </div>
    <!-- NEW COMPANY CONTENT -->
    <div
      class="new-wrapper"
      :class="{ 'new-wrapper-inactive': !isObjectActive() }"
    >
      <div
        class="new-content"
        :class="{ 'new-content-side-panel': this.$route.name !== 'newCompany' }"
      >
        <!-- ROW 1 -->
        <div class="new-content-inputs">
          <!-- ИМЕ НА ФИРМА -->
          <div class="new-input-wrapper">
            <label for="newCompany">
              <strong>{{ $t("company") }}*</strong>
            </label>
            <div class="new-input">
              <span class="tms">Z</span>
              <input
                v-if="!company._id"
                type="text"
                :placeholder="$t('company-name')"
                @focus="getCompanies(), openDropdown(0)"
                @blur="closeDropdown(0)"
                v-model="searchCompanies"
                @change="companyChanged()"
                :disabled="
                  company.companyName.length ||
                  this.$route.name === 'newProject' ||
                  this.$route.name === 'newObject' ||
                  this.$route.name === 'newPart' ||
                  this.$route.name === 'getPart'
                "
              />
              <input
                v-else
                type="text"
                :placeholder="$t('company-name')"
                @focus="getCompanies(), openDropdown(0)"
                v-model="company.companyName"
                @blur="closeDropdown(0)"
                :disabled="
                  company.companyName.length ||
                  this.$route.name === 'newProject' ||
                  this.$route.name === 'newObject' ||
                  this.$route.name === 'newPart' ||
                  this.$route.name === 'getPart'
                "
              />
              <span
                v-if="
                  company.companyName.length &&
                  !(
                    this.$route.name === 'newProject' ||
                    this.$route.name === 'newObject' ||
                    this.$route.name === 'newPart' ||
                    this.$route.name === 'getPart'
                  )
                "
                class="clear"
                @click="resetSelectedCompany()"
                >F</span
              >
              <template
                v-if="
                  this.$route.name === 'newProject' ||
                  this.$route.name === 'newObject' ||
                  this.$route.name === 'newPart' ||
                  this.$route.name === 'getPart'
                "
              >
                <span
                  class="edit"
                  @click="toggleEdit(company.companyName, 'company')"
                  v-if="
                    getUser.permissions.isSuperAdmin ||
                    getUser.permissions.isAdmin
                  "
                  >c</span
                >
                <span class="disabled">A</span>
              </template>
            </div>
            <div
              class="new-input-dropdown"
              :class="{ 'new-input-dropdown-active': openedDropdown[0] }"
            >
              <ul v-if="companies.length">
                <li
                  v-for="c in filteredCompanies()"
                  :key="c._id"
                  @click="selectCompany(c._id), companyChanged()"
                >
                  {{ c.companyName }}
                </li>
              </ul>
            </div>
          </div>
          <!-- MOBILE COMPANY -->
          <!-- <div class="new-input mobile">
              <span class="tms">Z</span>
            <select class="select" v-model="company._id" @change="selectCompany(company._id), companyChanged()">
                <option value="" disabled selected>Име на фирма:</option>
                <template v-for="(c,index) in companies">
                  <option v-if="companies.length" :value="c" :key="index">{{c.companyName}}</option>
                </template>
                <option :value="company" selected disabled>{{company.companyName}}</option>
            </select>
            <span v-if="this.$route.name === 'editReport'" class="disabled">A</span>
          </div>-->
          <!-- ИМЕ НА ПРОЕКТ -->
          <div class="new-input-wrapper">
            <label for>
              <strong>{{ $t("project") }}*</strong>
            </label>
            <div class="new-input">
              <span class="tms">Z</span>
              <input
                v-if="selectedProject === undefined"
                type="text"
                :placeholder="$t('project-name')"
                @focus="openDropdown(1)"
                @blur="closeDropdown(1)"
                v-model="searchProjects"
                @change="companyChanged()"
                :disabled="
                  selectedProject !== undefined ||
                  this.$route.name === 'newObject' ||
                  this.$route.name === 'newPart' ||
                  this.$route.name === 'getPart'
                "
              />
              <input
                v-else
                type="text"
                :placeholder="$t('project-name')"
                @focus="openDropdown(1)"
                @blur="closeDropdown(1)"
                v-model="company.projects[selectedProject].projectName"
                :disabled="
                  selectedProject !== undefined ||
                  this.$route.name === 'newObject' ||
                  this.$route.name === 'newPart' ||
                  this.$route.name === 'getPart'
                "
              />
              <span
                v-if="
                  selectedProject !== undefined &&
                  !(
                    this.$route.name === 'newObject' ||
                    this.$route.name === 'newPart' ||
                    this.$route.name === 'getPart'
                  )
                "
                class="clear"
                @click="resetSelectedProject()"
                >F</span
              >
              <template
                v-if="
                  this.$route.name === 'newObject' ||
                  this.$route.name === 'newPart' ||
                  this.$route.name === 'getPart'
                "
              >
                <span
                  class="edit"
                  @click="
                    toggleEdit(
                      company.projects[selectedProject].projectName,
                      'project'
                    )
                  "
                  v-if="
                    getUser.permissions.isSuperAdmin ||
                    getUser.permissions.isAdmin
                  "
                  >c</span
                >
                <span class="disabled">A</span>
              </template>
            </div>
            <div
              class="new-input-dropdown"
              :class="{ 'new-input-dropdown-active': openedDropdown[1] }"
            >
              <ul v-if="company._id">
                <li
                  v-for="(p, index) in filteredProjects()"
                  :key="p._id"
                  @click="selectProject(index), companyChanged()"
                >
                  {{ p.projectName }}
                </li>
              </ul>
            </div>
          </div>
          <!-- ИМЕ НА ОБЕКТ-->
          <div class="new-input-wrapper">
            <label for>
              <strong>{{ $t("site") }}*</strong>
            </label>
            <div
              class="new-input"
              :class="{ 'new-input---highlighted': $route.query.newObject }"
            >
              <span class="tms">Z</span>
              <input
                ref="newObject"
                v-if="selectedObject === undefined"
                type="text"
                :placeholder="$t('site-name')"
                @focus="openDropdown(2)"
                @blur="closeDropdown(2)"
                v-model="searchObjects"
                @change="companyChanged()"
                :disabled="
                  selectedObject !== undefined ||
                  this.$route.name === 'newPart' ||
                  this.$route.name === 'getPart'
                "
              />
              <input
                v-else
                type="text"
                :placeholder="$t('site-name')"
                @focus="openDropdown(2)"
                @blur="closeDropdown(2)"
                v-model="
                  company.projects[selectedProject].objects[selectedObject]
                    .objectName
                "
                :disabled="
                  selectedObject !== undefined ||
                  this.$route.name === 'newPart' ||
                  this.$route.name === 'getPart'
                "
              />
              <span
                v-if="
                  (selectedObject !== undefined &&
                    this.$route.name !== 'newPart') ||
                  this.$route.name === 'getPart'
                "
                class="clear"
                @click="resetSelectedObject()"
                >F</span
              >
              <template
                v-if="
                  this.$route.name === 'newPart' ||
                  this.$route.name === 'getPart'
                "
              >
                <span
                  class="edit"
                  @click="
                    toggleEdit(
                      company.projects[selectedProject].objects[selectedObject]
                        .objectName,
                      'object'
                    )
                  "
                  v-if="
                    getUser.permissions.isSuperAdmin ||
                    getUser.permissions.isAdmin
                  "
                  >c</span
                >
                <span class="disabled">A</span>
              </template>
              <!-- <span class="disabled">A</span> -->
            </div>
            <div
              class="new-input-dropdown"
              :class="{ 'new-input-dropdown-active': openedDropdown[2] }"
            >
              <ul v-if="selectedProject !== undefined">
                <li
                  v-for="(o, index) in filteredObjects()"
                  :key="o._id"
                  @click="selectObject(index), companyChanged()"
                >
                  {{ o.objectName }}
                </li>
              </ul>
            </div>
          </div>
          <!-- ИМЕ НА ЧАСТ-->
          <div class="new-input-wrapper">
            <label for>
              <strong>{{ $t("part") }}</strong>
            </label>
            <div
              class="new-input"
              :class="{ 'new-input---highlighted': $route.query.newPart }"
            >
              <span class="tms">Z</span>
              <input
                v-if="selectedPart === undefined"
                ref="newPart"
                type="text"
                :placeholder="$t('part-name')"
                @focus="openDropdown(3)"
                @blur="closeDropdown(3)"
                v-model="searchParts"
                @change="companyChanged()"
              />
              <input
                v-else-if="
                  company.projects[selectedProject].objects[selectedObject]
                    .parts[selectedPart]
                "
                type="text"
                :placeholder="$t('part-name')"
                @focus="openDropdown(3), resetSelectedPart()"
                @blur="closeDropdown(3)"
                v-model="
                  company.projects[selectedProject].objects[selectedObject]
                    .parts[selectedPart].partName
                "
                :disabled="
                  selectedPart !== undefined || this.$route.name === 'getPart'
                "
              />
              <span
                v-if="selectedPart !== undefined"
                class="clear"
                @click="resetSelectedPart()"
                >F</span
              >
              <template v-if="this.$route.name === 'getPart'">
                <span
                  class="edit"
                  @click="
                    toggleEdit(
                      company.projects[selectedProject].objects[selectedObject]
                        .parts[selectedPart].partName,
                      'part'
                    )
                  "
                  v-if="
                    getUser.permissions.isSuperAdmin ||
                    getUser.permissions.isAdmin
                  "
                  >c</span
                >
                <span class="disabled delete" @click="deletePart()">b</span>
              </template>
            </div>
            <div
              class="new-input-dropdown"
              :class="{ 'new-input-dropdown-active': openedDropdown[3] }"
            >
              <ul v-if="selectedObject !== undefined">
                <li
                  v-for="(p, index) in filteredParts()"
                  :key="p._id"
                  @click="selectPart(index), companyChanged()"
                >
                  {{ p.partName }}
                </li>
              </ul>
            </div>
          </div>
        </div>
        <!-- БЮДЖЕТИ -->
        <div
          class="new-content-inputs new-content-inputs-bigger"
          v-if="getUser.permissions.isSuperAdmin"
        >
          <!-- БЮДЖЕТ ПО ДОГОВОР -->
          <template
            v-if="
              (selectedObject !== undefined && selectedProject !== undefined) ||
              newObject.activities.length
            "
          >
            <div class="new-input-wrapper">
              <label for>
                <strong>{{ `${$t("budget-contract")}` }}</strong>
              </label>
              <div class="new-input">
                <span class="tms2">a</span>
                <input
                  type="text"
                  :placeholder="$t('budget-contract')"
                  v-model="budgetContract"
                  disabled="true"
                  @change="companyChanged()"
                  @focus="getBudgetContract()"
                  @blur="formatBudgetContract()"
                />
                <span class="disabled">A</span>
              </div>
            </div>
            <div class="new-input-wrapper">
              <label for>
                <strong>{{ `${$t("budget-company")}` }}</strong>
              </label>
              <div class="new-input">
                <span class="tms2">a</span>
                <input
                  type="text"
                  :placeholder="$t('budget-company')"
                  v-model="budgetCompany"
                  disabled="true"
                  @change="companyChanged()"
                  @focus="getBudgetCompany()"
                  @blur="formatBudgetCompany()"
                />
                <span class="disabled">A</span>
              </div>
            </div>
            <div class="new-input-wrapper">
              <label for>
                <strong>{{ `${$t("budget-total")}` }}</strong>
              </label>
              <div class="new-input">
                <span class="tms2">a</span>
                <input
                  type="text"
                  :placeholder="$t('budget-total')"
                  disabled
                  v-model="budgetTotal"
                  @change="companyChanged()"
                />
                <span class="disabled">A</span>
              </div>
            </div>
          </template>
        </div>

        <!-- DESKTOP REPORTERS / MATERIALS / ACTIVITIES / MECHANIZATIONS -->
        <div
          class="new-content-data-row desktop"
          v-if="
            (selectedObject !== undefined || searchObjects.length) &&
            (getUser.permissions.isSuperAdmin || getUser.permissions.isAdmin)
          "
        >
          <!-- REPORTERS -->
          <div class="new-content-data-item">
            <div
              class="new-content-data-item-toggle"
              @click="isAddUsersModalOpened = true"
            >
              <p>{{ $t("add-reporters") }}</p>
              <span class="new-content-data-item-toggle-circle">
                <span class="tms">V</span>
              </span>
            </div>
          </div>
          <!-- ACTIVITIES -->
          <div class="new-content-data-item">
            <div
              class="new-content-data-item-toggle"
              @click="isAddActivitiesModalOpened = true"
            >
              <p>{{ $t("add-activities") }}</p>
              <span class="new-content-data-item-toggle-circle">
                <span class="tms">U</span>
              </span>
            </div>
          </div>
          <!-- MATERIALS -->
          <div class="new-content-data-item">
            <div
              class="new-content-data-item-toggle"
              @click="isAddMaterialsModalOpened = true"
            >
              <p>{{ $t("add-materials") }}</p>
              <span class="new-content-data-item-toggle-circle">
                <span class="tms">T</span>
              </span>
            </div>
          </div>
          <!-- MECHANIZATION -->
          <div class="new-content-data-item">
            <div
              class="new-content-data-item-toggle"
              @click="isAddMechanizationsModalOpened = true"
            >
              <p>{{ $t("add-mechanizations") }}</p>
              <span class="new-content-data-item-toggle-circle">
                <span class="tms2">Y</span>
              </span>
            </div>
          </div>
        </div>

        <!-- MOBILE REPORTERS / MATERIALS / ACTIVITIES / MECHANIZATIONS -->
        <div
          class="new-content-data-row mobile"
          v-if="
            (selectedObject !== undefined || searchObjects.length) &&
            (getUser.permissions.isSuperAdmin || getUser.permissions.isAdmin)
          "
        >
          <!-- REPORTERS -->
          <div class="new-content-data-item">
            <div
              class="new-content-data-item-toggle mobile"
              @click="isAddUsersModalOpened = true"
            >
              <p>{{ $t("add-reporters") }}</p>
              <span class="new-content-data-item-toggle-circle">
                <span class="tms">V</span>
              </span>
            </div>
          </div>
          <!-- ACTIVITIES -->
          <div class="new-content-data-item">
            <div
              class="new-content-data-item-toggle mobile"
              @click="isAddActivitiesModalOpened = true"
            >
              <p>{{ $t("add-activities") }}</p>
              <span class="new-content-data-item-toggle-circle">
                <span class="tms">U</span>
              </span>
            </div>
          </div>
          <!-- MATERIALS -->
          <div class="new-content-data-item">
            <div
              class="new-content-data-item-toggle mobile"
              @click="isAddMaterialsModalOpened = true"
            >
              <p>{{ $t("add-materials") }}</p>
              <span class="new-content-data-item-toggle-circle">
                <span class="tms">T</span>
              </span>
            </div>
          </div>
          <!-- MECHANIZATIONS -->
          <div class="new-content-data-item">
            <div
              class="new-content-data-item-toggle mobile"
              @click="isAddMechanizationsModalOpened = true"
            >
              <p>{{ $t("add-mechanizations") }}</p>
              <span class="new-content-data-item-toggle-circle">
                <span class="tms2">Y</span>
              </span>
            </div>
          </div>
        </div>

        <!-- REPORTERS / MATERIALS / ACTIVITIES / MECHANIZATIONS -->
        <div
          class="new-content-data-row new-content-data-row-fullsize"
          v-if="viewLists()"
          :class="{ 'new-content-data-row-active': viewLists() }"
        >
          <!-- REPORTERS -->
          <div class="new-content-data-item">
            <div
              class="new-content-data-item-toggle"
              @click="
                getUser.permissions.isSuperAdmin || getUser.permissions.isAdmin
                  ? (isAddUsersModalOpened = true)
                  : null
              "
            >
              <p>{{ $t("reporters") }}</p>
              <span class="new-content-data-item-toggle-circle">
                <span class="tms">V</span>
              </span>
            </div>
            <div v-if="viewLists()" class="new-content-data-item-content">
              <div class="new-content-data-item-content-lists">
                <template v-if="selectedObject !== undefined">
                  <span
                    v-for="reporter in company.projects[selectedProject]
                      .objects[selectedObject].reporters"
                    :key="`addedReporter-${reporter._id}`"
                    >{{ reporter.name }}</span
                  >
                </template>
                <template v-else>
                  <span
                    v-for="reporter in newObject.reporters"
                    :key="`addedReporter-${reporter._id}`"
                    >{{ reporter.name }}</span
                  >
                </template>
              </div>
            </div>
          </div>
          <!-- ACTIVITIES -->
          <div class="new-content-data-item">
            <div
              class="new-content-data-item-toggle"
              @click="
                getUser.permissions.isSuperAdmin || getUser.permissions.isAdmin
                  ? (isAddActivitiesModalOpened = true)
                  : null
              "
            >
              <p class="ma-cell ma-cell-name--smaller">
                {{ $t("Activities") }}
              </p>
              <p class="ma-cell desktop">{{ $t("metric-unit") }}</p>
              <p class="ma-cell desktop">{{ $t("real") }}</p>
              <p class="ma-cell desktop">{{ $t("planned") }}</p>
              <p class="ma-cell desktop">{{ $t("difference") }}</p>
              <p class="ma-cell desktop ma-cell-name--price">
                {{ $t("Price") }}
              </p>
              <span class="new-content-data-item-toggle-circle">
                <span class="tms">U</span>
              </span>
            </div>
            <div v-if="viewLists()" class="new-content-data-item-content">
              <div class="new-content-data-item-content-lists">
                <table v-if="selectedObject !== undefined">
                  <template v-if="showLessActivities">
                    <tr
                      v-for="(activity, index) in company.projects[
                        selectedProject
                      ].objects[selectedObject].activities.slice(0, showLength)"
                      :key="`addedActivity-${index}-${activity.name}`"
                    >
                      <td class="ma-cell ma-cell-name">
                        <span
                          class="additional-zap"
                          v-if="activity.isAdditional"
                          >⚡</span
                        >
                        {{ activity.name }}
                        <span
                          v-if="activity.updateInReports"
                          title="Дейността е редактирана. Промените ще се запишат след запазване на обекта."
                        >
                          ✏️
                        </span>
                      </td>
                      <td class="ma-cell">{{ activity.unit }}</td>
                      <td class="ma-cell">{{ activity.totalQnt || 0 }}</td>
                      <td class="ma-cell">{{ activity.quantity }}</td>
                      <td class="ma-cell">
                        {{ activity.totalUsed || activity.quantity }}
                      </td>
                      <td class="ma-cell">
                        {{ (+activity.price).toFixed(2) }} {{ $t("currency") }}
                      </td>
                    </tr>
                    <tr
                      v-if="
                        company.projects[selectedProject].objects[
                          selectedObject
                        ].activities.length > showLength
                      "
                    >
                      <td colspan="3">
                        <a
                          class="show-all"
                          @click="showLessActivities = false"
                          >{{ $t("show-all") }}</a
                        >
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr
                      v-for="(activity, index) in company.projects[
                        selectedProject
                      ].objects[selectedObject].activities"
                      :key="`addedActivity-${index}-${activity.name}`"
                    >
                      <td class="ma-cell ma-cell-name">{{ activity.name }}</td>
                      <td class="ma-cell">{{ activity.unit }}</td>
                      <td class="ma-cell">{{ activity.totalQnt || 0 }}</td>
                      <td class="ma-cell">{{ activity.quantity }}</td>
                      <td class="ma-cell">
                        {{ activity.totalUsed || activity.quantity }}
                      </td>
                      <td class="ma-cell">
                        {{ (+activity.price).toFixed(2) }} {{ $t("currency") }}
                      </td>
                    </tr>
                  </template>
                </table>
                <table v-else>
                  <tr
                    v-for="(activity, index) in newObject.activities"
                    :key="`addedActivity-${index}-${activity.name}`"
                  >
                    <td class="ma-cell ma-cell-name">{{ activity.name }}</td>
                    <td class="ma-cell">{{ activity.unit }}</td>
                    <td class="ma-cell">{{ activity.quantity }}</td>
                    <td class="ma-cell">
                      {{ (+activity.price).toFixed(2) }} {{ $t("currency") }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <!-- MATERIALS -->
          <div class="new-content-data-item">
            <div
              class="new-content-data-item-toggle"
              @click="
                getUser.permissions.isSuperAdmin || getUser.permissions.isAdmin
                  ? (isAddMaterialsModalOpened = true)
                  : null
              "
            >
              <p class="ma-cell ma-cell-name--smaller">{{ $t("Materials") }}</p>
              <p class="ma-cell desktop">{{ $t("metric-unit") }}</p>
              <p class="ma-cell desktop">{{ $t("real") }}</p>
              <p class="ma-cell desktop">{{ $t("planned") }}</p>
              <p class="ma-cell desktop">{{ $t("difference") }}</p>
              <p class="ma-cell desktop ma-cell-name--price">
                {{ $t("Price") }}
              </p>
              <span class="new-content-data-item-toggle-circle">
                <span class="tms">T</span>
              </span>
            </div>
            <div v-if="viewLists()" class="new-content-data-item-content">
              <div class="new-content-data-item-content-lists">
                <table v-if="selectedObject !== undefined">
                  <template v-if="showLessMaterials">
                    <tr
                      v-for="(material, index) in company.projects[
                        selectedProject
                      ].objects[selectedObject].materials.slice(0, showLength)"
                      :key="`addedMaterial-${index}-${material.name}`"
                    >
                      <td class="ma-cell ma-cell-name">
                        <span
                          class="additional-zap"
                          v-if="material.isAdditional"
                          >⚡</span
                        >
                        {{ material.name }}
                        <span
                          v-if="material.updateInReports"
                          title="Материалът е редактиран. Промените ще се запишат след запазване на обекта."
                        >
                          ✏️
                        </span>
                      </td>
                      <td class="ma-cell">{{ material.unit }}</td>
                      <td class="ma-cell">{{ material.totalQnt || 0 }}</td>
                      <td class="ma-cell">{{ material.quantity }}</td>
                      <td class="ma-cell">
                        {{ material.totalUsed || material.quantity }}
                      </td>
                      <td class="ma-cell">
                        {{ (+material.price).toFixed(2) }} {{ $t("currency") }}
                      </td>
                    </tr>
                    <tr
                      v-if="
                        company.projects[selectedProject].objects[
                          selectedObject
                        ].materials.length > showLength
                      "
                    >
                      <td colspan="3">
                        <a
                          class="show-all"
                          @click="showLessMaterials = false"
                          >{{ $t("show-all") }}</a
                        >
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr
                      v-for="(material, index) in company.projects[
                        selectedProject
                      ].objects[selectedObject].materials"
                      :key="`addedMaterial-${index}-${material.name}`"
                    >
                      <td class="ma-cell ma-cell-name">{{ material.name }}</td>
                      <td class="ma-cell">{{ material.unit }}</td>
                      <td class="ma-cell">{{ material.totalQnt || 0 }}</td>
                      <td class="ma-cell">{{ 0 }}</td>
                      <td class="ma-cell">
                        {{ material.totalUsed || material.quantity }}
                      </td>
                      <td class="ma-cell">
                        {{ (+material.price).toFixed(2) }} {{ $t("currency") }}
                      </td>
                    </tr>
                    <!-- <td colspan="3"><a class="show-all" @click="showLessMaterials = true">Покажи първите {{ showLength }}</a></td> -->
                  </template>
                </table>
                <table v-else>
                  <tr
                    v-for="(material, index) in newObject.materials"
                    :key="`addedMaterial-${index}-${material.name}`"
                  >
                    <td class="ma-cell ma-cell-name">{{ material.name }}</td>
                    <td class="ma-cell">{{ material.unit }}</td>
                    <td class="ma-cell">
                      {{ (+material.price).toFixed(2) }} {{ $t("currency") }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <!-- MECHANIZATIONS -->
          <div class="new-content-data-item">
            <div
              class="new-content-data-item-toggle"
              @click="
                getUser.permissions.isSuperAdmin || getUser.permissions.isAdmin
                  ? (isAddMechanizationsModalOpened = true)
                  : null
              "
            >
              <p class="ma-cell ma-cell-name--smaller">
                {{ $t("Mechanizations") }}
              </p>
              <p class="ma-cell desktop">{{ $t("metric-unit") }}</p>
              <p class="ma-cell desktop">{{ $t("real") }}</p>
              <p class="ma-cell desktop">{{ $t("planned") }}</p>
              <p class="ma-cell desktop">{{ $t("difference") }}</p>
              <p class="ma-cell desktop ma-cell-name--price">
                {{ $t("Price") }}
              </p>
              <span class="new-content-data-item-toggle-circle">
                <span class="tms2">Y</span>
              </span>
            </div>
            <div v-if="viewLists()" class="new-content-data-item-content">
              <div class="new-content-data-item-content-lists">
                <table v-if="selectedObject !== undefined">
                  <template v-if="showLessMechanizations">
                    <tr
                      v-for="(mechanization, index) in company.projects[
                        selectedProject
                      ].objects[selectedObject].mechanizations.slice(
                        0,
                        showLength
                      )"
                      :key="`addedMechanization-${index}-${mechanization.name}`"
                    >
                      <td class="ma-cell ma-cell-name">
                        <span
                          class="additional-zap"
                          v-if="mechanization.isAdditional"
                          >⚡</span
                        >
                        {{ mechanization.name }}
                        <span
                          v-if="mechanization.updateInReports"
                          title="Механизацията е редактирана. Промените ще се запишат след запазване на обекта."
                        >
                          ✏️
                        </span>
                      </td>
                      <td class="ma-cell">{{ mechanization.unit }}</td>
                      <td class="ma-cell">{{ mechanization.totalQnt || 0 }}</td>
                      <td class="ma-cell">{{ mechanization.quantity }}</td>
                      <td class="ma-cell">
                        {{ mechanization.totalUsed || mechanization.quantity }}
                      </td>
                      <td class="ma-cell">
                        {{ (+mechanization.price).toFixed(2) }}
                        {{ $t("currency") }}
                      </td>
                    </tr>
                    <tr
                      v-if="
                        company.projects[selectedProject].objects[
                          selectedObject
                        ].mechanizations.length > showLength
                      "
                    >
                      <td colspan="3">
                        <a
                          class="show-all"
                          @click="showLessMechanizations = false"
                          >{{ $t("show-all") }}</a
                        >
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr
                      v-for="(mechanization, index) in company.projects[
                        selectedProject
                      ].objects[selectedObject].mechanizations"
                      :key="`addedMechanization-${index}-${mechanization.name}`"
                    >
                      <td class="ma-cell ma-cell-name">
                        {{ mechanization.name }}
                      </td>
                      <td class="ma-cell">{{ mechanization.unit }}</td>
                      <td class="ma-cell">{{ mechanization.totalQnt || 0 }}</td>
                      <td class="ma-cell">{{ 0 }}</td>
                      <td class="ma-cell">
                        {{ mechanization.totalUsed || mechanization.quantity }}
                      </td>
                      <td class="ma-cell">
                        {{ (+mechanization.price).toFixed(2) }}
                        {{ $t("currency") }}
                      </td>
                    </tr>
                  </template>
                </table>
                <table v-else>
                  <tr
                    v-for="(mechanization, index) in newObject.mechanizations"
                    :key="`addedMechanization-${index}-${mechanization.name}`"
                  >
                    <td class="ma-cell ma-cell-name">
                      {{ mechanization.name }}
                    </td>
                    <td class="ma-cell">{{ mechanization.unit }}</td>
                    <td class="ma-cell">
                      {{ (+mechanization.price).toFixed(2) }}
                      {{ $t("currency") }}
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- BUTONS MOBILE -->
        <div class="btns mobile">
          <button class="btn btn-close" @click="goBack">
            <span class="tms">F</span>
            {{ $t("cancel") }}
          </button>
          <button class="btn btn-new" @click="save()">
            <span class="tms">Y</span>
            {{ $t("save") }}
          </button>
        </div>
        <!-- BUTTON DELETE -->
        <!-- <div class="delete-wrapper">
          <button class="btn btn-delete" v-if="company._id" @click="deleteCompany()">
            <span class="tms">b</span>Изтриване на фирма
          </button>
        </div>-->
      </div>
    </div>

    <!-- ADD BUDGET MODAL -->
    <transition name="fade">
      <AddBudgetModal v-if="isAddBudgetModalOpened" />
    </transition>
    <!-- ADD REPORTERS MODAL -->
    <transition name="fade">
      <AddUsersModal
        v-if="isAddUsersModalOpened"
        :incomingUsers="getReportersForModal()"
        :usersType="'reporters'"
        :isCommingFromCompany="true"
      />
    </transition>
    <!-- ADD MATERIALS MODAL -->
    <transition name="fade">
      <AddMaterialsModal
        v-if="isAddMaterialsModalOpened"
        :incomingMaterials="getMaterialsForModal()"
        :incomingBudget="
          isAddMaterialsModalOpened
            ? selectedObject !== undefined
              ? Number(
                  company.projects[selectedProject].objects[selectedObject]
                    .budgetContract
                )
              : Number(newObject.budgetContract)
            : 0
        "
      />
    </transition>
    <!-- ADD ACTIVITIES MODAL -->
    <transition name="fade">
      <AddActivitiesModal
        v-if="isAddActivitiesModalOpened"
        :incomingActivities="getActivitiesForModal()"
        :incomingBudget="
          isAddActivitiesModalOpened
            ? selectedObject !== undefined
              ? Number(
                  company.projects[selectedProject].objects[selectedObject]
                    .budgetContract
                )
              : Number(newObject.budgetContract)
            : 0
        "
      />
    </transition>
    <!-- ADD MECHANIZATIONS MODAL -->
    <transition name="fade">
      <AddMechanizationsModal
        v-if="isAddMechanizationsModalOpened"
        :incomingMechanizations="getMechanizationsForModal()"
        :incomingBudget="
          isAddMechanizationsModalOpened
            ? selectedObject !== undefined
              ? Number(
                  company.projects[selectedProject].objects[selectedObject]
                    .budgetContract
                )
              : Number(newObject.budgetContract)
            : 0
        "
      />
    </transition>
    <!-- ADD ACTIVITIES MODAL -->
    <transition name="fade">
      <EditInputModal
        v-if="isEditInputModalOpened"
        :inputForEdit="inputForEdit"
        :isComingFromCompany="true"
      />
    </transition>
    <transition name="fade">
      <UnsavedChangesModal v-if="isUnsavedChangesModalOpened" />
    </transition>
  </div>
</template>

<script>
import AddBudgetModal from "./shared/AddBudgetModal";
import AddUsersModal from "./shared/AddUsersModal";
import AddMaterialsModal from "./shared/AddMaterialsModal";
import AddActivitiesModal from "./shared/AddActivitiesModal";
import AddMechanizationsModal from "./shared/AddMechanizationsModal";
import EditInputModal from "./shared/EditInputModal";
import UnsavedChangesModal from "./shared/UnsavedChangesModal";
import log from "./mixins/log.js";
export default {
  components: {
    AddUsersModal: AddUsersModal,
    AddMaterialsModal: AddMaterialsModal,
    AddActivitiesModal: AddActivitiesModal,
    AddMechanizationsModal: AddMechanizationsModal,
    UnsavedChangesModal: UnsavedChangesModal,
    EditInputModal: EditInputModal,
    AddBudgetModal,
  },
  mixins: [log],
  props: ["idToEdit"],
  watch: {
    "$route.path"() {
      this.setRoutes();
    },
    $route() {
      this.showLessMaterials = true;
      this.showLessActivities = true;
      this.showLessMechanizations = true;

      if (this.$route.query.newPart) {
        setTimeout(() => {
          this.focusInput("newPart");
        }, 100);
      }
      if (this.$route.query.newObject) {
        setTimeout(() => {
          this.focusInput("newObject");
        }, 100);
      }
    },
    isCompanyChanged(to) {
      this.$emit("company-changed", to);
    },
  },
  data() {
    return {
      isCompanyChanged: false,
      incomingActivities: [],
      incomingMaterials: [],
      incomingMechanizations: [],
      // А boolean for every dropdown in the template
      openedDropdown: [false, false, false, false],
      // Booleans for reporters/materials/activies/ selections modals
      isAddBudgetModalOpened: false,
      isAddUsersModalOpened: false,
      isAddMaterialsModalOpened: false,
      isAddActivitiesModalOpened: false,
      isAddMechanizationsModalOpened: false,
      isEditInputModalOpened: false,
      isUnsavedChangesModalOpened: false,
      showLength: 10,
      showLessMaterials: true,
      showLessActivities: true,
      showLessMechanizations: true,
      inputForEdit: {
        value: "",
        where: "",
      },
      isActive: true,
      selectedProject: undefined,
      selectedObject: undefined,
      selectedPart: undefined,
      // SELECTED ARRAYS
      selectedReporters: [],
      selectedMaterials: [],
      selectedActivities: [],
      selectedMechanizations: [],
      // SEARCH
      searchCompanies: "",
      searchProjects: "",
      searchObjects: "",
      searchParts: "",
      budgetContract: 0,
      budgetCompany: 0,
      budgetTotal: 0,
      // The main company object
      companies: [],
      users: [],
      // The specific company object
      company: {
        _id: undefined,
        companyName: "",
        // budgetContract: 0,
        // budgetCompany: 0,
        projects: [
          {
            projectName: "",
            objects: [
              {
                budgetContract: 0,
                budgetCompany: 0,
                isActive: true,
                objectName: "",
                materials: [],
                activities: [],
                mechanizations: [],
                reporters: [],
                parts: [],
              },
            ],
          },
        ],
      },
      newProject: {
        projectName: "",
        objects: [],
      },
      newObject: {
        objectName: "",
        isActive: true,
        materials: [],
        activities: [],
        mechanizations: [],
        reporters: [],
        parts: [],
        budgetContract: 0,
        budgetCompany: 0,
      },
      newPart: {
        partName: "",
      },
    };
  },
  methods: {
    focusInput(name) {
      let input = this.$refs[name];
      if (input) input.focus();

      setTimeout(() => {
        this.$router.push({
          path: this.$route.path,
          query: {},
        });
      }, 3000);
    },
    formatNums(n) {
      let num = parseFloat(n).toFixed(2);
      let parts = num.toString().split(".");

      if (typeof parts !== "undefined") {
        parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
        let formated = parts.join(".");
        // return formated + ` ${this.$t("currency")}`;
        return formated;
      } else {
        // return `0 ${this.$t("currency")}`;
        return `0`;
      }
    },
    getBudgetContract() {
      if (this.selectedObject !== undefined) {
        this.budgetContract = parseFloat(
          this.company.projects[this.selectedProject].objects[
            this.selectedObject
          ].budgetContract
        );
      }
    },
    formatBudgetContract() {
      if (Number.isNaN(parseFloat(this.budgetContract))) {
        this.budgetContract = 0;
      }
      // this.company.budgetContract = parseFloat(this.budgetContract).toFixed(2);
      this.budgetContract = this.formatNums(this.budgetContract);
    },
    getBudgetCompany() {
      if (this.selectedObject !== undefined) {
        this.budgetCompany = parseFloat(
          this.company.projects[this.selectedProject].objects[
            this.selectedObject
          ].budgetCompany
        );
      }
    },
    formatBudgetCompany() {
      if (Number.isNaN(parseFloat(this.budgetCompany))) {
        this.budgetCompany = 0;
      }
      // this.company.budgetCompany = parseFloat(this.budgetCompany).toFixed(2);
      this.budgetCompany = this.formatNums(this.budgetCompany);
    },
    getObjectSmrTotals() {
      let project = this.company.projects[this.selectedProject];
      let object = project.objects[this.selectedObject];
      if (project && object) {
        let requestUrl = `${process.env.VUE_APP_BASE_URI}/report/totalSmr/${this.company._id}/${project._id}/${object._id}`;
        this.$http
          .get(requestUrl)
          .then((res) => {
            if (res.data) {
              for (let tag of ["materials", "activities", "mechanizations"]) {
                this.company.projects[this.selectedProject].objects[
                  this.selectedObject
                ][tag] = res.data[tag];
              }
            }
          })
          .catch((error) => console.log(error));
      }
    },
    initBudgets() {
      this.getBudgetContract();
      this.formatBudgetContract();
      this.getBudgetCompany();
      this.formatBudgetCompany();
      this.getTotal(this.company._id);
      this.getObjectBudget();
    },
    // DROPDOWNS
    openDropdown(i) {
      this.$set(this.openedDropdown, i, true);
    },
    closeDropdown(i) {
      setTimeout(() => {
        this.$set(this.openedDropdown, i, false);
      }, 100);
    },
    isObjectActive() {
      if (
        this.selectedProject !== undefined &&
        this.selectedObject !== undefined
      ) {
        return this.company.projects[this.selectedProject].objects[
          this.selectedObject
        ].isActive;
      }
      return this.newObject.isActive;
    },
    // SELECTING
    // 1 - SELECT COMPANY BY ID
    selectCompany(id) {
      this.selectedProject = undefined;
      this.selectedObject = undefined;
      this.selectedPart = undefined;
      this.getCompany(id);
    },
    // 2 - SELECT THE INDEX OF A 'PROJECT' INSIDE COMPANY
    selectProject(project) {
      this.selectedObject = undefined;
      this.selectedPart = undefined;
      this.selectedProject = project;
    },
    // 3 - SELECT THE INDEX OF AN 'OBJECT' INSIDE A PROJECT
    selectObject(object) {
      this.selectedPart = undefined;
      this.selectedObject = object;
      this.getObjectBudget();
    },
    // 4 - SELECT THE INDEX OF A 'PART' INSIDE AN OBJECT
    selectPart(part) {
      this.selectedPart = part;
    },
    // RESET SELECTED AND EVERYTHING NESTED INSIDE ON FOCUS
    resetSelectedCompany() {
      this.company = {
        companyName: "",
        projects: [
          {
            _id: "",
            projectName: "",
            objects: [
              {
                _id: "",
                isActive: true,
                objectName: "",
                materials: [],
                activities: [],
                mechanizations: [],
                reporters: [],
                parts: [],
                budgetContract: 0,
                budgetCompany: 0,
              },
            ],
          },
        ],
      };
      this.newProject = {
        projectName: "",
        objects: [],
      };
      this.newObject = {
        objectName: "",
        isActive: true,
        materials: [],
        activities: [],
        mechanizations: [],
        reporters: [],
        parts: [],
      };
      this.newPart = {
        partName: "",
      };
      this.searchCompanies = "";
      this.resetSelectedProject();
    },
    resetSelectedProject() {
      this.selectedProject = undefined;
      this.searchProjects = "";
      this.resetSelectedObject();
    },
    resetSelectedObject() {
      this.selectedObject = undefined;
      this.searchObjects = "";
      this.resetSelectedPart();
      this.getObjectBudget();
    },
    resetSelectedPart() {
      this.selectedPart = undefined;
      this.searchParts = "";
    },
    // FILTERED RESULTS WHEN SEARCHING
    filteredCompanies() {
      return this.companies.filter((c) => {
        return c.companyName
          .toLowerCase()
          .includes(this.searchCompanies.toLowerCase());
      });
    },
    filteredProjects() {
      return this.company.projects.filter((p) => {
        return p.projectName
          .toLowerCase()
          .includes(this.searchProjects.toLowerCase());
      });
    },
    filteredObjects() {
      if (this.selectedProject !== undefined) {
        return this.company.projects[this.selectedProject].objects.filter(
          (o) => {
            return o.objectName
              .toLowerCase()
              .includes(this.searchObjects.toLowerCase());
          }
        );
      }
    },
    filteredParts() {
      if (
        this.selectedProject !== undefined &&
        this.selectedObject !== undefined
      ) {
        return this.company.projects[this.selectedProject].objects[
          this.selectedObject
        ].parts.filter((p) => {
          return p.partName
            .toLowerCase()
            .includes(this.searchParts.toLowerCase());
        });
      }
    },
    // RETURN IF REPORTERS/MATERIALS/ACTIVITIES SECTION SHOULD BE VISIBLE
    viewLists() {
      if (this.selectedObject !== undefined) {
        let path =
          this.company.projects[this.selectedProject].objects[
            this.selectedObject
          ];
        return (
          path.reporters.length ||
          path.materials.length ||
          path.mechanizations.length ||
          path.activities.length
        );
      } else {
        return (
          this.newObject.reporters.length ||
          this.newObject.materials.length ||
          this.newObject.mechanizations.length ||
          this.newObject.activities.length
        );
      }
    },
    getReportersForModal() {
      if (this.selectedObject === undefined) {
        return this.newObject.reporters;
      } else {
        return this.company.projects[this.selectedProject].objects[
          this.selectedObject
        ].reporters;
      }
    },
    getMaterialsForModal() {
      if (this.selectedObject === undefined) {
        return this.newObject.materials;
      } else {
        return this.company.projects[this.selectedProject].objects[
          this.selectedObject
        ].materials;
      }
    },
    getActivitiesForModal() {
      if (this.selectedObject === undefined) {
        return this.newObject.activities;
      } else {
        return this.company.projects[this.selectedProject].objects[
          this.selectedObject
        ].activities;
      }
    },
    getMechanizationsForModal() {
      if (this.selectedObject === undefined) {
        return this.newObject.mechanizations;
      } else {
        return this.company.projects[this.selectedProject].objects[
          this.selectedObject
        ].mechanizations;
      }
    },
    // HANDLE THE REPORTERS/MATERIALS/ACTIVITIES/MECHANIZATIONS RETURNED FROM THE MODALS
    // If we get the 'saveAddUsersModal' event, we assign the workers we receive to the selected/new part
    handleReporters(reporters) {
      if (this.selectedObject === undefined) {
        this.newObject.reporters = reporters;
      } else {
        this.company.projects[this.selectedProject].objects[
          this.selectedObject
        ].reporters = reporters;
      }
      this.closeAddUsersModal();
      this.companyChanged();
    },
    // If we get the 'saveAddMaterialsModal' event, we assign the materials we receive to the selected/new part
    handleMaterials(materials, budget) {
      console.log(budget);
      if (budget) this.handleBudget(budget);

      if (this.selectedObject === undefined) {
        this.newObject.materials = materials;
      } else {
        this.company.projects[this.selectedProject].objects[
          this.selectedObject
        ].materials = materials;
      }
      this.closeAddMaterialsModal();
      this.companyChanged();
    },
    handleBudget(budget) {
      (this.budgetContract = budget),
        (this.budgetCompany = budget - budget / 10);

      if (this.selectedObject === undefined) {
        this.newObject.budgetContract = parseFloat(this.budgetContract).toFixed(
          2
        );
        this.newObject.budgetCompany = parseFloat(this.budgetCompany).toFixed(
          2
        );
      }
      this.company.projects[this.selectedProject || 0].objects[
        this.selectedObject || 0
      ].budgetContract = parseFloat(this.budgetContract).toFixed(2);
      this.budgetContract = this.formatNums(this.budgetContract);
      this.company.projects[this.selectedProject || 0].objects[
        this.selectedObject || 0
      ].budgetCompany = parseFloat(this.budgetCompany).toFixed(2);
      this.budgetCompany = this.formatNums(this.budgetCompany);
      this.isAddBudgetModalOpened = false;
    },
    // If we get the 'saveAddActivitiesModal' event, we assign the activities we receive to the selected/new part
    handleActivities(activities, budget) {
      if (budget) this.handleBudget(budget);

      if (this.selectedObject === undefined) {
        this.newObject.activities = activities;
      } else {
        this.company.projects[this.selectedProject].objects[
          this.selectedObject
        ].activities = activities;
      }
      this.closeAddActivitiesModal();
      this.companyChanged();
    },
    // If we get the 'saveAddMechanizationsModal' event, we assign the mechanizations we receive to the selected/new part
    handleMechanizations(mechanizations, budget) {
      if (budget) this.handleBudget(budget);

      if (this.selectedObject === undefined) {
        this.newObject.mechanizations = mechanizations;
      } else {
        this.company.projects[this.selectedProject].objects[
          this.selectedObject
        ].mechanizations = mechanizations;
      }
      this.closeAddMechanizationsModal();
      this.companyChanged();
    },
    // If we get the 'saveEditInputModal' event, we change the name of the field we need to change
    handleEdit(edited) {
      if (edited.where === "company") {
        this.company.companyName = edited.value;
        this.$snotify.warning(
          `${this.$t("successfully-edited")} ${this.$t(
            "company"
          ).toLowerCase()}. ${this.$t("to-save-click-save")}`
        );
      } else if (edited.where === "project") {
        for (let i = 0; i < this.company.projects.length; i++) {
          if (this.selectedProject == i) continue;

          if (
            this.company.projects[i].projectName.toLowerCase() ===
            edited.value.toLowerCase()
          ) {
            this.$snotify.error(this.$t("project-name-exists"));
            return;
          }
        }

        this.company.projects[this.selectedProject].projectName = edited.value;
        this.$snotify.warning(
          `${this.$t("successfully-edited")} ${this.$t(
            "project"
          ).toLowerCase()}. ${this.$t("to-save-click-save")}`
        );
      } else if (edited.where === "object") {
        for (
          let i = 0;
          i < this.company.projects[this.selectedProject].objects.length;
          i++
        ) {
          if (this.selectedObject == i) continue;

          if (
            this.company.projects[this.selectedProject].objects[
              i
            ].objectName.toLowerCase() === edited.value.toLowerCase()
          ) {
            this.$snotify.error(this.$t("object-name-exists"));
            return;
          }
        }

        this.company.projects[this.selectedProject].objects[
          this.selectedObject
        ].objectName = edited.value;
        this.$snotify.warning(
          `${this.$t("successfully-edited")} ${this.$t(
            "site"
          ).toLowerCase()}. ${this.$t("to-save-click-save")}`
        );
      } else if (edited.where === "part") {
        for (
          let i = 0;
          i <
          this.company.projects[this.selectedProject].objects[
            this.selectedObject
          ].parts.length;
          i++
        ) {
          if (this.selectedPart == i) continue;

          if (
            this.company.projects[this.selectedProject].objects[
              this.selectedObject
            ].parts[i].partName.toLowerCase() === edited.value.toLowerCase()
          ) {
            this.$snotify.error(this.$t("part-name-exists"));
            return;
          }
        }

        this.company.projects[this.selectedProject].objects[
          this.selectedObject
        ].parts[this.selectedPart].partName = edited.value;
        this.$snotify.warning(
          `${this.$t("successfully-edited")} ${this.$t(
            "part"
          ).toLowerCase()}. ${this.$t("to-save-click-save")}`
        );
      }
      this.closeEditInputModal();
      this.companyChanged();
    },
    // Close the modals when we got the corresponding event
    closeAddBudgetModal() {
      this.isAddBudgetModalOpened = false;
    },
    closeAddUsersModal() {
      this.isAddUsersModalOpened = false;
    },
    closeAddMaterialsModal() {
      this.isAddMaterialsModalOpened = false;
    },
    closeAddActivitiesModal() {
      this.isAddActivitiesModalOpened = false;
    },
    closeAddMechanizationsModal() {
      this.isAddMechanizationsModalOpened = false;
    },
    closeEditInputModal() {
      this.isEditInputModalOpened = false;
    },
    toggleEdit(value, where) {
      this.inputForEdit = {
        value: value,
        where: where,
      };
      this.isEditInputModalOpened = true;
    },
    getObjectBudget() {
      let project = this.company.projects[this.selectedProject];
      let object =
        this.selectedObject !== undefined
          ? project.objects[this.selectedObject]
          : this.newObject;
      let budget = 0;

      if (object) {
        for (let x of ["activities", "materials", "mechanizations"]) {
          object[x].map((a) => {
            if (a.quantity && a.price) budget += a.quantity * a.price;
          });
        }
      }
      this.handleBudget(budget);
    },
    // REQUESTS
    // GET ALL COMPANIES REQUEST

    getTotal(id) {
      let project = this.company.projects[this.selectedProject];
      let object = project.objects[this.selectedObject];
      if (project && object) {
        let requestUrl = `${process.env.VUE_APP_BASE_URI}/report/total/${id}/${project._id}/${object._id}`;
        this.$http
          .get(requestUrl)
          .then((response) => {
            this.budgetTotal = this.formatNums(response.data.total);
          })
          .catch((error) => console.log(error));
      }
    },
    getCompanies() {
      let requestUrl = `${process.env.VUE_APP_BASE_URI}/company/names`;
      this.$http
        .get(requestUrl)
        .then((response) => {
          this.companies = response.data;
          // console.log(JSON.stringify(this.companies));
        })
        .catch((error) => console.log(error));
    },
    // GET SPECIFIC COMPANY BY ID REQUEST
    getCompany(companyId) {
      this.resetSelectedProject();
      let url = `${process.env.VUE_APP_BASE_URI}/company/${companyId}`;
      this.$http
        .get(url)
        .then((response) => {
          this.company = response.data;
          this.initBudgets();
        })
        .catch((error) => console.log(error));
    },
    // GET and SET Selected Company and Project
    getProject(companyId, projectId) {
      this.resetSelectedObject();
      let url = `${process.env.VUE_APP_BASE_URI}/company/${companyId}`;
      this.$http
        .get(url)
        .then((response) => {
          this.company = response.data;
          // this.initBudgets();
          for (let project in this.company.projects) {
            if (this.company.projects[project]._id == projectId) {
              // SET Project
              this.selectedProject = project;
            }
          }
          this.initBudgets();
        })
        .catch((error) => console.log(error));
    },
    // GET and SET Selected Company, Project and Object
    getObject(companyId, projectId, objectId) {
      this.resetSelectedPart();
      let url = `${process.env.VUE_APP_BASE_URI}/company/${companyId}`;
      this.$http
        .get(url)
        .then((response) => {
          this.company = response.data;
          // this.initBudgets();
          for (let project in this.company.projects) {
            if (this.company.projects[project]._id == projectId) {
              // SET Project
              this.selectedProject = project;
              for (let object in this.company.projects[project].objects) {
                if (
                  this.company.projects[this.selectedProject].objects[object]
                    ._id == objectId
                ) {
                  // SET Object
                  this.selectedObject = object;
                }
              }
            }
          }
          this.getObjectSmrTotals();
          this.initBudgets();
        })
        .catch((error) => console.log(error));
    },
    // GET and SET Selected Company, Project, Object and Part
    getPart(companyId, projectId, objectId, partId) {
      let url = `${process.env.VUE_APP_BASE_URI}/company/${companyId}`;
      this.$http
        .get(url)
        .then((response) => {
          this.company = response.data;
          // this.initBudgets();
          for (let project in this.company.projects) {
            if (this.company.projects[project]._id == projectId) {
              // SET Project
              this.selectedProject = project;
              for (let object in this.company.projects[project].objects) {
                if (
                  this.company.projects[this.selectedProject].objects[object]
                    ._id == objectId
                ) {
                  // SET Object
                  this.selectedObject = object;
                  for (let part in this.company.projects[project].objects[
                    object
                  ].parts) {
                    if (
                      this.company.projects[this.selectedProject].objects[
                        this.selectedObject
                      ].parts[part]._id == partId
                    ) {
                      // SET Object
                      this.selectedPart = part;
                    }
                  }
                }
              }
            }
          }
          this.initBudgets();
        })
        .catch((error) => console.log(error));
    },
    companyChanged() {
      if (!this.isCompanyChanged) {
        this.isCompanyChanged = true;
      }
    },
    save() {
      let dublicated = false;

      //companies
      this.companies.filter((n) => {
        if (
          n.companyName.toLowerCase() === this.searchCompanies.toLowerCase() &&
          !this.company.companyName
        ) {
          this.$snotify.error(this.$t("company-name-exists"));
          dublicated = true;
        }
      });
      //companies

      if (this.company._id && this.company.projects) {
        //projects and objects
        this.company.projects.filter((n) => {
          if (
            n.projectName.toLowerCase() === this.searchProjects.toLowerCase() &&
            this.selectedProject === undefined
          ) {
            this.$snotify.error(this.$t("project-name-exists"));
            dublicated = true;
          }

          for (let o of n.objects) {
            if (
              this.company.projects[this.selectedProject] &&
              this.company.projects[
                this.selectedProject
              ].projectName.toLowerCase() === n.projectName.toLowerCase()
            ) {
              if (
                o.objectName.toLowerCase() ===
                  this.searchObjects.toLowerCase() &&
                this.selectedObject === undefined
              ) {
                this.$snotify.error(this.$t("object-name-exists"));
                dublicated = true;
              }

              for (let p of o.parts) {
                if (
                  this.company.projects[this.selectedProject] &&
                  this.company.projects[this.selectedProject].objects[
                    this.selectedObject
                  ].objectName.toLowerCase() === o.objectName.toLowerCase()
                ) {
                  if (
                    p.partName.toLowerCase() ===
                      this.searchParts.toLowerCase() &&
                    this.selectedPart === undefined
                  ) {
                    this.$snotify.error(this.$t("part-name-exists"));
                    dublicated = true;
                  }
                }
              }
            }
          }
        });
        //projects and objects
      }

      // IF THERE IS NO SELECTED COMPANY WE'RE MAKING A BRAND NEW ONE
      if (!dublicated) {
        if (!this.company._id && this.fieldsValidation()) {
          if (confirm(this.$t("new-company-confirm"))) {
            this.postCompany();
          }
          // IN ALL OTHER CASES WE'RE UPDATING AN EXISTING ONE
        } else if (this.company._id && this.fieldsValidationEdit()) {
          this.updateCompany();
        }
      }
    },
    checkForUnsavedData(next) {
      if (
        !this.getUser.permissions.isSuperAdmin &&
        !this.getUser.permissions.isAdmin
      ) {
        return next();
      }
      this.isUnsavedChangesModalOpened = true;
      this.$once("closeUnsavedChangesModal", (answer) => {
        // confirm
        if (answer) {
          next(false);
          this.save();
        }
        // decline
        else {
          next();
        }
        this.isUnsavedChangesModalOpened = false;
        this.isCompanyChanged = false;
      });
    },
    // POST COMPANY REQUEST
    postCompany() {
      this.company.companyName = this.searchCompanies;
      this.company.projects[0].projectName = this.searchProjects;
      this.company.projects[0].objects[0].objectName = this.searchObjects;
      this.company.projects[0].objects[0].materials = this.newObject.materials;
      this.company.projects[0].objects[0].activities =
        this.newObject.activities;
      this.company.projects[0].objects[0].reporters = this.newObject.reporters;
      this.company.projects[0].objects[0].mechanizations =
        this.newObject.mechanizations;
      this.company.projects[0].objects[0].isActive = this.newObject.isActive;
      if (this.searchParts.length) {
        this.company.projects[0].objects[0].parts.push({
          partName: this.searchParts,
        });
      }
      let url = `${process.env.VUE_APP_BASE_URI}/company`;
      this.$http
        .post(url, this.company)
        .then((res) => {
          this.$snotify.success(this.$t("successfully-added-new-company"));
          this.isCompanyChanged = false;
          this.newLog("newCompany", this.company.companyName);

          try {
            this.$router.push(
              `/companies/${res.data._id}/${res.data.projects[0]._id}/${res.data.projects[0].objects[0]._id}`
            );
          } catch (err) {
            console.log(err);
            this.goBack();
          }
        })
        .catch((error) => {
          console.log(error);
          this.$snotify.error(this.$t("something-went-wrong"));
        });
    },
    // UPDATE COMPANY REQUEST
    updateCompany() {
      // NEW PART
      let projectName = "";
      let objectName = "";

      if (this.selectedPart === undefined) {
        // * PART IS OPTIONAL
        if (this.searchParts.length) {
          this.newPart.partName = this.searchParts;
        }
        // NEW OBJECT
        if (this.selectedObject === undefined) {
          this.newObject.objectName = this.searchObjects;
          objectName = this.searchObjects;
          // * PART IS OPTIONAL
          if (this.searchParts.length) {
            this.newObject.parts.push(this.newPart);
          }
          // NEW PROJECT
          if (this.selectedProject === undefined) {
            this.newProject.projectName = this.searchProjects;
            projectName = this.searchProjects;
            this.newProject.objects.push(this.newObject);
            this.company.projects.push(this.newProject);
          }
          // IF THERE IS SELECTED PROJECT PUSH THE NEW OBJECT TO IT
          else {
            this.company.projects[this.selectedProject].objects.push(
              this.newObject
            );
            projectName =
              this.company.projects[this.selectedProject].projectName;
          }
        }
        // IF THERE IS SELECTED OBJECT PUSH THE NEW PART TO IT
        else {
          // * PART IS OPTIONAL
          if (this.searchParts.length) {
            this.company.projects[this.selectedProject].objects[
              this.selectedObject
            ].parts.push({ partName: this.searchParts });
          }
          objectName =
            this.company.projects[this.selectedProject].objects[
              this.selectedObject
            ].objectName;
        }
      }

      // let requestObject = {
      //   _id: this.company._id,
      //   companyName: this.company.companyName,
      //   projects: []
      // };

      // if (this.selectedProject !== undefined) {
      //   requestObject.projects.push(
      //     this.company.projects[this.selectedProject]
      //   );
      // } else {
      //   requestObject.projects.push({
      //     projectName: this.searchProjects,
      //     objects: []
      //   });
      // }

      // if (this.selectedProject !== undefined && this.selectedObject !== undefined) {
      //   requestObject.projects[0].objects.push(
      //     this.company.projects[this.selectedProject].objects[this.selectedObject]
      //   );
      // } else {
      //   requestObject.projects[0].objects.push({
      //     objectName: this.searchObjects,
      //     parts: [],
      //     materials: this.newObject.materials,
      //     activities: this.newObject.activities,
      //     reporters: this.newObject.reporters,
      //     isActive: this.newObject.isActive
      //   });
      // }

      // if (this.selectedProject !== undefined && this.selectedObject !== undefined && this.selectedPart !== undefined) {
      //   requestObject.projects[0].objects[0].parts.push(
      //     this.company.projects[this.selectedProject].objects[this.selectedObject].parts
      //   );
      // } else {
      //   requestObject.projects[0].objects[0].parts.push({
      //     partName: this.searchParts,
      //   });
      // }

      // MAKE THE REQUEST
      let url = `${process.env.VUE_APP_BASE_URI}/company/${this.company._id}`;
      this.$http
        .put(url, this.company)
        .then((res) => {
          this.$snotify.success(this.$t("success-changes"));
          this.isCompanyChanged = false;
          this.newLog("editCompany", this.company.companyName);

          // this.getCompany()

          try {
            const companyId = this.$route.params.companyId;
            let projectId = this.$route.params.projectId;
            let objectId = this.$route.params.objectId;
            if (!projectId || !objectId) {
              for (let p of res.data.projects) {
                if (p.projectName === projectName) {
                  if (!projectId) projectId = p._id;

                  if (!objectId) {
                    for (let o of p.objects) {
                      if (o.objectName === objectName) objectId = o._id;
                    }
                  }
                }
              }
            }

            this.$nextTick(() => {
              this.$router.push(
                `/companies/${companyId}/${projectId}/${objectId}`
              );
            });
          } catch (err) {
            console.log(err);
            // this.goBack();
          }
        })
        .catch((error) => {
          console.log(error);
          if (error.response.data.message) {
            this.$snotify.error(error.response.data.message);
          } else {
            this.$snotify.error(this.$t("something-went-wrong"));
          }
        });
    },
    deleteCompany() {
      if (this.company._id) {
        if (confirm(this.$t("delete-company-confirm"))) {
          this.$router.push("/companies");
          this.deleteCompanyRequest();
        }
      }
    },
    deletePart() {
      if (this.company._id) {
        if (confirm(this.$t("delete-part-confirm"))) {
          this.company.projects[this.selectedProject].objects[
            this.selectedObject
          ].parts.splice(this.selectedPart, 1);

          this.updateCompany();
          let projectId = this.company.projects[this.selectedProject]._id;
          let objectId =
            this.company.projects[this.selectedProject].objects[
              this.selectedObject
            ]._id;

          this.getObject(this.company._id, projectId, objectId);
          this.$router.push(
            `/companies/${this.company._id}/${projectId}/${objectId}?removed=true`
          );
          this.$snotify.success(this.$t("successfully-deleted-part"));
        }
      }
    },
    deleteCompanyRequest() {
      let url = `${process.env.VUE_APP_BASE_URI}/company/${this.company._id}`;
      this.$http
        .delete(url)
        .then(this.$snotify.success(this.$t("successfully-deleted-company")))
        .catch((error) => {
          if (error.response.status == 404) {
            this.$snotify.error(this.$t("company-not-found"));
          } else {
            this.$snotify.error(this.$t("something-went-wrong"));
          }
        });
    },
    // CANCEL
    goBack() {
      this.$router.push("/companies");
    },
    fieldsValidation() {
      if (!this.searchCompanies) {
        this.$snotify.error(this.$t("please-add-company"));
        return false;
      }
      if (!this.searchProjects) {
        this.$snotify.error(this.$t("please-add-project"));
        return false;
      }
      if (!this.searchObjects) {
        this.$snotify.error(this.$t("please-add-site"));
        return false;
      }
      if (this.searchCompanies && this.searchProjects && this.searchObjects) {
        return true;
      }
    },
    fieldsValidationEdit() {
      let project = this.company.projects[this.selectedProject];
      let object = project ? project.objects[this.selectedObject] : false;

      if (!this.company.companyName && !this.searchCompanies) {
        this.$snotify.error(this.$t("please-add-company"));
        return false;
      }
      if (!project && !this.searchProjects) {
        this.$snotify.error(this.$t("please-add-project"));
        return false;
      }
      if (!object && !this.searchObjects) {
        this.$snotify.error(this.$t("please-add-site"));
        return false;
      }

      return true;
    },
    setRoutes() {
      // eslint-disable-next-line
      if (this.$route.name === "newCompany") {
      } else if (this.$route.name === "newProject") {
        this.getCompany(this.$route.params.companyId);
      } else if (this.$route.name === "newObject") {
        this.getProject(
          this.$route.params.companyId,
          this.$route.params.projectId
        );
      } else if (this.$route.name === "newPart") {
        this.getObject(
          this.$route.params.companyId,
          this.$route.params.projectId,
          this.$route.params.objectId
        );
      } else if (this.$route.name === "getPart") {
        this.getPart(
          this.$route.params.companyId,
          this.$route.params.projectId,
          this.$route.params.objectId,
          this.$route.params.partId
        );
      }
    },
  },
  mounted() {
    this.setRoutes();
    this.getCompanies();
    // SAVE HANDLERS
    this.$on("saveAddUsersModal", this.handleReporters);
    this.$on("saveAddBudgetModal", this.handleBudget);
    this.$on("saveAddMaterialsModal", this.handleMaterials);
    this.$on("saveAddActivitiesModal", this.handleActivities);
    this.$on("saveAddMechanizationsModal", this.handleMechanizations);
    this.$on("saveEditInputModal", this.handleEdit);
    // CLOSE HANDLERS
    this.$on("closeAddBudgetModal", this.closeAddBudgetModal);
    this.$on("closeAddUsersModal", this.closeAddUsersModal);
    this.$on("closeAddMaterialsModal", this.closeAddMaterialsModal);
    this.$on("closeAddActivitiesModal", this.closeAddActivitiesModal);
    this.$on("closeAddMechanizationsModal", this.closeAddMechanizationsModal);
    this.$on("closeEditInputModal", this.closeEditInputModal);

    this.$eventBus.$on("check-for-unsaved-data", (e) =>
      this.checkForUnsavedData(e)
    );
  },
  beforeRouteLeave(to, from, next) {
    if (this.isCompanyChanged) {
      this.checkForUnsavedData(next);
    } else {
      next();
    }
  },
  destroyed() {
    // STOP LISTENING FOR THE EVENTS
    this.$off("saveAddUsersModal");
    this.$off("saveAddMaterialsModal");
    this.$off("saveAddBudgetModal");
    this.$off("saveAddActivitiesModal");
    this.$off("saveAddMechanizationsModal");
    this.$off("saveEditInputModal");
    this.$off("closeAddBudgetModal");
    this.$off("closeAddUsersModal");
    this.$off("closeAddMaterialsModal");
    this.$off("closeAddActivitiesModal");
    this.$off("closeAddMechanizationsModal");
    this.$off("closeEditInputModal");

    this.$eventBus.$off("check-for-unsaved-data");
  },
};
</script>

<style scoped>
.title {
  color: #373737;
  font-size: 20px;
}
.new-content {
  width: 100%;
}
.new-content-side-panel {
  width: 100%;
  padding: 10px;
  /* height: 100vh; */
  /* overflow: auto; */
}
.new-project-container {
  align-items: center;
  margin-left: 40px;
  margin-right: 40px;
  margin-bottom: 27px;
}
.new-project-container-side-panel {
  margin-left: 24px;
  width: 70%;
}
.new-project-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding-bottom: 10px;
  padding-top: 11px;
}
.new-project-wrapper {
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  width: 100%;
}
.title-and-excell {
  align-items: center;
  display: flex;
  gap: 20px;
}
.title-and-excell .btn-excel {
  margin-left: 0;
}
.buttons-wrapper {
  display: flex;
  flex-direction: row;
}
.switch-wrapper {
  align-items: center;
  display: flex;
}
.switch {
  margin: 0 10px;
}
.switch-label {
  cursor: pointer;
  font-weight: 700;
  opacity: 0.4;
}
.switch-label-active {
  opacity: 1;
}
.delete-wrapper {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  width: 100%;
}
.show-all {
  color: #3b86ff;
  font-size: 11px;
  font-weight: 700;
  padding: 0 20px;
  text-decoration: underline;
}
.new-content-data-item-toggle-circle .tms2 {
  font-size: 18px;
}
.desktop {
  /* display: flex; */
}
.mobile {
  display: none;
}

/* Responsive */
@media screen and (max-width: 1000px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: flex;
  }
  .projects-wrapper {
    justify-content: center;
  }
  .new-project-container-side-panel {
    width: 100%;
  }
  .new-project-container {
    width: 92%;
    margin: 0 15px;
    margin-bottom: 27px;
  }
  .new-content-inputs {
    display: flex;
    flex-direction: column;
  }
  .new-input-wrapper {
    margin-right: 0px;
    width: 100%;
  }
  .new-project-header {
    justify-content: center;
  }
  .new-content-data-item-toggle.mobile {
    text-align: center;
    flex-direction: column;
    height: auto;
    padding-bottom: 0.6em;
  }
  .new-content-data-item-toggle.mobile p {
    margin-top: 0.6em;
    margin-bottom: 0.6em;
  }
  .btns.mobile {
    display: flex;
    justify-content: center;
    margin: 10px 0;
  }
  .delete-wrapper {
    justify-content: center;
  }
  .btn.btn-delete {
    width: 330px;
  }
  .new-content {
    width: 100%;
  }
  .new-content-data-item-content-lists > span {
    width: 30%;
  }
  .new-content-data-item-content-lists {
    overflow: auto;
  }
  .new-content-data-item-content .ma-cell {
    /* padding: 0px; */
    white-space: nowrap;
  }
  .new-content-data-item-content .ma-cell-name {
    max-width: 300px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .show-all {
    padding: 0;
  }
}
@media screen and (max-width: 600px) {
  .new-content-data-row {
    flex-wrap: wrap;
  }
  .new-content-data-item {
    width: calc(50% - 10px);
    margin-right: 20px;
  }
  .new-content-data-item:nth-child(even) {
    margin-right: 0;
  }
  .new-content-data-item:nth-child(1),
  .new-content-data-item:nth-child(2) {
    margin-bottom: 20px;
  }
  .new-content-data-row-fullsize .new-content-data-item {
    margin-right: 0;
    width: 100%;
  }
  .new-content-data-item-content-lists > span {
    padding: 5px 0;
    width: 100%;
  }
}
/* @media screen and (max-width: 340px) {
  .new-content-data-item {
    margin-right: 4px;
  }
} */
</style>
