var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"hours-and-tags"},[_c('SelectHour',{attrs:{"reported":_vm.report.reported ? _vm.report.reported.indexOf(_vm.worker._id) > -1 : false,"reportedNonplanned":_vm.report.reportedNonplanned
        ? _vm.report.reportedNonplanned.indexOf(_vm.worker._id) > -1
        : false,"end":_vm.worker.endHour},on:{"change":function($event){return _vm.$emit('changeWorker')}},model:{value:(_vm.worker.startHour),callback:function ($$v) {_vm.$set(_vm.worker, "startHour", $$v)},expression:"worker.startHour"}}),_c('SelectHour',{attrs:{"reported":_vm.report.reported ? _vm.report.reported.indexOf(_vm.worker._id) > -1 : false,"reportedNonplanned":_vm.report.reportedNonplanned
        ? _vm.report.reportedNonplanned.indexOf(_vm.worker._id) > -1
        : false,"start":_vm.worker.startHour},on:{"change":function($event){return _vm.$emit('changeWorker')}},model:{value:(_vm.worker.endHour),callback:function ($$v) {_vm.$set(_vm.worker, "endHour", $$v)},expression:"worker.endHour"}}),(
      (_vm.report.reported
        ? _vm.report.reported.length || _vm.report.notReported.length
        : false) && !_vm.worker.new
    )?[(
        _vm.report.reportedNonplanned
          ? _vm.report.reportedNonplanned.indexOf(_vm.worker._id) > -1
          : false
      )?_c('span',{staticClass:"state-tag state-tag--nonplanned",attrs:{"title":"Работникът е допълнително добавен без да е планиран"}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"17","height":"17","viewBox":"0 0 17 17"}},[_c('path',{attrs:{"id":"add","d":"M9,2a4,4,0,1,0,4,4A4,4,0,0,0,9,2ZM4.009,11A2,2,0,0,0,2,13a4.352,4.352,0,0,0,2.135,3.8A9.142,9.142,0,0,0,9,18c.411,0,.816-.019,1.21-.057A5.5,5.5,0,0,1,10.257,11ZM14.5,19A4.5,4.5,0,1,0,10,14.5,4.5,4.5,0,0,0,14.5,19Zm0-7a.5.5,0,0,1,.5.5V14h1.5a.5.5,0,0,1,0,1H15v1.5a.5.5,0,0,1-1,0V15H12.5a.5.5,0,0,1,0-1H14V12.5A.5.5,0,0,1,14.5,12Z","transform":"translate(-2 -2)","fill":"#212121"}})])]):_c('span',{staticClass:"state-tag",attrs:{"title":"Успешно отчетен планиран работник"}},[_c('svg',{attrs:{"xmlns":"http://www.w3.org/2000/svg","width":"17","height":"17","viewBox":"0 0 17 17"}},[_c('path',{attrs:{"id":"ok","d":"M5,6a4,4,0,1,1,4,4A4,4,0,0,1,5,6ZM2,13a2,2,0,0,1,2.009-2h6.248a5.5,5.5,0,0,0-.047,6.943c-.395.038-.8.057-1.21.057a9.142,9.142,0,0,1-4.865-1.2A4.352,4.352,0,0,1,2,13Zm17,1.5A4.5,4.5,0,1,1,14.5,10,4.5,4.5,0,0,1,19,14.5Zm-2.146-1.854a.5.5,0,0,0-.707,0L13.5,15.293l-.646-.646a.5.5,0,0,0-.707.707l1,1a.5.5,0,0,0,.707,0l3-3A.5.5,0,0,0,16.854,12.646Z","transform":"translate(-2 -2)","fill":"#212121"}})])])]:_vm._e(),_c('WorktimeTag',{attrs:{"date":_vm.report.date,"start":_vm.worker.startHour,"end":_vm.worker.endHour}})],2)
}
var staticRenderFns = []

export { render, staticRenderFns }