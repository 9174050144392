<template>
  <div class="hours-and-tags">
    <SelectHour
      :reported="
        report.reported ? report.reported.indexOf(worker._id) > -1 : false
      "
      :reportedNonplanned="
        report.reportedNonplanned
          ? report.reportedNonplanned.indexOf(worker._id) > -1
          : false
      "
      v-model="worker.startHour"
      :end="worker.endHour"
      @change="$emit('changeWorker')"
    />
    <SelectHour
      :reported="
        report.reported ? report.reported.indexOf(worker._id) > -1 : false
      "
      :reportedNonplanned="
        report.reportedNonplanned
          ? report.reportedNonplanned.indexOf(worker._id) > -1
          : false
      "
      v-model="worker.endHour"
      :start="worker.startHour"
      @change="$emit('changeWorker')"
    />
    <template
      v-if="
        (report.reported
          ? report.reported.length || report.notReported.length
          : false) && !worker.new
      "
    >
      <span
        class="state-tag state-tag--nonplanned"
        title="Работникът е допълнително добавен без да е планиран"
        v-if="
          report.reportedNonplanned
            ? report.reportedNonplanned.indexOf(worker._id) > -1
            : false
        "
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="17"
          viewBox="0 0 17 17"
        >
          <path
            id="add"
            d="M9,2a4,4,0,1,0,4,4A4,4,0,0,0,9,2ZM4.009,11A2,2,0,0,0,2,13a4.352,4.352,0,0,0,2.135,3.8A9.142,9.142,0,0,0,9,18c.411,0,.816-.019,1.21-.057A5.5,5.5,0,0,1,10.257,11ZM14.5,19A4.5,4.5,0,1,0,10,14.5,4.5,4.5,0,0,0,14.5,19Zm0-7a.5.5,0,0,1,.5.5V14h1.5a.5.5,0,0,1,0,1H15v1.5a.5.5,0,0,1-1,0V15H12.5a.5.5,0,0,1,0-1H14V12.5A.5.5,0,0,1,14.5,12Z"
            transform="translate(-2 -2)"
            fill="#212121"
          />
        </svg>
      </span>
      <span class="state-tag" title="Успешно отчетен планиран работник" v-else>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="17"
          height="17"
          viewBox="0 0 17 17"
        >
          <path
            id="ok"
            d="M5,6a4,4,0,1,1,4,4A4,4,0,0,1,5,6ZM2,13a2,2,0,0,1,2.009-2h6.248a5.5,5.5,0,0,0-.047,6.943c-.395.038-.8.057-1.21.057a9.142,9.142,0,0,1-4.865-1.2A4.352,4.352,0,0,1,2,13Zm17,1.5A4.5,4.5,0,1,1,14.5,10,4.5,4.5,0,0,1,19,14.5Zm-2.146-1.854a.5.5,0,0,0-.707,0L13.5,15.293l-.646-.646a.5.5,0,0,0-.707.707l1,1a.5.5,0,0,0,.707,0l3-3A.5.5,0,0,0,16.854,12.646Z"
            transform="translate(-2 -2)"
            fill="#212121"
          />
        </svg>
      </span>
    </template>
    <WorktimeTag
      :date="report.date"
      :start="worker.startHour"
      :end="worker.endHour"
    />
  </div>
</template>

<script>
import SelectHour from "./SelectHour";
import WorktimeTag from "./WorktimeTag";
import hours from "../mixins/hours.js";
export default {
  mixins: [hours],
  props: ["report", "worker"],
  components:{
    SelectHour,
    WorktimeTag
  },
  methods:{

  }
};
</script>

<style scoped>
</style>