<template>
  <div class="group-modal-wrapper">
    <div class="group-modal">
      <form>
        <!-- NAME -->
        <label for="groupModalName" class="input-label">{{
          $t("group-name")
        }}</label>
        <div class="input-wrapper" :class="{ invalid: !isNameValid }">
          <span class="tms2">D</span>
          <input
            id="groupModalName"
            type="text"
            :placeholder="$t('group-name')"
            v-model="group.name"
            @keyup="isNameValid = true"
            @keydown.enter.prevent
          />
        </div>
        <template v-if="group.users">
          <label for="groupModalUsers" class="input-label"
            >Работници в бригадата:</label
          >
          <div class="users-list" @click="isAddUsersModalOpened = true">
            <span class="tms">t</span>
            <p v-if="!group.users.length">{{ $t("add-workers") }}</p>
            <p v-else>{{ `${$t("edit")} ${$t("user-worker")}` }}</p>

            <span v-if="group.users.length" class="tms2">H</span>
            <p v-for="u in group.users" :key="u._id">{{ u.name }}</p>
          </div>
        </template>
        <div class="group-modal-actions">
          <div class="switch-container">
            <label class="switch">
              <input type="checkbox" v-model="group.isActive" />
              <span class="slider"></span>
            </label>
            <label v-if="group.isActive">{{ $t("active-single") }}</label>
            <label v-else>{{ $t("inactive-single") }}</label>
          </div>
          <div class="btns">
            <button
              v-if="!id"
              class="btn btn-new"
              type="submit"
              value="Submit"
              @click.prevent="create"
            >
              <span class="tms2">.</span>
              {{ $t("create") }}
            </button>
            <button
              v-else
              class="btn btn-new"
              type="submit"
              value="Submit"
              @click.prevent="edit"
            >
              <span class="tms2">.</span>
              {{ $t("edit") }}
            </button>
            <button class="btn btn-close" @click.prevent="closeModal(false)">
              <span class="tms">F</span>
              {{ $t("cancel") }}
            </button>
          </div>
        </div>
      </form>
    </div>
    <transition name="fade">
      <AddUsersModal
        v-if="isAddUsersModalOpened"
        :usersType="'noPermissions'"
        :incomingUsers="group.users"
      />
    </transition>
  </div>
</template>

<script>
import AddUsersModal from "../shared/AddUsersModal.vue";
import log from "../mixins/log.js";
export default {
  mixins: [log],
  props: ["id"],
  components: {
    AddUsersModal,
  },
  data() {
    return {
      isNameValid: true,
      isAddUsersModalOpened: false,
      group: {
        name: "",
        users: [],
        isActive: true,
      },
      users: [],
    };
  },
  methods: {
    getGroup() {
      this.$http
        .get(`${process.env.VUE_APP_BASE_URI}/group/${this.id}`)
        .then((response) => {
          this.group = response.data;
          this.getUsers();
        });
    },
    getUsers() {
      this.$http
        .get(`${process.env.VUE_APP_BASE_URI}/group/users/${this.id}`)
        .then((response) => {
          this.users = response.data;
          this.group.users = response.data;
        });
    },
    checkForm() {
      if (!this.group.name) {
        this.isNameValid = false;
        return false;
      }

      if(this.group.users.length < 2) {
        this.$snotify.error(this.$t('group-error-min-workers'))
        return false;
      }

      return true;
    },
    create() {
      if (this.checkForm()) {
        this.$http
          .post(`${process.env.VUE_APP_BASE_URI}/group`, this.group)
          .then((res) => {
             this.$snotify.success(this.$t('success-changes'));
            // this.newLog('newUser', this.user.username);

            // update users in group
            this.updateUsersInGroup(res.data);
            this.closeModal(true);
          })
          .catch((error) => {
            console.log(error.response);
            if(error.response.data.errorName) this.$snotify.error(this.$t(`${error.response.data.errorName}`));
             else this.$snotify.error(this.$t('something-went-wrong'));
          })
          .then(() => {});
      } else {
        // TODO: Do something
      }
    },
    edit() {
      if (this.checkForm()) {
        this.$http
          .put(`${process.env.VUE_APP_BASE_URI}/group`, this.group)
          .then(() => {
             this.$snotify.success(this.$t('success-changes'));
            // this.newLog('editUser', this.user.username);
            this.updateUsersInGroup(this.group);
            this.closeModal(true);
          })
          .catch((error) => {
            console.log(error.response);
            if(error.response.data.errorName) this.$snotify.error(this.$t(`${error.response.data.errorName}`));
             else this.$snotify.error(this.$t('something-went-wrong'));
          })
          .then(() => {});
      } else {
        // TODO: Do something
      }
    },
    // updates users group
    async updateUsersInGroup(group) {
      const promises = [];

      // this.users is before changes and group.users are the new selected in group
      let array =
        this.users.length > group.users.length ? this.users : group.users;

      for (let u of array) {
        promises.push(
          await new Promise((resolve) => {
            this.$http
              .get(`${process.env.VUE_APP_BASE_URI}/auth/users/${u._id}`)
              .then(async (res) => {
                let user = res.data;
                let inOtherGroup = false;

                if (
                  user.group &&
                  user.group._id &&
                  user.group._id.toString() !== group._id.toString()
                ) {
                  inOtherGroup = true;
                  this.$snotify.error(
                    `Внимание, ${user.name} е добавен в блигада ${user.group.name}.`
                  );
                }

                if (!inOtherGroup) {
                  user.group = null;

                  for (let gu of group.users) {
                    if (gu._id.toString() === user._id.toString())
                      user.group = group;
                  }

                  await new Promise((resolve) => {
                    this.$http
                      .put(
                        `${process.env.VUE_APP_BASE_URI}/auth/users/${user._id}`,
                        user
                      )
                      .then(() => {
                        resolve();
                      })
                      .catch((err) => {
                        console.log(err.response);
                        this.$snotify.error(
                        `${this.$t('something-went-wrong')}`
                        );
                      });
                  });
                }
                resolve();
              })
              .catch((err) => {
                console.log(err.response);
                this.$snotify.error(`${this.$t('something-went-wrong')}`);
              });
          })
        );
      }

      if (promises.length) {
        Promise.all(promises)
          .then(() => {})
          .catch((err) => {
            console.log(err.response);
            this.$snotify.error(`${this.$t('something-went-wrong')}`);
          });
      }
    },
    closeModal(update) {
      this.$emit("close", update);
    },
    closeAddWorkersModal() {
      this.isAddUsersModalOpened = false;
    },
    handleWorkers(workers) {
      // this.report[this.selectedWorkerType][this.selectedWorkerIndex].worker = w;
      this.group.users = [];

      for (let w of workers) {
        this.group.users.push(w);
      }
      this.isAddUsersModalOpened = false;
    },
    handleEvents(e) {
      if (e.code === "Enter") {
        if (this.id) {
          this.edit();
        } else {
          this.create();
        }
      } else if (e.code === "Escape") {
        this.closeModal(false);
      }
    },
  },
  mounted() {
    if (this.id) {
      this.getGroup();
    }

    window.addEventListener("keydown", this.handleEvents);
    this.$on("saveAddUsersModal", this.handleWorkers);
    this.$on("closeAddUsersModal", this.closeAddWorkersModal);
  },
  destroyed() {
    window.removeEventListener("keydown", this.handleEvents);
  },
};
</script>

<style scoped>
.group-modal-wrapper {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;
}
.group-modal {
  background-color: #fff;
  border-radius: 4px;
  /* border-top: 10px solid #6d2600; */
  display: flex;
  flex-direction: column;
  /* height: 60vh; */
  overflow: auto;
  padding: 10px 20px 0;
  width: 800px;
  -webkit-box-shadow: 10px 10px 204px -19px rgba(0, 0, 0, 0.52);
  -moz-box-shadow: 10px 10px 204px -19px rgba(0, 0, 0, 0.52);
  box-shadow: 10px 10px 204px -19px rgba(0, 0, 0, 0.52);
}
.group-modal-permissions {
  background-color: #f4f4f4;
  border-radius: 4px;
  display: flex;
  justify-content: center;
}
.group-modal-actions {
  display: flex;
  justify-content: space-between;
  padding: 20px 0;
}
.invalid .mck {
  color: #d91313;
}
.invalid input {
  background-color: #f8dcdc;
  color: #d91313;
}
.invalid input::placeholder {
  color: #d91313;
}
.invalid input::placeholder {
  color: #d91313;
}
.invalid input::placeholder {
  color: #d91313;
}
.invalid input::placeholder {
  color: #d91313;
}

/* Responsive */
@media screen and (max-width: 900px) {
  .group-modal {
    width: 90%;
  }
}
@media screen and (max-width: 500px) {
  .group-modal-actions {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .switch-container {
    display: flex;
    justify-content: center;
    margin-bottom: 1em;
  }
  .btns {
    justify-content: center;
  }
  .group-modal-permissions {
    flex-direction: column;
  }
}
@media screen and (max-height: 550px) {
  .group-modal {
    height: 100%;
  }
}
</style>
