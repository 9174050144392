import { render, staticRenderFns } from "./AddActivitiesModal.vue?vue&type=template&id=7b8e3201&scoped=true"
import script from "./AddActivitiesModal.vue?vue&type=script&lang=js"
export * from "./AddActivitiesModal.vue?vue&type=script&lang=js"
import style0 from "./AddActivitiesModal.vue?vue&type=style&index=0&id=7b8e3201&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7b8e3201",
  null
  
)

export default component.exports