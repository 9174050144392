<template>
  <div class="modal-wrapper">
    <div class="modal">
      <div class="modal-header">
        <div class="new-input">
          <span class="tms">N</span>
          <input type="text" :placeholder="$t('search')" v-model="search" />
        </div>
        <span class="tms modal-close" @click="close()">F</span>
      </div>
      <div class="modal-content">
        <table v-if="filteredUsers.length">
          <thead>
            <tr>
              <th></th>
              <th class="th-top">
                {{ $t('absence-paid') }}
              </th>
              <th class="th-space"></th>
              <th class="th-top">
                {{ $t('absence-unpaid') }}
              </th>
              <th class="th-space"></th>
              <th class="th-top">
                {{ $t('absence-illness') }}
              </th>
              <th class="th-space"></th>
              <th class="th-top">{{ $t('absence-compensation') }}</th>
              <th class="th-space"></th>
              <th colspan="2" class="th-top">{{ $t('absence-period') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="u in filteredUsers" :key="u._id">
              <td>
                {{ u.name }}
                <!-- <transition name="fade">
                  <img
                    class="warning"
                    src="../../assets/img/wrn.svg"
                    title="Променете периода на отсъствието"
                    v-if="typeof u.valid === 'boolean' && !u.valid"
                  />
                </transition> -->
              </td>
              <td>
                <label class="checkbox-wrapper">
                  <input
                    type="checkbox"
                    v-model="u.paid"
                    @change="checkboxToggle(u, 'paid', $event.target.checked)"
                  />
                  <span class="checkmark"></span>
                </label>
              </td>
              <td></td>
              <td>
                <label class="checkbox-wrapper">
                  <input
                    type="checkbox"
                    v-model="u.unpaid"
                    @change="checkboxToggle(u, 'unpaid', $event.target.checked)"
                  />
                  <span class="checkmark"></span>
                </label>
              </td>
              <td></td>
              <td>
                <label class="checkbox-wrapper">
                  <input
                    type="checkbox"
                    v-model="u.illness"
                    @change="checkboxToggle(u, 'illness', $event.target.checked)"
                  />
                  <span class="checkmark"></span>
                </label>
              </td>
              <td></td>
              <td>
                <label class="checkbox-wrapper">
                  <input
                    type="checkbox"
                    v-model="u.compensation"
                    @change="checkboxToggle(u, 'compensation', $event.target.checked)"
                  />
                  <span class="checkmark"></span>
                </label>
              </td>
              <td></td>
              <td class="add-hover" @click="openSelectDateModal(u, 'from')">
                <span class="tms2 date-icon">R</span>
                {{ $t("start") }}:
                <strong>{{ u.from | formatDate }}</strong>
              </td>
              <td class="add-hover" @click="openSelectDateModal(u, 'to')">
                <span class="tms2 date-icon">R</span>
                  {{$t("end")}}:
                <strong>{{ u.to | formatDate }}</strong>
              </td>
            </tr>
          </tbody>
          <tfoot>
            <tr>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
              <td></td>
            </tr>
          </tfoot>
        </table>
        <!-- NO RESULTS -->
        <p class="modal-content-no-results" v-if="users.length && !filteredUsers.length">
          {{ $t('no-results-for') }} '
          <strong>{{this.search}}</strong>'
        </p>
        <p class="modal-content-no-results" v-if="!loaded">{{ $t('loading') }}</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-new" @click="save()" :disabled="loading">
          <span class="tms">Y</span>
          {{ $t('save') }}
        </button>
      </div>
    </div>
    <transition name="fade">
      <SelectDateModal
        v-if="isSelectDateModalOpen"
        :title="selectedDateName === 'from' ?  $t('start') : $t('end')"
        :selected="selectedDate"
        @save="selectedDateInModal"
        @close="closeSelectDateModal"
      />
    </transition>
  </div>
</template>

<script>
import SelectDateModal from './SelectDateModal';
export default {
  props: ['selectedCalendarDate'],
  components: {
    SelectDateModal
  },
  data() {
    return {
      users: [],
      search: '',
      isSelectDateModalOpen: false,
      selectedDateName: null,
      selectedDate: null,
      selectedUser: null,
      loaded: false,
      loading: false
    };
  },

  computed: {
    filteredUsers() {
      return this.users.filter(u => {
        return u.name.toLowerCase().includes(this.search.toLowerCase());
      });
    }
  },
  methods: {
    getAllUsers() {
      this.loaded = false;
      this.$http
        .get(`${process.env.VUE_APP_BASE_URI}/auth/users?active=true`)
        .then(response => {
          this.users = response.data;
          this.users.map(u => {
            u.from = this.newDate(this.selectedCalendarDate);
            u.to = this.newDate(this.selectedCalendarDate);
          });
          this.loaded = true;
        });
    },
    checkboxToggle(u, type, val) {
      this.$nextTick(() => {
        const types = ['paid', 'unpaid', 'illness', 'compensation'];

        for (let t of types) {
          this.$set(u, t, t !== type ? false : val);
        }
      });

      this.validateUser(u);
    },
    newDate(d) {
      let date = new Date();
      if (d) {
        date = new Date(d);
      }

      date.setHours(12, 0, 0, 0);
      return date;
    },
    openSelectDateModal(u, d) {
      this.selectedUser = u;
      this.selectedDateName = d;
      this.selectedDate = u[d];
      this.isSelectDateModalOpen = true;
    },
    selectedDateInModal(d) {
      const date = this.newDate(d);
      if (this.selectedDateName === 'from' && date > this.selectedUser.to) {
        return this.$snotify.error(`${this.$t('start-error')}`);
      } else if (
        this.selectedDateName === 'to' &&
        date < this.selectedUser.from
      ) {
        return this.$snotify.error(
          `${this.$t('end-error')}`
        );
      }

      this.$set(this.selectedUser, this.selectedDateName, d);

      this.validateUser(this.selectedUser);

      this.closeSelectDateModal();
    },
    closeSelectDateModal() {
      this.isSelectDateModalOpen = false;
      this.selectedDateName = null;
      this.selectedDate = null;
      this.selectedUser = null;
    },
    close(update) {
      this.$emit('close', update);
    },
    validateUser(u) {
      if (u.paid || u.unpaid || u.illness || u.compensation) {
        this.loading = true;
        const url = `${process.env.VUE_APP_BASE_URI}/absence/validate`;
        const absence = {
          user: u._id,
          from: u.from,
          to: u.to
        };

        this.$http
          .post(url, absence)
          .then(res => {
            this.$set(u, 'valid', res.data.valid);

            if (!res.data.valid) {
              this.$snotify.error(`${u.name} ${this.$t('is-planned-or-reported')}`);
            }
          })
          .catch(error => {
            console.log(error);
          })
          .then(() => (this.loading = false));
      } else {
        this.$set(u, 'valid', null);
      }
    },
    isValid() {
      let count = 0;
      for (let u of this.users) {
        if (u.paid || u.unpaid || u.illness || u.compensation) {
          count++;
          if (!u.valid) {
            this.$snotify.error(`${this.$t('change-period-to')} ${u.name}`);
            return false;
          }
        }
      }
      if (!count) {
        this.$snotify.error(`${this.$t('select-at-least-one')}`);
        return false;
      }
      return true;
    },
    save() {
      if (!this.isValid()) {
        return;
      }

      const url = `${process.env.VUE_APP_BASE_URI}/absence`;
      const promises = [];
      for (let u of this.users) {
        if (u.paid || u.unpaid || u.illness || u.compensation) {
          const absence = {
            user: u._id,
            type: 'paid',
            from: u.from,
            to: u.to
          };

          if (u.unpaid) {
            absence.type = 'unpaid';
          } else if (u.illness) {
            absence.type = 'illness';
          } else if (u.compensation) {
            absence.type = 'compensation';
          }

          const p = new Promise((res, rej) => {
            this.$http
              .post(url, absence)
              .then(() => {
                res();
              })
              .catch(error => rej(error));
          });

          promises.push(p);
        }
      }

      Promise.all(promises)
        .then(() => {
          this.$snotify.success(`${this.$t('successfully-saved')}`);
          this.close(true);
        })
        .catch(err => {
          console.log(err);
        });
    },
    handleEvents(e) {
      if (e.code === 'Enter') {
        // this.save();
      } else if (e.code === 'Escape') {
        this.close();
      }
    }
  },
  mounted() {
    this.getAllUsers();
    window.addEventListener('keydown', this.handleEvents);
  },
  destroyed() {
    window.removeEventListener('keydown', this.handleEvents);
  }
};
</script>

<style scoped>
.modal {
  width: initial;
  min-width: 700px;
}
.modal-footer{
  justify-content: center;
}
table {
  border-collapse: collapse;
}
.th-top {
  background-color: #e5e5e5;
  border-bottom: 3px solid #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  font-size: 10px;
  line-height: 10px;
  min-width: 100px;
  padding: 7px 5px;
  vertical-align: middle;
}
.th-space {
  width: 10px;
}
tbody td {
  font-size: 12px;
  line-height: 12px;
  padding: 7px 10px;
}
tfoot td {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  height: 10px;
}

/* colors */
tbody tr:nth-child(even) td {
  background-color: #e5e5e5;
}
tbody tr td:first-child {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

tbody tr td:last-child {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  padding-left: 30px;
}
thead tr th:nth-child(2),
tbody tr td:nth-child(2),
tfoot tr td:nth-child(2) {
  background-color: #e1bee7;
}
tbody tr:nth-child(even) td:nth-child(2) {
  background-color: #caabcf;
}
thead tr th:nth-child(4),
tbody tr td:nth-child(4),
tfoot tr td:nth-child(4) {
  background-color: #cfd8dc;
}
tbody tr:nth-child(even) td:nth-child(4) {
  background-color: #bac2c6;
}
thead tr th:nth-child(6),
tbody tr td:nth-child(6),
tfoot tr td:nth-child(6) {
  background-color: #bbdefb;
}
tbody tr:nth-child(even) td:nth-child(6) {
  background-color: #a8c7e1;
}
thead tr th:nth-child(8),
tbody tr td:nth-child(8),
tfoot tr td:nth-child(8) {
  background-color: #b2dfdb;
}
tbody tr:nth-child(even) td:nth-child(8) {
  background-color: #a0c8c5;
}

.date-icon {
  font-size: 16px;
  line-height: 16px;
  margin-right: 10px;
  vertical-align: middle;
}

.checkbox-wrapper {
  display: flex;
  justify-content: center;
  margin: 0;
  padding: 0;
  vertical-align: middle;
}
.checkmark {
  background-color: #fff;
  border: 1px solid #656565;
  height: 22px;
  position: relative;
  width: 22px;
}
.checkbox-wrapper input:checked ~ .checkmark {
  border: 1px solid #ec6607;
}
.add-hover {
  cursor: pointer;
}
.add-hover:hover {
  opacity: 0.8;
}
.warning {
  height: 10px;
  margin-left: 5px;
  vertical-align: middle;
  transform: scale(1.5);
}

@media screen and (max-width: 1000px) {
  .modal {
    min-width: fit-content;
    width: 90%;
  }
  .modal-header .new-input {
    width: 72%;
  }
}
/* @media screen and (max-width: 600px) {
  .modal-content-check {
    width: 45%;
  }
  .modal-footer {
    flex-direction: column;
    height: 97px;
  }
  .select-btns {
    margin: 10px 0;
  }
  .btn.btn-new {
    margin: 0 0 10px 0;
  }
}
@media screen and (max-width: 400px) {
  .modal-content-check {
    width: 100%;
  }
} */
</style>
