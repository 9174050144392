<template>
  <div class="inquiry">
    <div class="inquiry-header">
      <h2 v-if="!reports.length" class="desktop">{{ $t("inquiry-create") }}</h2>
      <h2 v-else class="desktop">{{ $t("inquiry-view") }}</h2>
      <div class="inquiry-header-controlls" ref="top">
        <span class="inquiry-pre-datepicker-text desktop">{{
          $t("inquiry-period")
        }}</span>
        <div class="inquiry-datepicker" v-show="datePeriod !== 'all'">
          <div class="new-input-wrapper">
            <div class="new-input">
              <span class="tms">D</span>
              <input
                type="text"
                :placeholder="$t('date-from')"
                @click="openDropdown(0)"
                @focus="openDropdown(0)"
                @blur="closeDropdown(0)"
                v-model="formatedFromDate"
                readonly
              />
            </div>
          </div>
          <div class="new-input-wrapper">
            <div class="new-input">
              <span class="tms">D</span>
              <input
                type="text"
                :placeholder="$t('date-to')"
                @click="openDropdown(1)"
                @focus="openDropdown(1)"
                @blur="closeDropdown(1)"
                v-model="formatedToDate"
                readonly
              />
            </div>
          </div>
          <div
            class="new-input-dropdown datepicker-dropdown"
            :class="{ 'datepicker-dropdown-active': openedDropdown[0] }"
          >
            <datepicker
              :language="this[$i18n.locale]"
              :inline="true"
              :monday-first="true"
              :wrapper-class="'custom-datepicker'"
              :calendar-class="'custom-calendar'"
              v-model="inquiry.fromDate"
              @selected="closeDropdown(0), changeDatePeriod('none')"
            ></datepicker>
          </div>
          <div
            class="new-input-dropdown datepicker-dropdown"
            :class="{ 'datepicker-dropdown-active': openedDropdown[1] }"
          >
            <datepicker
              :language="this[$i18n.locale]"
              :inline="true"
              :monday-first="true"
              :wrapper-class="'custom-datepicker'"
              :calendar-class="'custom-calendar'"
              v-model="inquiry.toDate"
              @selected="closeDropdown(1), changeDatePeriod('none')"
            ></datepicker>
          </div>
        </div>
        <div class="date-toggles">
          <span
            class="tms2 date-toggle"
            :class="{ 'date-toggle-active': datePeriod === 'current' }"
            @click="changeDatePeriod('current')"
            :title="$t('reports-for-current-month')"
            >X</span
          >
          <span
            class="tms2 date-toggle"
            :class="{ 'date-toggle-active': datePeriod === 'previous' }"
            @click="changeDatePeriod('previous')"
            :title="$t('reports-for-previous-month')"
            >Z</span
          >
          <span
            class="tms2 date-toggle"
            :class="{ 'date-toggle-active': datePeriod === 'half' }"
            @click="changeDatePeriod('half')"
            :title="$t('reports-for-six-months')"
            >V</span
          >
          <span
            class="tms2 date-toggle"
            :class="{ 'date-toggle-active': datePeriod === 'year' }"
            @click="changeDatePeriod('year')"
            :title="$t('reports-for-one-year')"
            >U</span
          >
          <span
            class="tms2 date-toggle"
            :class="{ 'date-toggle-active': datePeriod === 'all' }"
            @click="changeDatePeriod('all')"
            :title="$t('reports-for-all-times')"
            >W</span
          >
        </div>
      </div>
    </div>
    <div class="inquiry-filters">
      <div class="inquiry-filters-inputs">
        <!-- DESKTOP COMPANY  -->
        <div class="new-input-wrapper">
          <label>{{ $t("company") }}</label>
          <div class="new-input">
            <span class="tms">N</span>
            <!-- NOT SELECTED -->
            <input
              id="companyInput"
              v-if="!inquiry.company._id"
              type="text"
              :placeholder="$t('company')"
              @focus="getCompanies(), openDropdown(2)"
              @blur="closeDropdown(2)"
              v-model="searchCompanies"
            />
            <!-- SELECTED -->
            <input
              v-else
              type="text"
              :placeholder="$t('company')"
              @focus="getCompanies(), openDropdown(2)"
              @blur="closeDropdown(2)"
              v-model="inquiry.company.companyName"
              :disabled="inquiry.company._id"
            />
            <span
              v-if="inquiry.company._id"
              class="clear"
              @click="resetSelectedCompany()"
              >F</span
            >
            <span
              @click.prevent="getCompanies(), focusInput('companyInput', 2)"
              class="arrow"
              v-else
              :class="{ 'arrow-reversed': openedDropdown[2] }"
              >K</span
            >
          </div>
          <div
            class="new-input-dropdown"
            :class="{ 'new-input-dropdown-active': openedDropdown[2] }"
          >
            <ul>
              <li
                v-for="c in filteredCompanies()"
                :key="c._id"
                @click="selectCompany(c)"
              >
                {{ c.companyName }}
              </li>
              <!-- <li v-for="c in companies" :key="c._id">{{c.companyName}}</li> -->
            </ul>
          </div>
        </div>
        <!-- PROJECT -->
        <div class="new-input-wrapper">
          <label>{{ $t("project") }}</label>
          <div class="new-input">
            <span class="tms">N</span>
            <!-- NOT SELECTED -->
            <input
              id="projectInput"
              v-if="!inquiry.project._id"
              type="text"
              :placeholder="$t('project')"
              @focus="getProjects(), openDropdown(3)"
              @blur="closeDropdown(3)"
              v-model="searchProjects"
            />
            <!-- SELECTED -->
            <input
              v-else
              type="text"
              :placeholder="$t('project')"
              @focus="getProjects(), openDropdown(3)"
              @blur="closeDropdown(3)"
              v-model="inquiry.project.projectName"
              :disabled="inquiry.project._id"
            />
            <span
              v-if="inquiry.project._id"
              class="clear"
              @click="resetSelectedProject()"
              >F</span
            >
            <span
              @click.prevent="getObjects(), focusInput('projectInput', 3)"
              class="arrow"
              v-else
              :class="{ 'arrow-reversed': openedDropdown[3] }"
              >K</span
            >
          </div>
          <div
            class="new-input-dropdown"
            :class="{ 'new-input-dropdown-active': openedDropdown[3] }"
          >
            <ul>
              <li
                v-for="p in filteredProjects()"
                :key="p._id"
                @click="selectProject(p)"
              >
                {{ p.projectName }}
              </li>
            </ul>
          </div>
        </div>
        <!-- ОBJECT -->
        <div class="new-input-wrapper">
          <label>{{ $t("site") }}</label>
          <div class="new-input">
            <span class="tms">N</span>
            <!-- NOT SELECTED -->
            <input
              id="objectInput"
              v-if="!inquiry.object._id"
              type="text"
              :placeholder="$t('site')"
              @focus="getObjects(), openDropdown(4)"
              @blur="closeDropdown(4)"
              v-model="searchObjects"
            />
            <!-- SELECTED -->
            <input
              v-else
              type="text"
              :placeholder="$t('site')"
              @focus="getObjects(), openDropdown(4)"
              @blur="closeDropdown(4)"
              v-model="inquiry.object.objectName"
              :disabled="inquiry.object._id"
            />
            <span
              v-if="inquiry.object._id"
              class="clear"
              @click="resetSelectedObject()"
              >F</span
            >
            <span
              @click.prevent="getObjects(), focusInput('objectInput', 4)"
              class="arrow"
              v-else
              :class="{ 'arrow-reversed': openedDropdown[4] }"
              >K</span
            >
          </div>
          <div
            class="new-input-dropdown"
            :class="{ 'new-input-dropdown-active': openedDropdown[4] }"
          >
            <ul>
              <li
                v-for="o in filteredObjects()"
                :key="o._id"
                @click="selectObject(o)"
              >
                {{ o.objectName }}
              </li>
            </ul>
          </div>
        </div>
        <!-- PART -->
        <div class="new-input-wrapper">
          <label>{{ $t("part") }}</label>
          <div class="new-input">
            <span class="tms">N</span>
            <input
              id="partInput"
              v-if="!inquiry.part._id"
              type="text"
              :placeholder="$t('part')"
              @focus="getParts(), openDropdown(5)"
              @blur="closeDropdown(5)"
              v-model="searchParts"
            />
            <!-- SELECTED -->
            <input
              v-else
              type="text"
              :placeholder="$t('part')"
              @focus="getParts(), openDropdown(5)"
              @blur="closeDropdown(5)"
              v-model="inquiry.part.partName"
              :disabled="inquiry.part._id"
            />
            <span
              v-if="inquiry.part._id"
              class="clear"
              @click="resetSelectedPart()"
              >F</span
            >
            <span
              @click.prevent="getObjects(), focusInput('partInput', 5)"
              class="arrow"
              v-else
              :class="{ 'arrow-reversed': openedDropdown[5] }"
              >K</span
            >
          </div>
          <div
            class="new-input-dropdown"
            :class="{ 'new-input-dropdown-active': openedDropdown[5] }"
          >
            <ul>
              <li
                v-for="p in filteredParts()"
                :key="p._id"
                @click="selectPart(p)"
              >
                {{ p.partName }}
              </li>
            </ul>
          </div>
        </div>
        <!-- MATERIAL -->
        <div class="new-input-wrapper">
          <label>{{ $t("mdm") }}</label>
          <div class="new-input">
            <span class="tms">N</span>
            <input
              id="matInput"
              type="text"
              :placeholder="$t('mdm')"
              @focus="openDropdown(6), getMDM()"
              @blur="closeDropdown(6)"
              @keyup="getMDM()"
              v-model="inquiry.search"
            />
            <!-- <span v-if="isInquiryMade" class="disabled">A</span> -->
            <span
              v-if="inquiry.search"
              class="clear"
              @click="(inquiry.search = ''), resetReports()"
              >F</span
            >
            <span
              @click.prevent="getObjects(), focusInput('matInput', 6)"
              class="arrow"
              v-else
              :class="{ 'arrow-reversed': openedDropdown[6] }"
              >K</span
            >
          </div>
          <div
            class="new-input-dropdown"
            :class="{ 'new-input-dropdown-active': openedDropdown[6] }"
          >
            <ul>
              <li
                v-for="(x, i) in mdm"
                :key="`mdm-${i}`"
                @click="(inquiry.search = x), resetReports()"
              >
                {{ x }}
              </li>
            </ul>
          </div>
        </div>
        <!-- NOT NEEDED IN TMS BUT I WILL LEAVE IT HERE JUST IN CASE -->
        <!-- DEKTOP REPORTER -->
        <!-- <div class="new-input-wrapper">
          <label>Отчетник</label>
          <div class="new-input">
            <span class="tms">N</span>
            <input
              v-if="!inquiry.reporter._id"
              type="text"
              placeholder="Отчетник"
              @focus="getReporters(), openDropdown(6)"
              @blur="closeDropdown(6)"
              v-model="searchReporters"
              :disabled="inquiry.worker._id.length > 0"
            >
            <input
              v-else
              type="text"
              placeholder="Отчетник"
              @focus="getReporters(), openDropdown(6)"
              @blur="closeDropdown(6)"
              v-model="inquiry.reporter.name"
              :disabled="inquiry.worker._id.length > 0 || inquiry.reporter._id"
            >
            <span
              v-if="inquiry.reporter._id && getUser.permissions.isAdmin"
              class="clear"
              @click="resetSelectedReporter()"
            >F</span>
            <span
              v-if="inquiry.worker._id.length > 0 || (!getUser.permissions.isAdmin && getUser.permissions.isReporter)"
              class="disabled"
            >A</span>
          </div>
          <div
            class="new-input-dropdown"
            :class="{ 'new-input-dropdown-active' : openedDropdown[6] }"
          >
            <ul>
              <li
                v-for="r in filteredReporters()"
                :key="r._id"
                @click="selectReporter(r)"
              >{{r.name}}</li>
            </ul>
          </div>
        </div>-->
        <!-- WORKER -->
        <!-- <div class="new-input-wrapper">
          <label>{{ $t("performer") }}</label>
          <div class="new-input">
            <span class="tms">N</span>
            <input
              v-if="!inquiry.worker._id"
              type="text"
              :placeholder="$t('performer')"
              @focus="getWorkers(), openDropdown(7)"
              @blur="closeDropdown(7)"
              v-model="searchWorkers"
              :disabled="inquiry.reporter._id.length > 0"
            />
            SELECTED
            <input
              v-else
              type="text"
              :placeholder="$t('worker')"
              @focus="getWorkers(), openDropdown(7)"
              @blur="closeDropdown(7)"
              v-model="inquiry.worker.name"
              :disabled="inquiry.reporter._id.length > 0 || inquiry.worker._id"
            />
            <span
              v-if="inquiry.worker._id"
              class="clear"
              @click="resetSelectedWorker()"
              >F</span
            >
            <span v-if="inquiry.reporter._id.length > 0" class="disabled"
              >A</span
            >
          </div>
          <div
            class="new-input-dropdown"
            :class="{ 'new-input-dropdown-active': openedDropdown[7] }"
          >
            <ul>
              <li
                v-for="w in filteredWorkers()"
                :key="w._id"
                @click="selectWorker(w)"
              >
                {{ w.name }}
              </li>
            </ul>
          </div>
        </div> -->

        <section class="users-inputs-wrapper">
          <!-- USERS TYPES dropdown -->
          <div
            class="new-input-wrapper new-input-wrapper--big"
            :class="{ 'new-input-wrapper--disabled': notAdminOrSuperAdmin }"
          >
            <div class="new-input new-input--user">
              <input
                id="userTypesInput"
                type="text"
                :placeholder="$t('user-types')"
                :disabled="notAdminOrSuperAdmin"
                @focus="openDropdown(7)"
                @blur="closeDropdown(7)"
                v-model="searchUsersTypes"
              />

              <span
                v-if="!notAdminOrSuperAdmin"
                @click.prevent="focusInput('userTypesInput', 7)"
                class="arrow"
                :class="{ 'arrow-reversed': openedDropdown[7] }"
                >K</span
              >
            </div>
            <div
              class="new-input-dropdown"
              :class="{
                'new-input-dropdown-active new-input-dropdown-active--fit-content':
                  openedDropdown[7],
              }"
            >
              <div
                class="option option--checkbox"
                v-for="(w, wi) in usersTypes"
                :key="w._id"
                @mousedown="selectUsersType(w, false, wi)"
              >
                <Checkbox :value="selectedUsersType[w.name]" :label="w.name" />
              </div>
            </div>
          </div>
          <!-- USERS TYPES dropdown -->

          <!-- USERS dropdown -->
          <div
            class="new-input-wrapper new-input-wrapper--big"
            :class="{ 'new-input-wrapper--disabled': notAdminOrSuperAdmin }"
          >
            <div class="new-input">
              <span class="tms">N</span>
              <input
                id="selectedUserTypesInput"
                v-if="!inquiry.worker._id && !inquiry.reporter._id"
                type="text"
                :placeholder="
                  !Object.entries(selectedUsersType).length
                    ? $t('select-users-type')
                    : $t('search-in') + getSelectedUsersType()
                "
                @focus="openDropdown(8)"
                @blur="closeDropdown(8)"
                v-model="searchAllUsers"
                :disabled="
                  !Object.entries(selectedUsersType).length ||
                  notAdminOrSuperAdmin
                "
              />
              <!-- SELECTED -->
              <input
                v-else
                type="text"
                :placeholder="$t('search-in') + getSelectedUsersType()"
                @focus="openDropdown(8)"
                @blur="closeDropdown(8)"
                :value="
                  inquiry.worker._id ? inquiry.worker.name : inquiry.worker.name
                "
                :disabled="
                  inquiry.worker._id ||
                  inquiry.reporter._id ||
                  !Object.entries(selectedUsersType).length ||
                  notAdminOrSuperAdmin
                "
              />

              <span
                v-if="!notAdminOrSuperAdmin"
                @click.prevent="focusInput('selectedUserTypesInput', 8)"
                class="arrow"
                :class="{ 'arrow-reversed': openedDropdown[8] }"
                >K</span
              >

              <span
                v-if="
                  (inquiry.worker._id || inquiry.reporter._id) &&
                  !notAdminOrSuperAdmin
                "
                class="clear"
                @click="resetSelectedUser()"
                >F</span
              >
              <span
                v-else-if="!Object.entries(selectedUsersType).length"
                class="disabled"
                >A</span
              >
            </div>
            <div
              class="new-input-dropdown"
              :class="{ 'new-input-dropdown-active': openedDropdown[8] }"
            >
              <div
                class="option"
                v-for="w in filteredAllUsers()"
                :key="w._id"
                @mousedown="selectUser(w)"
              >
                <span>{{ w.name }}</span>
              </div>
            </div>
          </div>
          <!-- USERS dropdown -->
        </section>
      </div>
      <div class="inquiry-filters-btns">
        <div class="flag-toggles">
          <p>{{ $t("amounts-entered") }}</p>
          <div
            class="flag-toggle"
            :class="{ 'flag-toggle-active': inquiry.priceStatus === 0 }"
            @click="selectPriceStatus(0)"
          >
            <img
              class="flag-indicator"
              src="../assets/img/red.svg"
              :alt="$t('no-amounts-entered')"
              :title="$t('no-amounts-entered')"
            />
          </div>
          <div
            class="flag-toggle"
            :class="{ 'flag-toggle-active': inquiry.priceStatus === 1 }"
            @click="selectPriceStatus(1)"
          >
            <img
              class="flag-indicator"
              src="../assets/img/yellow.svg"
              :alt="$t('missing-amounts-entered')"
              :title="$t('missing-amounts-entered')"
            />
          </div>
          <div
            class="flag-toggle"
            :class="{ 'flag-toggle-active': inquiry.priceStatus === 2 }"
            @click="selectPriceStatus(2)"
          >
            <img
              class="flag-indicator"
              src="../assets/img/green.svg"
              :alt="$t('all-amounts-entered')"
              :title="$t('all-amounts-entered')"
            />
          </div>
        </div>
        <div class="checkboxes-wrapper">
          <Checkbox
            v-model="activeProjects"
            @input="selectActiveProjects()"
            :label="$t('active-sites')"
          />
          <Checkbox
            v-model="inquiry.isInvalid"
            @input="selectInvalid()"
            :label="$t('invalid-reports')"
          />
        </div>
        <!-- DESKTOP BUTTONS -->
        <button
          v-show="!reports.length"
          class="btn btn-new"
          @click="getInquiry()"
        >
          {{ $t("make-inquiry") }}
        </button>
        <div v-show="reports.length" class="btns desktop">
          <!-- @click="exportFile()" -->
          <button
            class="btn btn-excel"
            @click="exportToExcelFile()"
            ref="excelBtn"
          >
            <span class="tms">E</span>
            {{ $t("export-to-excel") }}
          </button>
          <button class="btn btn-reset" @click="resetInquiry()">
            <span class="tms">F</span>
            {{ $t("reset-filters") }}
          </button>
        </div>
      </div>
    </div>
    <div class="inquiry-title-wrapper" v-if="isInquiryMade">
      <h3
        class="inquiry-title"
        :class="{ 'inquiry-title-invalid': !activeProjects }"
        ref="reports"
      >
        <span>{{ $t("inquiry") }}&nbsp;</span>
        <span v-if="inquiry.company._id">
          <span>{{ $t("inquiry-for-company") }}&nbsp;</span>
          <strong>{{ inquiry.company.companyName }}&nbsp;</strong>
        </span>
        <span v-else>
          <span>
            {{ $t("inquiry-for") }}
            <template v-if="inquiry.isInvalid"
              >{{ $t("inquiry-invalid") }}&nbsp;</template
            >
            {{ $t("inquiry-reports-for") }}&nbsp;
          </span>
          <strong>
            {{ $t("inquiry-all") }}
            <template v-if="!activeProjects"
              >{{ $t("inquiry-inactive") }}&nbsp;</template
            >
            {{ $t("companies").toLowerCase() }}&nbsp;
          </strong>
        </span>
        <span v-if="inquiry.project._id">
          <span>{{ $t("project").toLowerCase() }}&nbsp;</span>
          <strong>{{ inquiry.project.projectName }}&nbsp;</strong>
        </span>
        <span v-if="inquiry.object._id">
          <span>{{ $t("site").toLowerCase() }}&nbsp;</span>
          <strong>{{ inquiry.object.objectName }}&nbsp;</strong>
        </span>
        <span v-if="inquiry.part._id">
          <span>{{ $t("part").toLowerCase() }}&nbsp;</span>
          <strong>{{ inquiry.part.partName }}&nbsp;</strong>
        </span>
        <span v-if="inquiry.reporter._id">
          <span>{{ $t("reporter").toLowerCase() }}&nbsp;</span>
          <strong>{{ inquiry.reporter.name }}&nbsp;</strong>
        </span>
        <span v-if="inquiry.worker._id">
          <span
            >{{
              `${$t("for")} ${getUserPermissionName(inquiry.worker)}`
            }}&nbsp;</span
          >
          <!-- <span>{{ $t("worker").toLowerCase() }}&nbsp;</span> -->
          <strong>{{ inquiry.worker.name }}&nbsp;</strong>
        </span>
        <span v-if="inquiry.priceStatus === 0">
          <strong>{{ $t("inquiry-no-amounts") }}</strong>
        </span>
        <span v-if="inquiry.priceStatus === 1">
          <strong>{{ $t("inquiry-missing-amounts") }}</strong>
        </span>
        <span v-if="inquiry.priceStatus === 2">
          <strong>{{ $t("inquiry-all-amounts") }}</strong>
        </span>
      </h3>
      <h3
        class="inquiry-title"
        v-if="inquiry.search"
        :class="{ 'inquiry-title-invalid': !activeProjects }"
      >
        {{ $t("inquiry-mdm") }} -
        <strong>{{ inquiry.search }}</strong>
      </h3>
      <h3
        class="inquiry-title"
        :class="{ 'inquiry-title-invalid': !activeProjects }"
      >
        <template v-if="datePeriod !== 'all'">
          <span>{{ $t("inquiry-period-from") }}&nbsp;</span>
          <strong>{{ formatedFromDate }}</strong>
          <span>&nbsp;{{ $t("inquiry-period-to") }}&nbsp;</span>
          <strong>{{ formatedToDate }}</strong>
        </template>
        <template v-else>
          <span>
            {{ $t("inquiry-period-for") }}
            <strong>{{ $t("inquiry-period-all") }}</strong>
          </span>
        </template>
      </h3>
    </div>
    <div v-if="!isInquiryMade" class="no-reports">
      <p>{{ $t("inquiry-select-filters") }}</p>
    </div>
    <div
      class="no-reports"
      v-if="isInquiryMade && !reports.length && !getLoading"
      ref="no-reports"
    >
      <p>{{ $t("inquiry-no-reports") }}</p>
    </div>
    <div class="no-reports" v-if="getLoading">
      <p>{{ $t("inquiry-loading") }}</p>
      <img class="loading" src="../assets/img/loading_dark.svg" alt="loading" />
    </div>
    <div class="reports" v-if="reports.length && isInquiryMade">
      <div class="reports-tabs">
        <button
          class="reports-tab"
          :class="{ 'reports-tab-active': activeTab === 0 }"
          @click="selectTab(0)"
        >
          <span>{{ $t("reports") }}</span>
          <span v-if="!this.inquiry.reporter._id">({{ reports.length }})</span>
        </button>
        <button
          class="reports-tab"
          :class="{ 'reports-tab-active': activeTab === 1 }"
          @click="selectTab(1)"
        >
          <span>{{ $t("materials") }}</span>
          <span v-if="!this.inquiry.reporter._id"
            >({{ totalMaterialQuantity }})</span
          >
        </button>
        <button
          class="reports-tab"
          :class="{ 'reports-tab-active': activeTab === 2 }"
          @click="selectTab(2)"
        >
          <span>{{ $t("Activities") }}</span>
          <span v-if="!this.inquiry.reporter._id"
            >({{ totalActivityQuantity }})</span
          >
        </button>
        <button
          class="reports-tab"
          :class="{ 'reports-tab-active': activeTab === 3 }"
          @click="selectTab(3)"
        >
          <span>{{ $t("mechanizations") }}</span>
          <span v-if="!this.inquiry.reporter._id"
            >({{ totalMechanizationQuantity }})</span
          >
        </button>
        <button
          class="reports-tab"
          :class="{ 'reports-tab-active': activeTab === 4 }"
          @click="selectTab(4)"
        >
          <span>{{ $t("Workers") }}</span>
          <span>({{ totalWorkersItems }})</span>
        </button>
        <button
          class="reports-tab"
          :class="{ 'reports-tab-active': activeTab === 6 }"
          @click="selectTab(6)"
        >
          <span>{{ $t("users-groups") }}</span>
          <span>({{ totalGroupsItems }})</span>
        </button>
        <button
          class="reports-tab"
          :class="{ 'reports-tab-active': activeTab === 5 }"
          @click="selectTab(5)"
        >
          <span>{{ $t("summary") }}</span>
        </button>
      </div>
      <div class="reports-content">
        <!-- REPORTS -->
        <div class="table-wrapper">
          <table class="table" ref="reportsTable" v-show="activeTab === 0">
            <thead>
              <tr>
                <th>{{ $t("date") | capitalize }}</th>
                <th>{{ $t("reporter") }}</th>
                <th>{{ $t("company") }}</th>
                <th>{{ $t("project") }}</th>
                <th>{{ $t("site") }}</th>
                <th>{{ $t("part") }}</th>
                <!-- <th class="center">Отчетени<br>&nbsp;хора&nbsp;</th>
                <th class="center">Общо отчетено<br>&nbsp;време&nbsp;</th>-->
                <th class="center">
                  <template v-if="inquiry.search">{{
                    $t("filtered")
                  }}</template>
                  <template v-else>{{ $t("reported") }}</template>
                  <br />
                  &nbsp;{{ $t("materials") }}&nbsp;
                </th>
                <th class="center">
                  <template v-if="inquiry.search">{{
                    $t("filtered")
                  }}</template>
                  <template v-else>{{ $t("reported") }}</template>
                  <br />
                  &nbsp;{{ $t("activities") }}&nbsp;
                </th>
                <!-- <th class="center">
                  <template v-if="inquiry.search">{{ $t('filtered') }}</template>
                  <template v-else>{{ $t('reported') }}</template>
                  <br />
                  &nbsp;{{ $t('mechanizations') }}&nbsp;
                </th> -->
                <th
                  v-if="
                    getUser.permissions.isSuperAdmin ||
                    getUser.permissions.isAdmin
                  "
                ></th>
              </tr>
            </thead>
            <tbody>
              <tr
                v-for="r in reports"
                :key="`report-${r._id}`"
                @click="goToReport(r._id)"
                :class="{ 'tr-invalid': r.isInvalid }"
              >
                <td>
                  <span class="no-wrap">{{ r.date | formatDate }} г.</span>
                </td>
                <td>
                  <span>{{ r.createdBy.name }}</span>
                </td>
                <td class="td-company-name">
                  <img
                    class="td-invalid-flag"
                    v-if="r.isInvalid"
                    src="../assets/img/flag_yes.svg"
                    alt="invalid"
                  />
                  {{ r.company.companyName }}
                </td>
                <td>{{ r.project.projectName }}</td>
                <td>{{ r.object.objectName }}</td>
                <td>
                  <span v-if="r.part">{{ r.part.partName }}</span>
                </td>
                <!-- <td class="center"> -->
                <!-- {{report.workers.length}} -->
                <!-- </td> -->
                <!-- <td class="center"> -->
                <!-- Look here -->
                <!-- <div class="worktime-indicator-wrapper">
                    <p
                      v-if="calculateHours(report.date, report.startHour, report.endHour).normal * report.workers.length"
                    >
                      <span class="worktime-indicator">НРВ</span>
                      {{calculateHours(report.date, report.startHour, report.endHour).normal * report.workers.length}} ч.
                    </p>
                    <p
                      v-if="calculateHours(report.date, report.startHour, report.endHour).overtime * report.workers.length"
                    >
                      <span class="worktime-indicator overtime-indicator">ИРВ 1</span>
                      {{calculateHours(report.date, report.startHour, report.endHour).overtime * report.workers.length}} ч.
                    </p>
                    <p
                      v-if="calculateHours(report.date, report.startHour, report.endHour).holiday * report.workers.length"
                    >
                      <span class="worktime-indicator holiday-indicator">ИРВ 2</span>
                      {{calculateHours(report.date, report.startHour, report.endHour).holiday * report.workers.length}} ч.
                    </p>
                </div>-->
                <!-- </td> -->
                <td class="center">
                  <template v-if="r.materials">{{
                    r.materials.length
                  }}</template>
                </td>
                <td class="center">
                  <template v-if="r.activities">{{
                    r.activities.length
                  }}</template>
                </td>
                <!-- <td class="center">
                  <template v-if="r.mechanizations">{{r.mechanizations.length}}</template>
                </td> -->
                <td
                  v-if="
                    getUser.permissions.isSuperAdmin ||
                    getUser.permissions.isAdmin
                  "
                >
                  <img
                    class="flag-indicator"
                    v-if="r.priceStatus === 2"
                    src="../assets/img/green.svg"
                    :alt="$t('all-amounts-entered')"
                    :title="$t('all-amounts-entered')"
                  />
                  <img
                    class="flag-indicator"
                    v-else-if="r.priceStatus === 1"
                    src="../assets/img/yellow.svg"
                    :alt="$t('missing-amounts-entered')"
                    :title="$t('missing-amounts-entered')"
                  />
                  <img
                    class="flag-indicator"
                    v-else-if="r.priceStatus === 0"
                    src="../assets/img/red.svg"
                    :alt="$t('no-amounts-entered')"
                    :title="$t('no-amounts-entered')"
                  />
                </td>
              </tr>
              <tr v-if="isInquiryMade && !reports.length">
                <td colspan="9" class="td-center">
                  {{ $t("inquiry-no-reports") }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- MATERIALS -->
        <div class="table-wrapper">
          <table class="table" ref="materials" v-show="activeTab === 1">
            <thead>
              <tr>
                <th>{{ $t("date") | capitalize }}</th>
                <th>{{ $t("reporter") }}</th>
                <th>{{ $t("material") }}</th>
                <th>{{ $t("brigade") }} / {{ $t("employee") }}</th>
                <th>{{ $t("quantity") }}</th>
                <th>
                  {{ $t("metric") }}&nbsp;
                  <br />
                  {{ $t("unit") }}
                </th>
                <th
                  v-if="
                    getUser.permissions.isSuperAdmin ||
                    getUser.permissions.isAdmin
                  "
                >
                  {{ $t("price-total") }}&nbsp;
                  <br />
                  {{ $t("price-in") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="r in reports">
                <template v-for="w in r.workers">
                  <tr
                    v-for="(m, i) in r.materials"
                    :key="`r-${r._id}-ma-${m._id}-${i}-wo-${w._id}`"
                    @click="goToReport(r._id)"
                    :class="{
                      'tr-invalid': r.isInvalid,
                      'additional-row': m.isAdditional,
                    }"
                  >
                    <td>
                      <span class="no-wrap">{{ r.date | formatDate }} г.</span>
                    </td>
                    <td>{{ r.createdBy.name }}</td>
                    <td>
                      <span v-if="m.isAdditional">⚡</span>
                      {{ m.name }}
                    </td>
                    <td>
                      {{ w.name }}
                    </td>
                    <td>
                      <p v-if="w.users">
                        {{
                          (
                            (+m.quantity / w.totalWorkers) *
                            w.users.length
                          ).toFixed(2)
                        }}
                      </p>
                      <p v-else>
                        {{ (+m.quantity / w.totalWorkers).toFixed(2) }}
                      </p>
                    </td>
                    <td>{{ m.unit }}</td>
                    <td
                      v-if="
                        getUser.permissions.isSuperAdmin ||
                        getUser.permissions.isAdmin
                      "
                    >
                      <!-- {{(+m.price * +m.quantity).toFixed(2)}} -->
                      <p v-if="w.users">
                        {{
                          (
                            (+m.quantity / w.totalWorkers) *
                            +m.price *
                            w.users.length
                          ).toFixed(2)
                        }}
                      </p>
                      <p v-else>
                        {{
                          ((+m.quantity / w.totalWorkers) * +m.price).toFixed(2)
                        }}
                      </p>
                    </td>
                  </tr>
                </template>
              </template>
              <tr v-if="isInquiryMade && !totalMaterialQuantity">
                <td colspan="7" class="td-center">
                  {{ $t("inquiry-no-materials") }}
                </td>
              </tr>
              <tr
                class="sum-row"
                v-if="
                  getUser.permissions.isSuperAdmin ||
                  getUser.permissions.isAdmin
                "
              >
                <td colspan="6">
                  {{ $t("total-price-for-selected") }} {{ $t("materials") }}:
                </td>
                <td>{{ totalMaterials.toFixed(2) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- ACTIVITIES -->
        <div class="table-wrapper">
          <table class="table" ref="activities" v-show="activeTab === 2">
            <thead>
              <tr>
                <th>{{ $t("date") | capitalize }}</th>
                <th>{{ $t("reporter") }}</th>
                <th>{{ $t("activity") }}</th>
                <th>{{ $t("brigade") }} / {{ $t("employee") }}</th>
                <th>{{ $t("quantity") }}</th>
                <th>
                  {{ $t("metric") }}&nbsp;
                  <br />
                  {{ $t("unit") }}
                </th>
                <th
                  v-if="
                    getUser.permissions.isSuperAdmin ||
                    getUser.permissions.isAdmin
                  "
                >
                  {{ $t("price-total") }}&nbsp;
                  <br />
                  {{ $t("price-in") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="r in reports">
                <template v-for="w in r.workers">
                  <tr
                    v-for="(a, i) in r.activities"
                    :key="`r-${r._id}-a-${a._id}-${i}-wo-${w._id}`"
                    @click="goToReport(r._id)"
                    :class="{
                      'tr-invalid': r.isInvalid,
                      'additional-row': a.isAdditional,
                    }"
                  >
                    <td>
                      <span class="no-wrap">{{ r.date | formatDate }} г.</span>
                    </td>
                    <td>{{ r.createdBy.name }}</td>
                    <td>
                      <span v-if="a.isAdditional">⚡</span>
                      {{ a.name }}
                    </td>
                    <td>
                      {{ w.name }}
                    </td>
                    <td>
                      <p v-if="w.users">
                        {{
                          (
                            (+a.quantity / w.totalWorkers) *
                            w.users.length
                          ).toFixed(2)
                        }}
                      </p>
                      <p v-else>
                        {{ (+a.quantity / w.totalWorkers).toFixed(2) }}
                      </p>
                    </td>
                    <td>{{ a.unit }}</td>
                    <td
                      v-if="
                        getUser.permissions.isSuperAdmin ||
                        getUser.permissions.isAdmin
                      "
                    >
                      <!-- {{(+a.price * +a.quantity).toFixed(2)}} -->
                      <p v-if="w.users">
                        {{
                          (
                            (+a.quantity / w.totalWorkers) *
                            +a.price *
                            w.users.length
                          ).toFixed(2)
                        }}
                      </p>
                      <p v-else>
                        {{
                          ((+a.quantity / w.totalWorkers) * +a.price).toFixed(2)
                        }}
                      </p>
                    </td>
                  </tr>
                </template>
              </template>
              <tr v-if="isInquiryMade && !totalActivityQuantity">
                <td colspan="7" class="td-center">
                  {{ $t("inquiry-no-activities") }}
                </td>
              </tr>
              <tr
                class="sum-row"
                v-if="
                  getUser.permissions.isSuperAdmin ||
                  getUser.permissions.isAdmin
                "
              >
                <td colspan="6">
                  {{ $t("total-price-for-selected") }} {{ $t("activities") }}:
                </td>
                <td>{{ totalActivities.toFixed(2) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- MECHANIZATIONS -->
        <div class="table-wrapper">
          <table class="table" ref="mechanizations" v-show="activeTab === 3">
            <thead>
              <tr>
                <th>{{ $t("date") | capitalize }}</th>
                <th>{{ $t("reporter") }}</th>
                <th>{{ $t("mechanization") }}</th>
                <th>{{ $t("brigade") }} / {{ $t("employee") }}</th>
                <th>{{ $t("quantity") }}</th>
                <th>
                  {{ $t("metric") }}&nbsp;
                  <br />
                  {{ $t("unit") }}
                </th>
                <th
                  v-if="
                    getUser.permissions.isSuperAdmin ||
                    getUser.permissions.isAdmin
                  "
                >
                  {{ $t("price-total") }}&nbsp;
                  <br />
                  {{ $t("price-in") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="r in reports">
                <template v-for="w in r.workers">
                  <tr
                    v-for="(a, i) in r.mechanizations"
                    :key="`r-${r._id}-a-${a._id}-${i}-wo-${w._id}`"
                    @click="goToReport(r._id)"
                    :class="{
                      'tr-invalid': r.isInvalid,
                      'additional-row': a.isAdditional,
                    }"
                  >
                    <td>
                      <span class="no-wrap">{{ r.date | formatDate }} г.</span>
                    </td>
                    <td>{{ r.createdBy.name }}</td>
                    <td>
                      <span v-if="a.isAdditional">⚡</span>
                      {{ a.name }}
                    </td>
                    <td>
                      {{ w.name }}
                    </td>
                    <td>
                      <p v-if="w.users">
                        {{
                          (
                            (+a.quantity / w.totalWorkers) *
                            w.users.length
                          ).toFixed(2)
                        }}
                      </p>
                      <p v-else>
                        {{ (+a.quantity / w.totalWorkers).toFixed(2) }}
                      </p>
                    </td>
                    <td>{{ a.unit }}</td>
                    <td
                      v-if="
                        getUser.permissions.isSuperAdmin ||
                        getUser.permissions.isAdmin
                      "
                    >
                      <!-- {{(+a.price * +a.quantity).toFixed(2)}} -->
                      <p v-if="w.users">
                        {{
                          (
                            (+a.quantity / w.totalWorkers) *
                            +a.price *
                            w.users.length
                          ).toFixed(2)
                        }}
                      </p>
                      <p v-else>
                        {{
                          ((+a.quantity / w.totalWorkers) * +a.price).toFixed(2)
                        }}
                      </p>
                    </td>
                  </tr>
                </template>
              </template>
              <tr v-if="isInquiryMade && !totalMechanizationQuantity">
                <td colspan="7" class="td-center">
                  {{ $t("inquiry-no-mechanizations") }}
                </td>
              </tr>
              <tr
                class="sum-row"
                v-if="
                  getUser.permissions.isSuperAdmin ||
                  getUser.permissions.isAdmin
                "
              >
                <td colspan="6">
                  {{ $t("total-price-for-selected") }}
                  {{ $t("mechanizations") }}:
                </td>
                <td>{{ totalMechanizations.toFixed(2) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- SUMMARY -->
        <div class="table-wrapper">
          <table
            class="table summary-table"
            ref="summary"
            v-show="activeTab === 5"
          >
            <thead>
              <tr>
                <th></th>
                <th>{{ $t("date") | capitalize }}</th>
                <th>{{ $t("reporter") }}</th>
                <th>{{ $t("company") }}</th>
                <th>{{ $t("project") }}</th>
                <th>{{ $t("site") }}</th>
                <th>{{ $t("part") }}</th>
                <th>{{ $t("smr-type") }}</th>
                <th>{{ $t("quantity") }}</th>
                <th>
                  {{ $t("metric") }}&nbsp;
                  <br />
                  {{ $t("unit") }}
                </th>
                <th
                  v-if="
                    getUser.permissions.isSuperAdmin ||
                    getUser.permissions.isAdmin
                  "
                >
                  {{ $t("price-total") }}&nbsp;
                  <br />
                  {{ $t("price-in") }}
                </th>
              </tr>
            </thead>
            <template
              v-for="(t, i) of ['materials', 'activities', 'mechanizations']"
            >
              <tbody :key="`type-${t}-${i}`">
                <tr class="tr--split">
                  <td colspan="12" class="td-center">
                    {{ i + 1 }}. {{ $t(firstLetterUpperCase(t)) }}
                  </td>
                </tr>
              </tbody>
              <tbody :key="`type-${t}-${i}-2`">
                <template v-for="s of summary[t]">
                  <tr
                    @click="toggleShowReports(s)"
                    class="worker-name-row"
                    :key="`type-${t}-${i}-name-${s.name}`"
                  >
                    <td>
                      <span class="tms2" v-if="s.showReports">^</span>
                      <span class="tms2" v-else>]</span>
                    </td>
                    <td>{{ s.name }}</td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td></td>
                    <td>{{ s.quantity }}</td>
                    <td>{{ s.unit }}</td>
                    <td>{{ s.totalPrice }}</td>
                  </tr>
                  <template v-if="s.showReports">
                    <tr
                      v-for="(r, ri) of s.reports"
                      :key="`${r._id}-${ri}-${t}-${i}-${s.name}`"
                      class="worker-report-row"
                      :class="{ 'tr-invalid': r.isInvalid }"
                      @click="goToReport(r._id)"
                    >
                      <td class="inv-container">
                        <div class="inv"></div>
                      </td>
                      <!-- <td></td> -->
                      <td class="no-wrap">{{ r.date | formatDate }} г.</td>
                      <td class="td-company-name">{{ r.createdBy.name }}</td>
                      <td class="td-company-name">
                        {{ r.company.companyName }}
                      </td>
                      <td>{{ r.project.projectName }}</td>
                      <td>{{ r.object.objectName }}</td>
                      <td>
                        {{ r.part ? r.part.partName : "" }}
                      </td>
                      <td>
                        <span v-if="r[t][0].isAdditional">⚡</span>
                        {{ s.name }}
                      </td>
                      <td>{{ r[t][0].quantity }}</td>
                      <td>{{ r[t][0].unit }}</td>
                      <td>{{ r[t][0].totalPrice.toFixed(2) }}</td>
                    </tr>
                  </template>
                </template>
              </tbody>
            </template>
          </table>
        </div>
        <!-- WORKERS -->
        <div class="table-wrapper">
          <table class="table" ref="workers" v-show="activeTab === 4">
            <thead>
              <tr>
                <th>{{ $t("date") | capitalize }}</th>
                <th>{{ $t("reporter") }}</th>
                <th>{{ $t("smr-type") }}</th>
                <th>{{ $t("mdm-type") }}</th>
                <th>{{ $t("worker") }}</th>
                <th>{{ $t("quantity") }}</th>
                <th>
                  {{ $t("metric") }}&nbsp;
                  <br />
                  {{ $t("unit") }}
                </th>
                <th
                  v-if="
                    getUser.permissions.isSuperAdmin ||
                    getUser.permissions.isAdmin
                  "
                >
                  {{ $t("price-total") }}&nbsp;
                  <br />
                  {{ $t("price") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="type in ['materials', 'activities', 'mechanizations']"
              >
                <template v-for="r in reports">
                  <template v-if="getReportField(r, 'workers').length">
                    <template v-for="w in getReportField(r, 'workers')">
                      <tr
                        v-for="(m, i) in r[type]"
                        :key="`r-${r._id}-ma-${m._id}-${i}-wo-${w._id}`"
                        @click="goToReport(r._id)"
                        :class="{
                          'tr-invalid': r.isInvalid,
                          'additional-row': m.isAdditional,
                        }"
                      >
                        <td>
                          <span class="no-wrap"
                            >{{ r.date | formatDate }} г.</span
                          >
                        </td>
                        <td>{{ r.createdBy.name }}</td>
                        <td>
                          <span v-if="m.isAdditional">⚡</span>
                          {{ m.name }}
                        </td>
                        <td>{{ $t(firstLetterUpperCase(type)) }}</td>
                        <td>
                          {{ w.name }}
                        </td>
                        <td>
                          {{
                            (w.users
                              ? (m.quantity / w.totalWorkers) * w.users.length
                              : m.quantity / w.totalWorkers
                            ).toFixed(2)
                          }}
                        </td>
                        <td>{{ m.unit }}</td>
                        <td
                          v-if="
                            getUser.permissions.isSuperAdmin ||
                            getUser.permissions.isAdmin
                          "
                        >
                          {{
                            (w.users
                              ? +m.price *
                                w.users.length *
                                (m.quantity / w.totalWorkers)
                              : +m.price * (m.quantity / w.totalWorkers)
                            ).toFixed(2)
                          }}
                        </td>
                      </tr>
                    </template>
                  </template>
                </template>
              </template>
              <tr v-if="isInquiryMade && !totalWorkersItems">
                <td colspan="8" class="td-center">
                  {{ $t("inquiry-no-performers") }}
                </td>
              </tr>
              <tr
                class="sum-row"
                v-if="
                  getUser.permissions.isSuperAdmin ||
                  getUser.permissions.isAdmin
                "
              >
                <td colspan="7">
                  {{ $t("total-price-for-selected") }} {{ $t("workers") }}:
                </td>
                <td>{{ totalWorkers.toFixed(2) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- GROUPS -->
        <div class="table-wrapper">
          <table class="table" ref="users-groups" v-show="activeTab === 6">
            <thead>
              <tr>
                <th>{{ $t("date") | capitalize }}</th>
                <th>{{ $t("reporter") }}</th>
                <th>{{ $t("smr-type") }}</th>
                <th>{{ $t("mdm-type") }}</th>
                <th>{{ $t("users-groups") }}</th>
                <th>{{ $t("quantity") }}</th>
                <th>
                  {{ $t("metric") }}&nbsp;
                  <br />
                  {{ $t("unit") }}
                </th>
                <th
                  v-if="
                    getUser.permissions.isSuperAdmin ||
                    getUser.permissions.isAdmin
                  "
                >
                  {{ $t("price-total") }}&nbsp;
                  <br />
                  {{ $t("price") }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template
                v-for="type in ['materials', 'activities', 'mechanizations']"
              >
                <template v-for="r in reports">
                  <template v-if="getReportField(r, 'groups').length">
                    <template v-for="w in getReportField(r, 'groups')">
                      <tr
                        v-for="(m, i) in r[type]"
                        :key="`r-${r._id}-ma-${m._id}-${i}-wo-${w._id}`"
                        @click="goToReport(r._id)"
                        :class="{
                          'tr-invalid': r.isInvalid,
                          'additional-row': m.isAdditional,
                        }"
                      >
                        <td>
                          <span class="no-wrap"
                            >{{ r.date | formatDate }} г.</span
                          >
                        </td>
                        <td>{{ r.createdBy.name }}</td>
                        <td>
                          <span v-if="m.isAdditional">⚡</span>
                          {{ m.name }}
                        </td>
                        <td>{{ $t(firstLetterUpperCase(type)) }}</td>
                        <td>
                          {{ w.name }}
                        </td>
                        <td>
                          {{
                            (w.users
                              ? (m.quantity / w.totalWorkers) * w.users.length
                              : m.quantity / w.totalWorkers
                            ).toFixed(2)
                          }}
                        </td>
                        <td>{{ m.unit }}</td>
                        <td
                          v-if="
                            getUser.permissions.isSuperAdmin ||
                            getUser.permissions.isAdmin
                          "
                        >
                          {{
                            (w.users
                              ? +m.price *
                                w.users.length *
                                (m.quantity / w.totalWorkers)
                              : +m.price * (m.quantity / w.totalWorkers)
                            ).toFixed(2)
                          }}
                        </td>
                      </tr>
                    </template>
                  </template>
                </template>
              </template>
              <tr v-if="isInquiryMade && !totalGroupsItems">
                <td colspan="8" class="td-center">
                  {{ $t("inquiry-no-performers") }}
                </td>
              </tr>
              <tr
                class="sum-row"
                v-if="
                  getUser.permissions.isSuperAdmin ||
                  getUser.permissions.isAdmin
                "
              >
                <td colspan="7">
                  {{ $t("total-price-for-selected") }} {{ $t("workers") }}:
                </td>
                <td>{{ totalGroups.toFixed(2) }}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
    <!-- MOBILE BUTTONS -->
    <footer class="modal-footer mobile" v-if="reports.length" ref="footer">
      <div class="btns mobile">
        <!-- UP -->
        <span
          class="new-content-data-item-toggle-circle mobile top"
          @click="scrollToTop()"
        >
          <span class="tms">f</span>
        </span>
        <!-- NEW Inquiry -->
        <span
          class="new-content-data-item-toggle-circle mobile"
          @click="resetInquiry()"
        >
          <span class="tms">L</span>
        </span>
        <!-- EXPORT IN EXCEL -->
        <span
          class="new-content-data-item-toggle-circle mobile excel"
          @click="exportToExcelFile()"
        >
          <span class="tms">E</span>
        </span>
      </div>
    </footer>
  </div>
</template>

<script>
import Checkbox from "./shared/Checkbox.vue";
import hours from "./mixins/hours.js";
import Datepicker from "vuejs-datepicker";
import { bg, en } from "vuejs-datepicker/dist/locale";
import XLSX from "xlsx-js-style";
import { mapGetters } from "vuex";
export default {
  components: {
    Checkbox,
    Datepicker,
  },
  mixins: [hours],
  data() {
    return {
      // Vars for select v-model
      company: "",
      project: "",
      object: "",
      part: "",
      reporter: "",
      worker: "",
      // Scroll checker
      isScrolled: false,
      bg: bg,
      en: en,
      formatedFromDate: null,
      formatedToDate: null,
      activeTab: 0,
      datePeriod: "current",
      // DROPDOWNS
      openedDropdown: [false, false, false, false, false, false, false, false],
      // SEARCH
      searchCompanies: "",
      searchProjects: "",
      searchObjects: "",
      searchParts: "",
      searchReporters: "",
      searchWorkers: "",
      searchUsersTypes: "",
      searchAllUsers: "",
      selectedUsersType: {},
      usersTypes: [
        { name: "Всички потребители", value: "all" },
        { name: "Служители", value: "isEmployee" },
        { name: "Подизпълнители", value: "isWorker" },
        { name: this.$t("users-groups"), value: "groups" },
      ],
      companies: [],
      projects: [],
      objects: [],
      parts: [],
      reporters: [],
      workers: [],
      reports: [],
      summary: [],
      mdm: [],
      totalMaterials: 0,
      totalActivities: 0,
      totalMechanizations: 0,
      totalWorkers: 0,
      totalMaterialQuantity: 0,
      totalActivityQuantity: 0,
      totalMechanizationQuantity: 0,
      totalWorkersItems: 0,
      totalGroupsItems: 0,
      isInquiryMade: false,
      queryObject: {},
      activeProjects: true,
      inquiry: {
        fromDate: "",
        toDate: "",
        company: {
          _id: "",
          companyName: "",
        },
        project: {
          _id: "",
          projectName: "",
        },
        object: {
          _id: "",
          objectName: "",
        },
        part: {
          _id: "",
          partName: "",
        },
        reporter: {
          _id: "",
          name: "",
        },
        worker: {
          _id: "",
          name: "",
        },
        isInvalid: false,
        priceStatus: null,
        search: "",
      },
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      "getUser",
      "getLoading",
      // ...
    ]),
    notAdminOrSuperAdmin() {
      return (
        !this.getUser.permissions.isSuperAdmin &&
        !this.getUser.permissions.isAdmin
      );
    },
  },
  watch: {
    "inquiry.fromDate"(to, from) {
      // eslint-disable-next-line
      if (!!from) {
        this.resetReports();
      }
      this.inquiry.fromDate.setHours(0, 0, 0, 0);
      this.queryObject.fromDate = this.inquiry.fromDate.toISOString();
      this.formatedFromDate = this.$moment(
        String(this.inquiry.fromDate.toISOString())
      ).format("DD/MM/YYYY");
    },
    "inquiry.toDate"(to, from) {
      // eslint-disable-next-line
      if (!!from) {
        this.resetReports();
      }
      this.inquiry.toDate.setHours(23, 59, 59, 999);
      this.queryObject.toDate = this.inquiry.toDate.toISOString();
      this.formatedToDate = this.$moment(
        String(this.inquiry.toDate.toISOString())
      ).format("DD/MM/YYYY");
    },
    isInquiryMade(to) {
      console.log(`i = ${to}`);
    },
  },
  methods: {
    getUserPermissionName(u) {
      if (u.permissions) {
        return u.permissions.isWorker ? "подизпълнител" : "служител";
      } else return "бригада";
    },
    getSelectedUsersType() {
      return Object.keys(this.selectedUsersType).map((s) => s);
    },
    filteredAllUsers() {
      if (this.openedDropdown[8]) {
        if (this.allUsers.length) {
          return this.allUsers.filter((w) => {
            return w.name
              .toLowerCase()
              .includes(this.searchAllUsers.toLowerCase());
          });
        }
      }
    },
    async getAllUsers() {
      let filterBy = Object.values(this.selectedUsersType);

      let url = false;
      let types = [];
      if (filterBy.includes("isWorker")) types.push("isWorker");
      if (filterBy.includes("isEmployee")) types.push("isEmployee");

      if (types.length) {
        url = `${
          process.env.VUE_APP_BASE_URI
        }/auth/users/permissions/${JSON.stringify(types)}`;
      }

      if (filterBy.includes("all")) {
        url = `${process.env.VUE_APP_BASE_URI}/auth/users`;
      }

      let users = false;
      if (url) users = await this.$http.get(url);
      let groups = await this.$http.get(
        `${process.env.VUE_APP_BASE_URI}/group?notEmpty=true`
      );

      this.allUsers = [];
      if (users) this.allUsers.push(...users.data);
      if (filterBy.includes("groups") || filterBy.includes("all")) {
        this.allUsers.push(...groups.data);
      }

      this.allUsers = this.allUsers.sort((a, b) =>
        b.name.toLowerCase() > a.name.toLowerCase() ? -1 : 1
      );
    },
    toggleShowReports(s) {
      this.$set(s, "showReports", !s.showReports);
    },
    focusInput(id, dropdownNum) {
      let input = document.getElementById(id);
      if (this.openedDropdown[dropdownNum]) {
        input.blur();
      } else {
        input.focus();
      }
    },
    changeDatePeriod(p) {
      this.datePeriod = p;
      if (p === "current") {
        this.setDates(0, -1);
      } else if (p === "previous") {
        this.setDates(1, 0);
      } else if (p === "half") {
        this.setDates(6, -1);
      } else if (p === "year") {
        this.setDates(12, -1);
      } else if (p === "all") {
        this.setDates(12000, -12000);
      }
      this.queryObject.period = p;
      this.$router.push({ query: this.queryObject }).catch((err) => err);
    },
    selectPriceStatus(p) {
      this.resetReports();
      if (p === this.inquiry.priceStatus) {
        this.inquiry.priceStatus = null;
      } else {
        this.inquiry.priceStatus = p;
      }
      this.queryObject.status = p;
      this.$router.push({ query: this.queryObject }).catch((err) => err);
    },
    firstLetterUpperCase(str) {
      if (typeof str !== "string") str = str.toString();

      return str[0].toUpperCase() + str.slice(1);
    },
    formatSummary(type) {
      if (type) {
        let formatSummary = [];

        let splitStyle = {
          fill: { fgColor: { rgb: "515A7D" } },
          font: { color: { rgb: "ffffff" } },
        };
        let sStyle = {
          fill: { fgColor: { rgb: "c8dcf4" } },
          font: { bold: true },
        };
        let boldStyle = { font: { bold: true } };
        formatSummary.push([
          { v: "", s: splitStyle },
          { v: "", s: splitStyle },
          { v: "", s: splitStyle },
          { v: "", s: splitStyle },
          { v: `${this.$t(this.firstLetterUpperCase(type))}`, s: splitStyle },
          { v: "", s: splitStyle },
          { v: "", s: splitStyle },
          { v: "", s: splitStyle },
          { v: "", s: splitStyle },
          { v: "", s: splitStyle },
        ]);
        formatSummary.push([
          { v: this.$t("date"), s: boldStyle },
          { v: this.$t("reported"), s: boldStyle },
          { v: this.$t("company"), s: boldStyle },
          { v: this.$t("project"), s: boldStyle },
          { v: this.$t("site"), s: boldStyle },
          { v: this.$t("part"), s: boldStyle },
          {
            v: `${this.$t("activity")} / ${this.$t("material")}`,
            s: boldStyle,
          },
          { v: this.$t("quantity"), s: boldStyle },
          { v: `${this.$t("metric")}  ${this.$t("unit")} `, s: boldStyle },
          {
            v: `${this.$t("price-total")} / ${this.$t("price")}`,
            s: boldStyle,
          },
        ]);

        for (let m of this.summary[type]) {
          formatSummary.push([
            { v: m.name, s: sStyle },
            { v: "", s: sStyle },
            { v: "", s: sStyle },
            { v: "", s: sStyle },
            { v: "", s: sStyle },
            { v: "", s: sStyle },
            { v: "", s: sStyle },
            { v: m.quantity, s: sStyle },
            { v: m.unit, s: sStyle },
            { v: m.totalPrice, s: sStyle },
          ]);

          for (let r of m.reports) {
            formatSummary.push([
              { v: this.$options.filters.formatDate(r.date) },
              { v: r.createdBy.name },
              { v: r.company.companyName },
              { v: r.project.projectName },
              { v: r.object.objectName },
              { v: r.part.partName },
              { v: r[type][0].name },
              { v: r[type][0].quantity },
              { v: r[type][0].unit },
              { v: Number(r[type][0].totalPrice).toFixed(2) },
            ]);
          }
        }
        return formatSummary;
      }
    },
    exportToExcelFile() {
      const wscols = [
        { wpx: 150 },
        { wpx: 100 },
        { wpx: 100 },
        { wpx: 100 },
        { wpx: 100 },
        { wpx: 100 },
        { wpx: 100 },
        { wpx: 100 },
        { wpx: 100 },
        { wpx: 100 },
      ];

      const config = { dateNF: "dd/mm/yyyy" };
      // Convert Table to Worksheet

      if (!this.inquiry.reporter._id) {
        const reportsWorksheet = XLSX.utils.table_to_sheet(
          this.$refs["reportsTable"],
          config
        );
        const materialsWorksheet = XLSX.utils.table_to_sheet(
          this.$refs["materials"],
          config
        );
        const activitiesWorksheet = XLSX.utils.table_to_sheet(
          this.$refs["activities"],
          config
        );
        const mechanizationsWorksheet = XLSX.utils.table_to_sheet(
          this.$refs["mechanizations"],
          config
        );
        const workersWorksheet = XLSX.utils.table_to_sheet(
          this.$refs["workers"],
          config
        );
        const groupsWorksheet = XLSX.utils.table_to_sheet(
          this.$refs["users-groups"],
          config
        );
        const summariessWorksheet = XLSX.utils.json_to_sheet(
          [
            ...this.formatSummary("materials"),
            ...this.formatSummary("activities"),
            ...this.formatSummary("mechanizations"),
          ],
          { skipHeader: true }
        );
        // Define New Workbook
        const new_workbook = XLSX.utils.book_new();

        reportsWorksheet["!cols"] = wscols;
        materialsWorksheet["!cols"] = wscols;
        activitiesWorksheet["!cols"] = wscols;
        mechanizationsWorksheet["!cols"] = wscols;
        summariessWorksheet["!cols"] = wscols;
        workersWorksheet["!cols"] = wscols;
        groupsWorksheet["!cols"] = wscols;

        // Append Worksheet
        XLSX.utils.book_append_sheet(
          new_workbook,
          reportsWorksheet,
          this.$t("reports")
        );
        XLSX.utils.book_append_sheet(
          new_workbook,
          materialsWorksheet,
          this.$t("Materials")
        );
        XLSX.utils.book_append_sheet(
          new_workbook,
          activitiesWorksheet,
          this.$t("Activities")
        );
        XLSX.utils.book_append_sheet(
          new_workbook,
          mechanizationsWorksheet,
          this.$t("Mechanizations")
        );
        XLSX.utils.book_append_sheet(
          new_workbook,
          workersWorksheet,
          this.$t("Workers")
        );
        XLSX.utils.book_append_sheet(
          new_workbook,
          groupsWorksheet,
          this.$t("users-groups")
        );
        XLSX.utils.book_append_sheet(
          new_workbook,
          summariessWorksheet,
          this.$t("summary")
        );
        // Save File
        XLSX.writeFile(
          new_workbook,
          `${this.$t("inquiry")}-${this.formatedFromDate}-${
            this.formatedToDate
          }.xlsx`
        );
      } else {
        const workersWorksheet = XLSX.utils.table_to_sheet(
          this.$refs["workers"],
          config
        );
        // Define New Workbook
        const new_workbook = XLSX.utils.book_new();

        workersWorksheet["!cols"] = wscols;

        // Append Worksheet
        XLSX.utils.book_append_sheet(
          new_workbook,
          workersWorksheet,
          this.$t("Workers")
        );
        // Save File

        XLSX.writeFile(
          new_workbook,
          `${this.$t("inquiry")}-${this.$t("for")}-${
            this.inquiry.worker.name
          }-${this.formatedFromDate}-${this.formatedToDate}.xlsx`
        );
      }
    },
    // Open dropdown. Depends on index (hard coded in template)
    openDropdown(i) {
      this.$set(this.openedDropdown, i, true);
    },
    // Close dropdown. Depends on index (hard coded in template)
    closeDropdown(i) {
      setTimeout(() => {
        this.$set(this.openedDropdown, i, false);
      }, 100);
    },
    selectTab(num) {
      if (this.inquiry.reporter._id) {
        this.activeTab = 4;
      } else this.activeTab = num;
      this.queryObject.tab = this.activeTab;
      this.$router.push({ query: this.queryObject }).catch((err) => err);
    },
    // FILTERED RESULTS WHEN SEARCHING
    filteredCompanies() {
      if (this.companies.length) {
        return this.companies.filter((c) => {
          return c.companyName
            .toLowerCase()
            .includes(this.searchCompanies.toLowerCase());
        });
      }
    },
    filteredProjects() {
      if (this.projects.length) {
        return this.projects.filter((p) => {
          return p.projectName
            .toLowerCase()
            .includes(this.searchProjects.toLowerCase());
        });
      }
    },
    filteredObjects() {
      if (this.objects.length) {
        return this.objects.filter((o) => {
          return o.objectName
            .toLowerCase()
            .includes(this.searchObjects.toLowerCase());
        });
      }
    },
    filteredParts() {
      if (this.parts.length) {
        return this.parts.filter((p) => {
          return p.partName
            .toLowerCase()
            .includes(this.searchParts.toLowerCase());
        });
      }
    },
    filteredReporters() {
      if (this.reporters.length) {
        return this.reporters.filter((r) => {
          return r.name
            .toLowerCase()
            .includes(this.searchReporters.toLowerCase());
        });
      }
    },
    filteredWorkers() {
      if (this.workers.length) {
        return this.workers.filter((w) => {
          return w.name
            .toLowerCase()
            .includes(this.searchWorkers.toLowerCase());
        });
      }
    },
    // RESETS
    resetSelectedCompany() {
      this.inquiry.company = {
        _id: "",
        companyName: "",
      };
      this.company = "";
      delete this.queryObject.company;
      delete this.queryObject.companyName;
      this.resetSelectedProject();
    },
    resetSelectedProject() {
      this.inquiry.project = {
        _id: "",
        projectName: "",
      };
      this.project = "";
      delete this.queryObject.project;
      delete this.queryObject.projectName;
      this.resetSelectedObject();
    },
    resetSelectedObject() {
      this.inquiry.object = {
        _id: "",
        objectName: "",
      };
      this.object = "";
      delete this.queryObject.object;
      delete this.queryObject.objectName;
      this.resetSelectedPart();
    },
    resetSelectedPart() {
      this.resetReports();
      this.inquiry.part = {
        _id: "",
        partName: "",
      };
      this.part = "";
      delete this.queryObject.part;
      delete this.queryObject.partName;
    },
    resetSelectedReporter() {
      this.resetReports();
      this.inquiry.reporter = {
        _id: "",
        name: "",
      };
      this.reporter = "";
      delete this.queryObject.reporter;
      delete this.queryObject.reporterName;
    },
    resetSelectedWorker() {
      this.resetReports();
      this.inquiry.worker = {
        _id: "",
        name: "",
      };
      this.reporter = "";
      delete this.queryObject.worker;
      delete this.queryObject.workerName;
    },
    resetInquiry() {
      this.reports = [];
      this.isInquiryMade = false;
      this.selectedUsersType = {};
      this.resetSelectedCompany();
      if (
        this.getUser.permissions.isSuperAdmin ||
        this.getUser.permissions.isAdmin
      ) {
        this.resetSelectedReporter();
        this.resetSelectedWorker();
      }
      this.selectPriceStatus(null);
      this.changeDatePeriod("current");
      this.activeProjects = true;
      this.inquiry.isInvalid = false;
      this.inquiry.search = "";
    },
    resetReports() {
      this.reports = [];
      this.isInquiryMade = false;
      this.getAllUsers();
    },
    resetSelectedUser() {
      this.resetReports();
      this.inquiry.worker = {
        _id: "",
        name: "",
      };
      this.inquiry.reporter = {
        _id: "",
        name: "",
      };
      this.searchAllUsers = "";
      this.reporter = "";
      delete this.queryObject.worker;
      delete this.queryObject.workerName;
      delete this.queryObject.reporter;
      delete this.queryObject.reporterNameName;
    },
    // SELECTS
    selectCompany(c) {
      this.resetReports();
      this.inquiry.company = c;
      this.queryObject.company = c._id;
      this.queryObject.companyName = c.companyName;
    },
    selectProject(p) {
      this.resetReports();
      this.inquiry.project = p;
      this.queryObject.project = p._id;
      this.queryObject.projectName = p.projectName;
    },
    selectObject(o) {
      this.resetReports();
      this.inquiry.object = o;
      this.queryObject.object = o._id;
      this.queryObject.objectName = o.objectName;
    },
    selectPart(p) {
      this.resetReports();
      this.inquiry.part = p;
      this.queryObject.part = p._id;
      this.queryObject.partName = p.partName;
    },
    selectReporter(r) {
      this.resetReports();
      this.inquiry.reporter = r;
      this.queryObject.reporter = r._id;
      this.queryObject.reporterName = r.name;
    },
    selectWorker(w) {
      this.resetReports();
      this.inquiry.worker = w;
      this.queryObject.worker = w._id;
      this.queryObject.workerName = w.name;
    },
    selectInvalid() {
      this.resetReports();
      this.queryObject.isInvalid = this.inquiry.isInvalid;
    },
    selectUser(w) {
      this.resetReports();
      // if (w.permissions) {
      //   this.inquiry.reporter = w;
      //   this.queryObject.reporter = w._id;
      //   this.queryObject.reporterName = w.name;
      // } else {
      this.inquiry.worker = w;
      this.queryObject.worker = w._id;
      this.queryObject.workerName = w.name;
      // }
      this.closeDropdown(8);
    },
    selectUsersType(t) {
      this.selectedUsersType = {};
      this.resetReports();
      if (this.selectedUsersType[t.name]) delete this.selectedUsersType[t.name];
      else this.selectedUsersType[t.name] = t.value;

      this.inquiry.selectedUsersType = JSON.stringify(this.selectedUsersType);
      this.queryObject.selectedUsersType = JSON.stringify(
        this.selectedUsersType
      );
      this.getAllUsers();
    },
    selectActiveProjects() {
      this.resetReports();
      this.resetSelectedCompany();
      this.queryObject.active = this.activeProjects;
    },
    setDates(from, to) {
      const date = new Date();
      // set first day of previous month
      this.inquiry.fromDate = new Date(
        date.getFullYear(),
        date.getMonth() - from,
        1
      );
      this.inquiry.fromDate.setHours(0, 0, 0, 0);
      // set last day of previous month
      this.inquiry.toDate = new Date(
        date.getFullYear(),
        date.getMonth() + to * -1,
        0
      );
      this.inquiry.toDate.setHours(23, 59, 59, 999);
    },
    getTotals() {
      this.totalMaterialQuantity = 0;
      this.totalActivityQuantity = 0;
      this.totalMechanizationQuantity = 0;
      this.totalWorkersItems = 0;
      this.totalGroupsItems = 0;
      // let isWorkersCount = [];

      let materialsSum = 0;
      let activitiesSum = 0;
      let mechanizationsSum = 0;
      let workersSum = 0;
      let groupsSum = 0;

      if (this.reports.length) {
        this.reports.forEach((r) => {
          // if (r.workers) {
          //   r.workers.forEach(m => {
          //     if(!isWorkersCount.includes(m._id)) isWorkersCount.push(m._id)

          //     for (let tag of ['materials', 'activities', 'mechanizations']) {
          //       if (r[tag]) {

          //         r[tag].forEach(x => {
          //           workersSum += (+x.quantity / +r.repWorkersCount) * +x.price;
          //         });
          //       }
          //     }
          //   });
          // }
          if (r.workers) {
            // get workers and groups totals
            for (let type of ["workers", "groups"]) {
              this.getReportField(r, type).forEach((w) => {
                if (type === "workers") this.totalWorkersItems++;
                else this.totalGroupsItems++;
                for (let tag of ["materials", "activities", "mechanizations"]) {
                  if (r[tag]) {
                    r[tag].forEach((x) => {
                      // groups
                      if (w.users) {
                        groupsSum +=
                          (+x.quantity / +r.repWorkersCount) *
                          +x.price *
                          w.users.length;
                      } else {
                        // workers
                        workersSum +=
                          (+x.quantity / +r.repWorkersCount) * +x.price;
                      }
                    });
                  }
                }
              });
            }
            r.workers.forEach((w) => {
              for (let tag of ["materials", "activities", "mechanizations"]) {
                if (r[tag]) {
                  r[tag].forEach((x) => {
                    if (tag === "materials") {
                      this.totalMaterialQuantity++;
                      if (w.users)
                        materialsSum +=
                          (+x.quantity / w.totalWorkers) *
                          +x.price *
                          w.users.length;
                      else
                        materialsSum +=
                          (+x.quantity / w.totalWorkers) * +x.price;
                    }
                    if (tag === "activities") {
                      this.totalActivityQuantity++;
                      if (w.users)
                        activitiesSum +=
                          (+x.quantity / w.totalWorkers) *
                          +x.price *
                          w.users.length;
                      else
                        activitiesSum +=
                          (+x.quantity / w.totalWorkers) * +x.price;
                    }
                    if (tag === "mechanizations") {
                      this.totalMechanizationQuantity++;

                      if (w.users)
                        mechanizationsSum +=
                          (+x.quantity / w.totalWorkers) *
                          +x.price *
                          w.users.length;
                      else
                        mechanizationsSum +=
                          (+x.quantity / w.totalWorkers) * +x.price;
                    }
                  });
                }
              }
            });
          }
        });
      }
      // this.totalWorkersItems = isWorkersCount.length
      this.totalWorkers = workersSum;
      this.totalGroups = groupsSum;
      this.totalMaterials = materialsSum;
      this.totalActivities = activitiesSum;
      this.totalMechanizations = mechanizationsSum;
      this.$store.dispatch("stopLoading");
      this.isInquiryMade = true;
    },
    // REQUESTS
    getCompanies() {
      let url = "";
      if (
        this.getUser.permissions.isSuperAdmin ||
        this.getUser.permissions.isAdmin
      ) {
        url = `${process.env.VUE_APP_BASE_URI}/company/${
          this.activeProjects ? "active" : "inactive"
        }`;
      } else if (this.getUser.permissions.isReporter) {
        url = `${process.env.VUE_APP_BASE_URI}/company/reporters/companies/${this.getUser._id}`;
      } else {
        this.$snotify.error(this.$t("no-permissions"));
      }

      return this.$http
        .get(url)
        .then((response) => {
          this.companies = response.data;
        })
        .catch((error) => console.log(error));
    },
    getProjects() {
      this.projects = [];
      if (this.inquiry.company._id) {
        let url = "";

        if (
          this.getUser.permissions.isSuperAdmin ||
          this.getUser.permissions.isAdmin
        ) {
          url = `${process.env.VUE_APP_BASE_URI}/company/project-names/${
            this.inquiry.company._id
          }?active=${this.activeProjects ? "true" : "false"}`;
        } else if (this.getUser.permissions.isReporter) {
          url = `${process.env.VUE_APP_BASE_URI}/company/reporters/projects/${this.inquiry.company._id}/${this.getUser._id}`;
        } else {
          this.$snotify.error(this.$t("no-permissions"));
        }

        return this.$http
          .get(url)
          .then((response) => {
            this.projects = response.data;
          })
          .catch((error) => console.log(error.response));
      }
    },
    getObjects() {
      this.objects = [];
      if (this.inquiry.project._id) {
        let url = "";

        if (
          this.getUser.permissions.isSuperAdmin ||
          this.getUser.permissions.isAdmin
        ) {
          url = `${process.env.VUE_APP_BASE_URI}/company/object-names/${
            this.inquiry.company._id
          }/${this.inquiry.project._id}?active=${
            this.activeProjects ? "true" : "false"
          }`;
        } else if (this.getUser.permissions.isReporter) {
          url = `${process.env.VUE_APP_BASE_URI}/company/reporters/objects/${this.inquiry.company._id}/${this.inquiry.project._id}/${this.getUser._id}`;
        } else {
          this.$snotify.error(this.$t("no-permissions"));
        }

        return this.$http
          .get(url)
          .then((response) => {
            this.objects = response.data;
          })
          .catch((error) => console.log(error.response));
      }
    },
    getParts() {
      this.parts = [];
      if (this.inquiry.object._id) {
        let url = `${process.env.VUE_APP_BASE_URI}/company/part-names/${this.inquiry.company._id}/${this.inquiry.project._id}/${this.inquiry.object._id}`;
        return this.$http
          .get(url)
          .then((response) => {
            this.parts = response.data;
          })
          .catch((error) => console.log(error.response));
      }
    },
    getReporters() {
      this.reporters = [];
      this.$http
        .get(`${process.env.VUE_APP_BASE_URI}/auth/users/reporters`)
        .then((response) => {
          this.reporters = response.data;
        });
    },
    getWorkers() {
      this.workers = [];
      this.$http
        .get(`${process.env.VUE_APP_BASE_URI}/auth/users/workers-and-employees`)
        .then((response) => {
          this.workers = response.data;
        });
    },
    getReportField(r, f) {
      if (f === "workers")
        return r.workers.filter((w) => w.permissions && w.permissions.isWorker);
      if (f === "groups") return r.workers.filter((w) => !w.permissions);
    },
    async getQueryWorker(u) {
      let user = await this.$http.get(
        `${process.env.VUE_APP_BASE_URI}/auth/users/${u}`
      );
      return user.data ? user.data : "";
    },
    // if there are queries, load the existing parameters and assign them to the empty queryObject
    // TODO: refactor
    async loadQueries(query) {
      if (Object.keys(query).length) {
        if (query.fromDate) {
          this.inquiry.fromDate = new Date(query.fromDate);
          this.queryObject.fromDate = query.fromDate;
        }
        if (query.toDate) {
          this.inquiry.toDate = new Date(query.toDate);
          this.queryObject.toDate = query.toDate;
        }
        if (query.company) {
          this.inquiry.company._id = query.company;
          this.queryObject.company = query.company;
        }
        if (query.companyName) {
          this.inquiry.company.companyName = query.companyName;
          this.queryObject.companyName = query.companyName;
        }
        if (query.project) {
          this.inquiry.project._id = query.project;
          this.queryObject.project = query.project;
        }
        if (query.projectName) {
          this.inquiry.project.projectName = query.projectName;
          this.queryObject.projectName = query.projectName;
        }
        if (query.object) {
          this.inquiry.object._id = query.object;
          this.queryObject.object = query.object;
        }
        if (query.objectName) {
          this.inquiry.object.objectName = query.objectName;
          this.queryObject.objectName = query.objectName;
        }
        if (query.part) {
          this.inquiry.part._id = query.part;
          this.queryObject.part = query.part;
        }
        if (query.partName) {
          this.inquiry.part.partName = query.partName;
          this.queryObject.partName = query.partName;
        }
        if (query.reporter) {
          this.inquiry.reporter._id = query.reporter;
          this.queryObject.reporter = query.reporter;
        }
        if (query.reporterName) {
          this.inquiry.reporter.name = query.reporterName;
          this.queryObject.reporterName = query.reporterName;
        }
        if (query.worker) {
          // "populates" query.worker
          let queryWorker = await this.getQueryWorker(query.worker);
          if (queryWorker) this.inquiry.worker = queryWorker;
          else this.inquiry.worker._id = query.worker;
          this.queryObject.worker = query.worker;
        }
        if (query.selectedUsersType) {
          this.selectedUsersType = JSON.parse(query.selectedUsersType);
          this.queryObject.selectedUsersType = query.selectedUsersType;
        }
        if (query.workerName) {
          this.inquiry.worker.name = query.workerName;
          this.queryObject.workerName = query.workerName;
        }
        if (query.isInvalid) {
          const isInvalid = query.isInvalid == "true" ? true : false;
          this.inquiry.isInvalid = isInvalid;
          this.queryObject.isInvalid = isInvalid;
        }
        if (query.active) {
          const active = query.active == "true" ? true : false;
          this.activeProjects = active;
          this.queryObject.active = active;
        }
        if (query.tab) {
          this.selectTab(+query.tab);
        }
        if (query.period) {
          this.datePeriod = query.period;
          this.queryObject.period = query.period;
        }
        if (query.status) {
          this.selectPriceStatus(+query.status);
        }
        if (query.search) {
          this.inquiry.search = query.search;
        }

        this.getInquiry();
      }
    },
    getUrl() {
      // this.inquiry.search = this.inquiry.search.replace('/', '');

      let url = `${process.env.VUE_APP_BASE_URI}/report/inquiry`;
      url += `/${this.inquiry.company._id ? this.inquiry.company._id : 0}`;
      url += `/${this.inquiry.project._id ? this.inquiry.project._id : 0}`;
      url += `/${this.inquiry.object._id ? this.inquiry.object._id : 0}`;
      url += `/${this.inquiry.part._id ? this.inquiry.part._id : 0}`;
      url += `/${this.inquiry.reporter._id ? this.inquiry.reporter._id : 0}`;
      url += `/${this.inquiry.worker._id ? this.inquiry.worker._id : 0}`;
      url += `/${this.inquiry.fromDate.toISOString()}`;
      url += `/${this.inquiry.toDate.toISOString()}`;
      url += `/${this.inquiry.isInvalid}`;
      url += `/${this.activeProjects}`;
      url += `/${this.inquiry.priceStatus}`;
      url += `/${
        this.inquiry.search ? encodeURIComponent(this.inquiry.search) : null
      }`;
      return url;
    },
    getMDM() {
      this.$http.get(this.getUrl() + "?mdm=true").then((response) => {
        this.mdm = response.data;
      });
    },
    async getSummary() {
      this.summary = [];

      this.$http.get(this.getUrl() + `?summary=true`).then((res) => {
        for (let m of res.data.materials) {
          m.reports.forEach((r) => {
            r.materials = r.materials.filter((x) => x.name === m.name);
          });
        }
        for (let m of res.data.activities) {
          m.reports.forEach((r) => {
            r.activities = r.activities.filter((x) => x.name === m.name);
          });
        }
        for (let m of res.data.mechanizations) {
          m.reports.forEach((r) => {
            r.mechanizations = r.mechanizations.filter(
              (x) => x.name === m.name
            );
          });
        }
        this.summary = res.data;
      });
    },
    // group workers by brigades
    setReportWorkers(r, groups) {
      let newWorkers = [];
      let usersInGroup = [];

      groups.map((g) => {
        let filterByGroup = r.workers
          .filter((w) => {
            if (w.group && w.group === g._id) return w._id;
          })
          .map((t) => t._id);

        if (filterByGroup.length === g.users.length && g.users.length) {
          g.totalWorkers = r.repWorkersCount;
          newWorkers.push(g), usersInGroup.push(g.users.map((u) => u._id));
        }
      });
      r.workers.filter((u) => {
        u.totalWorkers = r.repWorkersCount;

        // if users is not in full group then add him
        if (usersInGroup.length && !usersInGroup[0].includes(u._id)) {
          newWorkers.push(u);
        }
      });
      r.workers.filter((u) => {
        u.totalWorkers = r.repWorkersCount;
      });
      if (newWorkers.length) this.$set(r, "workers", newWorkers);
      else this.$set(r, "workers", r.workers);

      this.getTotals();
    },
    async getInquiry() {
      this.getSummary();
      let groups = await this.$http.get(
        `${process.env.VUE_APP_BASE_URI}/group`
      );
      this.$store.dispatch("startLoading");
      if (this.inquiry.search) {
        this.queryObject.search = this.inquiry.search;
      }
      this.$router.push({ query: this.queryObject }).catch((err) => err);
      this.reports = [];

      let url = this.getUrl();

      if (
        Object.entries(this.selectedUsersType).length &&
        Object.values(this.selectedUsersType)[0] !== "all" &&
        !this.inquiry.worker._id
      ) {
        url += `?selectedUsersType=${Object.values(this.selectedUsersType)[0]}`;
      }

      this.$http.get(url).then((response) => {
        this.reports = response.data;

        if (this.reports.length) {
          this.reports.map((r) => {
            this.setReportWorkers(r, groups.data);
          });
        } else {
          this.$store.dispatch("stopLoading");
          this.isInquiryMade = true;
        }

        if (!this.$route.query.tab || this.$route.query.reporter) {
          if (this.inquiry.reporter._id) {
            this.activeTab = 4;
          } else {
            this.activeTab = 0;
          }
        }

        // Scroll to inquiry
        this.isScrolled = true;
      });
    },
    // Scroll to the inquiry after click of the button "Направи справка"
    scrollToInquiry() {
      if (
        this.$refs["reports"] != undefined &&
        this.reports.length &&
        this.isScrolled
      ) {
        this.$refs["reports"].scrollIntoView({
          block: "start",
          behavior: "smooth",
        });
        this.isScrolled = false;
      } else if (
        this.$refs["no-reports"] != undefined &&
        !this.reports.length &&
        this.isScrolled
      ) {
        this.$refs["no-reports"].scrollIntoView({ behavior: "smooth" });
        this.isScrolled = false;
      }
    },
    scrollToTop() {
      this.$refs["top"].scrollIntoView({ behavior: "smooth" });
    },
    goToReport(id) {
      this.$router.push(`/reports/edit/${id}`);
    },
  },
  beforeRouteLeave(to, from, next) {
    next();
  },
  mounted() {
    this.setDates(0, -1);
    this.loadQueries(this.$route.query);

    if (
      !this.getUser.permissions.isSuperAdmin &&
      !this.getUser.permissions.isAdmin &&
      this.getUser.permissions.isReporter
    ) {
      this.selectReporter({
        name: this.getUser.name,
        _id: this.getUser._id,
      });
    }

    this.$eventBus.$on("export", () => {
      this.$nextTick(() => {
        this.exportToExcelFile();
      });
    });
    this.$eventBus.$on("makeInquiryFromSpeechRecognition", (period) => {
      this.changeDatePeriod(period);
      this.getInquiry();
    });
  },
  updated() {
    this.scrollToInquiry();
  },
  destroyed() {
    this.$eventBus.$off("export");
    this.$eventBus.$off("makeInquiryFromSpeechRecognition");
  },
};
</script>

<style scoped lang='scss'>
.inquiry {
  padding: 10px 40px;
}
.inquiry-header {
  align-items: center;
  display: flex;
  height: 36px;
  justify-content: space-between;
}
.inquiry-header-controlls {
  align-items: center;
  display: flex;
}
.inquiry-pre-datepicker-text {
  color: #373737;
  display: inline-block;
  font-size: 11px;
  font-weight: 700;
  margin-right: 20px;
}
.inquiry-datepicker {
  border-radius: 4px;
  display: flex;
  position: relative;
  width: 330px;
  padding: 10px 0;
}
.inquiry-datepicker .new-input-wrapper {
  background-color: #104d8033;
  background-color: #104d8033;
  margin: 0;
  width: 50%;
}
.inquiry-datepicker .new-input-wrapper:first-child {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.inquiry-datepicker .new-input-wrapper:nth-child(2) {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.inquiry-datepicker .new-input {
  background-color: transparent;
  margin: 0;
}
.inquiry-datepicker .new-input input {
  background-color: transparent;
  border: 1px solid #104d8033;
  height: 36px;
  cursor: pointer;
}
.inquiry-datepicker .new-input input:focus {
  background-color: #fff;
  border: 1px solid #104d8033;
}
/* .inquiry-datepicker .datepicker-dropdown { */
/* background-color: #ccd1e3; */
/* } */
.inquiry-header-controlls .btns {
  align-items: center;
}
.inquiry-filters {
  background-color: #104d8033;
  border-radius: 4px;
  margin-top: 10px;
  padding: 10px 25px 15px;
}
.inquiry-filters-inputs {
  align-items: flex-end;
  display: flex;
  .new-input-wrapper:last-child {
    margin-right: 0;
  }
}
.users-inputs-wrapper {
  width: 25%;
}
.inquiry-filters-btns {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  position: relative;
}
.inquiry-filters-btns .checkboxes {
  display: flex;
}
.checkboxes-wrapper {
  display: flex;
  gap: 15px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.inquiry-filters-btns .checkbox-wrapper {
  margin-left: 0;
  margin-right: 15px;
}
.checkbox-wrapper .checkmark {
  background-color: #f4f4f4;
}
.checkbox-wrapper:hover input ~ .checkmark {
  background-color: #fff;
}
.checkbox-wrapper:hover input:checked ~ .checkmark {
  background-color: #0058bc;
}
.inquiry-filters-btns .btn {
  margin: 0;
}
.inquiry-filters-btns .btn-excel {
  margin-right: 10px;
}
/* CONTENT */
.reports-tabs {
  display: flex;
  font-weight: 700;
  justify-content: center;
  margin-top: 10px;
  margin-bottom: -1px;
  position: relative;
}
.reports-tab {
  align-items: center;
  background-color: #104d8033;
  border: none;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
  cursor: pointer;
  font-weight: 700;
  display: flex;
  height: 45px;
  justify-content: center;
  margin: 0 3px;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -ms-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
  width: 25%;
}
.reports-tab span {
  text-transform: capitalize;
}
.reports-tab span:first-child {
  margin-right: 2px;
}
.reports-tab:first-child {
  margin-left: 0;
}
.reports-tab:last-child {
  margin-right: 0;
}
.reports-tab:hover {
  background-color: #104d8033;
}
.reports-tab-active {
  background-color: #fff;
  border: 1px solid #ccd1e3;
  border-bottom: 1px solid #fff;
}
.reports-tab-active:hover {
  background-color: #fff;
}
.reports-content {
  background-color: #fff;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border: 1px solid #ccd1e3;
  padding: 0 10px;
}
.reports-content .table tbody tr {
  background-color: #f3f7fd;
}
.reports-content .table tbody td {
  padding: 10px;
}
.reports-content .table .td-company-name {
  position: relative;
  padding: 0 20px;
}
.reports-content .table tbody .tr-invalid {
  background-color: #fff8f8;
}
.reports-content .table tbody tr:hover {
  background-color: #104d8033;
}
.reports-content .table tbody .sum-row {
  background-color: transparent;
  font-size: 18px;
  font-weight: 700;
}
.reports-content .table tbody .sum-row td:first-child {
  padding-right: 30px;
  text-align: right;
}
.reports-content .table tbody .additional-row {
  background-color: #f1f4c0;
}
.inquiry-title-wrapper {
  padding: 30px 20px 20px;
}
.inquiry-title {
  color: #0058bc;
  font-size: 18px;
  font-weight: 400;
  text-align: center;
  width: 100%;
}
.inquiry-title-invalid {
  color: #bf392a;
}
.no-reports {
  /* background-color: #fff; */
  /* border-radius: 4px; */
  padding: 20px;
  text-align: center;
  width: 100%;
}
.td-center {
  text-align: center;
}
.table-wrapper {
  overflow: auto;
}
.date-toggles,
.flag-toggles {
  align-items: center;
  display: flex;
}
.date-toggle,
.flag-toggle {
  align-items: center;
  background-color: #104d8033;
  border: 1px solid #104d8033;
  border-radius: 4px;
  color: #0058bc;
  cursor: pointer;
  font-size: 24px;
  display: inline-flex;
  height: 36px;
  justify-content: center;
  margin-left: 10px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  width: 36px;
}
.flag-toggle {
  background-color: rgb(255, 255, 255);
}
.flag-toggles p {
  margin-right: 10px;
}
.flag-toggle img {
  width: 24px;
}
.date-toggle:hover,
.flag-toggle:hover {
  border-color: rgba(77, 77, 77, 0.2);
}
.date-toggle-active,
.flag-toggle-active,
.date-toggle-active:hover,
.flag-toggle-active:hover {
  background-color: rgb(214, 232, 252);
  border-color: #0058bc;
}
/* .desktop { */
/* display: flex; */
/* } */
.mobile {
  display: none;
}

/* Responsive */
@media screen and (max-width: 1200px) {
  .checkboxes-wrapper {
    position: static;
    transform: initial;
  }
}
@media screen and (max-width: 1000px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: inline-grid;
  }
  .inquiry {
    padding: 10px 15px;
  }
  .inquiry-header {
    height: initial;
  }
  .inquiry-header-controlls {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    width: 100%;
  }
  .reports-tab {
    font-size: 11px;
  }
  .date-toggle {
    margin: 0 5px;
  }
  .inquiry-pre-datepicker-text {
    font-size: 20px;
  }
  .inquiry-filters {
    padding: 10px;
    margin-top: 0;
  }
  .inquiry-filters-inputs {
    display: flex;
    flex-direction: column;
  }
  .inquiry-filters-btns {
    flex-direction: column;
    gap: 10px;
  }
  .inquiry-filters-btns .btn {
    margin-top: 10px;
  }
  .inquiry-datepicker {
    margin-top: 10px;
    padding: 2px 0;
    width: 100%;
  }
  .new-input-wrapper,
  .users-inputs-wrapper {
    margin-right: 0;
    width: 100%;
  }
  .checkboxes-wrapper {
    margin: 5px 0;
  }
  .table-wrapper {
    overflow: auto;
  }
  .new-content-data-item-toggle-circle {
    height: 50px;
    width: 50px;
  }
  .new-content-data-item-toggle-circle.mobile {
    bottom: 20px;
    cursor: pointer;
    position: fixed;
    right: 20px;
  }
  .new-content-data-item-toggle-circle.mobile.excel {
    background-color: green;
    bottom: 75px;
    cursor: pointer;
    position: fixed;
    right: 20px;
  }
  .new-content-data-item-toggle-circle.mobile.top {
    background-color: #083f75;
    margin-top: 50px;
    position: inherit;
  }
  .new-content-data-item-toggle-circle .tms {
    border: 0px;
    font-size: 20px;
  }
  .modal-footer {
    background-color: #fff;
    height: 125px;
    padding: 0px;
  }
  .no-wrap {
    white-space: nowrap;
  }
  @media screen and (max-width: 600px) {
    .reports-tab {
      flex-direction: column;
    }
    .reports-tab span:first-child {
      margin: 0;
    }
  }
  @media screen and (max-width: 380px) {
    .reports-tab {
      font-size: 12px;
    }
    // .checkboxes-wrapper {
    //   flex-direction: column;
    // }
  }
}
</style>
