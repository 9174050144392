<template>
  <div class="user-modal-wrapper">
    <div class="user-modal">
      <form>
        <!-- ASSET NAME -->
        <label for="assetName" class="input-label">{{
          $t("asset-name")
        }}</label>
        <div class="input-wrapper" :class="{ invalid: !isNameValid }">
          <span class="tms">Z</span>
          <input
            id="assetName"
            type="text"
            :placeholder="$t('asset-name')"
            v-model="asset.name"
            @keyup="isNameValid = true"
          />
        </div>

        <!-- ASSET PLATE -->
        <label for="assetNumber" class="input-label">{{
          $t("asset-number")
        }}</label>
        <div class="input-wrapper">
          <span class="tms">Z</span>
          <input
            id="assetNumber"
            type="text"
            :maxlength="10"
            :placeholder="$t('asset-number')"
            v-model="asset.numberPlate"
          />
        </div>

        <!-- ASSET LOCATION -->
        <label for="assetLocation" class="input-label">{{
          $t("asset-location")
        }}</label>
        <div class="input-wrapper">
          <span class="tms">Z</span>
          <input
            id="assetLocation"
            type="text"
            :placeholder="$t('asset-location')"
            v-model="asset.location"
          />
        </div>

        <!-- ASSET INSURANCE -->
        <label for="assetInsurance" class="input-label">{{
          $t("asset-insurance")
        }}</label>
        <div class="new-input">
          <span class="tms">Z</span>
          <datepicker
            :language="this[$i18n.locale]"
            :monday-first="true"
            :minimumView="'day'"
            :maximumView="'day'"
            :placeholder="$t('asset-insurance')"
            :full-month-name="true"
            v-model="asset.insurance"
          ></datepicker>
        </div>

        <!-- ASSET GTP -->
        <label for="assetGtp" class="input-label">{{
          $t("asset-gtp")
        }}</label>
        <div class="new-input">
          <span class="tms">Z</span>
          <datepicker
            :language="this[$i18n.locale]"
            :monday-first="true"
            :minimumView="'day'"
            :maximumView="'day'"
            :placeholder="$t('asset-gtp')"
            :full-month-name="true"
            v-model="asset.gtp"
          ></datepicker>
        </div>

        <!-- ASSET VIGNETTE -->
        <label for="assetVignette" class="input-label">{{
          $t("asset-vignette")
        }}</label>
        <div class="new-input">
          <span class="tms">Z</span>
          <datepicker
            :language="this[$i18n.locale]"
            :monday-first="true"
            :minimumView="'day'"
            :maximumView="'day'"
            :placeholder="$t('asset-vignette')"
            :full-month-name="true"
            v-model="asset.vignette"
          ></datepicker>
        </div>

        <!-- ASSET MILAGE -->
        <label for="assetMilage" class="input-label">{{
          $t("asset-mileage")
        }}</label>
        <div class="input-wrapper" :class="{ invalid: !isMilageValid }">
          <span class="tms">Z</span>
          <input
            id="assetMilage"
            type="text"
            :placeholder="$t('asset-mileage')"
            v-model="asset.mileage"
            @keyup="isMilageValid = true"
          />
        </div>

        <!-- ASSET NOTE -->
        <label for="assetNote" class="input-label">{{
          $t("asset-note")
        }}</label>
        <div class="input-wrapper">
          <span class="tms">Z</span>
          <input
            id="assetNote"
            type="text"
            :placeholder="$t('asset-note')"
            v-model="asset.note"
          />
        </div>

        <div class="user-modal-actions">
          <div class="switch-container">
            <label class="switch">
              <input type="checkbox" v-model="asset.isActive" />
              <span class="slider"></span>
            </label>
            <label v-if="asset.isActive">{{
              $t("active-single")
            }}</label>
            <label v-else>{{ $t("inactive-single") }}</label>
          </div>

          <div class="btns">
            <button
              v-if="!idToEdit"
              class="btn btn-new"
              type="submit"
              value="Submit"
              @click.prevent="createAsset"
            >
              <span class="tms2">.</span>
              {{ $t("create") }}
            </button>
            <button
              v-else
              class="btn btn-new"
              type="submit"
              value="Submit"
              @click.prevent="edit"
            >
              <span class="tms2">.</span>
              {{ $t("edit") }}
            </button>
            <button class="btn btn-close" @click.prevent="closeModal(false)">
              <span class="tms">F</span>
              {{ $t("cancel") }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { mapGetters } from "vuex";
import { bg, en } from "vuejs-datepicker/dist/locale";
import log from "../mixins/log.js";
export default {
  mixins: [log],
  props: ["idToEdit"],
  components: {
    Datepicker,
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      "getUser",
      // ...
    ]),
  },
  data() {
    return {
      bg,
      en,
      isNameValid: true,
      isMilageValid: true,
      asset: { isActive: true },
    };
  },
  methods: {
    getAsset(id) {
      this.$http
        .get(`${process.env.VUE_APP_BASE_URI}/asset/${id}`)
        .then((response) => {
          console.log(response.data);
          this.asset = response.data;
        });
    },
    checkForm() {
      if (!this.asset.name) return (this.isNameValid = false);
      if (this.asset.mileage && isNaN(this.asset.mileage)) {
        return (this.isMilageValid = false);
      }

      return this.isNameValid && this.isMilageValid;
    },
    createAsset() {
      if (this.checkForm()) {
        this.$http
          .post(
            `${process.env.VUE_APP_BASE_URI}/asset`,
            this.asset
          )
          .then(() => {
            this.$snotify.success(this.$t("success-new-asset"));
            this.newLog("newUser", this.asset.name);
            this.closeModal(true);
          })
          .catch((error) => {
            console.log(error.response);
            if (error.response.data.errorName)
              this.$snotify.error(this.$t(`${error.response.data.errorName}`));
            else this.$snotify.error(this.$t("something-went-wrong"));
          })
          .then(() => {});
      }
    },
    edit() {
      if (this.checkForm()) {
        this.$http
          .put(
            `${process.env.VUE_APP_BASE_URI}/asset/${this.idToEdit}`,
            this.asset
          )
          .then(() => {
            this.$snotify.success(this.$t("success-changes"));
            this.newLog("editUser", this.asset.name);
            this.closeModal(true);
          })
          .catch((error) => {
            console.log(error.response);
            if (error.response.data.errorName)
              this.$snotify.error(this.$t(`${error.response.data.errorName}`));
            else this.$snotify.error(this.$t("something-went-wrong"));
          })
          .then(() => {});
      }
    },
    closeModal(isEdited) {
      // isEdited = true => means something is changed
      // isEdited = false => just close the modal
      this.$parent.$emit("closeAssetModal", { isEdited: isEdited });
    },
  },
  mounted() {
    if (this.idToEdit) {
      this.getAsset(this.idToEdit);
    }

    this.$eventBus.$on("closeModalFromSpeechRecognition", () => {
      this.closeModal();
    });
  },
  destroyed() {
    this.$eventBus.$off("closeModalFromSpeechRecognition");
  },
};
</script>

<style scoped lang="scss">
.new-input {

  .tms {
    left: 10px;
  }
}
</style>
