<template>
  <div class="modal-wrapper">
    <div class="modal">
      <div class="modal-header" :class="{ 'modal-header-named' : selectedCompany !== undefined }">
        <div class="new-input" v-if="selectedCompany === undefined">
          <span class="tms">N</span>
          <input type="text" :placeholder="$t('search')" v-model="searchCompanies">
        </div>
        <h1 v-else>
          <span class="tms2" @click="selectedCompany = undefined">K</span>
          {{companies[selectedCompany].companyName}}
        </h1>
        <span class="tms modal-close" @click="close()">F</span>
      </div>
      <div class="modal-content" v-if="selectedCompany === undefined">
        <p class="category-p" v-if="!searchCompanies && latestFilteredCompanies.length">{{ $t('latest-active-projects') }}</p>
          <div
            class="modal-content-item"
            v-for="(c) in latestFilteredCompanies"
            v-show="!c.hide"
            :key="`addCompaniesModal-last-${c._id}`"
            @click="selectCompany(c._id)"
          >
            <span class="tms2">C</span>
            {{c.companyName}}
          </div>
        <p class="category-p" v-if="!searchCompanies">{{ $t('all-projects') }}</p>
        <div
          class="modal-content-item"
          v-for="(c) in filteredCompanies"
          v-show="!c.hide"
          :key="`addCompaniesModal-${c._id}`"
          @click="selectCompany(c._id)"
        >
          <span class="tms2">C</span>
          {{c.companyName}}
        </div>
        <!-- NO RESULTS -->
        <p class="modal-content-no-results" v-if="!filteredCompanies.length">
          {{ $t('no-results-for') }} '
          <strong>{{this.searchCompanies}}</strong>'
        </p>
      </div>
      <div class="modal-content" v-else>
        <ul class="projects-ul-wrapper">
          <!-- PROJECTS -->
          <li
            v-for="p in companies[selectedCompany].projects"
            :key="p._id"
            class="project-li"
            :ref="p._id"
          >
            <div class="project-toggle" @click="toggleProject(p._id)">
              <span class="tms">M</span>
              <span>
                <strong>{{ $t('project').toUpperCase() }}:</strong>
                {{p.projectName}}
              </span>
            </div>
            <!-- ОБЕКТИ -->
            <ul class="objects-ul-wrapper">
              <li v-for="o in p.objects" :key="o._id" class="object-li" :ref="o._id">
                <div class="object-toggle" @click="selectProjectAndObject(p._id, o._id)">
                  <span>
                    <strong>{{ $t('site').toUpperCase() }}:</strong>
                    {{o.objectName}}
                  </span>
                </div>
              </li>
            </ul>
          </li>
        </ul>
      </div>
      <!-- <div class="modal-footer" v-if="selectedCompany !== undefined">
        <button class="btn btn-new" @click="save()">
          <span class="tms">Y</span>
          Запази
        </button>
      </div>-->
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      companies: [],
      latestCompanies: [],
      searchCompanies: "",
      selectedCompany: undefined,
      company: {
        companyId: "",
        projectId: "",
        objectId: ""
      }
    };
  },
  computed: {
    filteredCompanies() {
      return this.filterCompanies();
    },
    latestFilteredCompanies() {
      return this.filterLatestCompanies();
    },
    // mix the getters into computed with object spread operator
    ...mapGetters([
      "getUser"
      // ...
    ])
  },
  methods: {
    toggleProject(id) {
      const parentElement = this.$refs[id][0];
      const selectedChildren = parentElement.getElementsByClassName(
        "object-toggle"
      );
      if (selectedChildren) {
        // Close
        if (parentElement.classList.contains("project-li-active")) {
          for (let i = 0; i < selectedChildren.length; i++) {
            selectedChildren[i].classList.remove("object-toggle-active");
          }
          parentElement.classList.remove("project-li-active");
        } else {
          // Open
          for (let i = 0; i < selectedChildren.length; i++) {
            selectedChildren[i].classList.add("object-toggle-active");
          }
          parentElement.classList.add("project-li-active");
        }
      }
    },
    getAllCompanies() {      
      // gets all companies that user is a reporter 
      this.$http
        .get(`${process.env.VUE_APP_BASE_URI}/company/active?reporterId=${this.getUser._id}`)
        .then(response => {
          this.companies = response.data;
          this.getLatestUsedCompanies();
        });
    },
    getLatestUsedCompanies() {
      const id = this.getUser._id;
      const url = `${process.env.VUE_APP_BASE_URI}/report/limited/${id}/9`;
      this.$http
        .get(url)
        .then(response => {
          if (this.companies.length) {
            this.latestCompanies = response.data;
            this.latestCompanies.map((lc) => {
              const i = this.companies.map((c) => {return c._id;}).indexOf(lc._id);

              // shows only latestCompanies that user is a reporter
              if (i === -1) this.latestCompanies[ this.latestCompanies.indexOf(lc) ].hide = true; 

              if (this.companies[i]) this.companies[i].hide = true;
            });
          }
        })
        .catch(error => console.log(error));
    },
    filterCompanies() {
      return this.companies.filter(c => {
        return c.companyName
          .toLowerCase()
          .includes(this.searchCompanies.toLowerCase());
      });
    },
    filterLatestCompanies() {
      return this.latestCompanies.filter(c => {
        return c.companyName
          .toLowerCase()
          .includes(this.searchCompanies.toLowerCase());
      });
    },
    selectCompany(id) {
      console.log(this.companies);
      this.selectedCompany = this.companies.findIndex(c => c._id === id);
      this.company.companyId = this.companies[this.selectedCompany]._id;
    },
    selectProjectAndObject(p, o) {
      this.company.projectId = p;
      this.company.objectId = o;
      this.save();
    },
    close() {
      this.$parent.$emit("closeAddCompaniesModal");
    },
    save() {
      if (this.company.objectId.length) {
        this.$parent.$emit("saveAddCompaniesModal", this.company);
      } else {
        this.$snotify.error(this.$t('not-selected-site'));
      }
    }
  },
  mounted() {
    this.getAllCompanies();
    this.$eventBus.$on('closeModalFromSpeechRecognition', () => {
      this.close();
    })
  },
  destroyed() {
    this.$eventBus.$off('closeModalFromSpeechRecognition')
  }
};
</script>

<style scoped>
.modal-header-named {
  justify-content: flex-start;
}
.modal-header-named h1 {
  color: #fff;
  font-size: 20px;
  padding: 0 20px;
}
.modal-header-named h1 .tms2 {
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  margin-right: 10px;
  padding: 10px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.modal-header-named h1 .tms2:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.modal-content-item {
  cursor: pointer;
  padding-left: 30px;
  padding-right: 10px;
  width: calc(33.33% - 20px);
}
.modal-content-item .tms2 {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
}
.modal-footer {
  justify-content: center;
}
/* DROPDOWNS */
/* PROJECTS */
.project-li {
  background-color: #f3f7fd ;
  border-radius: 4px;
  display: block;
  overflow: hidden;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.project-li-inactive {
  background-color: #fff8f8;
}
.project-toggle {
  align-items: center;
  background-color: #f3f7fd ;
  cursor: pointer;
  display: flex;
  padding: 7px 10px;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -ms-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.project-toggle-inactive {
  background-color: #fff8f8;
}
.project-toggle:hover {
  color: rgba(0, 0, 0, 0.8);
}
.project-toggle > .tms {
  margin: 0 7px 0 4px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
/* OBJECTS */
.objects-ul-wrapper {
  padding-left: 50px;
}
.object-toggle {
  align-items: center;
  cursor: pointer;
  display: flex;
  height: 0;
  overflow: hidden;
  padding: 0 10px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.object-toggle:hover {
  color: rgba(0, 0, 0, 0.8);
}
.object-toggle-active {
  border-radius: 4px;
  height: 25px;
}
.object-toggle-active:hover {
  background-color: #d3e0f5;
}
.object-toggle > .tms {
  margin-right: 7px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.project-li-active .project-toggle .tms {
  transform: rotate(90deg);
}
/* FIX TEXTS OVERFLOW */
.project-li span:nth-child(2),
.object-li span:nth-child(2) {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.category-p {
  font-weight: 700;
  margin: 15px 0;
  text-align: center;
  width: 100%;
}

/* Responsive */
@media screen and (max-width: 1000px) {
  .modal {
    max-height: 80vh;
    width: 90%;
  }
  .modal-header .new-input {
    width: 72%;
  }
}
@media screen and (max-width: 600px) {
  .modal-content-check {
    width: 45%;
  }
  .modal-footer {
    flex-direction: column;
    height: 97px;
  }
  .select-btns {
    margin: 10px 0;
  }
  .btn.btn-new {
    margin: 0 0 10px 0;
  }
}
@media screen and (max-width: 400px) {
  .modal-content-check {
    width: 100%;
  }
}
</style>
