<template>
  <div class="new-report">
    <div class="report-header">
      <!-- Added back button -->
      <div class="heads">
        <span
          class="btn new-content-data-item-toggle-circle"
          @click="cancelReport()"
        >
          <span class="tms">e</span>
        </span>
        <h2 v-if="this.$route.name === 'newReport'" class="title">
          {{ $t("new-report") }}
        </h2>
        <h2 v-if="this.$route.name === 'editReport'" class="title">
          {{ $t("edit-report") }}
          <span v-if="report.isInvalid">
            (
            <img class="flag" src="../assets/img/flag_yes.svg" alt="Valid" />
            {{ $t("invalid-report") }})
          </span>
        </h2>
      </div>
      <!-- NEW REPORT BUTTONS DESKTOP-->
      <div class="btns desktop" v-if="allowSave">
        <button class="btn btn-close" @click="cancelReport()">
          <span class="tms">F</span>
          {{ $t("cancel") }}
        </button>
        <button class="btn btn-new" @click="save()">
          <span class="tms">Y</span>
          {{ $t("save") }}
        </button>
      </div>
    </div>
    <!-- NEW REPORT CONTENT -->
    <div
      class="new-wrapper"
      :class="{ 'new-wrapper-invalid': report.isInvalid }"
    >
      <div class="new-content">
        <!-- ROW 1 DESKTOP -->
        <!-- <div class="new-content-inputs desktop">
          DATE
          <div class="new-input-wrapper">
            <label for="newReportDate">{{ $t('date') | capitalize }}</label>
            <div class="new-input">
              <span class="tms">D</span>
              <input
                class="date"
                type="text"
                id="newReportDate"
                @click="openDropdown(0)"
                @focus="openDropdown(0)"
                @blur="closeDropdown(0)"
                :placeholder="$t('date') | capitalize"
                v-model="formatedDate"
                :disabled="this.$route.name === 'editReport'"
                readonly
              />
              <span v-if="this.$route.name === 'editReport'" class="disabled">A</span>
            </div>
            <div
              class="new-input-dropdown datepicker-dropdown"
              :class="{ 'datepicker-dropdown-active' : openedDropdown[0] }"
            >
              <datepicker
                :language="bg"
                :inline="true"
                :monday-first="true"
                :wrapper-class="'custom-datepicker'"
                :calendar-class="'custom-calendar'"
                v-model="report.date"
                @selected="closeDropdown(0), reportChanged()"
              ></datepicker>
            </div>
          </div> -->
        <!-- START HOUR -->
        <!-- <div class="new-input-wrapper">
            <label for="newReportStartHour">{{ $t('hour-start') }}</label>
            <div class="new-input">
              <span class="tms">R</span>
              <input
                type="text"
                id="newReportStartHour"
                v-model="report.startHour"
                @focus="openDropdown(1)"
                @blur="closeDropdown(1)"
                :disabled="!allowSave"
              />
              <span v-if="!allowSave" class="disabled">A</span>
            </div>
            <div
              class="new-input-dropdown"
              :class="{ 'new-input-dropdown-active' : openedDropdown[1] }"
            >
              <ul>
                GETTING HOURS FROM THE MIXIN
                <li
                  v-for="hour in hours"
                  :key="`startHour-${hour}`"
                  @click="report.startHour = hour, reportChanged()"
                >{{hour}}</li>
              </ul>
            </div>
          </div> -->
        <!-- END HOUR -->
        <!-- <div class="new-input-wrapper">
            <label for="newReportEndHour">{{ $t('hour-end') }}</label>
            <div class="new-input">
              <span class="tms">R</span>
              <input
                type="text"
                id="newReportEndHour"
                v-model="report.endHour"
                @focus="openDropdown(2)"
                @blur="closeDropdown(2)"
                :disabled="!allowSave"
              />
              <span v-if="!allowSave" class="disabled">A</span>
            </div>
            <div
              class="new-input-dropdown"
              :class="{ 'new-input-dropdown-active' : openedDropdown[2] }"
            >
              <ul>
                GETTING HOURS FROM THE MIXIN
                <li
                  v-for="hour in hours"
                  :key="`startHour-${hour}`"
                  @click="report.endHour = hour, reportChanged()"
                >{{hour}}</li>
              </ul>
            </div>
          </div> -->
        <!-- ИРВ 2 -->
        <!-- <div class="new-input-wrapper">
            <label for="newReportOvertime">{{ $t('working-time-type') }}</label>
            <div class="new-input indicator-display">
              <span class="tms">R</span>
              <p>{{calculateHours(report.date, report.startHour, report.endHour).total}} ч.</p>
              <div class="indicators">
                <span
                  class="worktime-indicator"
                  :class="{ 'indicator-inactive' : !calculateHours(report.date, report.startHour, report.endHour).normal }"
                >
                  <span>{{ $t('working-time-normal') }}</span>
                </span>
                <span
                  class="worktime-indicator overtime-indicator"
                  :class="{ 'indicator-inactive' : !calculateHours(report.date, report.startHour, report.endHour).overtime }"
                >
                  <span>{{ $t('working-time-overtime-1') }}</span>
                </span>
                <span
                  class="worktime-indicator holiday-indicator"
                  :class="{ 'indicator-inactive' : !calculateHours(report.date, report.startHour, report.endHour).holiday }"
                >
                  <span>{{ $t('working-time-overtime-2') }}</span>
                </span>
              </div>
              <label class="checkbox-wrapper">
                ИРВ 2
                <input
                  type="checkbox"
                  v-model="report.overtime"
                  id="newReportOvertime"
                  @change="reportChanged()"
                >
                <span class="checkmark" :class="{ 'checkmark-red' : report.overtime}"></span>
              </label>
            </div>
          </div> -->
        <!-- </div> -->
        <!-- ROW 1 MOBILE -->
        <!-- <div class="new-content-inputs mobile">
          <div class="date-irv-wrapper mobile"> -->
        <!-- Date -->
        <!-- <div class="new-input-wrapper">
              <div class="new-input">
                <span class="tms">D</span>
                <input
                  class="date"
                  type="text"
                  id="newReportDate"
                  @click="openDropdown(0)"
                  @focus="openDropdown(0)"
                  @blur="closeDropdown(0)"
                  :placeholder="$t('hour-end') | capitalize"
                  v-model="formatedDate"
                  :disabled="this.$route.name === 'editReport'"
                  readonly
                />
                <span v-if="this.$route.name === 'editReport'" class="disabled">A</span>
              </div>
              <div
                class="new-input-dropdown datepicker-dropdown"
                :class="{ 'datepicker-dropdown-active' : openedDropdown[0] }"
              >
                <datepicker
                  :language="bg"
                  :inline="true"
                  :monday-first="true"
                  :wrapper-class="'custom-datepicker'"
                  :calendar-class="'custom-calendar'"
                  v-model="report.date"
                  @selected="closeDropdown(0), reportChanged()"
                ></datepicker>
              </div>
            </div> -->

        <!-- ИРВ 2 -->
        <!-- <div class="new-input-wrapper">
              <div class="new-input indicator-display">
                <span class="tms">R</span>
                <p>{{calculateHours(report.date, report.startHour, report.endHour).total}} ч.</p>
                <div class="indicators">
                  <span
                    class="worktime-indicator"
                    :class="{ 'indicator-inactive' : !calculateHours(report.date, report.startHour, report.endHour).normal }"
                  >
                    <span>{{ $t('working-time-normal') }}</span>
                  </span>
                  <span
                    class="worktime-indicator overtime-indicator"
                    :class="{ 'indicator-inactive' : !calculateHours(report.date, report.startHour, report.endHour).overtime }"
                  >
                    <span>{{ $t('working-time-overtime-1') }}</span>
                  </span>
                  <span
                    class="worktime-indicator holiday-indicator"
                    :class="{ 'indicator-inactive' : !calculateHours(report.date, report.startHour, report.endHour).holiday }"
                  >
                    <span>{{ $t('working-time-overtime-2') }}</span>
                  </span>
                </div>
                <input type="checkbox" id="newReportDate" @focus="openDropdown(3)" @blur="closeDropdown(3)">
                <label class="checkbox-wrapper">
                  ИРВ 2
                  <input
                    type="checkbox"
                    v-model="report.overtime"
                    id="newReportOvertime"
                    @change="reportChanged()"
                  >
                  <span class="checkmark" :class="{ 'checkmark-red' : report.overtime}"></span>
                </label>
              </div>
            </div> -->
        <!-- </div> -->

        <!-- <div class="start-end-wrapper"> -->
        <!-- START HOUR -->
        <!-- <div class="new-input-wrapper">
              <div class="new-input mobile">
                <span class="tms">R</span>
                <select
                  class="select"
                  v-model="report.startHour"
                  @change="reportChanged()"
                  :disabled="!allowSave"
                >
                  {{report.startHour}}">
                  <option :value="hour" v-for="hour in hours" :key="`startHour-${hour}`">{{hour}}</option>
                </select>
                <span v-if="!allowSave" class="disabled">A</span>
              </div>
            </div> -->
        <!-- END HOUR -->
        <!-- <div class="new-input-wrapper">
              <div class="new-input mobile">
                <span class="tms">R</span>
                <select
                  class="select"
                  v-model="report.endHour"
                  @change="reportChanged()"
                  :disabled="!allowSave"
                >
                  {{report.startHour}}">
                  <option :value="hour" v-for="hour in hours" :key="`endHour-${hour}`">{{hour}}</option>
                </select>
                <span v-if="!allowSave" class="disabled">A</span>
              </div>
            </div> -->
        <!-- </div> -->
        <!-- </div> -->

        <!-- ROW 2 DESKTOP AND MOBILE  -->
        <div class="new-content-inputs">
          <!-- DATE -->
          <div class="new-input-wrapper">
            <label for="newReportDate" class="desktop">{{
              $t("date") | capitalize
            }}</label>
            <div class="new-input">
              <span class="tms">D</span>
              <input
                class="date"
                type="text"
                id="newReportDate"
                @click="openDropdown(0)"
                @focus="openDropdown(0)"
                @blur="closeDropdown(0)"
                :placeholder="$t('date') | capitalize"
                v-model="formatedDate"
                :disabled="this.$route.name === 'editReport'"
                readonly
              />
              <span v-if="this.$route.name === 'editReport'" class="disabled"
                >A</span
              >
            </div>
            <div
              class="new-input-dropdown datepicker-dropdown"
              :class="{ 'datepicker-dropdown-active': openedDropdown[0] }"
            >
              <datepicker
                :language="this[$i18n.locale]"
                :inline="true"
                :monday-first="true"
                :wrapper-class="'custom-datepicker'"
                :calendar-class="'custom-calendar'"
                v-model="report.date"
                @selected="closeDropdown(0), reportChanged()"
              ></datepicker>
            </div>
          </div>
          <!-- DESKTOP COMPANY -->
          <div class="new-input-wrapper desktop">
            <label class="desktop">{{ $t("company") }}</label>
            <div
              class="new-input"
              :class="{
                'new-input-disabled': this.$route.name === 'editReport',
              }"
            >
              <span class="tms">Z</span>
              <!-- NOT SELECTED -->
              <input
                v-if="!report.company._id.length"
                type="text"
                :placeholder="$t('company-name')"
                @focus="getCompanies(), openDropdown(3)"
                @blur="closeDropdown(3)"
                v-model="searchCompanies"
                :disabled="this.$route.name === 'editReport'"
              />
              <!-- SELECTED -->
              <input
                v-else
                type="text"
                :placeholder="$t('company-name')"
                @focus="getCompanies(), openDropdown(3)"
                v-model="report.company.companyName"
                @blur="closeDropdown(3)"
                :disabled="
                  this.$route.name === 'editReport' || report.company._id.length
                "
              />
              <span
                v-if="
                  report.company._id.length && this.$route.name !== 'editReport'
                "
                class="clear"
                @click="resetSelectedCompany()"
                >F</span
              >
              <span v-if="this.$route.name === 'editReport'" class="disabled"
                >A</span
              >
            </div>
            <!-- DROP-DOWN COMPANIES -->
            <div
              class="new-input-dropdown"
              :class="{ 'new-input-dropdown-active': openedDropdown[3] }"
            >
              <ul v-if="companies.length">
                <li
                  v-for="c in filteredCompanies()"
                  :key="c._id"
                  @click="selectCompany(c), getProjects(true), reportChanged()"
                >
                  {{ c.companyName }}
                </li>
              </ul>
            </div>
          </div>
          <!-- MOBILE COMPANY -->
          <!-- <label class="label mobile" for="company">{{ $t('company') }}</label> -->
          <div class="new-input mobile">
            <span class="tms">Z</span>
            <!-- NOT SELECTED -->
            <select
              v-if="$route.name !== 'editReport'"
              id="company"
              class="select"
              v-model="company"
              @change="
                selectCompany(company), getProjects(true), reportChanged()
              "
            >
              <!-- @click.native="selectCompany(c), getProjects(), reportChanged()"    -->
              <option value disabled hidden v-if="$route.name !== 'editReport'">
                {{ $t("company-name") }}
              </option>
              <option
                :value="`${$t('last-companies')}:`"
                v-if="$route.name !== 'editReport'"
                disabled
              >
                {{ $t("last-companies") }}:
              </option>
              <template v-for="c in latestCompanies">
                <option
                  :value="c"
                  v-if="$route.name !== 'editReport'"
                  :key="`latestCompanies-${c._id}`"
                  selected
                >
                  {{ c.companyName }}
                </option>
              </template>
              <option value v-if="$route.name !== 'editReport'" disabled>
                {{ $t("all-companies") }}:
              </option>
              <template v-for="(c, index) in companies">
                <option
                  v-if="companies.length && $route.name !== 'editReport'"
                  :value="c"
                  :key="index"
                  selected
                >
                  {{ c.companyName }}
                </option>
              </template>
              <option
                :value="report.company"
                v-if="this.$route.name === 'editReport'"
                selected
                disabled
              >
                {{ report.company.companyName }}
              </option>
            </select>
            <!-- SELECTED -->
            <input
              v-else
              type="text"
              :placeholder="$t('company-name')"
              v-model="report.company.companyName"
              :disabled="
                this.$route.name === 'editReport' || report.company._id.length
              "
            />
            <span v-if="this.$route.name === 'editReport'" class="disabled"
              >A</span
            >
          </div>
          <!-- PROJECT -->
          <div class="new-input-wrapper desktop">
            <label for="newReportCompany" class="desktop">{{
              $t("project")
            }}</label>
            <div
              class="new-input"
              :class="{
                'new-input-disabled': this.$route.name === 'editReport',
              }"
            >
              <span class="tms">Z</span>
              <!-- NOT SELECTED -->
              <input
                v-if="!report.project._id.length"
                type="text"
                :placeholder="$t('project-name')"
                @focus="getProjects(), openDropdown(4)"
                @blur="closeDropdown(4)"
                v-model="searchProjects"
                :disabled="this.$route.name === 'editReport'"
              />
              <!-- SELECTED -->
              <input
                v-else
                type="text"
                :placeholder="$t('project-name')"
                @focus="getProjects(), openDropdown(4)"
                @blur="closeDropdown(4)"
                v-model="report.project.projectName"
                :disabled="
                  this.$route.name === 'editReport' || report.project._id.length
                "
              />
              <span
                v-if="
                  report.project._id.length && this.$route.name !== 'editReport'
                "
                class="clear"
                @click="resetSelectedProject()"
                >F</span
              >
              <span v-if="this.$route.name === 'editReport'" class="disabled"
                >A</span
              >
            </div>
            <!-- DROP-DOWN PROJECTS -->
            <div
              class="new-input-dropdown"
              :class="{ 'new-input-dropdown-active': openedDropdown[4] }"
            >
              <ul v-if="projects.length">
                <li
                  v-for="p in filteredProjects()"
                  :key="p._id"
                  @click="selectProject(p), getObjects(true)"
                >
                  {{ p.projectName }}
                </li>
              </ul>
            </div>
          </div>
          <!-- MOBILE PROJECT -->
          <!-- <label class="label mobile" for="project">{{ $t('project') }}</label> -->
          <div class="new-input mobile">
            <span class="tms">Z</span>
            <!-- NOT SELECTED -->
            <select
              v-if="this.$route.name !== 'editReport'"
              id="project"
              class="select"
              v-model="project"
              @change="selectProject(project), getObjects(true)"
            >
              <option value disabled hidden>{{ $t("project-name") }}</option>
              <template v-for="(p, index) in projects">
                <option :key="index" :value="p">{{ p.projectName }}</option>
              </template>
              <!-- <option
                :value="report.project"                
                selected
              >{{report.project.projectName}}</option>-->
            </select>
            <!-- SELECTED -->
            <input
              v-else
              type="text"
              :placeholder="$t('project-name')"
              v-model="report.project.projectName"
              :disabled="
                this.$route.name === 'editReport' || report.project._id.length
              "
            />
            <span v-if="this.$route.name === 'editReport'" class="disabled"
              >A</span
            >
          </div>
          <!-- OBJECT -->
          <div class="new-input-wrapper desktop">
            <label for="newReportCompany" class="desktop">{{
              $t("site")
            }}</label>
            <div
              class="new-input"
              :class="{
                'new-input-disabled': this.$route.name === 'editReport',
              }"
            >
              <span class="tms">Z</span>
              <!-- NOT SELECTED -->
              <input
                v-if="!report.object._id.length"
                type="text"
                :placeholder="$t('site-name')"
                @focus="getObjects(), openDropdown(5)"
                @blur="closeDropdown(5)"
                v-model="searchObjects"
                :disabled="this.$route.name === 'editReport'"
              />
              <!-- SELECTED -->
              <input
                v-else
                type="text"
                :placeholder="$t('site-name')"
                @focus="getObjects(), openDropdown(5)"
                @blur="closeDropdown(5)"
                v-model="report.object.objectName"
                :disabled="
                  this.$route.name === 'editReport' || report.object._id.length
                "
              />
              <span
                v-if="
                  report.object._id.length && this.$route.name !== 'editReport'
                "
                class="clear"
                @click="resetSelectedObject()"
                >F</span
              >
              <span v-if="this.$route.name === 'editReport'" class="disabled"
                >A</span
              >
            </div>
            <!-- DROP-DOWN OBJECTS -->
            <div
              class="new-input-dropdown"
              :class="{ 'new-input-dropdown-active': openedDropdown[5] }"
            >
              <ul v-if="objects.length">
                <li
                  v-for="o in filteredObjects()"
                  :key="o._id"
                  @click="selectObject(o), getParts(true)"
                >
                  {{ o.objectName }}
                </li>
              </ul>
            </div>
          </div>
          <!-- MOBILE OBJECT -->
          <!-- <label class="label mobile" for="object">{{ $t('site') }}</label> -->
          <div class="new-input mobile">
            <span class="tms">Z</span>
            <!-- NOT SELECTED -->
            <select
              v-if="this.$route.name !== 'editReport'"
              id="object"
              class="select"
              v-model="object"
              @change="selectObject(object), getParts()"
            >
              <option value disabled hidden>{{ $t("site-name") }}</option>
              <template v-for="(o, index) in objects">
                <option :value="o" :key="index">{{ o.objectName }}</option>
              </template>
              <!-- <option
                :value="report.object"
                v-if="this.$route.name === 'editReport'"
                selected                
              >{{report.object.objectName}}</option>-->
            </select>
            <!-- SELECTED -->
            <input
              v-else
              type="text"
              :placeholder="$t('site-name')"
              v-model="report.object.objectName"
              :disabled="
                this.$route.name === 'editReport' || report.object._id.length
              "
            />
            <span v-if="this.$route.name === 'editReport'" class="disabled"
              >A</span
            >
          </div>
          <!-- PART -->
          <div class="new-input-wrapper desktop last-input">
            <label for="newReportCompany" class="desktop">{{
              $t("part")
            }}</label>
            <div
              class="new-input"
              :class="{
                'new-input-disabled': this.$route.name === 'editReport',
              }"
            >
              <span class="tms">Z</span>
              <!-- NOT SELECTED -->
              <template v-if="this.$route.name === 'newReport'">
                <input
                  v-if="!report.part._id.length"
                  type="text"
                  :placeholder="$t('part-name')"
                  @focus="getParts(), openDropdown(6)"
                  @blur="closeDropdown(6)"
                  v-model="searchParts"
                />
                <!-- SELECTED -->
                <input
                  v-else
                  type="text"
                  :placeholder="$t('part-name')"
                  @focus="getParts(), openDropdown(6)"
                  @blur="closeDropdown(6)"
                  v-model="report.part.partName"
                  :disabled="report.part._id.length"
                />
              </template>
              <template v-else>
                <input
                  v-if="report.part"
                  type="text"
                  v-model="report.part.partName"
                  :disabled="true"
                />
                <input
                  v-else
                  type="text"
                  :placeholder="$t('part-not-selected')"
                  :disabled="true"
                  class="italic"
                />
              </template>
              <template v-if="report.part">
                <span
                  v-if="
                    report.part._id.length && this.$route.name !== 'editReport'
                  "
                  class="clear"
                  @click="resetSelectedPart()"
                  >F</span
                >
              </template>
              <span v-if="this.$route.name === 'editReport'" class="disabled"
                >A</span
              >
            </div>
            <!-- DROP-DOWN PARTS -->
            <div
              class="new-input-dropdown"
              :class="{ 'new-input-dropdown-active': openedDropdown[6] }"
            >
              <ul v-if="parts.length">
                <li
                  v-for="p in filteredParts()"
                  :key="p._id"
                  @click="selectPart(p)"
                >
                  {{ p.partName }}
                </li>
              </ul>
            </div>
          </div>
          <!-- MOBILE PART -->
          <!-- <label
            class="label mobile"
            for="part"
            v-if="report.part || this.$route.name !== 'editReport'"
          >{{ $t('part') }}</label> -->
          <div
            class="new-input mobile"
            v-if="report.part || this.$route.name !== 'editReport'"
          >
            <span class="tms">Z</span>
            <!-- NOT SELECTED -->
            <select
              v-if="this.$route.name !== 'editReport'"
              id="part"
              class="select"
              v-model="part"
              @change="selectPart(part)"
            >
              <option value disabled hidden>{{ $t("part-name") }}</option>
              <option v-for="(part, index) in parts" :value="part" :key="index">
                {{ part.partName }}
              </option>
              <!-- <option
                :value="report.part"
                v-if="$route.name === 'editReport'"
                selected
                disabled
              >{{report.part.partName}}</option>-->
            </select>
            <!-- SELECTED -->
            <input
              v-else
              type="text"
              :placeholder="$t('part-name')"
              v-model="report.part.partName"
              :disabled="report.part._id.length"
            />
            <span v-if="this.$route.name === 'editReport'" class="disabled"
              >A</span
            >
          </div>
        </div>

        <!-- DESKTOP MATERIALS / ACTIVITIES / MECHANIZATIONS FIRST ROW SHOWN WHEN VIEW LISTS IS ACTIVE -->
        <div class="new-content-data-row desktop" v-if="allowSave">
          <div
            class="new-content-data-item"
            v-if="
              selectedObject !== undefined || this.$route.name === 'editReport'
            "
          >
            <div
              class="new-content-data-item-toggle"
              @click="openAddWorkersModal()"
            >
              <p>{{ $t("add-workers") }}</p>
              <span class="new-content-data-item-toggle-circle">
                <span class="tms">V</span>
              </span>
            </div>
          </div>
          <!-- DESKTOP ACTIVITIES -->
          <div
            class="new-content-data-item"
            v-if="
              selectedObject !== undefined || this.$route.name === 'editReport'
            "
          >
            <div
              class="new-content-data-item-toggle"
              @click="openAddActivitiesModal()"
            >
              <p>{{ $t("add-activities") }}</p>
              <span class="new-content-data-item-toggle-circle">
                <span class="tms">U</span>
              </span>
            </div>
          </div>
          <!-- DESKTOP MATERIALS -->
          <div
            class="new-content-data-item"
            v-if="
              selectedObject !== undefined || this.$route.name === 'editReport'
            "
          >
            <div
              class="new-content-data-item-toggle"
              @click="openAddMaterialsModal()"
            >
              <p>{{ $t("add-materials") }}</p>
              <span class="new-content-data-item-toggle-circle">
                <span class="tms">T</span>
              </span>
            </div>
          </div>
          <!-- DESKTOP MECHANIZATIONS -->
          <div
            class="new-content-data-item"
            v-if="
              selectedObject !== undefined || this.$route.name === 'editReport'
            "
          >
            <div
              class="new-content-data-item-toggle"
              @click="openAddMechanizationsModal()"
            >
              <p>{{ $t("add-mechanizations") }}</p>
              <span class="new-content-data-item-toggle-circle">
                <span class="tms2">Y</span>
              </span>
            </div>
          </div>
        </div>
        <!-- MOBILE MATERIALS / ACTIVITIES / MECHANIZATIONS FIRST ROW SHOWN WHEN VIEW LISTS IS ACTIVE -->
        <div class="new-content-data-row mobile" v-if="allowSave">
          <!-- MOBILE WORKERS -->
          <div
            class="new-content-data-item new-content-data-item-small"
            v-if="
              selectedObject !== undefined || this.$route.name === 'editReport'
            "
          >
            <div
              class="new-content-data-item-toggle mobile"
              @click="openAddWorkersModal()"
            >
              <p>{{ $t("user-workers") }}</p>
              <span class="new-content-data-item-toggle-circle">
                <span class="tms">V</span>
              </span>
            </div>
          </div>
          <!-- MOBILE ACTIVITIES -->
          <div
            class="new-content-data-item new-content-data-item-small"
            v-if="
              selectedObject !== undefined || this.$route.name === 'editReport'
            "
          >
            <div
              class="new-content-data-item-toggle mobile"
              @click="openAddActivitiesModal()"
            >
              <p>{{ $t("Activities") }}</p>
              <span class="new-content-data-item-toggle-circle">
                <span class="tms">U</span>
              </span>
            </div>
          </div>
          <!-- MOBILE MATERIALS -->
          <div
            class="new-content-data-item new-content-data-item-small"
            v-if="
              selectedObject !== undefined || this.$route.name === 'editReport'
            "
          >
            <div
              class="new-content-data-item-toggle mobile"
              @click="openAddMaterialsModal()"
            >
              <p>{{ $t("Materials") }}</p>
              <span class="new-content-data-item-toggle-circle">
                <span class="tms">T</span>
              </span>
            </div>
          </div>
          <!-- MOBILE MECHANIZATION -->
          <div
            class="new-content-data-item new-content-data-item-small"
            v-if="
              selectedObject !== undefined || this.$route.name === 'editReport'
            "
          >
            <div
              class="new-content-data-item-toggle mobile"
              @click="openAddMechanizationsModal()"
            >
              <p>{{ $t("Mechanizations") }}</p>
              <span class="new-content-data-item-toggle-circle">
                <span class="tms2">Y</span>
              </span>
            </div>
          </div>
        </div>

        <!-- WORKERS / MATERIALS / ACTIVITIES / MECHANIZATIONS -->
        <div
          class="new-content-data-row"
          v-if="viewLists()"
          :class="{ 'new-content-data-row-active': viewLists() }"
        >
          <div
            class="new-content-data-item"
            v-if="
              selectedObject !== undefined || this.$route.name === 'editReport'
            "
          >
            <div
              class="new-content-data-item-toggle mobile"
              @click="openAddWorkersModal()"
            >
              <p>{{ $t("user-workers") }}</p>
              <span class="new-content-data-item-toggle-circle">
                <span class="tms">V</span>
              </span>
            </div>
            <table>
              <thead
                class="new-content-data-item-toggle desktop"
                @click="openAddWorkersModal()"
              >
                <tr>
                  <td class="ma-cell ma-cell-name">
                    <!-- :style="
                      !getUser.permissions.isSuperAdmin ? 'width: 80%' : ''
                    " -->
                    {{ $t("user-workers") }}
                  </td>
                  <td class="ma-cell ma-cell-hours">Изработени часове</td>
                  <!-- <td
                    class="users-cell users-cell-nowrap desktop"
                    v-if="getUser.permissions.isSuperAdmin"
                  >
                    Коефициент
                  </td> -->
                  <td>
                    <span class="new-content-data-item-toggle-circle">
                      <span class="tms">V</span>
                    </span>
                  </td>
                </tr>
              </thead>
            </table>
            <div v-if="viewLists()" class="new-content-data-item-content new-content-data-item-content--no-padding-top">
              <!-- <table>
                <tr
                  v-for="(worker, i) in report.workers"
                  :key="`addedWorker-${worker._id}`"
                >
                  <td class="ma-cell ma-cell-name">
                    <span class="tms" @click="removeWorker(i)" v-if="allowSave"
                      >b</span
                    >
                    {{ worker.name }}
                  </td>
                  <td class="users-cell ma-cell-hours ma-cell-nowrap">
                    <div class="hours-and-tags">
                      <SelectHour
                        :reported="
                          report.reported
                            ? report.reported.indexOf(worker._id) > -1
                            : false
                        "
                        :reportedNonplanned="
                          report.reportedNonplanned
                            ? report.reportedNonplanned.indexOf(worker._id) > -1
                            : false
                        "
                        v-model="worker.startHour"
                        :end="worker.endHour"
                        @change="changeWorker(i)"
                      />
                      <SelectHour
                        :reported="
                          report.reported
                            ? report.reported.indexOf(worker._id) > -1
                            : false
                        "
                        :reportedNonplanned="
                          report.reportedNonplanned
                            ? report.reportedNonplanned.indexOf(worker._id) > -1
                            : false
                        "
                        v-model="worker.endHour"
                        :start="worker.startHour"
                        @change="changeWorker(i)"
                      />
                      <template
                        v-if="
                          (report.reported
                            ? report.reported.length ||
                              report.notReported.length
                            : false) && !worker.new
                        "
                      >
                        <span
                          class="state-tag state-tag--nonplanned"
                          title="Работникът е допълнително добавен без да е планиран"
                          v-if="
                            report.reportedNonplanned
                              ? report.reportedNonplanned.indexOf(worker._id) >
                                -1
                              : false
                          "
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="17"
                            viewBox="0 0 17 17"
                          >
                            <path
                              id="add"
                              d="M9,2a4,4,0,1,0,4,4A4,4,0,0,0,9,2ZM4.009,11A2,2,0,0,0,2,13a4.352,4.352,0,0,0,2.135,3.8A9.142,9.142,0,0,0,9,18c.411,0,.816-.019,1.21-.057A5.5,5.5,0,0,1,10.257,11ZM14.5,19A4.5,4.5,0,1,0,10,14.5,4.5,4.5,0,0,0,14.5,19Zm0-7a.5.5,0,0,1,.5.5V14h1.5a.5.5,0,0,1,0,1H15v1.5a.5.5,0,0,1-1,0V15H12.5a.5.5,0,0,1,0-1H14V12.5A.5.5,0,0,1,14.5,12Z"
                              transform="translate(-2 -2)"
                              fill="#212121"
                            />
                          </svg>
                        </span>
                        <span
                          class="state-tag"
                          title="Успешно отчетен планиран работник"
                          v-else
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="17"
                            viewBox="0 0 17 17"
                          >
                            <path
                              id="ok"
                              d="M5,6a4,4,0,1,1,4,4A4,4,0,0,1,5,6ZM2,13a2,2,0,0,1,2.009-2h6.248a5.5,5.5,0,0,0-.047,6.943c-.395.038-.8.057-1.21.057a9.142,9.142,0,0,1-4.865-1.2A4.352,4.352,0,0,1,2,13Zm17,1.5A4.5,4.5,0,1,1,14.5,10,4.5,4.5,0,0,1,19,14.5Zm-2.146-1.854a.5.5,0,0,0-.707,0L13.5,15.293l-.646-.646a.5.5,0,0,0-.707.707l1,1a.5.5,0,0,0,.707,0l3-3A.5.5,0,0,0,16.854,12.646Z"
                              transform="translate(-2 -2)"
                              fill="#212121"
                            />
                          </svg>
                        </span>
                      </template>
                      <WorktimeTag
                        :date="report.date"
                        :start="worker.startHour"
                        :end="worker.endHour"
                      />
                    </div>
                  </td>
                </tr>
                <template v-if="report.notReported">
                  <tr
                    v-for="worker in notReported"
                    :key="`notReportedWorker-${worker._id}`"
                  >
                    <td class="ma-cell ma-cell-name">
                      {{ worker.name }}
                    </td>
                    <td class="ma-cell ma-cell-nowrap desktop">
                      <button
                        class="add-to-report"
                        :class="{
                          'add-to-report--elsewhere': report.reportedElsewhere
                            ? report.reportedElsewhere.indexOf(worker._id) > -1
                            : false,
                        }"
                        :title="
                          report.reportedElsewhere
                            ? report.reportedElsewhere.indexOf(worker._id) > -1
                              ? 'Работникът е вече отчетен в друг отчет'
                              : 'Работникът не е отчитан другаде'
                            : ''
                        "
                        @click="addWorkerToReport(worker)"
                      >
                        <template
                          v-if="
                            report.reportedElsewhere
                              ? report.reportedElsewhere.indexOf(worker._id) >
                                -1
                              : false
                          "
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16.205"
                            height="16.205"
                            viewBox="0 0 16.205 16.205"
                          >
                            <path
                              id="rel"
                              d="M9.13,13.992a4.846,4.846,0,0,0,1.37,3.383,10.881,10.881,0,0,1-2.018.182C4.729,17.557,2,15.893,2,13.483v-.463a1.945,1.945,0,0,1,1.945-1.945H10.1A4.84,4.84,0,0,0,9.13,13.992ZM8.482,2A3.889,3.889,0,1,1,4.593,5.889,3.889,3.889,0,0,1,8.482,2Zm1.3,11.992a4.213,4.213,0,1,0,4.213-4.213A4.213,4.213,0,0,0,9.778,13.992Zm3.467-2.645a2.593,2.593,0,0,1,3.015,1.228v-.527a.324.324,0,1,1,.648,0v1.3a.324.324,0,0,1-.324.324h-1.3a.324.324,0,0,1,0-.648h.471a1.945,1.945,0,0,0-3.143-.565A.324.324,0,1,1,12.158,12,2.592,2.592,0,0,1,13.246,11.347Zm1.492,5.29a2.593,2.593,0,0,1-3.015-1.228v.527a.324.324,0,1,1-.648,0v-1.3a.324.324,0,0,1,.324-.324h1.3a.324.324,0,0,1,0,.648h-.471a1.945,1.945,0,0,0,3.143.565.324.324,0,1,1,.458.458A2.592,2.592,0,0,1,14.738,16.637Z"
                              transform="translate(-2 -2)"
                              fill="#212121"
                            /></svg
                        ></template>
                        <template v-else>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="17"
                            viewBox="0 0 17 17"
                          >
                            <path
                              id="no"
                              d="M14.5,19A4.5,4.5,0,1,0,10,14.5,4.5,4.5,0,0,0,14.5,19Zm.624-2a.625.625,0,1,1-.625-.625A.625.625,0,0,1,15.124,17Zm1.229-3.552a1.513,1.513,0,0,1-.728,1.319l-.277.214c-.246.195-.328.3-.345.448l-.011.156A.5.5,0,0,1,14,15.5a1.48,1.48,0,0,1,.716-1.289L14.994,14c.288-.229.36-.342.36-.544a.884.884,0,0,0-.853-.95.853.853,0,0,0-.854.945.5.5,0,1,1-1,.01A1.849,1.849,0,0,1,14.5,11.5,1.882,1.882,0,0,1,16.353,13.453ZM9,2a4,4,0,1,0,4,4A4,4,0,0,0,9,2ZM4.009,11A2,2,0,0,0,2,13a4.352,4.352,0,0,0,2.135,3.8A9.142,9.142,0,0,0,9,18c.411,0,.816-.019,1.21-.057A5.5,5.5,0,0,1,10.257,11Z"
                              transform="translate(-2 -2)"
                              fill="#212121"
                            />
                          </svg>
                        </template>

                        Добави към отчета
                      </button>
                    </td>
                    <td
                      class="users-cell users-cell-nowrap"
                      v-if="getUser.permissions.isSuperAdmin"
                    ></td>
                  </tr>
                </template>
              </table> -->

               <table class="test">
                <tr v-if="report.workers.length < 1">
                  <td colspan="5" class="nothing-added">
                    {{ $t("no-reporters") }}
                  </td>
                </tr>

                <!-- REPORT USERS VAS -->
                <tr
                  class="first-tr first-tr--report-users"
                  v-if="reportUsersVas(report).length"
                >
                  <td class="ma-cell ma-cell-name">
                    <strong>{{ $t("employees") }}</strong>
                  </td>
                  <td></td>
                  <td class="ma-cell ma-cell-nowrap desktop">
                    <strong>Изработени часове</strong>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                <template v-for="(worker, i) in reportUsersVas(report)">
                  <tr
                    :class="{
                      'tr--border-top': i === 0,
                      'tr--disabled': worker.disabled,
                    }"
                    :key="`addedWorker-${worker._id}`"
                  >
                    <td class="ma-cell ma-cell-name">
                      <span class="tms" @click="removeWorker(worker)"
                        >b</span
                      >
                      <span class="tms tms--blue tms--role">y</span>
                      {{ worker.name }}
                    </td>
                    <td class="td--state-type">
                      <span
                        v-if="worker.state"
                        class="state-type"
                        :class="`state-type--${worker.state}`"
                        >{{ stateTypes[worker.state] }}</span
                      >
                    </td>
                    <td class="users-cell ma-cell-hours ma-cell-nowrap">
                      <ReportWorkerHourAndTag
                        v-if="!worker.disabled"
                        :report="report"
                        :worker="worker"
                        @changeWorker="changeWorker(report, worker)"
                      />
                    </td>
                  </tr>
                </template>
                <!-- REPORT USERS VAS -->

                <!-- REPORT WORKERS VAS -->
                <tr
                  class="first-tr first-tr--report-users"
                  v-if="reportWorkersVas(report).length"
                >
                  <td class="ma-cell ma-cell-name">
                    <strong>{{ $t("Workers") }}</strong>
                  </td>
                  <td></td>
                  <td class="ma-cell ma-cell-nowrap desktop">
                    <strong>Изработени часове</strong>
                  </td>
                  <td></td>
                  <td></td>
                </tr>
                <template v-for="(worker, i) in reportWorkersVas(report)">
                  <tr
                    :class="{
                      'tr--border-top': i === 0,
                      'tr--disabled': worker.disabled,
                    }"
                    :key="`addedWorker-${worker._id}`"
                  >
                    <td class="ma-cell ma-cell-name">
                      <span class="tms" @click="removeWorker(worker)"
                        >b</span
                      >
                      <span class="tms tms--blue tms--role">y</span>
                      {{ worker.name }}
                    </td>
                    <td class="td--state-type">
                      <span
                        v-if="worker.state"
                        class="state-type"
                        :class="`state-type--${worker.state}`"
                        >{{ stateTypes[worker.state] }}</span
                      >
                    </td>
                    <td class="users-cell ma-cell-hours ma-cell-nowrap">
                      <ReportWorkerHourAndTag
                        v-if="!worker.disabled"
                        :report="report"
                        :worker="worker"
                        @changeWorker="changeWorker(report, worker)"
                      />
                    </td>
                  </tr>
                </template>
                <!-- REPORT WORKERS VAS -->

                <!-- REPORT GROUPS -->
                <tr
                  class="first-tr first-tr--report-users"
                  v-if="reportGroups(report).length"
                >
                  <td class="ma-cell ma-cell-name">
                    <strong>{{ $t("users-groups") }}</strong>
                  </td>
                  <td></td>
                  <td class="ma-cell ma-cell-nowrap desktop">
                    <strong>Изработени часове</strong>
                  </td>
                </tr>
                <template v-for="(group, i) in reportGroups(report)">
                  <tr
                    :class="{ 'tr--border-top': i !== 0 }"
                    :key="`addedWorker-${group._id}`"
                  >
                    <td
                      class="ma-cell ma-cell-name ma-cell-nowrap--bigger-font"
                    >
                      <span class="tms" @click="removeWorker(group, true)"
                        >b</span
                      >
                      <strong>{{ group.name }}</strong>
                    </td>
                  </tr>
                  <template v-for="worker in group.users">
                    <tr
                      class="users-cell ma-cell-hours ma-cell-nowrap"
                      :class="{ 'tr--disabled': worker.disabled }"
                      :key="`worker-${worker._id}`"
                    >
                      <td
                        class="ma-cell ma-cell-name ma-cell-name--left-padding"
                      >
                        <span class="tms" @click="removeWorker(worker)"
                          >b</span
                        >
                        {{ worker.name }}
                      </td>
                      <td class="td--state-type">
                        <span
                          v-if="worker.state"
                          class="state-type"
                          :class="`state-type--${worker.state}`"
                          >{{ stateTypes[worker.state] }}</span
                        >
                      </td>
                      <td class="users-cell ma-cell-hours ma-cell-nowrap">
                        <ReportWorkerHourAndTag
                          v-if="!worker.disabled"
                          :report="report"
                          :worker="worker"
                          @changeWorker="changeWorker(report, worker)"
                        />
                      </td>
                    </tr>
                  </template>
                </template>
                <!-- REPORT GROUPS -->

                <template v-if="report.notReported">
                  <tr
                    v-for="worker in notReported"
                    :key="`notReportedWorker-${worker._id}`"
                  >
                    <td class="ma-cell ma-cell-name">
                      {{ worker.name }}
                    </td>
                    <td class="ma-cell ma-cell-nowrap desktop">
                      <button
                        class="add-to-report"
                        :class="{
                          'add-to-report--elsewhere': report.reportedElsewhere
                            ? report.reportedElsewhere.indexOf(worker._id) > -1
                            : false,
                        }"
                        :title="
                          report.reportedElsewhere
                            ? report.reportedElsewhere.indexOf(worker._id) > -1
                              ? 'Работникът е вече отчетен в друг отчет'
                              : 'Работникът не е отчитан другаде'
                            : ''
                        "
                        @click="addWorkerToReport(worker)"
                      >
                        <template
                          v-if="
                            report.reportedElsewhere
                              ? report.reportedElsewhere.indexOf(worker._id) >
                                -1
                              : false
                          "
                          ><svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="16.205"
                            height="16.205"
                            viewBox="0 0 16.205 16.205"
                          >
                            <path
                              id="rel"
                              d="M9.13,13.992a4.846,4.846,0,0,0,1.37,3.383,10.881,10.881,0,0,1-2.018.182C4.729,17.557,2,15.893,2,13.483v-.463a1.945,1.945,0,0,1,1.945-1.945H10.1A4.84,4.84,0,0,0,9.13,13.992ZM8.482,2A3.889,3.889,0,1,1,4.593,5.889,3.889,3.889,0,0,1,8.482,2Zm1.3,11.992a4.213,4.213,0,1,0,4.213-4.213A4.213,4.213,0,0,0,9.778,13.992Zm3.467-2.645a2.593,2.593,0,0,1,3.015,1.228v-.527a.324.324,0,1,1,.648,0v1.3a.324.324,0,0,1-.324.324h-1.3a.324.324,0,0,1,0-.648h.471a1.945,1.945,0,0,0-3.143-.565A.324.324,0,1,1,12.158,12,2.592,2.592,0,0,1,13.246,11.347Zm1.492,5.29a2.593,2.593,0,0,1-3.015-1.228v.527a.324.324,0,1,1-.648,0v-1.3a.324.324,0,0,1,.324-.324h1.3a.324.324,0,0,1,0,.648h-.471a1.945,1.945,0,0,0,3.143.565.324.324,0,1,1,.458.458A2.592,2.592,0,0,1,14.738,16.637Z"
                              transform="translate(-2 -2)"
                              fill="#212121"
                            /></svg
                        ></template>
                        <template v-else>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="17"
                            height="17"
                            viewBox="0 0 17 17"
                          >
                            <path
                              id="no"
                              d="M14.5,19A4.5,4.5,0,1,0,10,14.5,4.5,4.5,0,0,0,14.5,19Zm.624-2a.625.625,0,1,1-.625-.625A.625.625,0,0,1,15.124,17Zm1.229-3.552a1.513,1.513,0,0,1-.728,1.319l-.277.214c-.246.195-.328.3-.345.448l-.011.156A.5.5,0,0,1,14,15.5a1.48,1.48,0,0,1,.716-1.289L14.994,14c.288-.229.36-.342.36-.544a.884.884,0,0,0-.853-.95.853.853,0,0,0-.854.945.5.5,0,1,1-1,.01A1.849,1.849,0,0,1,14.5,11.5,1.882,1.882,0,0,1,16.353,13.453ZM9,2a4,4,0,1,0,4,4A4,4,0,0,0,9,2ZM4.009,11A2,2,0,0,0,2,13a4.352,4.352,0,0,0,2.135,3.8A9.142,9.142,0,0,0,9,18c.411,0,.816-.019,1.21-.057A5.5,5.5,0,0,1,10.257,11Z"
                              transform="translate(-2 -2)"
                              fill="#212121"
                            />
                          </svg>
                        </template>

                        Добави към отчета
                      </button>
                    </td>
                    <td
                      class="users-cell users-cell-nowrap"
                      v-if="getUser.permissions.isSuperAdmin"
                    ></td>
                  </tr>
                </template>
              </table>
            </div>
          </div>

          <!-- ACTIVITIES -->
          <div
            class="new-content-data-item"
            v-if="
              selectedObject !== undefined || this.$route.name === 'editReport'
            "
          >
            <!-- MOBILE -->
            <div
              class="new-content-data-item-toggle mobile"
              @click="openAddActivitiesModal()"
            >
              <p>{{ $t("Activities") }}</p>
              <span class="new-content-data-item-toggle-circle">
                <span class="tms">U</span>
              </span>
            </div>
            <!-- DESKTOP -->
            <table>
              <thead
                class="new-content-data-item-toggle desktop"
                @click="openAddActivitiesModal()"
              >
                <tr>
                  <td
                    class="ma-cell ma-cell-name--bigger"
                    :style="
                      !getUser.permissions.isSuperAdmin ? 'width: 75%' : ''
                    "
                  >
                    {{ $t("Activities") }}
                  </td>
                  <td class="ma-cell">{{ $t("quantity") }}</td>
                  <td
                    v-if="
                      getUser.permissions.isSuperAdmin ||
                      getUser.permissions.isAdmin
                    "
                    class="ma-cell"
                  >
                    {{ $t("price-one") }}
                  </td>
                  <td
                    v-if="
                      getUser.permissions.isSuperAdmin ||
                      getUser.permissions.isAdmin
                    "
                    class="ma-cell"
                  >
                    {{ $t("price") }}
                  </td>
                  <td>
                    <span class="new-content-data-item-toggle-circle">
                      <span class="tms">U</span>
                    </span>
                  </td>
                </tr>
              </thead>
            </table>
            <div v-if="viewLists()" class="new-content-data-item-content">
              <table>
                <!-- <tr class="first-tr">
                  <td class="ma-cell ma-cell-name">
                    <strong>{{ $t('Activities') }}</strong>
                  </td>
                  <td class="ma-cell ma-cell-nowrap desktop">
                    <strong>{{ $t('Workers') }}</strong>
                  </td>
                  <td class="ma-cell ma-cell-nowrap desktop">
                    <strong>{{ $t('quantity') }}</strong>
                  </td>
                  <template v-if="getUser.permissions.isSuperAdmin || getUser.permissions.isAdmin">
                    <td class="ma-cell ma-cell-nowrap desktop">
                      <strong>{{ $t('price-one') }}</strong>
                    </td>
                    <td class="ma-cell ma-cell-nowrap desktop">
                      <strong>{{ $t('price') }}</strong>
                    </td>
                  </template>
                </tr> -->
                <tr v-if="report.activities.length < 1">
                  <td colspan="5" class="nothing-added">
                    {{ $t("no-activities") }}
                  </td>
                </tr>
                <tr
                  v-for="(activity, index) in report.activities"
                  :key="`addedReportActivity-${index}-${activity.activitylName}`"
                >
                  <td
                    class="ma-cell ma-cell-name"
                    :class="{ 'ma-cell-additional': activity.isAdditional }"
                  >
                    <!-- DELETE ACTIVITY -->
                    <span
                      class="tms"
                      @click="removeActivity(index)"
                      v-if="allowSave"
                      >b</span
                    >
                    <span v-if="activity.isAdditional">⚡</span>
                    {{ activity.name }}
                  </td>
                  <!-- <td class="ma-cell ma-cell-nowrap">
                    <p
                      v-if="activity.worker"
                      class="add-worker-p"
                      @click="openAddWorkersModal('activities', index)"
                    >{{ activity.worker.name }}</p>
                    <p class="add-workers-toggle-wrapper" v-if="!activity.worker">
                      <span class="tms" @click="openAddWorkersModal('activities', index)">V</span>
                    </p>
                  </td> -->
                  <td class="ma-cell ma-cell-nowrap">
                    <input
                      class="ma-cell-input"
                      v-model="activity.quantity"
                      @keyup="changeActivity(index)"
                      type="number"
                      min="0"
                    />
                    {{ activity.unit }}
                  </td>
                  <template
                    v-if="
                      getUser.permissions.isSuperAdmin ||
                      getUser.permissions.isAdmin
                    "
                  >
                    <td class="ma-cell ma-cell-nowrap">
                      <input
                        class="ma-cell-input"
                        v-model="activity.price"
                        @keyup="changeActivity(index)"
                        type="number"
                        step="any"
                        min="0"
                      />
                      <!-- {{ $t("currency") }} -->
                    </td>
                    <td class="ma-cell ma-cell-nowrap">
                      {{ (+activity.quantity * +activity.price).toFixed(2) }}
                      <!-- {{ $t("currency") }} -->
                    </td>
                  </template>
                </tr>
              </table>
            </div>
          </div>
          <!-- MATERIALS -->
          <div
            class="new-content-data-item"
            v-if="
              selectedObject !== undefined || this.$route.name === 'editReport'
            "
          >
            <!-- MOBILE -->
            <div
              class="new-content-data-item-toggle mobile"
              @click="openAddMaterialsModal()"
            >
              <p>{{ $t("Materials") }}</p>
              <span class="new-content-data-item-toggle-circle">
                <span class="tms">T</span>
              </span>
            </div>
            <!-- DESKTOP -->
            <table>
              <thead
                class="new-content-data-item-toggle desktop"
                @click="openAddMaterialsModal()"
              >
                <tr>
                  <td
                    class="ma-cell ma-cell-name ma-cell-name--bigger"
                    :style="
                      !getUser.permissions.isSuperAdmin ? 'width: 75%' : ''
                    "
                  >
                    {{ $t("Materials") }}
                  </td>
                  <td class="ma-cell">{{ $t("quantity") }}</td>
                  <td
                    v-if="
                      getUser.permissions.isSuperAdmin ||
                      getUser.permissions.isAdmin
                    "
                    class="ma-cell"
                  >
                    {{ $t("price-one") }}
                  </td>
                  <td
                    v-if="
                      getUser.permissions.isSuperAdmin ||
                      getUser.permissions.isAdmin
                    "
                    class="ma-cell"
                  >
                    {{ $t("price") }}
                  </td>
                  <td>
                    <span class="new-content-data-item-toggle-circle">
                      <span class="tms">T</span>
                    </span>
                  </td>
                </tr>
              </thead>
            </table>
            <!-- LIST -->
            <div v-if="viewLists()" class="new-content-data-item-content">
              <table>
                <tbody>
                  <!-- <tr class="first-tr">
                    <td class="ma-cell ma-cell-name">
                      <strong>{{ $t('Materials') }}</strong>
                    </td>
                    <td class="ma-cell ma-cell-nowrap desktop">
                      <strong>{{ $t('Workers') }}</strong>
                    </td> 
                    <td class="ma-cell ma-cell-nowrap desktop">
                      <strong>{{ $t('quantity') }}</strong>
                    </td>
                    <template
                      v-if="getUser.permissions.isSuperAdmin || getUser.permissions.isAdmin"
                    >
                      <td class="ma-cell ma-cell-nowrap desktop">
                        <strong>{{ $t('price-one') }}</strong>
                      </td>
                      <td class="ma-cell ma-cell-nowrap desktop">
                        <strong>{{ $t('price') }}</strong>
                      </td>
                    </template>
                  </tr> -->
                  <tr v-if="report.materials.length < 1">
                    <td colspan="5" class="nothing-added">
                      {{ $t("no-materials") }}
                    </td>
                  </tr>
                  <tr
                    v-for="(material, index) in report.materials"
                    :key="`addedReportMaterial-${index}-${material.name}`"
                  >
                    <td
                      class="ma-cell ma-cell-name"
                      :class="{ 'ma-cell-additional': material.isAdditional }"
                    >
                      <!-- DELETE MATERIAL -->
                      <span
                        class="tms"
                        @click="removeMaterial(index)"
                        v-if="allowSave"
                        >b</span
                      >
                      <span v-if="material.isAdditional">⚡</span>
                      {{ material.name }}
                    </td>
                    <!-- <td class="ma-cell ma-cell-nowrap">
                      <p
                        v-if="material.worker"
                        class="add-worker-p"
                        @click="openAddWorkersModal('materials', index)"
                      >{{ material.worker.name }}</p>
                      <p class="add-workers-toggle-wrapper" v-if="!material.worker">
                        <span class="tms" @click="openAddWorkersModal('materials', index)">V</span>
                      </p>
                    </td> -->
                    <td class="ma-cell ma-cell-nowrap">
                      <input
                        class="ma-cell-input"
                        v-model="material.quantity"
                        @keyup="changeMaterial(index)"
                        type="number"
                        min="0"
                      />
                      {{ material.unit }}
                    </td>
                    <template
                      v-if="
                        getUser.permissions.isSuperAdmin ||
                        getUser.permissions.isAdmin
                      "
                    >
                      <td class="ma-cell ma-cell-nowrap">
                        <input
                          class="ma-cell-input"
                          v-model="material.price"
                          @keyup="changeMaterial(index)"
                          type="number"
                          step="any"
                          min="0"
                        />
                        <!-- {{ $t("currency") }} -->
                      </td>
                      <td class="ma-cell ma-cell-nowrap">
                        {{ (+material.quantity * +material.price).toFixed(2) }}
                        <!-- {{ $t("currency") }} -->
                      </td>
                    </template>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <!-- MECHANIZATIONS -->
          <div
            class="new-content-data-item"
            v-if="
              selectedObject !== undefined || this.$route.name === 'editReport'
            "
          >
            <!-- MOBILE -->
            <div
              class="new-content-data-item-toggle mobile"
              @click="openAddMechanizationsModal()"
            >
              <p>{{ $t("Mechanizations") }}</p>
              <span class="new-content-data-item-toggle-circle">
                <span class="tms">U</span>
              </span>
            </div>
            <!-- DESKTOP -->
            <table>
              <thead
                class="new-content-data-item-toggle desktop"
                @click="openAddMechanizationsModal()"
              >
                <tr>
                  <td class="ma-cell ma-cell-name ma-cell-name--bigger">
                    {{ $t("Mechanizations") }}
                  </td>
                  <td class="ma-cell">{{ $t("quantity") }}</td>
                  <td
                    v-if="
                      getUser.permissions.isSuperAdmin ||
                      getUser.permissions.isAdmin
                    "
                    class="ma-cell"
                  >
                    {{ $t("price-one") }}
                  </td>
                  <td
                    v-if="
                      getUser.permissions.isSuperAdmin ||
                      getUser.permissions.isAdmin
                    "
                    class="ma-cell"
                  >
                    {{ $t("price") }}
                  </td>
                  <td>
                    <span class="new-content-data-item-toggle-circle">
                      <span class="tms2">Y</span>
                    </span>
                  </td>
                </tr>
              </thead>
            </table>
            <div v-if="viewLists()" class="new-content-data-item-content">
              <table>
                <!-- <tr class="first-tr">
                  <td class="ma-cell ma-cell-name">
                    <strong>{{ $t('Mechanizations') }}</strong>
                  </td>
                  <td class="ma-cell ma-cell-nowrap desktop">
                    <strong>{{ $t('Workers') }}</strong>
                  </td>
                  <td class="ma-cell ma-cell-nowrap desktop">
                    <strong>{{ $t('quantity') }}</strong>
                  </td>
                  <template v-if="getUser.permissions.isSuperAdmin || getUser.permissions.isAdmin">
                    <td class="ma-cell ma-cell-nowrap desktop">
                      <strong>{{ $t('price-one') }}</strong>
                    </td>
                    <td class="ma-cell ma-cell-nowrap desktop">
                      <strong>{{ $t('price') }}</strong>
                    </td>
                  </template>
                </tr> -->
                <tr v-if="report.mechanizations.length < 1">
                  <td colspan="5" class="nothing-added">
                    {{ $t("no-mechanizations") }}
                  </td>
                </tr>
                <tr
                  v-for="(mechanization, index) in report.mechanizations"
                  :key="`addedReportMechanization-${index}-${mechanization.mechanizationlName}`"
                >
                  <td
                    class="ma-cell ma-cell-name"
                    :class="{
                      'ma-cell-additional': mechanization.isAdditional,
                    }"
                  >
                    <!-- DELETE MECHANIZATION -->
                    <span
                      class="tms"
                      @click="removeMechanization(index)"
                      v-if="allowSave"
                      >b</span
                    >
                    <span v-if="mechanization.isAdditional">⚡</span>
                    {{ mechanization.name }}
                  </td>
                  <!-- <td class="ma-cell ma-cell-nowrap">
                    <p
                      v-if="mechanization.worker"
                      class="add-worker-p"
                      @click="openAddWorkersModal('mechanizations', index)"
                    >{{ mechanization.worker.name }}</p>
                    <p class="add-workers-toggle-wrapper" v-if="!mechanization.worker">
                      <span class="tms" @click="openAddWorkersModal('mechanizations', index)">V</span>
                    </p>
                  </td> -->
                  <td class="ma-cell ma-cell-nowrap">
                    <input
                      class="ma-cell-input"
                      v-model="mechanization.quantity"
                      @keyup="changeMechanization(index)"
                      type="number"
                      min="0"
                    />
                    {{ mechanization.unit }}
                  </td>
                  <template
                    v-if="
                      getUser.permissions.isSuperAdmin ||
                      getUser.permissions.isAdmin
                    "
                  >
                    <td class="ma-cell ma-cell-nowrap">
                      <input
                        class="ma-cell-input"
                        v-model="mechanization.price"
                        @keyup="changeMechanization(index)"
                        type="number"
                        step="any"
                        min="0"
                      />
                      <!-- {{ $t("currency") }} -->
                    </td>
                    <td class="ma-cell ma-cell-nowrap">
                      {{
                        (
                          +mechanization.quantity * +mechanization.price
                        ).toFixed(2)
                      }}
                      <!-- {{ $t("currency") }} -->
                    </td>
                  </template>
                </tr>
              </table>
            </div>
          </div>
          <div class="invalid-report-wrap total-price">
            <p>{{ $t("total-price-report") }}:</p>
            <p>{{ reportTotalSum }} 
              <!-- {{ $t("currency") }} -->
              </p>
          </div>
          <div
            class="invalid-report-wrap"
            v-if="this.$route.name === 'editReport'"
          >
            <p>
              <strong>{{ $t("mark-report-as-invalid") }}</strong>
              - {{ $t("mark-report-as-invalid-2") }}.
            </p>
            <div class="switch-wrapper">
              <div class="switch-container">
                <label class="switch">
                  <input
                    type="checkbox"
                    v-model="report.isInvalid"
                    @change="reportChanged()"
                    :disabled="!allowSave"
                  />
                  <span class="slider"></span>
                </label>
              </div>
              <label class="switch-label" @click="report.isInvalid = true">
                <img
                  v-if="report.isInvalid"
                  class="flag"
                  src="../assets/img/flag_yes.svg"
                  alt="Invalid"
                />
                <img
                  v-else
                  class="flag"
                  src="../assets/img/flag_no.svg"
                  alt="Valid"
                />
                {{ $t("invalid-report") }}
              </label>
            </div>
          </div>
        </div>
        <!-- NEW USER BUTTONS MOBILE -->
        <div class="btns mobile" v-if="allowSave">
          <button class="btn btn-close" @click="cancelReport()">
            <span class="tms">F</span>
            {{ $t("cancel") }}
          </button>
          <button class="btn btn-new" @click="save()">
            <span class="tms">Y</span>
            {{ $t("save") }}
          </button>
        </div>
      </div>
    </div>
    <!-- ADD MATERIALS/ACTIVITIES/MECHANIZATIONS MODALS -->
    <transition name="fade">
      <AddUsersModal
        v-if="isAddUsersModalOpened"
        :isComingFromReport="true"
        :usersType="'workers'"
        :incomingUsers="report.workers"
      />
    </transition>
    <transition name="fade">
      <AddMaterialsModal
        v-if="isAddMaterialsModalOpened"
        :isComingFromReport="true"
        :companyId="report.company._id"
        :projectId="report.project._id"
        :objectId="report.object._id"
        :incomingMaterials="selectedObject.materials"
        :selectedMaterials="report.materials"
      />
    </transition>
    <transition name="fade">
      <AddActivitiesModal
        v-if="isAddActivitiesModalOpened"
        :isComingFromReport="true"
        :companyId="report.company._id"
        :projectId="report.project._id"
        :objectId="report.object._id"
        :incomingActivities="selectedObject.activities"
        :selectedActivities="report.activities"
      />
    </transition>
    <transition name="fade">
      <AddMechanizationsModal
        v-if="isAddMechanizationsModalOpened"
        :isComingFromReport="true"
        :companyId="report.company._id"
        :projectId="report.project._id"
        :objectId="report.object._id"
        :incomingMechanizations="selectedObject.mechanizations"
        :selectedMechanizations="report.mechanizations"
      />
    </transition>
    <transition name="fade">
      <UnsavedChangesModal v-if="isUnsavedChangesModalOpened" />
    </transition>
    <transition name="fade">
      <ElsewhereModal
        v-if="reportedElsewhereForConfirmation.length"
        :workers="reportedElsewhereForConfirmation"
        @confirmed="reportedElsewhereConfirmed"
        @declined="reportedElsewhereDeclined"
      />
    </transition>
  </div>
</template>

<script>
import hours from "./mixins/hours.js";
import AddUsersModal from "./shared/AddUsersModal";
import AddMaterialsModal from "./shared/AddMaterialsModal";
import AddActivitiesModal from "./shared/AddActivitiesModal";
import AddMechanizationsModal from "./shared/AddMechanizationsModal";
import UnsavedChangesModal from "./shared/UnsavedChangesModal";
import { mapGetters } from "vuex";
import ReportWorkerHourAndTag from "./shared/ReportWorkerHourAndTag";
import WorktimeTag from "./shared/WorktimeTag";
import SelectHour from "./shared/SelectHour";
import ElsewhereModal from "./shared/ElsewhereModal";
import Datepicker from "vuejs-datepicker";
import { bg, en } from "vuejs-datepicker/dist/locale";
import log from "./mixins/log.js";
import { reactive } from 'vue';

export default {
  components: {
    AddUsersModal: AddUsersModal,
    AddMaterialsModal: AddMaterialsModal,
    AddActivitiesModal: AddActivitiesModal,
    AddMechanizationsModal: AddMechanizationsModal,
    UnsavedChangesModal: UnsavedChangesModal,
    Datepicker: Datepicker,
    SelectHour,
    WorktimeTag,
    ReportWorkerHourAndTag,
    ElsewhereModal,
  },
  mixins: [hours, log],
  watch: {
    "report.date"(to) {
      if (!(to.getTime() < this.today.getTime())) {
        this.report.date = new Date();
        this.$snotify.error("Не можете да създавате отчети с бъдеща дата!");
      }

      this.formatedDate = this.$moment(
        String(this.report.date.toISOString())
      ).format("DD/MM/YYYY");
    },
    // ,
    // "report.company"(to) {
    //   if(this.report)
    //   this.company = this.report.company;
    //   // console.log(this.company);
    // },
    // "report.project"(to) {
    //   if(this.report)
    //   this.project = this.report.project;
    // },
    // "report.object"(to) {
    //   if(this.report)
    //   this.object = this.report.object;
    // },
    // "report.part"(to) {
    //   if(this.report)
    //   this.part = this.report.part;
    // }
  },
  data() {
    return {
      // Vars for select v-model
      company: "",
      project: "",
      object: "",
      part: "",
      isReportChanged: false,
      // Vars for date picker
      bg: bg,
      en: en,
      formatedDate: "",
      today: undefined,
      // А boolean for every dropdown in the template
      openedDropdown: [false, false, false, false, false, false, false],
      // A boolean for the modal for workers selection
      isAddUsersModalOpened: false,
      isAddMaterialsModalOpened: false,
      isAddActivitiesModalOpened: false,
      isAddMechanizationsModalOpened: false,
      isUnsavedChangesModalOpened: false,
      isComingFromOutside: null,
      // SEARCH
      searchCompanies: "",
      searchProjects: "",
      searchObjects: "",
      searchParts: "",
      // The main company object
      companies: [],
      latestCompanies: [{ company: { _id: "", companyName: "" } }],
      projects: [],
      objects: [],
      parts: [],
      reportedElsewhereForConfirmation: [],
      selectedObject: undefined,
      selectedWorkerType: undefined,
      selectedWorkerIndex: null,
      allowSave: true,
      // The main report object
      report: {
        date: undefined,
        startHour: "08:30",
        endHour: "17:00",
        overtime: false,
        createdBy: {
          _id: "",
          name: "",
        },
        company: {
          _id: "",
          companyName: "",
        },
        project: {
          _id: "",
          projectName: "",
        },
        object: {
          _id: "",
          objectName: "",
        },
        part: {
          _id: "",
          partName: "",
        },
        isInvalid: false,
        materials: [],
        activities: [],
        mechanizations: [],
        workers: [],
      },
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      "getUser",
      // ...
    ]),
    reportTotalSum() {
      let sum = 0;
      for (let tag of ["materials", "activities", "mechanizations"]) {
        for (let msm of this.report[tag]) {
          sum += msm.quantity * +msm.price;
        }
      }
      return sum.toFixed(2);
    },
  },
  methods: {
        reportGroups(r) {
      let groupIds = [];
      let groups = [];

      r.workers.forEach((w) => {
        if (w.isFromGroup) {
          if (w.group._id && !groupIds.includes(w.group._id)) {
            groupIds.push(w.group._id);
          }
        }
      });

      for (let gId of groupIds) {
        let g = { _id: gId, users: [] };
        r.workers.forEach((w) => {
          if (w.isFromGroup && w.group._id === gId) {
            g.name = w.group.name;
            g.users.push(w);
          }
        });
        groups.push(g);
      }

      return groups;
    },
        reportUsersVas(r) {
      return r.workers.filter((w) => !w.isFromGroup && !w.permissions.isWorker);
    },
    reportWorkersVas(r) {
      return r.workers.filter((w) => !w.isFromGroup && w.permissions.isWorker);
    },
    // Open dropdown. Depends on index (hard coded in template)
    openDropdown(i) {
      this.$set(this.openedDropdown, i, true);
    },
    // Close dropdown. Depends on index (hard coded in template)
    closeDropdown(i) {
      setTimeout(() => {
        this.$set(this.openedDropdown, i, false);
      }, 100);
    },
    // SELECTING
    // 1 - SELECT COMPANY BY ID
    selectCompany(company) {
      this.resetSelectedProject();
      this.report.company = company;
      // console.log(this.report.company);
    },
    // 2 - SELECT THE INDEX OF A 'PROJECT' INSIDE COMPANY
    selectProject(project) {
      this.resetSelectedObject();
      this.report.project = project;
    },
    // 3 - SELECT THE INDEX OF AN 'OBJECT' INSIDE A PROJECT
    selectObject(object) {
      this.resetSelectedPart();
      this.report.object = object;
      this.getSelectedObject(object._id);
    },
    // 4 - SELECT THE INDEX OF A 'PART' INSIDE AN OBJECT
    selectPart(part) {
      this.report.part = part;
    },
    // RESET SELECTED AND EVERYTHING NESTED INSIDE ON FOCUS
    resetSelectedCompany() {
      this.report.company = {
        _id: "",
        companyName: "",
      };
      this.searchCompanies = "";
      this.company = "";
      this.resetSelectedProject();
    },
    resetSelectedProject() {
      this.projects = [];
      this.searchProjects = "";
      this.report.project = {
        _id: "",
        projectName: "",
      };
      this.project = "";
      this.resetSelectedObject();
    },
    resetSelectedObject() {
      this.objects = [];
      this.searchObjects = "";
      this.report.object = {
        _id: "",
        objectName: "",
      };
      this.selectedObject = undefined;
      this.report.materials = [];
      this.report.activities = [];
      this.report.mechanizations = [];
      this.object = "";
      this.resetSelectedPart();
    },
    resetSelectedPart() {
      this.reportChanged();
      this.parts = [];
      this.searchParts = "";
      this.report.part = {
        _id: "",
        partName: "",
      };
      this.part = "";
    },
    // FILTERED RESULTS WHEN SEARCHING
    filteredCompanies() {
      if (this.companies.length) {
        return this.companies.filter((c) => {
          return c.companyName
            .toLowerCase()
            .includes(this.searchCompanies.toLowerCase());
        });
      }
    },
    filteredProjects() {
      if (this.report.company._id && this.projects.length) {
        return this.projects.filter((p) => {
          return p.projectName
            .toLowerCase()
            .includes(this.searchProjects.toLowerCase());
        });
      }
    },
    filteredObjects() {
      if (this.report.project._id && this.objects.length) {
        return this.objects.filter((o) => {
          return o.objectName
            .toLowerCase()
            .includes(this.searchObjects.toLowerCase());
        });
      }
    },
    filteredParts() {
      if (this.report.object._id && this.parts.length) {
        return this.parts.filter((p) => {
          return p.partName
            .toLowerCase()
            .includes(this.searchParts.toLowerCase());
        });
      }
    },
    // We have a selected materials, activities OR mechanizations at least and change styles in template
    viewLists() {
      return (
        this.report.workers.length ||
        this.report.materials.length ||
        this.report.activities.length ||
        this.report.mechanizations.length
      );
    },
    // If we get the 'saveWorkersModal' event, we assign the workers we receive to the report
    handleWorker(workers) {
      // this.report[this.selectedWorkerType][this.selectedWorkerIndex].worker = w;
      this.report.workers = [];

      for (let w of workers) {
        if (!w.startHour && !w.endHour) {
          this.$set(w, "startHour", "08:00");
          this.$set(w, "endHour", "17:00");
          this.$set(w, "new", true);
        }
        this.report.workers.push(w);
      }
      this.closeAddWorkersModal();
      this.reportChanged();
      this.checkForReportedElsewhereWorkers();
    },
    checkForReportedElsewhereWorkers() {
      if (!this.report.reportedElsewhere) return;

      for (let w of this.report.workers) {
        if (w.new && !w.elsewhereConfirmed) {
          if (this.report.reportedElsewhere.indexOf(w._id) > -1) {
            this.reportedElsewhereForConfirmation.push(w);
          }
        }
      }
    },
    reportedElsewhereConfirmed(id) {
      const i = this.report.workers.map((w) => w._id).indexOf(id);

      if (i > -1) {
        this.$set(this.report.workers[i], "elsewhereConfirmed", true);
      }

      const j = this.reportedElsewhereForConfirmation
        .map((w) => w._id)
        .indexOf(id);
      if (j > -1) this.reportedElsewhereForConfirmation.splice(j, 1);
    },
    reportedElsewhereDeclined(id) {
      const i = this.report.workers.map((w) => w._id).indexOf(id);

      if (i > -1) {
        this.report.workers.splice(i, 1);
      }

      const j = this.reportedElsewhereForConfirmation
        .map((w) => w._id)
        .indexOf(id);
      if (j > -1) this.reportedElsewhereForConfirmation.splice(j, 1);
    },
    handleMaterials(materials) {
      for (let mat of materials) {
        const m = { ...mat };
        m.quantity = 0;
        m.worker = null;
        this.report.materials.push(m);
      }
      this.closeAddMaterialsModal();
      this.reportChanged();
    },
    handleActivities(activities) {
      for (let act of activities) {
        const a = { ...act };
        a.quantity = 0;
        a.worker = null;
        this.report.activities.push(a);
      }
      this.closeAddActivitiesModal();
      this.reportChanged();
    },
    handleMechanizations(mechanizations) {
      for (let mech of mechanizations) {
        const m = { ...mech };
        m.quantity = 0;
        m.worker = null;
        this.report.mechanizations.push(m);
      }
      this.closeAddMechanizationsModal();
      this.reportChanged();
    },
    openAddWorkersModal(t, i) {
      if (this.allowSave) {
        this.selectedWorkerType = t;
        this.selectedWorkerIndex = i;
        this.isAddUsersModalOpened = true;
      }
    },
    openAddMaterialsModal() {
      if (this.allowSave) {
        this.isAddMaterialsModalOpened = true;
      }
    },
    openAddActivitiesModal() {
      if (this.allowSave) {
        this.isAddActivitiesModalOpened = true;
      }
    },
    openAddMechanizationsModal() {
      if (this.allowSave) {
        this.isAddMechanizationsModalOpened = true;
      }
    },
    // Close the workers modal
    closeAddWorkersModal() {
      this.isAddUsersModalOpened = false;
      this.selectedWorkerIndex = null;
      this.selectedWorkerType = null;
    },
    closeAddMaterialsModal() {
      this.isAddMaterialsModalOpened = false;
    },
    closeAddActivitiesModal() {
      this.isAddActivitiesModalOpened = false;
    },
    closeAddMechanizationsModal() {
      this.isAddMechanizationsModalOpened = false;
    },
    // JUST FOR THE CASE OF REACTIVITY TO WORK AND NOT USE forceUpdate()
    changeMaterial(i) {
      this.$set(this.report.materials, i, this.report.materials[i]);
      this.reportChanged();
    },
    changeActivity(i) {
      this.$set(this.report.activities, i, this.report.activities[i]);
      this.reportChanged();
    },
    changeMechanization(i) {
      this.$set(this.report.mechanizations, i, this.report.mechanizations[i]);
      this.reportChanged();
    },
    changeWorker(i) {
      this.$set(this.report.workers, i, this.report.workers[i]);
      this.reportChanged();
    },
    removeMaterial(i) {
      this.report.materials.splice(i, 1);
    },
    removeActivity(i) {
      this.report.activities.splice(i, 1);
    },
    removeWorker(w, isGroup) {
      if(isGroup) {
        for(let u of w.users) {
          let i = this.report.workers.indexOf(u) 
          this.report.workers.splice(i, 1);
        }
      } else {
        let i = this.report.workers.indexOf(w)
        this.report.workers.splice(i, 1);
      }
    },
    removeMechanization(i) {
      this.report.mechanizations.splice(i, 1);
    },
    // GET ALL COMPANIES REQUEST
    getCompanies() {
      this.companies = [];
      let id = this.getUser._id;
      let requestUrl = `${process.env.VUE_APP_BASE_URI}/company/reporters/companies/${id}`;
      this.$http
        .get(requestUrl)
        .then((response) => {
          this.companies = response.data;
          // console.log(this.companies);
        })
        .catch((error) => console.log(error));
    },
    // GET LATEST REPORTED COMPANIES
    getLatestUsedCompanies() {
      const id = this.getUser._id;
      const url = `${process.env.VUE_APP_BASE_URI}/report/limited/${id}/5`;
      this.$http
        .get(url)
        .then((response) => {
          this.latestCompanies = response.data;
          // console.log(this.latestCompanies);
        })
        .catch((error) => console.log(error));
    },
    // GET ALL VISIBLE PROJECTS INSIDE THE SELECTED COMPANY
    getProjects(autoselect) {
      this.projects = [];
      if (this.report.company._id.length) {
        let id = this.getUser._id;
        let requestUrl = `${process.env.VUE_APP_BASE_URI}/company/reporters/projects/${this.report.company._id}/${id}`;
        this.$http
          .get(requestUrl)
          .then((response) => {
            this.projects = response.data;
            if (autoselect) {
              if (this.projects.length === 1) {
                this.selectProject(this.projects[0]);
                this.getObjects(true);
              }
            }
          })
          .catch((error) => console.log(error));
      }
    },
    // GET ALL VISIBLE OBJECTS INSIDE THE SELECTED PROJECT
    getObjects(autoselect) {
      this.objects = [];
      if (this.report.project._id.length) {
        let id = this.getUser._id;
        let requestUrl = `${process.env.VUE_APP_BASE_URI}/company/reporters/objects/${this.report.company._id}/${this.report.project._id}/${id}`;
        this.$http
          .get(requestUrl)
          .then((response) => {
            this.objects = response.data;
            if (autoselect) {
              if (this.objects.length === 1) {
                this.selectObject(this.objects[0]);
                this.getParts(true)
              }
            }
            // console.log(this.objects);
          })
          .catch((error) => console.log(error));
      }
    },
    // GET ALL VISIBLE PARTS INSIDE THE SELECTED OBJECT
    getParts(autoselect) {
      this.parts = [];
      if (this.report.project._id.length) {
        let id = this.getUser._id;
        let requestUrl = `${process.env.VUE_APP_BASE_URI}/company/reporters/parts/${this.report.company._id}/${this.report.project._id}/${this.report.object._id}/${id}`;
        this.$http
          .get(requestUrl)
          .then((response) => {
            this.parts = response.data;
            if (autoselect) {
              if (this.parts.length === 1) {
                this.selectPart(this.parts[0]);
              }
            }
          })
          .catch((error) => console.log(error));
      }
    },
    // GET SPECIFIC PART BY ID REQUEST
    getSelectedObject(id) {
      this.selectedObject = undefined;
      let url = `${process.env.VUE_APP_BASE_URI}/company/object/${id}`;
      return this.$http
        .get(url)
        .then((response) => {
          this.selectedObject = response.data[0];
        })
        .catch((error) => console.log(error));
    },
    getMaterials() {
      let url = `${process.env.VUE_APP_BASE_URI}/company/object/${this.selectedObject._id}`;
      return this.$http
        .get(url)
        .then((response) => {
          this.selectedObject.materials = response.data[0].materials;
        })
        .catch((error) => console.log(error));
    },
    getActivities() {
      let url = `${process.env.VUE_APP_BASE_URI}/company/object/${this.selectedObject._id}`;
      return this.$http
        .get(url)
        .then((response) => {
          this.selectedObject.activities = response.data[0].activities;
        })
        .catch((error) => console.log(error));
    },
    getMechanizations() {
      let url = `${process.env.VUE_APP_BASE_URI}/company/object/${this.selectedObject._id}`;
      return this.$http
        .get(url)
        .then((response) => {
          this.selectedObject.mechanizations = response.data[0].mechanizations;
        })
        .catch((error) => console.log(error));
    },
    // GET SPECIFIC REPORT
    getReport(id) {
      let url = `${process.env.VUE_APP_BASE_URI}/report/${id}`;
      this.$http
        .get(url)
        .then((response) => {
          this.getSelectedObject(response.data.object._id).then(() => {
            this.report = response.data;
            this.report.date = new Date(response.data.date);
            if (!this.getUser.permissions.isReporter) {
              this.allowSave = false;
            }
            if (this.report.isInvalid) {
              if (
                !this.getUser.permissions.isSuperAdmin &&
                !this.getUser.permissions.isAdmin
              ) {
                this.allowSave = false;
                this.$snotify.warning(
                  this.$t("invalid-reports-warning-message")
                );
              }
            }
          });
        })
        .catch((error) => console.log(error));
    },
    isValid() {
      if (!this.report.company._id.length) {
        this.$snotify.error(this.$t("not-selected-company"));
        return false;
      }
      if (!this.report.project._id.length) {
        this.$snotify.error(this.$t("not-selected-project"));
        return false;
      }
      if (!this.report.object._id.length) {
        this.$snotify.error(this.$t("not-selected-site"));
        return false;
      }
      return true;
    },
    removeTempIds() {
      if (this.report.materials.length) {
        this.report.materials.map((m) => {
          if (m.tempId) {
            delete m._id;
          }
        });
      }
      if (this.report.activities.length) {
        this.report.activities.map((a) => {
          if (a.tempId) {
            delete a._id;
          }
        });
      }
      if (this.report.mechanizations.length) {
        this.report.mechanizations.map((a) => {
          if (a.tempId) {
            delete a._id;
          }
        });
      }
    },
    editReportCompany() {
      let url = `${process.env.VUE_APP_BASE_URI}/company/${this.report.company._id}`;

      this.$http.get(url).then((response) => {
        const company = response.data;
        // get index of selected project
        const indexOfProject = company.projects.findIndex(
          (p) => p._id === this.report.project._id
        );
        // get index of selected object
        const indexOfObject = company.projects[
          indexOfProject
        ].objects.findIndex((o) => o._id === this.report.object._id);

        this.report.activities.forEach((ra) => {
          company.projects[indexOfProject].objects[
            indexOfObject
          ].activities.forEach((a) => {
            if (ra.name == a.name && a.price === "0" && a.isAdditional) {
              a.price = ra.price;
              a.quantity = ra.quantity;
            }
          });
        });

        // make the post request
        let url = `${process.env.VUE_APP_BASE_URI}/company/${company._id}`;

        this.$http
          .put(url, company)
          .then(() => {})
          .catch((error) => {
            console.log(error);
            this.$snotify.error(this.$t("something-went-wrong"));
          });
      });
    },
    validateReport() {
      let url = `${process.env.VUE_APP_BASE_URI}/report/validate-report`;
      return this.$http.post(url, this.report);
    },
    // POST NEW REPORT
    postReport() {
      this.saving = true;
      if (this.isValid()) {
        this.report.createdAt = new Date();
        this.validateReport().then((a) => {
          if (a.data.errors) {
            this.$snotify.error(`${this.$t("something-went-wrong")}`);
            this.saving = false;
          }
          if (a.data.exists.length) {
            for (let i = 0; i < a.data.exists.length; i++) {
              this.$snotify.error(
                `${a.data.exists[i].workers.name} е вече добавен към друг отчет в диапазона ${a.data.exists[i].workers.startHour} - ${a.data.exists[i].workers.endHour}`
              );
            }
            this.saving = false;
          } else {
            this.removeTempIds();
            this.report.createdBy._id = this.getUser._id;
            this.report.createdBy.name = this.getUser.name;
            // if (this.report.part) { // moved in report post route
            //   if (!this.report.part._id) {
            //     delete this.report.part;
            //   }
            // }
            let url = `${process.env.VUE_APP_BASE_URI}/report`;
            // used for mails
            this.report.lng = this.$i18n.locale

            this.$http
              .post(url, this.report)
              .then((a) => {
                if (a.data.errors) {
                  this.$snotify.error(`${this.$t("something-went-wrong")}`);
                  this.saving = false;
                } else {
                  this.editReportCompany();
                  this.$snotify.success("Вашият отчет беше създаден успешно!");
                  this.newLog(
                    "newReport",
                    `обект - ${this.report.object.objectName}`
                  );
                  this.isReportChanged = false;
                  this.saving = false;
                  this.cancelReport();
                }
              })
              .catch((error) => {
                console.log(error.response);
                this.$snotify.error(`${this.$t("something-went-wrong")}`);
                this.saving = false;
              });
          }
        });
      } else {
        this.saving = false;
      }
    },
    // postReport() {
    //   if (this.isValid()) {
    //     this.removeTempIds();
    //     this.report.createdBy._id = this.getUser._id;
    //     this.report.createdBy.name = this.getUser.name;
    //     if (this.report.part) {
    //       if (!this.report.part._id) {
    //         delete this.report.part;
    //       }
    //     }
    //     let url = `${process.env.VUE_APP_BASE_URI}/report`;
    //     this.$http
    //       .post(url, this.report)
    //       .then(a => {
    //         if (a.data.errors) {
    //           this.$snotify.error(this.$t('something-went-wrong'));
    //         } else {
    //           this.$snotify.success(this.$t('success-report'));
    //           this.newLog(
    //             'newReport',
    //             `${this.$t('site').toLowerCase()} - ${
    //               this.report.object.objectName
    //             }`
    //           );
    //           this.isReportChanged = false;
    //           this.cancelReport();
    //         }
    //       })
    //       .catch(error => {
    //         console.log(error.response);
    //         this.$snotify.error(this.$t('something-went-wrong'));
    //       });
    //   }
    // },
    // EDIT REPORT
    editReport() {
      if (this.isValid()) {
        this.removeTempIds();
        let url = `${process.env.VUE_APP_BASE_URI}/report/${this.$route.params.id}`;
        this.$http
          .put(url, this.report)
          .then(() => {})
          .catch((error) => {
            console.log(error);
            this.$snotify.error(this.$t("something-went-wrong"));
          })
          .then(() => {
            this.editReportCompany();
            this.$snotify.success(this.$t("success-changes"));
            this.isReportChanged = false;
            this.newLog(
              "editReport",
              `${this.$t("site").toLowerCase()} - ${
                this.report.object.objectName
              }`
            );
            this.cancelReport();
          });
      }
    },
    // Cancel report and go back 1 route behind
    cancelReport() {
      if (this.isComingFromOutside) {
        this.$router.push("/reports/own");
      } else {
        this.$router.go(-1);
      }
    },
    reportChanged() {
      if (!this.isReportChanged) {
        this.isReportChanged = true;
      }
    },
    save() {
      if (this.$route.name === "newReport") {
        this.postReport();
      } else if (this.$route.name === "editReport") {
        this.editReport();
      }
    },
    checkForUnsavedData(next) {
      this.isUnsavedChangesModalOpened = true;
      this.$once("closeUnsavedChangesModal", (answer) => {
        // confirm
        if (answer) {
          next(false);
          this.save();
        }
        // decline
        else {
          next();
        }
        this.isUnsavedChangesModalOpened = false;
        this.isReportChanged = false;
      });
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.isComingFromOutside = from.name ? false : true;
    });
  },
  mounted() {
    this.today = new Date();
    this.today.setHours(23, 59, 59, 999);

    this.getCompanies();
    this.getLatestUsedCompanies();
    if (this.$route.name === "newReport") {
      this.report.date = new Date();
    } else if (this.$route.name === "editReport") {
      this.getReport(this.$route.params.id);
    }
    // Start listening for children's events
    this.$on("saveAddUsersModal", this.handleWorker);
    this.$on("saveAddMaterialsModal", this.handleMaterials);
    this.$on("saveAddActivitiesModal", this.handleActivities);
    this.$on("saveAddMechanizationsModal", this.handleMechanizations);
    this.$on("getMaterialsAgain", this.getMaterials);
    this.$on("getActivitiesAgain", this.getActivities);
    this.$on("getMechanizationsAgain", this.getMechanizations);
    // Close listening for children's events
    this.$on("closeAddUsersModal", this.closeAddWorkersModal);
    this.$on("closeAddMaterialsModal", this.closeAddMaterialsModal);
    this.$on("closeAddActivitiesModal", this.closeAddActivitiesModal);
    this.$on("closeAddMechanizationsModal", this.closeAddMechanizationsModal);
  },
  beforeRouteLeave(to, from, next) {
    if (this.isReportChanged) {
      this.checkForUnsavedData(next);
    } else {
      next();
    }
  },
  destroyed() {
    // Stop listening
    this.$off("saveAddUsersModal");
    this.$off("saveAddMaterialsModal");
    this.$off("saveAddActivitiesModal");
    this.$off("saveAddMechanizationsModal");
    this.$off("getMaterialsAgain");
    this.$off("getActivitiesAgain");
    this.$off("getMechanizationsAgain");

    this.$off("closeAddUsersModal");
    this.$off("closeAddMaterialsModal");
    this.$off("closeAddActivitiesModal");
    this.$off("closeAddMechanizationsModal");
  },
};
</script>

<style scoped lang="scss">
.new-report {
  padding: 10px 40px;
}
.report-header {
  align-items: center;
  display: flex;
  height: 36px;
  justify-content: space-between;
  margin-bottom: 10px;
}
.heads {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}
.title {
  margin-left: 0.5em;
}
.italic {
  font-style: italic;
}
.italic::placeholder {
  font-style: italic;
}
.italic::-moz-placeholder {
  font-style: italic;
}
.italic:-moz-placeholder {
  font-style: italic;
}
.italic:-ms-input-placeholder {
  font-style: italic;
}
.italic::-webkit-input-placeholder {
  font-style: italic;
}
/* .desktop { */
/* display: flex; */
/* } */
.mobile {
  display: none;
}
.new-content-data-item-toggle-circle .tms2 {
  font-size: 18px;
}
.btn.new-content-data-item-toggle-circle {
  margin-left: 0;
}
.date {
  cursor: pointer;
}
.indicator-display {
  align-items: center;
  border: 1px solid #d3e0f5;
  display: flex;
  height: 40px;
  justify-content: space-between;
  padding: 5px 15px 5px 40px;
}
.indicator-inactive {
  background-color: #c4c4c4;
}
.new-wrapper-invalid {
  background-color: #fff8f8;
}
.invalid-report-wrap {
  align-items: center;
  background-color: #fff;
  border: 1px solid #d3e0f5;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  padding: 10px;
}
.invalid-report-wrap.total-price {
  font-size: 18px;
  font-weight: 700;
  margin-bottom: 15px;
  justify-content: flex-end;
  width: 100%;
}
.invalid-report-wrap .switch-wrapper {
  align-items: center;
  display: flex;
}
.invalid-report-wrap .switch {
  margin: 0;
}
.invalid-report-wrap .switch-label {
  align-items: center;
  display: flex;
}
.invalid-report-wrap .slider {
  background-color: #c4c4c4;
}
.invalid-report-wrap input:checked + .slider {
  background-color: #9f0510;
}
.invalid-report-wrap .flag {
  height: 24px;
  margin: 0 8px;
}
.invalid-report-wrap > p {
  padding-right: 20px;
}
.title .flag {
  height: 24px;
  vertical-align: baseline;
  margin: 0 5px;
}
.title span {
  display: inline-flex;
  align-items: center;
}
.first-tr td {
  padding-bottom: 10px;
}
.test {
  tr:not(.first-tr):not(.users-cell) {
    td {
      padding: 10px 20px;
    }
  }
}
.first-tr--report-users {
  position: relative;
  z-index: 1;
}
.first-tr--report-users:after {
  background-color: #0d5683;
  content: "";
  height: 25px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: calc(100% + 23px);
  z-index: -1;
}
.first-tr--report-users td {
  color: #ffffff;
  padding: 0 20px;
  z-index: 1;
}
.add-worker-p {
  cursor: pointer;
}
.add-workers-toggle-wrapper {
  text-align: center;
}
.add-workers-toggle-wrapper .tms {
  align-items: center;
  background-color: #0058bc;
  border-radius: 50%;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 12px;
  justify-content: center;
  height: 20px;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -ms-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
  width: 20px;
}
.add-workers-toggle-wrapper .tms:hover {
  background-color: #0058bc;
}
/* .new-content-data-item-content table {
  border-collapse: collapse;
} */
/* .new-content-data-item-content tr td { 
  padding: 5px 10px;
} */
/* .new-content-data-item-content tr:nth-child(even) td { */
/* border-bottom: 1px solid #d3e0f5; */
/* } */
/* Responsive */
@media screen and (max-width: 1280px) {
  .indicator-display {
    padding-right: 2px;
  }
  .indicator-display .indicators {
    display: inline-flex;
  }
  .indicator-display .worktime-indicator {
    height: 34px;
    margin: 0 2px;
    width: 14px;
  }
  .indicator-display .worktime-indicator span {
    transform: rotate(-90deg);
    font-size: 9px;
    white-space: nowrap;
    font-weight: 700;
  }
}
@media screen and (max-width: 1000px) {
  .mobile {
    display: flex;
    width: 100%;
    flex-direction: row;
  }
  .new-report {
    padding: 10px 15px;
  }
  .new-content-inputs.desktop,
  .desktop {
    display: none;
  }
  .new-content {
    width: 100%;
  }
  .new-content-inputs.mobile {
    display: flex;
    flex-direction: column;
  }
  .new-input-wrapper {
    width: 100%;
  }
  /* .new-input.mobile.drop {
    padding: 15px;
  } */
  .new-content-inputs {
    flex-direction: column;
  }
  .new-wrapper {
    padding: 5px;
  }
  .new-wrapper .new-input {
    margin: 5px 0;
  }
  .new-wrapper input,
  .select {
    height: 30px;
    padding: 5px 4px 5px 40px;
  }
  .new-content-data-row {
    overflow: auto;
  }
  .new-content-data-row .new-content-data-item {
    min-width: 33%;
  }
  .new-content-data-item {
    margin-right: 10px;
  }
  .new-content-data-item-content table td {
    height: 20px;
  }
  .new-content-data-item-content .ma-cell-name,
  .new-content-data-item-toggle .ma-cell-name {
    width: 20%;
  }
  .new-report {
    padding: 5px;
  }
  .date-irv-wrapper {
    display: flex;
    width: 100%;
  }
  .datepicker-dropdown-active {
    width: 210%;
  }
  .start-end-wrapper {
    display: flex;
    width: 100%;
  }
  .report-header {
    justify-content: flex-start;
  }
  .new-content-data-item-toggle.mobile {
    text-align: center;
    /* flex-direction: column; */
    height: auto;
    padding-bottom: 0.6em;
  }
  .new-content-data-item-small {
    width: calc(33.33% - 13.33px);
  }
  .new-content-data-item-small .new-content-data-item-toggle.mobile {
    flex-direction: column;
  }
  .new-content-data-item-toggle.mobile p {
    margin-top: 0.6em;
    margin-bottom: 0.6em;
    margin-right: 0px;
  }
  .new-content-data-item-content {
    overflow: auto;
  }
  .new-content-data-item-content .ma-cell-name {
    white-space: nowrap;
  }
  .new-content-data-item-content .ma-cell-nowrap {
    white-space: nowrap;
  }
  .new-content-data-item-content .users-cell p {
    display: flex;
    -webkit-box-pack: end;
    -ms-flex-pack: end;
    justify-content: flex-end;
    margin-right: 0px;
  }
  .btns.mobile {
    display: flex;
    justify-content: center;
    margin-top: 1.5em;
    margin-bottom: 1.5em;
  }
  .date,
  .indicator-display {
    height: 51px;
  }
  .label {
    color: #373737;
    font-size: 11px;
    font-weight: 700;
  }
}
@media screen and (max-width: 600px) {
  .invalid-report-wrap {
    flex-direction: column;
  }
  .invalid-report-wrap > p {
    padding: 0 0 15px;
    text-align: center;
  }
}
</style>
