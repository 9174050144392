var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"header"},[_c('div',{staticClass:"header-top"},[_c('img',{staticClass:"header-logo desktop",attrs:{"src":require("../../assets/img/logo.svg"),"alt":"TMS"}}),_c('img',{staticClass:"header-logo mobile",attrs:{"src":require("../../assets/img/login/logo.svg"),"alt":"TMS"}}),(_vm.getLoading)?_c('div',{staticClass:"loading-container"},[_c('img',{staticClass:"loading",attrs:{"src":require("../../assets/img/loading.svg"),"alt":"loading"}})]):_vm._e(),_c('div',{staticClass:"header-top-right"},[(
          this.$route.name !== 'inquiry' &&
          this.$route.name !== 'log' &&
          this.$route.name !== 'calendar'
        )?_c('div',{staticClass:"header-toggle header-search mobile",on:{"click":function($event){return _vm.showInput()}}},[_c('span',{staticClass:"tms"},[_vm._v("N")])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(
          _vm.isSearchShown == true &&
          this.$route.name !== 'inquiry' &&
          this.$route.name !== 'log' &&
          this.$route.name !== 'calendar'
        ),expression:"\n          isSearchShown == true &&\n          this.$route.name !== 'inquiry' &&\n          this.$route.name !== 'log' &&\n          this.$route.name !== 'calendar'\n        "}],staticClass:"search-input-wrapper mobile"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],ref:"searchInputField",staticClass:"search-input",attrs:{"type":"text","placeholder":_vm.$t('search')},domProps:{"value":(_vm.search)},on:{"keyup":function($event){return _vm.sendSearchInput()},"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}})]),_c('div',{staticClass:"header-toggle",on:{"click":function($event){return _vm.changeLang()}}},[_c('p',[_vm._v(_vm._s(_vm.$i18n.locale === "bg" ? "EN" : "БГ"))])]),_c('div',{staticClass:"vl desktop"}),_c('div',{staticClass:"vl desktop"}),(_vm.getUser.permissions.isSuperAdmin)?_c('div',{staticClass:"header-toggle header-toggle--logs",on:{"click":function($event){return _vm.goToLog()}}},[_c('span',{staticClass:"tms2"},[_vm._v("n")]),_c('div',{staticClass:"desktop"},[_c('p',[_vm._v(_vm._s(_vm.$t("logs")))])])]):_vm._e(),_c('div',{staticClass:"vl desktop"}),(_vm.getUser)?_c('div',{staticClass:"header-toggle",on:{"click":function($event){_vm.isUserModalOpened = true}}},[_c('span',{staticClass:"tms"},[_vm._v("B")]),_c('div',{staticClass:"desktop"},[_c('p',[_vm._v(_vm._s(_vm.getUser.name))])])]):_vm._e(),_c('span',{staticClass:"header-logout tms",on:{"click":function($event){return _vm.logout()}}},[_vm._v("F")])])]),_c('div',{staticClass:"header-bottom"},[_c('div',{staticClass:"header-bottom-left"},[(
          _vm.getUser.permissions.isSuperAdmin ||
          _vm.getUser.permissions.isAdmin ||
          _vm.getUser.permissions.isReporter ||
          _vm.getUser.permissions.isEmployee
        )?_c('router-link',{staticClass:"header-bottom-tab",attrs:{"exact-active-class":"header-bottom-tab-active","to":"/planning"}},[_c('span',{staticClass:"tms2 mobile-inline"},[_vm._v("F")]),_c('span',{staticClass:"desktop"},[_vm._v(_vm._s(_vm.$t("planning")))]),_c('span',{staticClass:"mobile-text"},[_vm._v(_vm._s(_vm.$t("planning")))])]):_vm._e(),(
          _vm.getUser.permissions.isSuperAdmin ||
          _vm.getUser.permissions.isAdmin ||
          _vm.getUser.permissions.isReporter
        )?_c('router-link',{staticClass:"header-bottom-tab",class:{
          'header-bottom-tab-active': this.$route.name === 'inquiry',
        },attrs:{"exact-active-class":"header-bottom-tab-active","to":"/inquiry"}},[_c('span',{staticClass:"tms2 mobile-inline"},[_vm._v("A")]),_c('span',{staticClass:"desktop"},[_vm._v(_vm._s(_vm.$t("inquiries")))]),_c('span',{staticClass:"mobile-text"},[_vm._v(_vm._s(_vm.$t("inquiries")))])]):_vm._e(),(_vm.getUser.permissions.isReporter)?_c('router-link',{staticClass:"header-bottom-tab",class:{
          'header-bottom-tab-active':
            this.$route.name === 'newReport' ||
            this.$route.name === 'editReport',
        },attrs:{"exact-active-class":"header-bottom-tab-active","to":"/reports/own"}},[_c('span',{staticClass:"tms2 mobile-inline"},[_vm._v("B")]),_c('span',{staticClass:"desktop"},[_vm._v(_vm._s(_vm.$t("reports")))]),_c('span',{staticClass:"mobile-text"},[_vm._v(_vm._s(_vm.$t("reports")))])]):_vm._e(),(_vm.getUser.permissions.isSuperAdmin || _vm.getUser.permissions.isAdmin)?_c('router-link',{staticClass:"header-bottom-tab",class:{
          'header-bottom-tab-active': this.$route.name === 'assets',
        },attrs:{"exact-active-class":"header-bottom-tab-active","to":"/assets"}},[_c('span',{staticClass:"tms2 mobile-inline"},[_vm._v("B")]),_c('span',{staticClass:"desktop"},[_vm._v(_vm._s(_vm.$t("assets")))]),_c('span',{staticClass:"mobile-text"},[_vm._v(_vm._s(_vm.$t("assets")))])]):_vm._e(),(
          _vm.getUser.permissions.isSuperAdmin ||
          _vm.getUser.permissions.isAdmin ||
          _vm.getUser.permissions.isReporter
        )?_c('router-link',{staticClass:"header-bottom-tab",class:{
          'header-bottom-tab-active':
            this.$route.name === 'newCompany' ||
            this.$route.name === 'editCompany' ||
            this.$route.name === 'newProject' ||
            this.$route.name === 'newObject' ||
            this.$route.name === 'newPart' ||
            this.$route.name === 'getPart',
        },attrs:{"exact-active-class":"header-bottom-tab-active","to":"/companies"}},[_c('span',{staticClass:"tms2 mobile-inline"},[_vm._v("C")]),_c('span',{staticClass:"desktop"},[_vm._v(_vm._s(_vm.$t("companies")))]),_c('span',{staticClass:"mobile-text"},[_vm._v(_vm._s(_vm.$t("companies")))])]):_vm._e(),_c('router-link',{staticClass:"header-bottom-tab",attrs:{"exact-active-class":"header-bottom-tab-active","to":"/users"}},[_c('span',{staticClass:"tms2 mobile-inline"},[_vm._v("D")]),_c('span',{staticClass:"desktop"},[_vm._v(_vm._s(_vm.$t("users")))]),_c('span',{staticClass:"mobile-text"},[_vm._v(_vm._s(_vm.$t("users")))])])],1),_c('div',{staticClass:"header-bottom-right desktop"},[(
          this.$route.name !== 'inquiry' &&
          this.$route.name !== 'log' &&
          this.$route.name !== 'calendar'
        )?_c('div',{staticClass:"search-input-wrapper"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],staticClass:"search-input",attrs:{"type":"text","placeholder":_vm.$t('search')},domProps:{"value":(_vm.search)},on:{"keyup":function($event){return _vm.sendSearchInput()},"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}})]):_vm._e()])]),_c('transition',{attrs:{"name":"fade"}},[(_vm.voiceListening)?_c('div',{staticClass:"voice-listener"},[_c('div',{staticClass:"voice-listener-inner"},[_c('div',{staticClass:"voice-listener-inner"},[_c('div',{staticClass:"voice-listener-inner"},[_c('div',{staticClass:"voice-listener-inner"})])])])]):_vm._e()]),_c('transition',{attrs:{"name":"fade"}},[(_vm.isUserModalOpened)?_c('UserModal',{attrs:{"idToEdit":_vm.getUser._id,"limitedFunctionality":true,"userMode":true}}):_vm._e()],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.isVoiceCommandsModalOpened)?_c('VoiceCommandsModal'):_vm._e()],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }