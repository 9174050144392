<template>
  <div
    class="modal-wrapper copy-move-modal"
    :class="{ 'modal-copy': action === 'copy' }"
  >
    <div class="modal">
      <div class="modal-header">
        <h1 v-if="action === 'copy'">{{ $t("copying") }}</h1>
        <h1 v-if="action === 'move'">{{ $t("move") }}</h1>
        <span class="tms modal-close" @click="close(false)">F</span>
      </div>
      <div class="modal-content">
        <datepicker
          :language="this[$i18n.locale]"
          :inline="true"
          :monday-first="true"
          :minimumView="'day'"
          :maximumView="'day'"
          :full-month-name="true"
          :highlighted="selected"
          @selected="selectDate"
          v-model="date"
        ></datepicker>
      </div>
      <div class="modal-footer">
        <button class="btn btn-new" @click="save()">
          <span class="tms">Y</span>
          {{ $t("save") }}
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { bg, en } from "vuejs-datepicker/dist/locale";
export default {
  components: {
    Datepicker: Datepicker,
  },
  props: ["action", "id"],
  data() {
    return {
      bg: bg,
      en: en,
      months: this.$t("months"),
      date: undefined,
      selected: {
        dates: [],
      },
      calendar: {
        date: undefined,
      },
    };
  },
  methods: {
    selectDate(date) {
      if (this.action === "copy") {
        for (let [index, d] of this.selected.dates.entries()) {
          const slct = `${d.getDate()}/${d.getMonth()}/${d.getFullYear()}`;
          const nw = `${date.getDate()}/${date.getMonth()}/${date.getFullYear()}`;

          if (slct === nw) {
            this.selected.dates.splice(index, 1);
            return;
          }
        }
        this.selected.dates.push(date);
      }
    },
    getCalendar() {
      this.$store.dispatch("startLoading");
      let requestUrl = `${process.env.VUE_APP_BASE_URI}/calendar/${this.id}`;
      this.$http
        .get(requestUrl)
        .then((response) => {
          this.calendar = response.data;
          this.$store.dispatch("stopLoading");
        })
        .catch((err) => {
          console.log(err);
          this.$store.dispatch("stopLoading");
          this.$snotify.error(this.$t("something-went-wrong"));
          this.close(false);
        });
    },
    save() {
      if (this.action === "move") {
        this.exists(this.date).then((response) => {
          if (!response.data.length) {
            this.move();
          } else {
            this.$snotify.error(
              `Този обект е вече добавен за ${this.date.getDate()} ${
                this.months[this.date.getMonth()]
              } ${this.date.getFullYear()}`
            );
          }
        });
      } else if (this.action === "copy") {
        const promises = [];

        for (let d of this.selected.dates) {
          const p = new Promise((res, rej) => {
            this.exists(d)
              .then((response) => {
                if (!response.data.length) {
                  res();
                } else {
                  const resDate = new Date(response.data[0].date);
                  rej(
                    `Този обект е вече добавен за ${resDate.getDate()} ${
                      this.months[resDate.getMonth()]
                    } ${resDate.getFullYear()}`
                  );
                }
              })
              .catch((err) => {
                console.log(err);
                rej(`${this.$t('something-went-wrong')}`);
              });
          });

          promises.push(p);
        }

        Promise.all(promises)
          .then(() => {
            this.copy();
          })
          .catch((err) => {
            this.$snotify.error(err);
          });
      }
    },
    exists(d) {
      const url = `${
        process.env.VUE_APP_BASE_URI
      }/calendar/exists/${d.toISOString()}/${this.calendar.companyId}/${
        this.calendar.projectId
      }/${this.calendar.objectId}`;
      return this.$http.get(url);
    },
    copy() {
      if (!this.selected.dates.length) {
        this.$snotify.error("Трябва да изберете поне един ден!");
        return;
      }
      const url = `${process.env.VUE_APP_BASE_URI}/calendar`;
      const promises = [];

      delete this.calendar._id;
      this.calendar.isReady = false;
      this.calendar.isConfirmed = false;
      delete this.calendar.readyAt;
      delete this.calendar.readyBy;
      delete this.calendar.confirmedBy;
      delete this.calendar.confirmedAt;

      for (let d of this.selected.dates) {
        const c = Object.assign({}, this.calendar);
        c.date = d.toISOString();

        // eslint-disable-next-line
        const p = new Promise((res, rej) => {
          this.$http
            .post(url, c)
            .then(() => {
              res();
            })
            .catch((error) => {
              rej(error);
            });
        });
      }

      Promise.all(promises)
        .then(() => {
          const count = this.selected.dates.length;
          this.$snotify.success(
            `Успешно копиран обект за ${count} ${count === 1 ? "ден" : "дни"}!`
          );
          this.close(true);
        })
        .catch((error) => {
          console.log(error);
          this.$snotify.error(`${this.$t('something-went-wrong')}`);
        });
    },
    move() {
      this.calendar.date = this.date.toISOString();
      const url = `${process.env.VUE_APP_BASE_URI}/calendar/${this.calendar._id}`;
      this.$http
        .put(url, this.calendar)
        .then(() => {
          this.$snotify.success(
            `Успешно преместен обект на ${this.date.getDate()} ${
              this.months[this.date.getMonth()]
            } ${this.date.getFullYear()}`
          );
          this.close(true);
        })
        .catch((error) => {
          console.log(error);
          this.$snotify.error(`${this.$t('something-went-wrong')}`);
        });
    },
    close(update) {
      this.$parent.$emit("closeCopyMoveCalendarModal", { updated: update });
    },
  },
  mounted() {
    this.getCalendar();
    this.date = new Date();
    this.$eventBus.$on("closeModalFromSpeechRecognition", () => {
      this.close();
    });
  },
  destroyed() {
    this.$eventBus.$off("closeModalFromSpeechRecognition");
  },
};
</script>

<style scoped>
.modal {
  width: initial;
}
.modal-header {
  justify-content: flex-start;
  padding: 0 30px;
}
.modal-header h1 {
  color: #fff;
  font-size: 20px;
}
.modal-footer {
  justify-content: center;
}
.btn {
  margin: 0;
}
/* Responsive */
@media screen and (max-width: 1000px) {
  .modal {
    max-height: 80vh;
    width: 90%;
  }
  .modal-header .new-input {
    width: 72%;
  }
}
@media screen and (max-width: 600px) {
  .modal-content-check {
    width: 45%;
  }
  .modal-footer {
    flex-direction: column;
    height: 97px;
  }
  .select-btns {
    margin: 10px 0;
  }
  .btn.btn-new {
    margin: 0 0 10px 0;
  }
}
@media screen and (max-width: 400px) {
  .modal-content-check {
    width: 100%;
  }
}
</style>
