<template>
  <div class="input-modal-wrapper">
    <div
      class="input-modal"
      v-for="w in workers"
      :key="`elsewhere-modal-${w._id}`"
    >
      <header>
        ПЛАНИРАН РАБОТНИК
        <span class="tms">F</span>
      </header>
      <form>
        <div class="texts">
          <p>
            <strong>{{ w.name }}</strong> е планиран за друг обект за този
            период. Сигурни ли сте че искате да го добавите в избрани от вас
            обект.
          </p>
          <p>
            След преместване, в отчета за другия обект, ще излезе със статус
            ПРЕМЕСТЕН.
          </p>
        </div>

        <div class="input-modal-actions">
          <div>
            <!-- JUST AND EMPTY DIV FOR THE SPACE-BETWEEN RULE TO WORK -->
          </div>
          <div class="btns">
            <button
              class="btn btn-close"
              @click.prevent="$emit('declined', w._id)"
            >
              ОТКАЗ
            </button>
            <button
              class="btn btn-new"
              type="submit"
              value="Submit"
              @click.prevent="$emit('confirmed', w._id)"
            >
              ОТЧЕТИ И ТУК
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  props: ["workers"],
};
</script>

<style scoped>
.input-modal-wrapper {
  background-color: rgba(0, 0, 0, 0.4);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}
.input-modal {
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  /* height: 60vh; */
  left: 50%;
  overflow: auto;
  padding: 10px 0 0;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 430px;
}
header {
  font-weight: 700;
  position: relative;
  text-align: center;
}
header .tms {
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  position: absolute;
  right: 20px;
  top: 50%;
  transform: translateY(-50%);
}
.input-modal-actions {
  background: #eeeeee;
  display: flex;
  justify-content: center;
  padding: 20px 0;
}

.texts {
  margin: 35px auto;
  text-align: center;
  width: 300px;
}

.texts p:first-child {
  margin-bottom: 1rem;
}

.btns .btn:first-child {
  margin-left: 0;
}

.hide {
  display: none;
}

/* Responsive */
@media screen and (max-width: 1000px) {
  .btn.btn-close {
    margin-bottom: 5px;
  }
}
@media screen and (max-width: 650px) {
  .input-modal {
    width: 90%;
  }
}
@media screen and (max-width: 450px) {
  .inputs {
    flex-direction: column;
  }
  .input-wrapper {
    margin-right: 0px;
  }
  .btns {
    display: flex;
    flex-direction: column;
  }
  .btn.btn-close {
    margin-bottom: 10px;
  }
}
</style>
