<template>
  <div class="header">
    <div class="header-top">
      <img
        class="header-logo desktop"
        src="../../assets/img/logo.svg"
        alt="TMS"
      />
      <img
        class="header-logo mobile"
        src="../../assets/img/login/logo.svg"
        alt="TMS"
      />
      <div v-if="getLoading" class="loading-container">
        <img class="loading" src="../../assets/img/loading.svg" alt="loading" />
      </div>
      <div class="header-top-right">
        <div
          class="header-toggle header-search mobile"
          v-if="
            this.$route.name !== 'inquiry' &&
            this.$route.name !== 'log' &&
            this.$route.name !== 'calendar'
          "
          @click="showInput()"
        >
          <span class="tms">N</span>
        </div>
        <div
          class="search-input-wrapper mobile"
          v-show="
            isSearchShown == true &&
            this.$route.name !== 'inquiry' &&
            this.$route.name !== 'log' &&
            this.$route.name !== 'calendar'
          "
        >
          <input
            class="search-input"
            type="text"
            :placeholder="$t('search')"
            v-model="search"
            ref="searchInputField"
            @keyup="sendSearchInput()"
          />
        </div>
        <div class="header-toggle" @click="changeLang()">
          <p>{{ $i18n.locale === "bg" ? "EN" : "БГ" }}</p>
        </div>
        <div class="vl desktop"></div>
        <!-- <div
          class="header-toggle"
          v-if="getUser"
          @click="isVoiceCommandsModalOpened = true"
        >
          <span class="tms2 voice">?</span>
        </div> -->
        <!-- <div class="vl desktop"></div> -->
        <!-- <div
          class="header-toggle toggle-voice-recognition"
          v-if="getUser"
          @click="toggleVoiceRecognition()"
        >
          <span class="tms2 voice">{{ voiceListening ? "o" : "p" }}</span>
        </div> -->
        <div class="vl desktop"></div>
        <div
          class="header-toggle header-toggle--logs"
          v-if="getUser.permissions.isSuperAdmin"
          @click="goToLog()"
        >
          <span class="tms2">n</span>
          <div class="desktop">
            <p>{{ $t("logs") }}</p>
          </div>
        </div>
        <div class="vl desktop"></div>
        <div
          class="header-toggle"
          v-if="getUser"
          @click="isUserModalOpened = true"
        >
          <span class="tms">B</span>
          <div class="desktop">
            <p>{{ getUser.name }}</p>
          </div>
        </div>
        <span class="header-logout tms" @click="logout()">F</span>
      </div>
    </div>
    <div class="header-bottom">
      <div class="header-bottom-left">
        <router-link
          v-if="
            getUser.permissions.isSuperAdmin ||
            getUser.permissions.isAdmin ||
            getUser.permissions.isReporter ||
            getUser.permissions.isEmployee
          "
          class="header-bottom-tab"
          exact-active-class="header-bottom-tab-active"
          to="/planning"
        >
          <span class="tms2 mobile-inline">F</span>
          <span class="desktop">{{ $t("planning") }}</span>
          <span class="mobile-text">{{ $t("planning") }}</span>
        </router-link>
        <router-link
          v-if="
            getUser.permissions.isSuperAdmin ||
            getUser.permissions.isAdmin ||
            getUser.permissions.isReporter
          "
          class="header-bottom-tab"
          exact-active-class="header-bottom-tab-active"
          :class="{
            'header-bottom-tab-active': this.$route.name === 'inquiry',
          }"
          to="/inquiry"
        >
          <span class="tms2 mobile-inline">A</span>
          <span class="desktop">{{ $t("inquiries") }}</span>
          <span class="mobile-text">{{ $t("inquiries") }}</span>
        </router-link>
        <router-link
          v-if="getUser.permissions.isReporter"
          class="header-bottom-tab"
          exact-active-class="header-bottom-tab-active"
          to="/reports/own"
          :class="{
            'header-bottom-tab-active':
              this.$route.name === 'newReport' ||
              this.$route.name === 'editReport',
          }"
        >
          <span class="tms2 mobile-inline">B</span>
          <span class="desktop">{{ $t("reports") }}</span>
          <span class="mobile-text">{{ $t("reports") }}</span>
        </router-link>
        <router-link
          v-if="getUser.permissions.isSuperAdmin || getUser.permissions.isAdmin"
          class="header-bottom-tab"
          exact-active-class="header-bottom-tab-active"
          to="/assets"
          :class="{
            'header-bottom-tab-active': this.$route.name === 'assets',
          }"
        >
          <span class="tms2 mobile-inline">B</span>
          <span class="desktop">{{ $t("assets") }}</span>
          <span class="mobile-text">{{ $t("assets") }}</span>
        </router-link>
        <!-- <router-link class="header-bottom-tab" exact-active-class="header-bottom-tab-active" to="/">Справки по отчети</router-link> -->
        <router-link
          v-if="
            getUser.permissions.isSuperAdmin ||
            getUser.permissions.isAdmin ||
            getUser.permissions.isReporter
          "
          class="header-bottom-tab"
          exact-active-class="header-bottom-tab-active"
          to="/companies"
          :class="{
            'header-bottom-tab-active':
              this.$route.name === 'newCompany' ||
              this.$route.name === 'editCompany' ||
              this.$route.name === 'newProject' ||
              this.$route.name === 'newObject' ||
              this.$route.name === 'newPart' ||
              this.$route.name === 'getPart',
          }"
        >
          <span class="tms2 mobile-inline">C</span>
          <span class="desktop">{{ $t("companies") }}</span>
          <span class="mobile-text">{{ $t("companies") }}</span>
        </router-link>
        <router-link
          class="header-bottom-tab"
          exact-active-class="header-bottom-tab-active"
          to="/users"
        >
          <span class="tms2 mobile-inline">D</span>
          <span class="desktop">{{ $t("users") }}</span>
          <span class="mobile-text">{{ $t("users") }}</span>
        </router-link>
        <!-- <router-link
          v-if="getUser.permissions.isSuperAdmin"
          class="header-bottom-tab"
          exact-active-class="header-bottom-tab-active"
          to="/charts"
        >
          <span class="tms2 mobile-inline">E</span>
          <span class="desktop">{{ $t('charts') }}</span>
          <span class="mobile-text">{{ $t('charts') }}</span>
        </router-link>-->
      </div>
      <div class="header-bottom-right desktop">
        <div
          class="search-input-wrapper"
          v-if="
            this.$route.name !== 'inquiry' &&
            this.$route.name !== 'log' &&
            this.$route.name !== 'calendar'
          "
        >
          <input
            class="search-input"
            type="text"
            :placeholder="$t('search')"
            @keyup="sendSearchInput()"
            v-model="search"
          />
        </div>
      </div>
      <!-- <div class="header-bottom-right ">

      </div>-->
    </div>
    <transition name="fade">
      <div class="voice-listener" v-if="voiceListening">
        <div class="voice-listener-inner">
          <div class="voice-listener-inner">
            <div class="voice-listener-inner">
              <div class="voice-listener-inner"></div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="fade">
      <UserModal
        v-if="isUserModalOpened"
        :idToEdit="getUser._id"
        :limitedFunctionality="true"
        :userMode="true"
      />
    </transition>
    <transition name="fade">
      <VoiceCommandsModal v-if="isVoiceCommandsModalOpened" />
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import UserModal from "./UserModal";
import VoiceCommandsModal from "./VoiceCommandsModal";
// import voiceRecognition from '../mixins/voiceRecognition';
export default {
  components: {
    UserModal,
    VoiceCommandsModal,
  },
  // mixins: [voiceRecognition],
  watch: {
    "$route.name"(to) {
      // don't reset search if route is 'newPart' or 'getPart', because that will make
      // a request to get all companies again and will reset the companies dropdown tree
      if (
        to !== "newPart" &&
        to !== "getPart" &&
        to !== "newProject" &&
        to !== "newObject"
      ) {
        this.search = "";
        this.sendSearchInput();
      }
    },
  },
  data() {
    return {
      isUserModalOpened: false,
      isVoiceCommandsModalOpened: false,
      keydownFired: false,
      search: "",
      isSearchShown: false,
      voiceListening: false,
      recognition: null,
    };
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      "getUser",
      "getLoading",
      // ...
    ]),
  },
  methods: {
    // initVoiceRecognition() {
    //   const stored = JSON.parse(localStorage.getItem('voiceListening'));

    //   if (stored) {
    //     this.voiceListening = true;
    //     this.startVoiceRecognition();
    //   } else if (stored === undefined || stored === null) {
    //     localStorage.setItem('voiceListening', false);
    //   }
    // },
    toggleVoiceRecognition() {
      this.voiceListening = !this.voiceListening;
      // localStorage.setItem('voiceListening', this.voiceListening);

      if (this.voiceListening) {
        this.startVoiceRecognition();
      } else {
        this.recognition.stop();
      }
    },
    startVoiceRecognition() {
      this.recognition = new (window.speechRecognition ||
        window.webkitSpeechRecognition ||
        window.mozSpeechRecognition ||
        window.msSpeechRecognition)();
      this.recognition.lang = "bg-BG";
      this.recognition.interimResults = false;
      this.recognition.maxAlternatives = 5;
      // this.recognition.continuous = true;

      this.recognition.start();

      this.recognition.onresult = (event) => {
        for (const i of event.results[event.results.length - 1]) {
          console.log(i);
          if (i.transcript.toLowerCase().includes("нов отчет")) {
            if (this.$route.name !== "editReport") {
              this.$router.push("/reports/new");
            }
            return;
          } else if (
            i.transcript.toLowerCase().includes("отвори отчети") ||
            i.transcript.toLowerCase().includes("моите отчети")
          ) {
            this.$router.push("/reports/own");
            return;
          } else if (i.transcript.toLowerCase().includes("отвори справки")) {
            this.$router.push("/inquiry");
            return;
          } else if (i.transcript.toLowerCase().includes("отвори планиране")) {
            this.$router.push("/planning");
            return;
          } else if (i.transcript.toLowerCase().includes("отвори фирми")) {
            this.$router.push("/companies");
            return;
          } else if (
            i.transcript.toLowerCase().includes("отвори потребители")
          ) {
            this.$router.push("/users");
            return;
          } else if (i.transcript.toLowerCase().includes("планирай обекти")) {
            this.$router.push("/planning?plan=true");
            return;
          } else if (
            i.transcript
              .toLowerCase()
              .includes("направи справка за текущия месец")
          ) {
            if (this.$route.name === "inquiry") {
              this.$eventBus.$emit(
                "makeInquiryFromSpeechRecognition",
                "current"
              );
            } else {
              this.$router.push(
                `/inquiry?fromDate=${this.getDates(
                  0
                ).from.toISOString()}&toDate=${this.getDates(
                  0
                ).to.toISOString()}&period=current&voice=true`
              );
            }
            return;
          } else if (
            i.transcript
              .toLowerCase()
              .includes("направи справка за миналия месец")
          ) {
            if (this.$route.name === "inquiry") {
              this.$eventBus.$emit(
                "makeInquiryFromSpeechRecognition",
                "previous"
              );
            } else {
              this.$router.push(
                `/inquiry?fromDate=${this.getDates(
                  1
                ).from.toISOString()}&toDate=${this.getDates(
                  1
                ).to.toISOString()}&period=previous&voice=true`
              );
            }
            return;
          } else if (
            i.transcript.toLowerCase().includes("експорт") ||
            i.transcript.toLowerCase().includes("експортирай")
          ) {
            this.$eventBus.$emit("export");
            return;
          } else if (i.transcript.toLowerCase().includes("смени ми паролата")) {
            this.isUserModalOpened = true;
            return;
          } else if (i.transcript.toLowerCase().includes("назад")) {
            this.$router.go(-1);
            return;
          } else if (
            i.transcript.toLowerCase().includes("отказ") ||
            i.transcript.toLowerCase().includes("затвори")
          ) {
            this.$eventBus.$emit("closeModalFromSpeechRecognition");
            return;
          } else if (
            i.transcript.toLowerCase().includes("спри да слушаш") ||
            i.transcript.toLowerCase().includes("стоп")
          ) {
            this.voiceListening = false;
            this.recognition.stop();
            return;
          }
        }
      };

      navigator.mediaDevices
        .getUserMedia({ audio: true })
        .then(function (stream) {
          // Handle the incoming audio stream
          // const bars = []; // We'll use this later
          const audioContext = new AudioContext();
          const input = audioContext.createMediaStreamSource(stream);
          const analyser = audioContext.createAnalyser();
          const scriptProcessor = audioContext.createScriptProcessor();

          // Some analyser setup
          analyser.smoothingTimeConstant = 0.3;
          analyser.fftSize = 1024;

          input.connect(analyser);
          analyser.connect(scriptProcessor);
          scriptProcessor.connect(audioContext.destination);
        })
        .catch(function (err) {
          console.log(err);
          /* handle the error */
        });

      this.recognition.onend = () => {
        this.voiceListening = false;
        // if (this.voiceListening) {
        //   recognition.start();
        // }
      };
    },
    getDates(months) {
      const date = new Date();
      // set first day of previous month
      let startDate = new Date(date.getFullYear(), date.getMonth() - months, 1);
      startDate.setHours(0, 0, 0, 0);
      // set last day of previous month
      let toDate = new Date(
        date.getFullYear(),
        date.getMonth() + months + 1,
        0
      );
      toDate.setHours(23, 59, 59, 999);

      return {
        from: startDate,
        to: toDate,
      };
    },
    goToLog() {
      this.$router.push("/log");
    },
    logout() {
      this.$store
        .dispatch("logout")
        .then(() => this.$router.push("/login"))
        .catch((err) => console.log(err));
    },
    // Send searched string globally
    sendSearchInput() {
      this.$root.$emit("sendSearchInput", this.search);
    },
    showInput() {
      this.isSearchShown = !this.isSearchShown;
      if (this.isSearchShown) {
        this.$nextTick(() => {
          this.$refs["searchInputField"].focus();
        });
      }
      this.search = "";
    },
    handleKeydown(event) {
      if (event.key === "Alt") {
        event.preventDefault();
        let hold = true;

        window.addEventListener(
          "keyup",
          () => {
            hold = false;
            this.keydownFired = false;
            return false;
          },
          { once: true }
        );

        if (!this.keydownFired) {
          setTimeout(() => {
            if (hold) {
              this.toggleVoiceRecognition();
              return false;
            }
          }, 500);
        }

        this.keydownFired = true;
      }
    },
    changeLang() {
      if (this.$i18n.locale === "bg") {
        this.$i18n.locale = "en";
      } else {
        this.$i18n.locale = "bg";
      }
      localStorage.setItem("lang", this.$i18n.locale);
    },
  },
  mounted() {
    this.$on("closeUserModal", (result) => {
      this.isUserModalOpened = false;
      // if result.isEdited = true, this means something is changed and we get the users again
      if (result.isEdited) {
        this.$store
          .dispatch("logout")
          .then(() => this.$router.push("/login"))
          .catch((err) => console.log(err));
      }
    });
    // this.initVoiceRecognition();
    this.$on("closeVoiceCommandsModal", () => {
      this.isVoiceCommandsModalOpened = false;
    });

    // window.addEventListener("keydown", this.handleKeydown);
  },
  destroyed() {
    this.$off("closeUserModal");
    this.$off("closeVoiceCommandsModal");
    // window.removeEventListener("keydown", this.handleKeydown);
  },
};
</script>

<style scoped>
.header {
  background: #3377b1;
  height: 75px;
  width: 100%;
}
.header-top,
.header-bottom {
  align-items: center;
  display: flex;
  height: 40px;
  justify-content: space-between;
  padding: 0 40px;
  width: 100%;
}
.header-bottom {
  align-items: flex-start;
  height: 35px;
}
/* HEADER TOP */
.header-logo {
  height: 28px;
  width: auto;
}
.header-top-right {
  align-items: center;
  display: flex;
}
.header-toggle {
  align-items: center;
  border-radius: 1px;
  cursor: pointer;
  display: flex;
  height: 31px;
  padding: 0 10px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.header-toggle:hover {
  background-color: rgba(0, 0, 0, 0.15);
}
.header-toggle .tms,
.header-toggle .tms2 {
  color: #fff;
  font-size: 16px;
  margin-right: 10px;
}
.header-toggle .voice {
  margin-right: 0;
}
.header-toggle--logs {
  min-width: 115px;
}
.header-toggle p {
  color: #fff;
  font-size: 12px;
}
.header-logout {
  border-radius: 1px;
  color: #fff;
  cursor: pointer;
  font-size: 14px;
  padding: 10px;
  margin: 0 -10px 0 8px;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -ms-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.header-logout:hover {
  background-color: rgba(0, 0, 0, 0.15);
}
/* HEADER BOTTOM */
.header-bottom-left {
  align-items: flex-end;
  display: flex;
  height: 35px;
}
.header-bottom-tab {
  align-items: center;
  background-color: rgba(240, 240, 247, 0.2);
  border-top-left-radius: 1px;
  border-top-right-radius: 1px;
  color: #fff;
  font-size: 12px;
  display: inline-flex;
  justify-content: center;
  margin-right: 3px;
  padding: 6px 15px;
  text-transform: uppercase;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  white-space: nowrap;
}
.header-bottom-tab:hover {
  background-color: rgba(240, 240, 247, 0.3);
}
.header-bottom-tab-active {
  background-color: #fff;
  color: #000;
}
.header-bottom-tab-active:hover {
  background-color: #fff;
}
.header-bottom-right {
  align-items: center;
  display: flex;
}
.search-input-wrapper {
  height: 31px;
  position: relative;
  width: 250px;
}
.search-input-wrapper::before {
  color: #fff;
  content: "N";
  font-family: "tms";
  font-size: 16px;
  left: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.search-input {
  background-color: rgba(255, 255, 255, 0.2);
  border: none;
  border-radius: 1px;
  caret-color: #fff;
  color: #fff;
  height: 100%;
  padding: 5px 15px 5px 34px;
  width: 100%;
}
.search-input::placeholder {
  color: #fff;
}
.mobile,
.mobile-inline,
.mobile-text {
  display: none;
}

.voice-listener {
  align-items: center;
  animation: pulse 1s infinite ease-in-out;
  background-color: rgba(117, 44, 243, 0.2);
  border-radius: 50%;
  bottom: 0;
  display: flex;
  height: 50vw;
  justify-content: center;
  left: 50%;
  position: fixed;
  transform: translateX(-50%) translateY(50%);
  width: 50vw;
  z-index: 9999;
}

@keyframes pulse {
  0% {
    background-color: rgba(117, 44, 243, 0.2);
  }
  50% {
    background-color: rgba(117, 44, 243, 0.05);
  }
  100% {
    background-color: rgba(117, 44, 243, 0.2);
  }
}

.voice-listener-inner {
  align-items: center;
  background-color: rgba(117, 44, 243, 0.2);
  border-radius: 50%;
  display: flex;
  height: 85%;
  justify-content: center;
  width: 85%;
}

.vl {
  background-color: #fff;
  height: 31px;
  opacity: 0.5;
  width: 1px;
}

/* RESPONSIVE */
@media screen and (max-width: 1000px) {
  .desktop {
    display: none;
  }
  .mobile {
    display: flex;
  }
  .mobile-inline {
    display: inline;
  }
  .header {
    height: auto;
    width: 100%;
  }
  .header-top,
  .header-bottom {
    padding: 0 15px;
    width: 100%;
  }
  .header-bottom-tab {
    flex-direction: column;
    height: 100%;
    padding: 0;
    width: 100%;
  }
  .header-bottom-tab .tms2 {
    font-size: 14px;
  }
  .header-bottom-tab .mobile-text {
    display: inline-block;
    font-size: 8px;
    margin-top: 2px;
  }
  .header-bottom-tab:last-child {
    margin-right: 0;
  }
  .header-top-right {
    justify-content: flex-end;
  }
  .header-bottom-left {
    justify-content: center;
    width: 100%;
  }
  .search-input {
    padding: 5px 15px 5px 5px;
  }
  .search-input-wrapper {
    height: auto;
    margin-left: 5px;
    width: 100%;
  }
  .header-toggle .tms,
  .header-toggle .tms2 {
    margin: 0;
  }
  .header-logout {
    margin: 0px;
  }
  .search-input-wrapper::before {
    display: none;
  }
  .toggle-voice-recognition {
    align-items: center;
    background-color: #752cf3;
    border-radius: 50%;
    bottom: 20px;
    display: flex;
    height: 50px;
    justify-content: center;
    left: 20px;
    position: fixed;
    width: 50px;
    z-index: 10;
  }
  .toggle-voice-recognition:hover {
    background-color: #752cf3;
  }
}

@media print {
  .header {
    display: none;
  }
}
</style>
