<template>
  <div class="home-wrapper">
    <h1></h1>
  </div>
</template>

<script>
export default {
  name: 'Home',
  mounted() {
    if (this.$route.name === 'home') {
      // if (
      //   !this.$store.state.user.permissions.isAdmin &&
      //   !this.$store.state.user.permissions.isReporter
      // ) {
      //   this.$router.push('/users');
      // } else {
      //   this.$router.push('/reports/own');
      // }
        this.$router.push('/planning');
    }
  }
};
</script>

<style scoped>
h1 {
  color: rgba(8, 63, 117, 1);
  margin: 15px 0;
  text-align: center;
}
</style>