<template>
  <div></div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      'getUser'
      // ...
    ])
  },
  data() {
    return {};
  },
  methods: {
    getCalendar() {
      this.$store.dispatch('startLoading');
      const requestUrl = `${process.env.VUE_APP_BASE_URI}/calendar/${this.$route.params.id}`;

      this.$http
        .get(requestUrl)
        .then(response => {
          this.$store.dispatch('stopLoading');
          const c = response.data;

          if (!c) {
            this.$snotify.error(this.$t('request-was-deleted'));
            this.$router.push('/planning');
            return;
          }

          if (c.isReady && !c.isConfirmed) {
            if (this.$route.name === 'calendarRejection') {
              c.isReady = false;
              c.readyAt = new Date();
              c.readyBy = this.getUser._id;
            } else if (this.$route.name === 'calendarConfirmation') {
              c.isConfirmed = true;
              c.confirmedAt = new Date();
              c.confirmedBy = this.getUser._id;
            }

            const url = `${process.env.VUE_APP_BASE_URI}/calendar/${c._id}`;

            this.$http
              .put(url, c)
              .then(a => {
                if (a.data.errors) {
                  console.log(a.data.errors);
                  this.$snotify.error(this.$t('something-went-wrong'));
                } else {
                  this.$snotify.success(
                    `${this.$t('request-is-successfully')} ${
                      this.$route.name === 'calendarConfirmation'
                        ? this.$t('request-is-confirmed')
                        : this.$t('request-is-rejected')
                    }!`
                  );
                  this.$router.push('/planning');
                }
              })
              .catch(error => {
                console.log(error.response);
                this.$snotify.error(this.$t('something-went-wrong'));
              });
          } else {
            this.$snotify.error(this.$t('request-already-checked'));
            this.$router.push('/planning');
            return;
          }
        })
        .catch(error => console.log(error));
    }
  },
  mounted() {
    this.getCalendar();
  }
};
</script>

<style scoped>
</style>