<template>
  <div class="voice-modal-wrapper">
    <div class="voice-modal">
      <div class="modal-header">
        <h2>Базови гласови команди</h2>
        <span class="tms modal-close" @click="close()">F</span>
      </div>
      <div class="modal-content">
        <table>
          <thead>
            <tr>
              <th>Базови Действие:</th>
              <th>Гласова команда:</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>Отваряне на таб от програмата</td>
              <td>
                <span class="tms2">o</span> "Отвори &lt;име на таб&gt;"
              </td>
            </tr>
            <tr>
              <td>Отваряне на последно направените собствени отчети</td>
              <td>
                <span class="tms2">o</span> "Моите отчети"
              </td>
            </tr>
            <tr>
              <td>Планиране на нови обекти</td>
              <td>
                <span class="tms2">o</span> "Планирай обекти"
              </td>
            </tr>
            <tr>
              <td>Справка за текущия месец за всички фирми</td>
              <td>
                <span class="tms2">o</span> "Направи справка за текущия месец"
              </td>
            </tr>
            <tr>
              <td>Справка за миналия месец за всички фирми</td>
              <td>
                <span class="tms2">o</span> "Направи справка за миналия месец"
              </td>
            </tr>
            <tr>
              <td>Експорт на текущата справка в Ексел</td>
              <td>
                <span class="tms2">o</span> "Експортирай", "Експорт"
              </td>
            </tr>
            <tr>
              <td>Създаване на нов отчет</td>
              <td>
                <span class="tms2">o</span> "Нов отчет"
              </td>
            </tr>
            <tr>
              <td>Смяна на парола</td>
              <td>
                <span class="tms2">o</span> "Смени ми паролата"
              </td>
            </tr>
            <tr>
              <td>Върни се на предишна страница</td>
              <td>
                <span class="tms2">o</span> "Назад"
              </td>
            </tr>
            <tr>
              <td>Бутони Отказ/Затвори</td>
              <td>
                <span class="tms2">o</span> "Отказ", "Затвори"
              </td>
            </tr>
            <tr>
              <td>Спиране на гласовия режим</td>
              <td>
                <span class="tms2">o</span> "Спри да слушаш"
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="modal-footer">
        <div class="btns">
          <button class="btn btn-close" @click.prevent="close()">
            <span class="tms">F</span>
            {{ $t('cancel') }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    close() {
      this.$parent.$emit('closeVoiceCommandsModal');
    }
  },
  mounted() {
    this.$eventBus.$on('closeModalFromSpeechRecognition', () => {
      this.close();
    });
  },
  destroyed() {
    this.$eventBus.$off('closeModalFromSpeechRecognition');
  }
};
</script>

<style scoped>
.voice-modal-wrapper {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
}
.voice-modal {
  background-color: #fff;
  border-radius: 4px;
  /* border-top: 10px solid #3377b1; */
  display: flex;
  flex-direction: column;
  /* height: 60vh; */
  overflow: auto;
  /* padding: 10px 20px 0; */
  width: 70%;
}
.modal-header {
  background: #3b86ff;
  background-color: #3b86ff;
  justify-content: flex-start;
  padding: 0 20px;
}
.modal-header h2 {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}
.voice-modal-permissions {
  background-color: #fff;
  border-radius: 4px;
  display: flex;
  justify-content: center;
}
.voice-modal-actions {
  display: flex;
  justify-content: center;
  padding: 20px 0;
}
.modal-content {
  padding: 0;
  max-height: 70vh;
}

.modal-content table {
  border-collapse: collapse;
  width: 100%;
}
.modal-content table th {
  font-size: 18px;
  font-weight: 700;
  padding: 15px 20px;
  text-align: left;
}
.modal-content table td {
  font-size: 16px;
  padding: 10px 20px;
}
.modal-content table tbody tr:nth-child(odd) {
  background-color: #d7e7ff;
}
.modal-content table tbody td:nth-child(2n) {
  font-weight: 700;
}

.modal-content table td .tms2 {
  color: #3b86ff;
  font-weight: 400;
  margin-right: 20px;
}
.modal-footer {
  justify-content: center;
}
.btn-close {
  margin-left: 0;
}

/* Responsive */
@media screen and (max-width: 1280px) {
  .modal-header h2,
  .modal-content table th {
    font-size: 16px;
  }
  .modal-content table td {
    font-size: 14px;
  }
  .modal-content table td .tms2 {
    margin-right: 5px;
  }
}
@media screen and (max-width: 650px) {
  .voice-modal {
    width: 90%;
  }
  .modal-header h2,
  .modal-content table th {
    font-size: 12px;
  }
  .modal-content table td {
    font-size: 11px;
  }
}
</style>
