<template>
  <div id="app">
    <appHeader v-if="this.$route.name !== 'login'" />
    <router-view />
    <vue-snotify></vue-snotify>
  </div>
</template>

<script>
import Header from './components/shared/Header';
export default {
  components: {
    appHeader: Header
  },
  methods: {
    goToLogin() {
      if (this.$route.name !== 'login') {
        this.$router.push('/login');
      }
    }
  },
  mounted() {
    const token = localStorage.getItem('token');
    this.$http
      .post(`${process.env.VUE_APP_BASE_URI}/auth/validate`, { token: token })
      .then(res => {
        if (!res.data.user) {
          this.$store
            .dispatch('logout')
            .then(() => this.goToLogin())
            .catch(err => console.log(err));
        }
      })
      .catch(err => {
        console.log(err);
        this.$store
          .dispatch('logout')
          .then(() => this.goToLogin())
          .catch(err => console.log(err));
      });
  }
};
</script>


<style lang='scss'>
@import './assets/snotify/material.css';
@font-face {
  font-family: 'Google Sans';
  src: url('./assets/fonts/GoogleSans-Regular.woff2') format('woff2'),
    url('./assets/fonts/GoogleSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'Google Sans';
  src: url('./assets/fonts/GoogleSans-Bold.woff2') format('woff2'),
    url('./assets/fonts/GoogleSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}
@font-face {
  font-family: 'Google Sans';
  src: url('./assets/fonts/GoogleSans-MediumItalic.woff2') format('woff2'),
    url('./assets/fonts/GoogleSans-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: 'Google Sans';
  src: url('./assets/fonts/GoogleSans-Italic.woff2') format('woff2'),
    url('./assets/fonts/GoogleSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}
@font-face {
  font-family: 'Google Sans';
  src: url('./assets/fonts/GoogleSans-Medium.woff2') format('woff2'),
    url('./assets/fonts/GoogleSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: 'Google Sans';
  src: url('./assets/fonts/GoogleSans-BoldItalic.woff2') format('woff2'),
    url('./assets/fonts/GoogleSans-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}
/* Generated by Glyphter (http://www.glyphter.com) on  Mon Nov 12 2018*/
@font-face {
  font-family: 'tms';
  src: url('./assets/fonts/tms.eot');
  src: url('./assets/fonts/tms.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/tms.woff') format('woff'),
    url('./assets/fonts/tms.ttf') format('truetype'),
    url('./assets/fonts/tms.svg#tms') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class*='icon-']:before {
  display: inline-block;
  font-family: 'tms';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-pass:before {
  content: '\0041';
}
.icon-user:before {
  content: '\0042';
}
.icon-charts:before {
  content: '\0043';
}
.icon-date:before {
  content: '\0044';
}
.icon-excel:before {
  content: '\0045';
}
.icon-exit:before {
  content: '\0046';
}
.icon-materials:before {
  content: '\0047';
}
.icon-new:before {
  content: '\0048';
}
.icon-options:before {
  content: '\0049';
}
.icon-overtime:before {
  content: '\004a';
}
.icon-down:before {
  content: '\004b';
}
.icon-plus:before {
  content: '\004c';
}
.icon-right:before {
  content: '\004d';
}
.icon-search:before {
  content: '\004e';
}
.icon-services:before {
  content: '\004f';
}
.icon-settings:before {
  content: '\0050';
}
.icon-spravka:before {
  content: '\0051';
}
.icon-tme:before {
  content: '\0052';
}
.icon-units:before {
  content: '\0053';
}
.icon-add_mtr:before {
  content: '\0054';
}
.icon-add_src:before {
  content: '\0055';
}
.icon-add_user:before {
  content: '\0056';
}
.icon-all:before {
  content: '\0057';
}
.icon-none:before {
  content: '\0058';
}
.icon-save:before {
  content: '\0059';
}
.icon-list:before {
  content: '\005a';
}
.icon-projects:before {
  content: '\0061';
}
.icon-delete:before {
  content: '\0062';
}
.icon-edit:before {
  content: '\0063';
}
.icon-view:before {
  content: '\0064';
}
.icon-back:before {
  content: '\0065';
}
.icon-up:before {
  content: '\0066';
}
.tms {
  font-family: 'tms';
}
/* TMS 2 */
/* Generated by Glyphter (http://www.glyphter.com) on  Thu Jan 17 2019*/
@font-face {
  font-family: 'tms2';
  src: url('./assets/fonts/tms2.eot');
  src: url('./assets/fonts/tms2.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/tms2.woff') format('woff'),
    url('./assets/fonts/tms2.ttf') format('truetype'),
    url('./assets/fonts/tms2.svg#tms2') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class*='icon-']:before {
  display: inline-block;
  font-family: 'tms2';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon-inquiry:before {
  content: '\0041';
}
.icon-reports:before {
  content: '\0042';
}
.icon-companies:before {
  content: '\0043';
}
.icon-users:before {
  content: '\0044';
}
.icon-log:before {
  content: '\0045';
}
.icon-calendar:before {
  content: '\0046';
}
.icon-personalAgenda:before {
  content: '\0047';
}
.icon-publicAgenda:before {
  content: '\0048';
}
.icon-addObject:before {
  content: '\0049';
}
.icon-crown:before {
  content: '\004a';
}
.icon-left:before {
  content: '\004b';
}
.icon-right:before {
  content: '\004c';
}
.icon-copy:before {
  content: '\004d';
}
.icon-move:before {
  content: '\004e';
}
.icon-paste:before {
  content: '\004f';
}
.icon-company:before {
  content: '\0050';
}
.icon-worker:before {
  content: '\0051';
}
.icon-month:before {
  content: '\0052';
}
.icon-week:before {
  content: '\0053';
}
.icon-day:before {
  content: '\0054';
}
.icon-1y:before {
  content: '\0055';
}
.icon-6m:before {
  content: '\0056';
}
.icon-at:before {
  content: '\0057';
}
.icon-cm:before {
  content: '\0058';
}
.icon-mech:before {
  content: '\0059';
}
.icon-pm:before {
  content: '\005a';
}
.icon-prices:before {
  content: '\0061';
}
.icon-today:before {
  content: '\0062';
}
.icon-yesterday:before {
  content: '\0063';
}
.icon-current:before {
  content: '\0064';
}
.icon-previous:before {
  content: '\0065';
}
.icon-approved:before {
  content: '\0066';
}
.icon-ok:before {
  content: '\0067';
}
.icon-no:before {
  content: '\0068';
}
.icon-waiting:before {
  content: '\0069';
}
.icon-vacation:before {
  content: '\006a';
}
.icon-doc:before {
  content: '\006b';
}
.icon-wait:before {
  content: '\006c';
}
.icon-print:before {
  content: '\006d';
}
.icon-log:before {
  content: '\006e';
}
.icon-mic:before {
  content: '\006f';
}
.icon-nomic:before {
  content: '\0070';
}
.icon-help:before {
  content: '\0071';
}
.tms2 {
  font-family: 'tms2';
}
html {
  font-size: 13px;
}
body {
  background-color: #fff;
}
* {
  margin: 0;
  outline: 0;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  -webkit-font-smoothing: antialiased;
  padding: 0;
}
#app {
  font-family: 'Google Sans', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
h2 {
  color: #373737;
  font-size: 20px;
  font-weight: 400;
}
ul {
  list-style: none;
  padding: 0;
}
li {
  display: inline;
}
a {
  cursor: pointer;
  text-decoration: none;
}
/* INPUTS */
input,
select {
  height: 40px;
  padding: 5px 15px;
  width: 100%;
}
.input-label {
  color: #373737;
  font-weight: 700;
  display: inline-block;
  margin: 5px 0;
}
.input-wrapper {
  position: relative;
  border: 1px solid #104d8033;
  border-radius: 4px;
  overflow: hidden;
}
.input-wrapper .tms,
.input-wrapper .tms2,
.users-list .tms,
.users-list .tms2 {
  color: #0058bc;
  font-size: 16px;
  left: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}
.input-wrapper input,
.input-wrapper select,
.input-wrapper textarea,
.users-list {
  background-color: #fff;
  border-radius: 4px;
  border: none;
  padding: 5px 15px 5px 34px;
}
.input-wrapper select {
  padding-right: 34px;
}
.users-list .tms{
  top: 20px;
}

.users-list .tms2 {
  top: 45px;
}

.users-list {
  cursor: pointer;
  max-height: 30vh;
  border: 1px solid #104d8033;
  overflow: auto;
  position: relative;
  min-height: 40px;
}

.users-list p {
  font-size: 14px;
  margin: 8px 0;
}
/* The checkbox-wrapper */
.checkbox-wrapper {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  // height: 20px;
  margin: 10px 0;
  position: relative;
  padding-left: 30px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  p {
    padding-right: 5px;
  }
}
/* Hide the browser's default checkbox */
.checkbox-wrapper input {
  cursor: pointer;
  height: 0;
  opacity: 0;
  position: absolute;
  width: 0;
}
/* Create a custom checkbox */
.checkmark {
  background-color: #d9d9d9;
  border-radius: 4px;
  height: 20px;
  left: 0;
  position: absolute;
  top: 0;
  width: 20px;
}
.user-modal-wrapper {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 2;

  .user-modal {
    background-color: #f3f7fd;
    border-radius: 4px;
    border-top: 10px solid #253153;
    display: flex;
    flex-direction: column;
    /* height: 60vh; */
    max-height: 90vh;
    overflow: auto;
    padding: 10px 20px 0;
    width: 800px;

    &.user-modal-small {
      width: 600px;
    }

    .user-modal-permissions {
      background-color: #fff;
      border-radius: 4px;
      border: 1px solid #104d8033;
      display: flex;
      justify-content: space-evenly;
      gap: 10px;
    }

    .user-modal-actions {
      display: flex;
      justify-content: space-between;
      padding: 20px 0;
    }
    .invalid .tms {
      color: #d91313;
    }
    .invalid input {
      background-color: #f8dcdc;
      color: #d91313;
    }
    .invalid input::placeholder {
      color: #d91313;
    }
    .invalid input::placeholder {
      color: #d91313;
    }
    .invalid input::placeholder {
      color: #d91313;
    }
    .invalid input::placeholder {
      color: #d91313;
    }

    .invalid.user-modal-permissions {
      background-color: #f8dcdc;
      color: #d91313;
    }
    .invalid.user-modal-permissions .checkmark {
      background-color: rgba(217, 19, 19, 0.2);
    }
    .new-input-wrapper {
      width: 100%;
    }
    .new-input {
      margin: 0;
    }
    .input-label {
      font-size: 13px;
    }
    .new-input-dropdown {
      transform: scaleY(0);
      transform-origin: top;
    }
    .new-input-dropdown-active {
      top: calc(100% + 10px);
      transform: scaleY(1);
      height: initial;
    }
    .leave-wrapper {
      padding-bottom: 10px;
      margin-top: 10px;
    }
    .leave-list {
      display: flex;
      justify-content: space-between;
    }

    .leave {
      align-items: center;
      display: flex;
      flex-direction: column;
      justify-content: center;
      width: calc(25% - 10px);
    }
  }
}
.users-companies-wrapper {
  padding: 10px 40px;
  overflow: hidden;
  width: 100%;

  .desktop {
    display: flex;
  }
  .mobile {
    display: none;
  }


  .table-wrapper {
    min-height: 100px;
  }

  header {
    align-items: center;
    display: flex;
    height: 36px;
    justify-content: space-between;
  }
  .table {
    thead {
      th {
        background-color: white;
      }
    }
  }
  .table tr td:last-child {
    white-space: nowrap;
  }
  .table tr .td-widthless {
    width: 1%;
  }
  .td-user-name span {
    vertical-align: middle;
  }
  .td-user-name .tms,
  .td-user-name .tms2 {
    margin: 0 7px 0 4px;
  }
  .inactive-row {
    background-color: #fddddd;
  }
  .inactive-row:hover {
    background-color: #f3c4c4;
  }
  .desktop {
    display: flex;
  }
  .mobile {
    display: none;
  }

  .user-types {
    display: flex;
    overflow: auto;
    /* flex-wrap: wrap; */
    width: 100%;

    h1 {
      font-size: 20px;
    }
  }
  .user-type {
    background-color: #0058bc;
    border-radius: 4px;
    color: #fff;
    cursor: pointer;
    font-size: 12px;
    margin: 3px 5px 3px 0;
    max-width: 170px;
    text-transform: uppercase;
    text-align: center;
    transition: all 0.3s;
    padding: 7px 10px;
    opacity: 0.4;
    white-space: nowrap;
    width: 100%;
  }

  .user-type-active {
    opacity: 1;
  }

  .user-type:not(.user-type-active):hover {
    opacity: 0.6;
  }

  .btn {
    width: initial;
    padding: 0 15px;
    white-space: nowrap;
  }

  .btn-blue {
    background-color: #03799e;
  }

  .btn-green {
    background-color: #89aa89;
  }
  .user-permissions {
    display: flex;
  }
}
/* On mouse-over, add a grey background color */
.checkbox-wrapper:hover input ~ .checkmark {
  background-color: #ccc;
}
/* When the checkbox is checked, add a blue background */
.checkbox-wrapper input:checked ~ .checkmark {
  background-color: #0058bc;
}
/* When the checkbox is checked, add a red background on top */
.checkbox-wrapper input:checked ~ .checkmark-red {
  background-color: #bf392a;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  display: none;
  position: absolute;
}
/* Show the checkmark when checked */
.checkbox-wrapper input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.checkbox-wrapper .checkmark:after {
  border: solid #fff;
  border-width: 0 2px 2px 0;
  height: 10px;
  left: 7px;
  top: 2px;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  width: 5px;
}
.strong {
  font-weight: 700;
}
.normal {
  font-weight: 400;
}
/* SWITCH */
.switch-container {
  align-items: center;
  display: flex;
}
.switch-container > span {
  cursor: pointer;
}
.switch {
  display: inline-block;
  height: 24px;
  margin: 0 10px 0 0;
  position: relative;
  width: 48px;
}
.switch input {
  display: none;
}
.slider {
  background-color: #9f0510;
  border-radius: 34px;
  bottom: 0;
  cursor: pointer;
  left: 0;
  position: absolute;
  right: 0;
  top: 0;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  -o-transition: 0.4s;
}
.slider:before {
  background-color: white;
  border-radius: 50%;
  bottom: 4px;
  content: '';
  height: 16px;
  left: 4px;
  position: absolute;
  transition: 0.4s;
  -webkit-transition: 0.4s;
  -moz-transition: 0.4s;
  -ms-transition: 0.4s;
  -o-transition: 0.4s;
  width: 16px;
}
input:checked + .slider {
  background-color: #3b86ff;
}
input:focus + .slider {
  box-shadow: 0 0 1px #dedede;
}
input:checked + .slider:before {
  -webkit-transform: translateX(23px);
  -ms-transform: translateX(23px);
  transform: translateX(23px);
}
.switch-selected {
  text-decoration: underline;
}
.input-hide {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}
/* BUTTONS */
.btns {
  display: flex;
}
button {
  height: 40px;
  width: 100%;
}
.btn {
  align-items: center;
  border-radius: 4px;
  border: none;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 13px;
  height: 36px;
  justify-content: center;
  margin-left: 10px;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -ms-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
  width: 160px;
}
.btn .tms, .tms2 {
  font-size: 16px;
  margin-right: 6px;
}
.btn-new {
  background-color: #0058bc;
}
.add-new-users-wrapper {
  position: relative;
}
.add-options {
  background: #ffffff;
  border-radius: 4px;
  border: 1px solid #beccea;
  box-shadow: 0px 1px 4px #00000026;
  color: #496ec2;
  display: flex;
  flex-direction: column;
  height: fit-content;
  position: absolute;
  overflow: hidden;
  top: calc(100% + 5px);
  right: 0;
  z-index: 9999;
}
.add-options p {
  cursor: pointer;
  padding: 5px 30px 5px 10px;
  white-space: nowrap;
}
.add-options p:hover {
  background: #f3f7fd;
}
.btn-new:hover {
  background-color: rgb(13, 101, 202);
}
.btn-reset {
  background-color: #c80d0d;
}
.btn-reset:hover {
  background-color: #dd2c2c;
}
.btn-excel {
  background-color: #217346;
}
.btn-excel:hover {
  background-color: rgb(65, 144, 101);
}
.btn-widthless {
  padding: 0 10px;
  width: initial;
  white-space: nowrap;
}
.btn-close {
  background-color: #104d8033;
  color: #291003;
}
.btn-close:hover {
  background-color: rgb(226, 226, 226);
}
.btn-select {
  background-color: #104d8033;
  color: #000;
  height: 30px;
  padding: 0 15px;
  width: initial;
}
.btn-select:hover {
  background-color: #104d8033;
}
.btn-delete {
  background-color: #c42530;
  color: #ffffff;
}
.btn-delete:hover {
  background-color: #e03d48;
  color: #ffffff;
}
.btn-delete2 {
    background-color: #7d7d7d;
  color: #ffffff;
  }
  .btn-delete2:hover {
    background-color: #8d8b8b;
    color: #fff;
  }
/* SELECTS */
.select {
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iNTAiIGhlaWdodD0iNTAiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+IDxnPiAgPHRpdGxlPmJhY2tncm91bmQ8L3RpdGxlPiAgPHJlY3QgZmlsbD0ibm9uZSIgaWQ9ImNhbnZhc19iYWNrZ3JvdW5kIiBoZWlnaHQ9IjQwMiIgd2lkdGg9IjU4MiIgeT0iLTEiIHg9Ii0xIi8+IDwvZz4gPGc+ICA8dGl0bGU+TGF5ZXIgMTwvdGl0bGU+ICA8cmVjdCBpZD0ic3ZnXzEiIHdpZHRoPSI1MCIgaGVpZ2h0PSI1MCIgZmlsbD0ibm9uZSIvPiAgPHBvbHlnb24gZmlsbD0iIzhkMzUwNiIgaWQ9InN2Z18yIiBwb2ludHM9IjQ3LjI1LDE1IDQ1LjE2NCwxMi45MTQgMjUsMzMuMDc4IDQuODM2LDEyLjkxNCAyLjc1LDE1IDI1LDM3LjI1ICIvPiA8L2c+PC9zdmc+);
  background-repeat: no-repeat;
  background-position: right 10px top 50%;
  background-size: 16px;
  background-color: #fff;
  border: 1px solid #104d8033;
  border-radius: 4px;
  cursor: pointer;
  padding: 0px 35px;
  width: 100%;
}
/* TABLES */
.table {
  border-collapse: separate;
  border-spacing: 0 10px;
  width: 100%;
}
.table tr {
  height: 50px;
}
.table tr td:first-child,
.table tr th:first-child {
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}
.table tr td:last-child,
.table tr th:last-child {
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}
.table td {
  padding: 0 10px;

    &.td--center {
    text-align: center;
  }
}
.table thead tr {
  background-color: #104d8033;
}
.table thead th {
  background-color: #104d8033;
  line-height: 12px;
  text-align: left;
  padding: 0 10px;

  &.th--big {
    width: 30%;
  }
}
.table tbody tr {
  background-color: #f3f7fd ;
  cursor: pointer;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -ms-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.table tbody tr td {
  border-bottom: 1px solid #104d8033;
  border-top: 1px solid #104d8033;
}
.table tbody tr td:first-child {
  border-left: 1px solid #104d8033;
}
.table tbody tr td:last-child {
  border-right: 1px solid #104d8033;
}
.table tbody .tr-invalid {
  background-color: #fff8f8;
}
.table tbody .tr--split {
  color: white;
  height: 23px;
  }
.table tbody .tr--split td {
  background-color: #515a7d;
  border: none;
}
.table tbody .worker-name-row td{
  background-color: white !important;
  border: none !important;
    color: #0058bc;
  font-weight: 500;
  user-select: none;
}
.inv {
  height: 45px;
  width: 33px;
  position: relative;
  z-index: 1;
}
.inv::before {
  border-bottom: 1px solid #d3e0f5;
  border-left: 1px solid #d3e0f5;
  border-bottom-left-radius: 4px;
  content: "";
  position: absolute;
  right: 0;
  height: calc(100% + 15px);
  bottom: 50%;
  width: 50%;
  z-index: 2;
} 
.reports-content .table tbody .tr-invalid {
  background-color: #fff8f8;
}
.reports-content .table tbody tr.worker-name-row .tms2 {
  font-size: 18px;
  position: relative;
  z-index: 2;
}
.reports-content .table tbody tr.worker-report-row {
  background: transparent !important;
}

.reports-content .table tbody tr.worker-report-row td:not(:first-child) {
  background: #f3f7fd;
  transition: background-color 0.3s;
}

.reports-content .table tbody tr.worker-report-row td:nth-child(2) {
  border-left: 1px solid #d3e0f5;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;
}

.reports-content .table tbody tr.worker-name-row td:first-child,
.reports-content .table tbody tr.worker-report-row td:first-child {
  border-color: transparent;
  background-color: transparent;
  padding: 0;
  text-align: center;
  width: 33px;
  min-width: 33px;
  max-width: 33px;
}
.reports-content .table tbody tr.worker-name-row td,
.reports-content .table tbody tr.worker-report-row td {
  max-width: 155px;
  min-width: 155px;
}

.reports-content .table tbody tr.worker-report-row:hover td:not(:first-child) {
  background: #d3e0f5;
}
.table .center {
  text-align: center;
}
/* TAGS */
/* WORK INDICATORS */
.worktime-indicator-wrapper {
  align-items: flex-start;
  display: inline-flex;
  flex-direction: column;
  width: 90px;
}
.worktime-indicator {
  align-items: center;
  background-color: #479538;
  border-radius: 4px;
  color: #fff;
  display: inline-flex;
  font-size: 11px;
  height: 18px;
  justify-content: center;
  margin: 1px 5px 1px 0;
  width: 38px;
}
.overtime-indicator {
  background-color: #bf892a;
}
.holiday-indicator {
  background-color: #bf392a;
}
/* USER PERMISSIONS INDICATORS */
.permission-indicator {
  align-items: center;
  background-color: #f3f3f3;
  border-radius: 4px;
  color: #fff;
  display: inline-flex;
  font-size: 11px;
  font-weight: 700;
  height: 24px;
  margin-right: 8px;
  vertical-align: middle;
  padding: 5px 10px;
}
.superadmin-indicator {
  background-color: #878787;
  font-family: 'tms2';
  padding: 5px 7px;
}
.admin-indicator {
  background-color: #d48166;
}
.reporter-indicator {
  background-color: #dfb35b;
}
.worker-indicator {
  background-color: #8e5bdf;
}
.employee-indicator {
  background-color: #89aa89;
}
.ceo-indicator {
  background-color: #3a9173;
}
.hr-indicator {
  background-color: #9179a5;
}
.tr-indicator {
  background-color: #03799e;
}
.group-indicator {
  background-color: #5bc1df;
}
.status-indicator {
  background-color: #dfdfdf;
  border: 1px solid #707070;
  border-radius: 85px;
  color: #707070;
  position: relative;
  justify-content: center;
  text-transform: uppercase;
  min-width: fit-content;
  padding: 0 5px;
  width: 118px;

  &.status-indicator--green {
    background-color: #e6f8f1;
    border-color: #58a182;
    color: #58a182;
  }

  &.status-indicator--orange {
    background-color: #fff6ee;
    border-color: #986516;
    color: #986516;
  }
}
/* NEW REPORT/PROJECT */
.new-wrapper {
  align-items: center;
  border: 1px solid #104d8033;
  background-color: #f3f7fd ;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  padding: 10px;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -ms-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
  width: 100%;
}
.new-wrapper-inactive {
  background-color: #fff8f8;
}
.new-content {
  width: 80%;
}
/* NEW INPUTS ROW */
.new-content-inputs {
  display: flex;
  width: 100%;
}
/* NEW INPUTS */
.new-input-wrapper {
  position: relative;
  margin-right: 20px;
  width: 25%;
}
.new-input-wrapper--big {
  width: 100%;
}
.new-content-inputs-bigger {
  border-top: 1px solid #afafaf;
  margin-top: 5px;
  padding-top: 10px;
}
.new-content-inputs-bigger .new-input-wrapper {
  width: 33.33%;
}
.new-content-inputs .new-input-wrapper:last-child,
.last-input {
  margin-right: 0;
}
.new-input-wrapper label {
  color: #373737;
  font-size: 11px;
  font-weight: 700;
}
.new-input {
  background-color: #fff;
  border-radius: 4px;
  margin: 10px 0;
  position: relative;

  &.new-input---highlighted {
    input {
      animation: highlighted 1s infinite ease-in-out;
      transition: 0.3s all;
    }

    @keyframes highlighted {
      0% {
        border: 1px solid blue;
      }
      50% {
        border: 1px solid #104d8033;
      }
      100% {
        border: 1px solid blue;
      }
    }
  }
}
.new-input .tms,
.new-input .tms2 {
  color: #0058bc;
  font-size: 16px;
  left: 14px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}
.new-input .disabled,
.new-input .clear,
.new-input .arrow,
.new-input .edit,
.reports-tab .disabled,
.input-wrapper .disabled {
  align-items: center;
  background-color: rgb(214, 232, 252);
  border-radius: 50%;
  /* color: #fff; */
  display: flex;
  font-family: 'tms';
  font-size: 9px;
  height: 16px;
  justify-content: center;
  right: 10px;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 16px;
}
.new-input .delete{
  cursor: pointer;
}
.new-input .clear {
  background-color: #0058bc;
  color: #fff;
  cursor: pointer;
  font-size: 6px;
  font-weight: 700;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -ms-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.new-input .edit {
  background-color: #0058bc;
  color: #fff;
  cursor: pointer;
  font-size: 8px;
  font-weight: 700;
  right: 30px;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -ms-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
}
.new-input .arrow {
  background-color: transparent;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.new-input .arrow-reversed {
  transform: translateY(-50%) scaleY(-1);
}
.new-input .clear:hover,
.new-input .edit:hover {
  opacity: 0.9;
}
.new-input input {
  background-color: #fff;
  border: 1px solid #104d8033;
  border-radius: 4px;
  padding: 5px 15px 5px 34px;
  // padding: 5px 15px 5px 34px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.new-input--user input {
  padding: 5px 35px 5px 14px;
}
.new-input-disabled input {
  padding-right: 30px;
}
.new-input input:focus {
  border: 1px solid #104d8033;
  background-color: #fff;
}
.new-input-dropdown {
  background-color: #fff;
  border-radius: 4px;
  border: 0px solid #f4f4f4;
  height: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 100%;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  padding: 0;
  width: 100%;
  z-index: 2;
}
.new-input-dropdown ul li,
.option {
  align-items: center;
  cursor: pointer;
  display: flex;
  gap: 5px;
  padding: 5px 14px;

  &.option--disabled{
    pointer-events: none;
    opacity: 0.5;
  }
}
.new-input-dropdown ul li:hover,
.option:hover {
  background-color: #f9f9f9;
}
.new-input-dropdown-active,
.datepicker-dropdown-active {
  border: 1px solid #104d8033;
  height: 25vh;
  overflow: auto;
}
.new-input-dropdown-active--fit-content {
  height: fit-content;
}
.datepicker-dropdown {
  height: initial;
  transform: scaleY(0);
  transform-origin: top;
}
.datepicker-dropdown-active {
  transform: scaleY(1);
  height: initial;
}
/* NEW CONTENT DATA */
.new-content-data-row {
  border-top: 1px solid #afafaf;
  display: flex;
  margin-top: 5px;
  padding-top: 15px;
}
.new-content-data-item {
  background-color: #fff;
  border: 1px solid #104d8033;
  border-radius: 4px;
  height: initial;
  margin-right: 20px;
  width: 33.33%;
}
.new-content-data-item table {
  width: 100%;
}
.new-content-data-item:last-child {
  margin-right: 0;
}
.new-content-data-item-toggle {
  align-items: center;
  background-color: #fff;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: space-between;
  padding: 0 10px;
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  width: 100%;
}
.new-content-data-item-toggle-circle {
  align-items: center;
  background-color: #0058bc;
  border-radius: 50%;
  color: #fff;
  font-size: 16px;
  display: flex;
  height: 34px;
  justify-content: center;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  width: 34px;
}
.new-content-data-item-toggle-circle .tms,
.new-content-data-item-toggle-circle .tms2 {
  align-items: center;
  border: 1px solid #0058bc;
  border-radius: 50%;
  display: flex;
  height: 30px;
  justify-content: center;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  width: 30px;
}
.new-content-data-item-toggle:hover {
  background-color: #104d8033;
}
.new-content-data-item-toggle:hover .new-content-data-item-toggle-circle {
  /* background-color: #5193fd; */
}
.new-content-data-item-toggle:hover .tms,
.new-content-data-item-toggle:hover .tms2 {
  border-color: #fff;
}
thead.new-content-data-item-toggle {
  display: table-caption;
}
.new-content-data-item-toggle .ma-cell {
  padding: 10px 20px 10px 5px;
  /* width: 66px; */
  white-space: nowrap;
}
.new-content-data-item-toggle--absence p{
  align-items: center;
  display: flex;
  gap: 10px;
}
.new-content-data-item-toggle--absence {
  background: #0058bc !important;
  color: #fff;
}
.new-content-data-item-toggle--absence p .tms2 {
  font-size: 17px;
}
.new-content-data-item-toggle .ma-cell {
  /* width: 50px; */
}
.new-content-data-item-toggle tr {
  display: flex;
  justify-content: space-between;
}
.new-content-data-item .btn-excel {
margin-bottom: -15px;
}
/* WHEN EITHER WORKERS, MATERIALS OR ACTIVITIES IS SELECTED */
.new-content-data-row-active {
  /* border-top: none; */
  flex-direction: column;
  margin-top: 15px;
}
.new-content-data-row-active .new-content-data-item-toggle {
  /* background-color: #104d8033; */
  background-color: #104d8033;
  height: 36px;
}
.new-content-data-row-active .new-content-data-item-toggle:hover {
  /* background-color: #104d8033; */
  background-color: #104d8033;
}
.new-content-data-row-active .new-content-data-item-toggle-circle {
  position: absolute;
  right: 10px;
  top: 6px;
}
.new-content-data-row-active .new-content-data-item {
  margin-bottom: 15px;
  width: 100%;
}
/* CONTENT */
.new-content-data-item-content {
  padding: 10px;
  width: 100%;
}
.new-content-data-item-content.new-content-data-item-content--no-padding-top {
  padding: 0 10px 10px 10px;
}
.new-content-data-item-content table,
.new-content-data-item-content tbody {
  width: 100%;
}
.new-content-data-item-content table td {
  height: 20px;
}
.new-content-data-item-content .users-cell {
  color: #737373;
  font-size: 11px;
  padding: 0 20px;
  width: 50%;
}
.new-content-data-item-content .users-cell p {
  display: inline-block;
  margin-right: 20px;
}
.table .td-company-name {
  position: relative;
  padding: 0 20px;
}
.table .td-invalid-flag {
  position: absolute;
  width: 14px;
  left: 0px;
  top: 50%;
  transform: translateY(-50%);
}
.new-content-data-item-content .ma-cell,
.nothing-added {
  color: #737373;
  font-size: 11px;
  padding: 0 20px;
  width: 10%;
  /* white-space: nowrap; */
}
.nothing-added {
  text-align: center;
}
.new-content-data-item-content .ma-cell-hours,
.new-content-data-item-toggle .ma-cell-hours {
  white-space: nowrap;
  width: 40%;
}
.new-content-data-item-content .ma-cell-additional {
  padding-left: 15px;
}
.modal-content-check-additional {
  background-color: #f1f4c0;
}
.modal-content-check-disabled {
  cursor: default;
  opacity: 0.4;
}
.modal-content-check-disabled .checkbox-wrapper {
  cursor: default;
  padding: 0;
}
.new-content-data-item-content .ma-cell-name--price,
.new-content-data-item-toggle .ma-cell-name--price {
  width: 10%;
}
.new-content-data-item-content .ma-cell-name--smaller,
.new-content-data-item-toggle .ma-cell-name--smaller {
  width: 45%;
}
.new-content-data-item-content .ma-cell-name,
.new-content-data-item-toggle .ma-cell-name {
  position: relative;
  width: 50%;
}
.new-content-data-item-content .ma-cell-name--bigger,
.new-content-data-item-toggle .ma-cell-name--bigger {
  width: 55%;
}
.new-content-data-item-content .ma-cell.ma-cell-nowrap--bigger-font {
  font-size: 14px;
}
.new-content-data-item-content .ma-cell.ma-cell-nowrap--bigger-font .tms--role {
  font-size: 20px;
}
.new-content-data-item-content .ma-cell.ma-cell-name--left-padding {
  padding-left: 40px;
}
.new-content-data-item-content .ma-cell.ma-cell-name--left-padding .tms {
  left: 20px;
}
.ma-cell-name .tms {
  color: #bf392a;
  cursor: pointer;
  font-size: 14px;
  font-weight: 700;
  position: absolute;
  left: 0px;
  opacity: 0.1;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  top: 50%;
  transform: translateY(-50%);
}
.ma-cell-name .additional-zap {
  cursor: default;
  position: absolute;
  left: 0px;
}
.new-content-data-item-content table tr:hover .ma-cell-name .tms {
  opacity: 1;
}
.ma-cell-input {
  background-color: #e8e8e8;
  border: 1px solid #fff;
  border-radius: 4px;
  color: #737373;
  height: 18px;
  margin-right: 6px;
  padding: 0 2px;
  text-align: right;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  width: 60px;
}
.ma-cell-input:focus {
  border-color: #0099ff;
}
.new-content-data-item-content-lists {
  display: flex;
  flex-wrap: wrap;
}
.new-content-data-item-content-lists > span {
  color: #737373;
  display: inline-block;
  font-size: 11px;
  padding: 5px 20px;
  width: 20%;
}
/* MODAL */
.modal-wrapper {
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1;
}
.modal {
  background-color: #f3f7fd ;
  border-radius: 4px;
  max-height: 70vh;
  overflow: hidden;
  width: 60%;
}
.modal-header {
  align-items: center;
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#3377b1+0,0f0f0f+100 */
  background: rgb(77, 77, 77); /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgba(77, 77, 77, 1) 0%,
    rgba(15, 15, 15, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(77, 77, 77, 1) 0%,
    rgba(15, 15, 15, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(77, 77, 77, 1) 0%,
    rgba(15, 15, 15, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#3377b1', endColorstr='#0f0f0f',GradientType=0 ); /* IE6-9 */
  display: flex;
  height: 70px;
  justify-content: center;
  position: relative;
}
.modal-header .new-input {
  margin: 0;
  width: 80%;
}
.modal-header .new-input input {
  border: none;
}
.modal-close {
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  position: absolute;
  right: 10px;
  top: 50%;
  padding: 10px;
  transform: translateY(-50%);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.modal-close:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.modal-content {
  display: flex;
  flex-wrap: wrap;
  max-height: 50vh;
  overflow: auto;
  padding: 20px;
}
.modal-footer {
  align-items: center;
  background-color: #f3f7fd ;
  display: flex;
  height: 70px;
  justify-content: space-between;
  padding: 0 20px;
  width: 100%;
}
.modal-content-check {
  border-radius: 4px;
  margin: 2px;
  overflow: hidden;
  width: calc(33.33% - 4px);
}
.modal-content-check .checkbox-wrapper {
  width: 100%;
}
.modal-content-add {
  align-items: center;
  background-color: rgba(59, 134, 255, 0.1);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding: 10px 20px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.modal-content-add .tms {
  color: #3b86ff;
  font-size: 18px;
}
.modal-content-add:hover {
  background-color: rgba(59, 134, 255, 0.2);
}
.modal-content-item {
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  display: flex;
  height: fit-content;
  min-height: 40px;
  margin-bottom: 5px;
  margin-right: 20px;
  padding-right: 30px;
  padding-left: 10px;
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  width: calc(50% - 20px);
}
.modal-content-item > p {
  width: 100%;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}
.modal-content-item.with-edit {
  padding-right: 60px;
}
.modal-content-item .tms {
  align-items: center;
  cursor: pointer;
  display: flex;
  font-size: 20px;
  height: 20px;
  justify-content: center;
  opacity: 0;
  position: absolute;
  right: 10px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  width: 20px;
}
.modal-content-item .tms.edit {
  right: 40px;
}
.modal-content-item:hover {
  background-color: #104d8033;
}
.modal-content-item:hover .tms {
  color: #d80000;
  opacity: 0.4;
}
.modal-content-item:hover .tms.edit {
  color: #0078d7;
}
.modal-content-item .tms:hover {
  opacity: 1;
}
.modal-content-template {
  cursor: pointer;
  display: flex;
  justify-content: center;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  width: 100%;
}
.modal-content-template img{
  object-fit: scale-down;
  width: 100%;
}
.modal-content-template:hover {
  opacity: 0.8;
}
.modal-content-no-results {
  padding: 20px 0;
  text-align: center;
  width: 100%;
}
/* DATEPICKER */
.custom-datepicker {
  margin: 0 auto !important;
  width: 100% !important;
  padding: 10px;
}
.custom-calendar {
  background-color: transparent !important;
  border: none !important;
  width: 100% !important;
}
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).day:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).month:hover,
.vdp-datepicker__calendar .cell:not(.blank):not(.disabled).year:hover {
  /* background-color: rgba(0, 153, 255, 0.1) !important; */
  border-color: transparent !important;
  border-radius: 4px;
}
.vdp-datepicker__calendar .cell.highlighted {
  background-color: #d9e4ec !important;
  border: 1px solid #8fa4bf !important;
  border-radius: 4px !important;
}
.vdp-datepicker .vdp-datepicker__calendar .cell.selected,
.vdp-datepicker .vdp-datepicker__calendar .cell.highlighted {
  background-color: #0058bc !important;
  border: none !important;
  border-radius: 4px !important;
  color: #fff;
}
/* CALENDAR DATEPICKER STYLES */
.calendar-wrapper .vdp-datepicker .vdp-datepicker__calendar {
  background-color: #fff !important;
  border-radius: 4px;
  border: 1px solid #f4eaea !important;
  width: 100% !important;
}
.calendar-wrapper .vdp-datepicker__calendar header .prev,
.calendar-wrapper .vdp-datepicker__calendar header .next,
.copy-move-modal .vdp-datepicker__calendar header .prev,
.copy-move-modal .vdp-datepicker__calendar header .next {
  text-indent: initial;
  color: transparent;
}
.calendar-wrapper .vdp-datepicker .vdp-datepicker__calendar .prev::after,
.calendar-wrapper .vdp-datepicker .vdp-datepicker__calendar .next::after,
.copy-move-modal .vdp-datepicker .vdp-datepicker__calendar .prev::after,
.copy-move-modal .vdp-datepicker .vdp-datepicker__calendar .next::after {
  border: none;
  color: #000;
  content: 'L';
  font-family: 'tms2';
  font-size: 12px;
  left: 50%;
  opacity: 0.5;
  position: absolute;
  top: 50%;
}
.calendar-wrapper .vdp-datepicker .vdp-datepicker__calendar .prev::after,
.copy-move-modal .vdp-datepicker .vdp-datepicker__calendar .prev::after {
  content: 'K';
}
.calendar-wrapper .vdp-datepicker .vdp-datepicker__calendar .cell.selected,
.calendar-wrapper .vdp-datepicker .vdp-datepicker__calendar .cell.highlighted,
.copy-move-modal .vdp-datepicker .vdp-datepicker__calendar .cell.selected {
  background-color: transparent !important;
  border-radius: 50% !important;
  color: #fff !important;
  position: relative;
  z-index: 1;
}
.calendar-wrapper .vdp-datepicker .vdp-datepicker__calendar .cell.highlighted {
  border: none !important;
}
.calendar-wrapper .vdp-datepicker .vdp-datepicker__calendar .cell.day,
.copy-move-modal .vdp-datepicker .vdp-datepicker__calendar .cell.day {
  position: relative;
}
.calendar-wrapper .vdp-datepicker .vdp-datepicker__calendar .cell.day::before,
.copy-move-modal .vdp-datepicker .vdp-datepicker__calendar .cell.day::before {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.calendar-wrapper
  .vdp-datepicker
  .vdp-datepicker__calendar
  .cell.day:hover::before,
.copy-move-modal
  .vdp-datepicker
  .vdp-datepicker__calendar
  .cell.day:hover::before {
  background-color: rgba(0, 88, 188, 0.1);
  box-sizing: border-box;
  border-radius: 50%;
  content: '';
  height: 34px;
  left: 50%;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  width: 34px;
}
.calendar-wrapper
  .vdp-datepicker
  .vdp-datepicker__calendar
  .cell.selected::before,
.calendar-wrapper
  .vdp-datepicker
  .vdp-datepicker__calendar
  .cell.highlighted::before,
.copy-move-modal
  .vdp-datepicker
  .vdp-datepicker__calendar
  .cell.selected::before {
  background-color: #0058bc;
  box-sizing: border-box;
  border-radius: 50%;
  content: '';
  height: 34px;
  left: 50%;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  width: 34px;
  z-index: -1;
}
.calendar-wrapper
  .vdp-datepicker
  .vdp-datepicker__calendar
  .cell.selected::after,
.calendar-wrapper
  .vdp-datepicker
  .vdp-datepicker__calendar
  .cell.highlighted::after,
.copy-move-modal
  .vdp-datepicker
  .vdp-datepicker__calendar
  .cell.selected::after {
  box-sizing: border-box;
  border-radius: 50%;
  border: 1px solid #0058bc;
  content: '';
  height: 30px;
  left: 50%;
  margin: 0;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  width: 30px;
  z-index: -1;
}
.calendar-wrapper
  .vdp-datepicker
  .vdp-datepicker__calendar
  .cell.selected:hover::before,
.calendar-wrapper
  .vdp-datepicker
  .vdp-datepicker__calendar
  .cell.highlighted:hover::before,
.copy-move-modal
  .vdp-datepicker
  .vdp-datepicker__calendar
  .cell.selected:hover::before {
  background-color: #0058bc !important;
}
.calendar-wrapper
  .vdp-datepicker
  .vdp-datepicker__calendar
  .cell.selected:hover::after,
.calendar-wrapper
  .vdp-datepicker
  .vdp-datepicker__calendar
  .cell.highlighted:hover::after,
.copy-move-modal
  .vdp-datepicker
  .vdp-datepicker__calendar
  .cell.selected:hover::after {
  background-color: #0058bc !important;
  border: 1px solid #fff !important;
}
.vdp-datepicker__calendar .today {
  font-weight: 700 !important;
}
/* HIGHLIGHTED EXISTING DAYS */
.calendar-wrapper
  .left-panel-day-mode
  .vdp-datepicker
  .vdp-datepicker__calendar
  .cell.highlighted:not(.selected)::before {
  background-color: rgba(0, 88, 188, 0.2);
  height: 32px !important;
  width: 32px !important;
}
.calendar-wrapper
  .left-panel-day-mode
  .vdp-datepicker
  .vdp-datepicker__calendar
  .cell.highlighted:not(.selected)::after {
  border: 1px solid transparent;
}
.calendar-wrapper
  .left-panel-day-mode
  .vdp-datepicker
  .vdp-datepicker__calendar
  .cell.highlighted:not(.selected) {
  color: #000 !important;
}
.calendar-wrapper
  .left-panel-day-mode
  .vdp-datepicker
  .vdp-datepicker__calendar
  .cell.highlighted:not(.selected):hover::before {
  background-color: rgba(0, 88, 188, 0.1) !important;
  height: 34px !important;
  width: 34px !important;
}
.calendar-wrapper
  .left-panel-day-mode
  .vdp-datepicker
  .vdp-datepicker__calendar
  .cell.highlighted:not(.selected):hover::after {
  background-color: rgba(0, 88, 188, 0.1) !important;
  border: 1px solid transparent !important;
}
/* CALENDAR WEEK MODE  */
.calendar-wrapper
  .left-panel-week-mode
  .vdp-datepicker
  .vdp-datepicker__calendar
  .cell.highlighted::after {
  border: none;
  opacity: 0;
}
.calendar-wrapper
  .left-panel-week-mode
  .vdp-datepicker
  .vdp-datepicker__calendar
  .cell.highlighted::before {
  border-radius: 0;
  /* height: 100%; */
  width: 110%;
}
.calendar-wrapper
  .left-panel-week-mode
  .vdp-datepicker
  .vdp-datepicker__calendar
  .cell.highlight-start::before {
  border-top-left-radius: 30px !important;
  border-bottom-left-radius: 30px !important;
  left: initial;
  right: 0;
  transform: translateY(-50%);
  width: 90%;
}
.calendar-wrapper
  .left-panel-week-mode
  .vdp-datepicker
  .vdp-datepicker__calendar
  .cell.highlight-end::before {
  border-top-right-radius: 30px !important;
  border-bottom-right-radius: 30px !important;
  left: 0;
  transform: translateY(-50%);
  width: 90%;
}
.calendar-wrapper
  .left-panel-week-mode
  .vdp-datepicker
  .vdp-datepicker__calendar
  .cell.highlighted:hover::before {
  background-color: #0058bc !important;
}
/* COPY-MOVE MODAL */
.copy-move-modal .vdp-datepicker__calendar {
  border: none !important;
}
.copy-move-modal .vdp-datepicker__calendar header .prev:after {
  margin: 0 !important;
}
.copy-move-modal .vdp-datepicker__calendar header .next:after {
  margin: 0 !important;
}
.modal-copy
  .vdp-datepicker
  .vdp-datepicker__calendar
  .cell.selected:not(.highlighted) {
  background: transparent !important;
  background-color: transparent !important;
  color: #000 !important;
}
.modal-copy
  .vdp-datepicker
  .vdp-datepicker__calendar
  .cell.selected:not(.highlighted)::before,
.modal-copy
  .vdp-datepicker
  .vdp-datepicker__calendar
  .cell.selected:not(.highlighted)::after {
  display: none;
}
.loading-container {
  align-items: center;
  display: flex;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}
.loading {
  width: 50px;
}
.no-wrap {
  white-space: nowrap;
}
/* TRANSITIONS */
.fade-enter-active,
.fade-leave-active {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.fade-enter, .fade-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
}
.slide-enter-active,
.slide-leave-active {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.slide-enter, .slide-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(-25%);
}
.slideFromRight-enter-active,
.slideFromRight-leave-active {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.slideFromRight-enter, .slideFromRight-leave-to /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  position: absolute;
  transform: translateX(100%);
}
.slide-right-and-fade-enter-active,
.slide-right-and-fade-leave-active {
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}
.slide-right-and-fade-enter  /* .fade-leave-active below version 2.1.8 */ {
  opacity: 0;
  position: absolute;
  transform: translateX(100%);
}

.slide-right-and-fade-leave-to {
  opacity: 0;
}
/* .desktop{
  display:inherit;
}
.mobile{
  display: none;
} */
.text-align-center {
  text-align: center;
  width: 100%;
}

.flag-indicator {
  height: 24px;
  width: 30px;
}
/* hide snotify when priting */
@media print{ 
  .snotify{
    display: none !important;
  }
}
@media screen and (max-width: 1000px) {
    .user-modal-wrapper {
    .user-modal {
      width: 90%;
    }
  }
  .users-companies-wrapper {
    .desktop {
      display: none;
    }
    .mobile {
      display: flex;
    }
    .users {
      padding: 10px 15px;
    }
    .users-header {
      justify-content: center;
    }
    .table-wrapper {
      overflow: auto;
    }
    .user-mobile {
      display: flex;
      justify-content: center;
      padding-top: 0.5em;
      padding-bottom: 0.5em;
    }
    .user-permissions {
      justify-content: center;
      padding-bottom: 0.5em;
    }
    .new-content-data-item-toggle-circle {
      width: 50px;
      height: 50px;
      font-size: 20px;
    }
    .new-content-data-item-toggle-circle .tms {
      border-color: transparent;
    }
    .new-content-data-item-toggle-circle.mobile {
      bottom: 20px;
      cursor: pointer;
      position: fixed;
      right: 20px;
    }
    .new-content-data-item-toggle-circle-2.mobile {
      bottom: 80px;
      cursor: pointer;
      position: fixed;
      right: 20px;
    }
    .modal-footer {
      background-color: #fff;
      height: 75px;
      padding: 0px;
    }
  }
  .mobile-datepicker-wrapper .vdp-datepicker .vdp-datepicker__calendar {
    background-color: #fff !important;
    border-radius: 4px;
    width: 100%;
  }
  .add-options {
    bottom: 80px;
    top: initial;
    position: fixed;
    right: 20px;
}
  .new-content-data-item-content .ma-cell-name--smaller,
  .new-content-data-item-toggle .ma-cell-name--smaller {
  width: 100%;
}
}
@media screen and (max-width: 700px) {
  .user-modal-wrapper {
    .user-modal {
      &.user-modal-small {
        width: 90%;
      }
    }
  }
}
@media screen and (max-width: 500px) {
  .user-modal-wrapper {
    .user-modal-actions {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .switch-container {
      display: flex;
      justify-content: center;
      margin-bottom: 1em;
    }
    .btns {
      justify-content: center;
    }
    .user-modal-permissions {
      flex-direction: column;
    }
  }
}
@media screen and (max-height: 550px) {
  .user-modal-wrapper {
    .user-modal {
      height: 100%;
    }
    .user-modal.user-modal-small {
      height: 68%;
    }
  }
}
@media screen and (max-height: 500px) {
  .user-modal-wrapper {
    .user-modal.user-modal-small {
      height: 73%;
    }
  }
  @media screen and (max-height: 365px) {
    .user-modal.user-modal-small {
      height: 100%;
    }
  }
}
</style>
