import Vue from "vue";
import Router from "vue-router";
import store from "./store.js";
import Home from "./components/Home.vue";
import Inquiry from "./components/Inquiry.vue";
import Assets from "./components/Assets.vue";
import ownReports from "./components/OwnReports.vue";
import Report from "./components/Report.vue";
import Companies from "./components/Companies.vue";
import Company from "./components/Company.vue";
import Users from "./components/Users.vue";
import Login from "./components/Login.vue";
import Log from "./components/Log.vue";
// import Charts from './components/Charts.vue'
import Calendar from "./components/Calendar.vue";
import CalendarConfirmation from "./components/CalendarConfirmation.vue";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "home",
      component: Home,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/inquiry",
      name: "inquiry",
      component: Inquiry,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/reports/own",
      name: "ownReports",
      component: ownReports,
      meta: {
        requiresAuthAndReporter: true,
      },
    },
    {
      path: "/reports/new",
      name: "newReport",
      component: Report,
      meta: {
        requiresAuthAndReporter: true,
      },
    },
    {
      path: "/reports/edit/:id",
      name: "editReport",
      component: Report,
      meta: {
        requiresAuthAndReporterOrAdmin: true,
      },
    },
    {
      path: "/companies",
      name: "companies",
      component: Companies,
      meta: {
        requiresAuthAndReporterOrAdmin: true,
      },
    },
    {
      path: "/assets",
      name: "assets",
      component: Assets,
      meta: {
        requiresAuthAndReporterOrAdmin: true,
      },
    },
    {
      path: "/companies/new",
      name: "newCompany",
      component: Company,
      meta: {
        requiresAuthAndAdmin: true,
      },
    },
    {
      path: "/companies/:companyId",
      name: "newProject",
      component: Companies,
      meta: {
        requiresAuthAndReporterOrAdmin: true,
      },
    },
    {
      path: "/companies/:companyId/:projectId",
      name: "newObject",
      component: Companies,
      meta: {
        requiresAuthAndReporterOrAdmin: true,
      },
    },
    {
      path: "/companies/:companyId/:projectId/:objectId",
      name: "newPart",
      component: Companies,
      meta: {
        requiresAuthAndReporterOrAdmin: true,
      },
    },
    {
      path: "/companies/:companyId/:projectId/:objectId/:partId",
      name: "getPart",
      component: Companies,
      meta: {
        requiresAuthAndReporterOrAdmin: true,
      },
    },
    {
      path: "/planning",
      name: "calendar",
      component: Calendar,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/planning/confirm/:id",
      name: "calendarConfirmation",
      component: CalendarConfirmation,
      meta: {
        requiresAuthAndSuperAdmin: true,
      },
    },
    {
      path: "/planning/reject/:id",
      name: "calendarRejection",
      component: CalendarConfirmation,
      meta: {
        requiresAuthAndSuperAdmin: true,
      },
    },
    {
      path: "/users",
      name: "users",
      component: Users,
      meta: {
        requiresAuth: true,
      },
    },
    {
      path: "/log",
      name: "log",
      component: Log,
      meta: {
        requiresAuthAndSuperAdmin: true,
      },
    },
    // {
    //   path: '/charts',
    //   name: 'charts',
    //   component: Charts,
    //   meta: {
    //     requiresAuthAndSuperAdmin: true
    //   }
    // },
    {
      path: "/login",
      name: "login",
      component: Login,
    },
    // {
    //   path: '/about',
    //   name: 'about',
    //   // route level code-splitting
    //   // this generates a separate chunk (about.[hash].js) for this route
    //   // which is lazy-loaded when the route is visited.
    //   component: () => import(/* webpackChunkName: "about" */ './views/About.vue')
    // }
  ],
});

router.beforeEach((to, from, next) => {
  store.dispatch("checkAuth").then(() => {
    if (to.matched.some((record) => record.meta.requiresAuth)) {
      if (
        store.getters.isLoggedIn &&
        (store.getters.getUser.permissions.isAdmin ||
          store.getters.getUser.permissions.isSuperAdmin ||
          store.getters.getUser.permissions.isReporter ||
          store.getters.getUser.permissions.isHR ||
          store.getters.getUser.permissions.isCEO ||
          store.getters.getUser.permissions.isEmployee)
      ) {
        next();
        return;
      }
      const loginpath = window.location.pathname;
      next({
        path: "/login",
        query: { from: loginpath },
      });
    } else if (
      to.matched.some((record) => record.meta.requiresAuthAndSuperAdmin)
    ) {
      if (
        store.getters.isLoggedIn &&
        store.getters.getUser.permissions.isSuperAdmin
      ) {
        next();
        return;
      }
      next({
        path: "/",
      });
    } else if (to.matched.some((record) => record.meta.requiresAuthAndAdmin)) {
      if (
        store.getters.isLoggedIn &&
        (store.getters.getUser.permissions.isAdmin ||
          store.getters.getUser.permissions.isSuperAdmin)
      ) {
        next();
        return;
      }
      next({
        path: "/",
      });
    } else if (
      to.matched.some((record) => record.meta.requiresAuthAndAdminOrHrOrCeo)
    ) {
      if (
        store.getters.isLoggedIn &&
        (store.getters.getUser.permissions.isAdmin ||
          store.getters.getUser.permissions.isSuperAdmin ||
          store.getters.getUser.permissions.isHR ||
          store.getters.getUser.permissions.isCEO)
      ) {
        next();
        return;
      }
      next({
        path: "/",
      });
    } else if (
      to.matched.some((record) => record.meta.requiresAuthAndReporter)
    ) {
      if (
        store.getters.isLoggedIn &&
        store.getters.getUser.permissions.isReporter
      ) {
        next();
        return;
      }
      next({
        path: "/",
      });
    } else if (
      to.matched.some((record) => record.meta.requiresAuthAndReporterOrAdmin)
    ) {
      if (
        store.getters.isLoggedIn &&
        (store.getters.getUser.permissions.isReporter ||
          store.getters.getUser.permissions.isAdmin ||
          store.getters.getUser.permissions.isSuperAdmin)
      ) {
        next();
        return;
      }
      next({
        path: "/",
      });
    } else {
      next();
    }
  });
});

export default router;
