import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import moment from 'moment'
import jsonExcel from '../node_modules/vue-json-to-excel'
import Snotify from 'vue-snotify'
import _ from 'lodash';
import VueI18n from 'vue-i18n'

Vue.config.productionTip = false;

Date.prototype.GetFirstDayOfWeek = function () {
  return new Date(this.setDate(this.getDate() - this.getDay() + (this.getDay() == 0 ? -6 : 1)));
}
Date.prototype.GetLastDayOfWeek = function () {
  return new Date(this.setDate(this.getDate() - (this.getDay() == 0 ? 7 : this.getDay()) + 7));
}

Vue.prototype.$http = axios;
Vue.prototype.$moment = moment
Vue.prototype.$_ = _;
Vue.prototype.$eventBus = new Vue();

// Object.defineProperty(Vue.prototype, '$_', { value: _ });

const token = localStorage.getItem('token')
if (token) {
  Vue.prototype.$http.defaults.headers.common['Authorization'] = token
}

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
})

Vue.filter('formatFullDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY - HH:mm:ss')
  }
})

Vue.filter('formatDay', function (value) {
  if (value) {
    return moment(String(value)).locale("bg").format('D MMMM')
  }
})

Vue.filter('formatDayOfWeek', function (value) {
  if (value) {
    return moment(String(value)).locale("bg").format('dddd')
  }
})

Vue.filter('formatDayYear', function (value) {
  if (value) {
    return moment(String(value)).locale("bg").format('D MMMM YYYY')
  }
})

Vue.filter('getMonth', function (value) {
  if (value) {
    let m = moment(String(value)).locale("bg").format('MMMM')
    return m[0].toUpperCase() + m.slice(1, m.length)
  }
})

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

const options = {
  toast: {
    timeout: 5000,
    showProgressBar: false
  }
}

Vue.use(Snotify, options)

Vue.use(VueI18n)

const messages = {
  bg: require('./locale/bg.json'),
  en: require('./locale/en.json'),
}

const i18n = new VueI18n({
  locale: localStorage.getItem('lang') || 'bg',
  messages
})

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY')
  }
})

Vue.filter('formatFullDate', function (value) {
  if (value) {
    return moment(String(value)).format('DD/MM/YYYY - HH:mm:ss')
  }
})

Vue.filter('formatDay', function (value) {
  if (value) {
    return moment(String(value)).locale(i18n.locale).format('D MMMM')
  }
})

Vue.filter('formatDayOfWeek', function (value) {
  if (value) {
    return moment(String(value)).locale(i18n.locale).format('dddd')
  }
})

Vue.filter('formatDayYear', function (value) {
  if (value) {
    return moment(String(value)).locale(i18n.locale).format('D MMMM YYYY')
  }
})

Vue.filter('capitalize', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.charAt(0).toUpperCase() + value.slice(1)
})

// VUE COMPONENTS
Vue.component('downloadExcel', jsonExcel);

new Vue({
  i18n,
  router,
  store,
  render: h => h(App)
}).$mount('#app')