<template>
  <div class="calendar-wrapper">
    <div class="calendar">
      <div class="calendar-header">
        <h2 class="desktop">{{ $t("planning") }}</h2>
        <div class="selected-date desktop">
          <p v-if="activePeriodTab === 0">
            <strong>{{ date | formatDay }}</strong>
            <span
              >, {{ date | formatDayOfWeek }} ({{ calendars.length }}
              {{ $t("sites") }})</span
            >
          </p>
          <p v-if="activePeriodTab === 1">
            <strong>{{ firstDay | formatDay }}</strong>
            -
            <strong>{{ lastDay | formatDay }}</strong>
          </p>
        </div>
        <div class="mobile-datepicker">
          <div
            class="mobile-datepicker-toggle"
            @click="showMobileDatepicker = !showMobileDatepicker"
          >
            <span v-if="activePeriodTab === 0">{{ date | formatDay }}</span>
            <span v-if="activePeriodTab === 1"
              >{{ firstDay | formatDay }} - {{ lastDay | formatDay }}</span
            >
            <span class="tms" :class="{ 'rotate-arrow': showMobileDatepicker }"
              >K</span
            >
          </div>
          <div
            class="mobile-datepicker-wrapper left-panel-week-mode"
            v-show="showMobileDatepicker"
          >
            <datepicker
              :language="this[$i18n.locale]"
              :inline="true"
              :monday-first="true"
              :minimumView="'day'"
              :highlighted="highlighted"
              :maximumView="'day'"
              :full-month-name="true"
              v-model="date"
            ></datepicker>
          </div>
        </div>
        <div class="calendar-header-left" v-if="activeContentTab !== 3">
          <label class="desktop" for="views1">{{ $t("view-by") }}:</label>
          <div class="views" id="views1">
            <button
              class="view left"
              :class="{ 'view-active': activePeriodTab === 0 }"
              @click="changePeriodTab(0)"
            >
              <span class="desktop">{{ $t("day") }}</span>
              <span class="tms2 mobile-inline">T</span>
            </button>
            <button
              class="view right"
              :class="{ 'view-active': activePeriodTab === 1 }"
              @click="changePeriodTab(1)"
            >
              <span class="desktop">{{ $t("week") }}</span>
              <span class="tms2 mobile-inline">S</span>
            </button>
            <!-- <button class="view right" :class="{ 'view-active' : activePeriodTab === 2 }">Месец</button> -->
          </div>
        </div>
        <div class="btns" v-else>
          <a
            class="btn btn-excel btn-widthless"
            :href="normativeUrl"
            target="_blank"
            @click="toggleExport($event)"
          >
            <span class="tms">E</span>
            {{ $t("normative-inquiry") }} {{ date | getMonth }}
          </a>
          <!-- <a class="btn btn--help" :href="normativePdf" target="_blank">?</a> -->
        </div>
      </div>
      <div class="calendar-content">
        <div
          class="left-panel desktop"
          :class="{
            'left-panel-week-mode': activePeriodTab === 1,
            'left-panel-day-mode': activePeriodTab === 0,
          }"
        >
          <div class="left-panel-header" v-if="getUser.permissions.isAdmin">
            <div class="views" id="views2">
              <!-- <template v-if="getUser.permissions.isAdmin"> -->
              <button
                class="view left"
                :class="{ 'view-active': activeContentTab === 0 }"
                @click="changeContentTab(0)"
              >
                {{ $t("tab-calendar") }}
              </button>
              <button
                class="view right"
                :class="{ 'view-active': activeContentTab === 3 }"
                @click="changeContentTab(3)"
              >
                {{ $t("tab-hr") }}
              </button>
            </div>
          </div>
          <datepicker
            :language="this[$i18n.locale]"
            :inline="true"
            :monday-first="true"
            :minimumView="'day'"
            :highlighted="highlighted"
            :maximumView="'day'"
            :full-month-name="true"
            v-model="date"
          ></datepicker>
          <template v-if="activePeriodTab === 0 && activeContentTab !== 3">
            <div
              class="new-content-data-item-toggle"
              @click="isAddCompaniesModalOpened = true"
            >
              <p>
                <strong>{{ date | formatDayYear }}</strong>
                - {{ $t("sites-for-the-day") }} ({{ calendars.length }})
              </p>
              <span
                class="new-content-data-item-toggle-circle"
                v-if="
                  getUser.permissions.isSuperAdmin ||
                  getUser.permissions.isAdmin ||
                  getUser.permissions.isReporter
                "
              >
                <span class="tms2">I</span>
              </span>
            </div>
            <div class="new-content-data-item">
              <div
                class="new-content-data-item-content"
                :class="{
                  'new-content-data-item-content-no-results': !calendars.length,
                }"
              >
                <template v-if="calendars.length">
                  <p
                    v-for="(c, index) in calendars"
                    :key="`object-${index}-addedWorker-${c._id}`"
                  >
                    {{ c.object.objectName }}
                  </p>
                </template>
                <p v-else>{{ $t("no-sites-for-the-day") }}</p>
              </div>
            </div>
          </template>
          <div
            class="new-content-data-item-toggle new-content-data-item-toggle--absence desktop"
            @click="openAddAbsencesModal()"
            v-if="activeContentTab === 3"
          >
            <p>
              <transition name="fade">
                <span class="tms2">0</span>
              </transition>
              <transition name="fade" mode="out-in">
                <strong key="legend1">{{ $t("add-absence") }}</strong>
              </transition>
            </p>
          </div>
        </div>
        <div class="right-panel" id="rightPanel">
          <!-- OBJECTS CALENDAR -->
          <div
            class="objects-calendar"
            v-if="calendars.length"
          >
            <template v-if="activePeriodTab === 0">
              <div
                class="objects-calendar-item"
                :class="{
                  'objects-calendar-item-ok': c.isReady && c.isConfirmed,
                  'objects-calendar-item-printable': printable === index,
                  'objects-calendar-item-wait': c.isReady && !c.isConfirmed,
                  'objects-calendar-item-send': !c.isReady && !c.isConfirmed,
                }"
                v-for="(c, index) in calendars"
                :key="`${index}-${c.company._id}-${c.project._id}-${c.object._id}`"
                :id="`item-${c._id}`"
              >
                <div class="objects-calendar-item-header">
                  <p>{{ c.company.companyName }}</p>
                  <p>{{ c.project.projectName }}</p>
                  <p>{{ c.object.objectName }}</p>
                </div>
                <div class="objects-calendar-item-content">
                  <div
                    class="list-container"
                    @click="openWorkersModal(index, c.isReady)"
                    v-if="c.workers.length"
                  >
                    <div class="tms2 toggle">Q</div>
                    <header>
                      <p>{{ $t("Workers") }} ({{ c.workers.length }})</p>
                    </header>
                    <div class="list-tag-wrapper">
                      <div
                        v-for="(w, i) in c.workers"
                        :key="`${c.object._id}-${w._id}`"
                        class="list-tag"
                      >
                        <span
                          class="tms delete-item"
                          v-if="
                            !c.isReady &&
                            (getUser.permissions.isSuperAdmin ||
                              getUser.permissions.isAdmin ||
                              getUser.permissions.isReporter)
                          "
                          @click.stop="deleteItem(index, 'workers', i)"
                          >b</span
                        >
                        {{ w.name }}
                      </div>
                    </div>
                  </div>
                  <div class="big-toggle-wrapper big-toggle-workers" v-else>
                    <div
                      class="big-toggle"
                      @click="openWorkersModal(index, c.isReady)"
                    >
                      <div class="big-toggle-inner">
                        <div class="tms2">Q</div>
                        <p>
                          <template
                            v-if="
                              getUser.permissions.isSuperAdmin ||
                              getUser.permissions.isAdmin ||
                              getUser.permissions.isReporter
                            "
                          >
                            {{ $t("add") }}
                            <br />
                          </template>
                          {{ $t("employees") }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="list-container"
                    @click="openMaterials(index, c.isReady)"
                    v-if="c.materials.length"
                  >
                    <div class="tms toggle">T</div>
                    <header>
                      <p>{{ $t("Materials") }} ({{ c.materials.length }})</p>
                    </header>
                    <div class="list-tag-wrapper">
                      <div
                        v-for="(m, j) in c.materials"
                        :key="`${c.object._id}-${m._id}-${j}`"
                        class="list-tag"
                      >
                        <span
                          class="tms delete-item"
                          v-if="
                            !c.isReady &&
                            (getUser.permissions.isSuperAdmin ||
                              getUser.permissions.isAdmin ||
                              getUser.permissions.isReporter)
                          "
                          @click.stop="deleteItem(index, 'materials', j)"
                          >b</span
                        >
                        {{ m.name }} - {{ m.quantity }} {{ m.unit }}
                      </div>
                    </div>
                  </div>
                  <div class="big-toggle-wrapper big-toggle-materials" v-else>
                    <div
                      class="big-toggle"
                      @click="openMaterials(index, c.isReady)"
                    >
                      <div class="big-toggle-inner">
                        <div class="tms">T</div>
                        <p>
                          <template
                            v-if="
                              getUser.permissions.isSuperAdmin ||
                              getUser.permissions.isAdmin ||
                              getUser.permissions.isReporter
                            "
                          >
                            {{ $t("add") }}
                            <br />
                          </template>
                          {{ $t("materials") }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="list-container"
                    @click="openMechanizations(index, c.isReady)"
                    v-if="c.mechanizations.length"
                  >
                    <div class="tms2 toggle">Y</div>
                    <header>
                      <p>
                        {{ $t("Mechanizations") }} ({{
                          c.mechanizations.length
                        }})
                      </p>
                    </header>
                    <div class="list-tag-wrapper">
                      <div
                        v-for="(m, j) in c.mechanizations"
                        :key="`${c.object._id}-${m._id}-${j}`"
                        class="list-tag"
                      >
                        <span
                          class="tms delete-item"
                          v-if="
                            !c.isReady &&
                            (getUser.permissions.isSuperAdmin ||
                              getUser.permissions.isAdmin ||
                              getUser.permissions.isReporter)
                          "
                          @click.stop="deleteItem(index, 'mechanizations', j)"
                          >b</span
                        >
                        {{ m.name }} - {{ m.quantity }} {{ m.unit }}
                      </div>
                    </div>
                  </div>
                  <div
                    class="big-toggle-wrapper big-toggle-mechanizations"
                    v-else
                  >
                    <div
                      class="big-toggle"
                      @click="openMechanizations(index, c.isReady)"
                    >
                      <div class="big-toggle-inner">
                        <div class="tms2">Y</div>
                        <p>
                          <template
                            v-if="
                              getUser.permissions.isSuperAdmin ||
                              getUser.permissions.isAdmin ||
                              getUser.permissions.isReporter
                            "
                          >
                            {{ $t("add") }}
                            <br />
                          </template>
                          {{ $t("mechanizations") }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="list-container"
                    @click="openActivities(index, c.isReady)"
                    v-if="c.activities && c.activities.length"
                  >
                    <div class="tms toggle">T</div>
                    <header>
                      <p>{{ $t("Activities") }} ({{ c.activities.length }})</p>
                    </header>
                    <div class="list-tag-wrapper">
                      <div
                        v-for="(m, j) in c.activities"
                        :key="`${c.object._id}-${m._id}-${j}`"
                        class="list-tag"
                      >
                        <span
                          class="tms delete-item"
                          v-if="
                            !c.isReady &&
                            (getUser.permissions.isSuperAdmin ||
                              getUser.permissions.isAdmin ||
                              getUser.permissions.isReporter)
                          "
                          @click.stop="deleteItem(index, 'activities', j)"
                          >b</span
                        >
                        {{ m.name }} - {{ m.quantity }} {{ m.unit }}
                      </div>
                    </div>
                  </div>
                  <div class="big-toggle-wrapper big-toggle-activities" v-else>
                    <div
                      class="big-toggle"
                      @click="openActivities(index, c.isReady)"
                    >
                      <div class="big-toggle-inner">
                        <div class="tms">T</div>
                        <p>
                          <template
                            v-if="
                              getUser.permissions.isSuperAdmin ||
                              getUser.permissions.isAdmin ||
                              getUser.permissions.isReporter
                            "
                          >
                            {{ $t("add") }}
                            <br />
                          </template>
                          {{ $t("activities") }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div
                    class="objects-calendar-item-content-add"
                    v-if="
                      getUser.permissions.isSuperAdmin ||
                      getUser.permissions.isAdmin ||
                      getUser.permissions.isReporter
                    "
                  >
                    <div class="add-items-wrapper">
                      <button
                        class="objects-calendar-item-content-add-item"
                        @click="openCopyMoveCalendarModal('copy', c._id)"
                        :title="$t('copying')"
                      >
                        <img src="../assets/img/copy.png" :alt="$t('copy')" />
                      </button>
                      <button
                        class="objects-calendar-item-content-add-item"
                        @click="openCopyMoveCalendarModal('move', c._id)"
                        :disabled="c.isReady"
                        :title="`${
                          c.isReady ? $t('not-allowed-move') : $t('move')
                        }`"
                      >
                        <img src="../assets/img/move.png" :alt="$t('move')" />
                      </button>
                      <button
                        class="objects-calendar-item-content-add-item"
                        @click="removeCalendar(c)"
                        :disabled="c.isReady"
                        :title="`${c.isReady ? $t('not-allowed-delete') : ''}`"
                      >
                        <img
                          src="../assets/img/delete.png"
                          :alt="$t('delete')"
                        />
                      </button>
                      <button class="objects-calendar-item-content-add-item">
                        <img
                          src="../assets/img/print.png"
                          @click="print(index)"
                          :title="$t('print')"
                          :alt="$t('print')"
                        />
                      </button>
                      <button
                        class="objects-calendar-item-content-add-item"
                        :title="getInfo(c, false)"
                        @click="displayInfo(c)"
                      >
                        <img src="../assets/img/info.png" :alt="$t('info')" />
                      </button>
                    </div>
                    <div class="add-items-wrapper">
                      <template v-if="c.isReady && !c.isConfirmed">
                        <button
                          v-if="getUser.permissions.isSuperAdmin"
                          class="objects-calendar-item-content-add-item button-ok"
                          @click="sendConfirm(c)"
                        >
                          <span>{{ $t("approve") }}</span>
                          <img
                            src="../assets/img/ok.png"
                            :alt="$t('approve')"
                          />
                        </button>
                        <button
                          v-else
                          class="objects-calendar-item-content-add-item button-wait"
                        >
                          <span>{{ $t("stand-by") }}</span>
                        </button>
                      </template>
                      <button
                        v-else-if="!c.isReady && !c.isConfirmed"
                        class="objects-calendar-item-content-add-item button-send"
                        @click="sendForConfirmation(c)"
                      >
                        <span>{{ $t("send") }}</span>
                        <img src="../assets/img/send.png" :alt="$t('send')" />
                      </button>
                      <button
                        v-if="c.isConfirmed && c.isReady"
                        class="objects-calendar-item-content-add-item button-confirmed"
                      >
                        <span>{{ $t("approved") }}</span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="activePeriodTab === 1">
              <div
                class="big-calendar-item"
                v-for="c in calendars"
                :key="c._id"
              >
                <div class="date-calendar-item-header">
                  <p>{{ c.date | formatDay }}</p>
                  <p class="date-calendar-objects-count">
                    <span class="tms2">P</span>
                    {{ c.calendars.length }}&nbsp;
                    <span v-if="c.calendars.length === 1">{{
                      $t("site")
                    }}</span>
                    <span v-else>{{ $t("sites") }}</span>
                  </p>
                </div>
                <div class="big-calendar-item-content">
                  <div class="companies-container">
                    <div
                      v-for="ca in c.calendars"
                      :key="ca._id"
                      class="companies-tag"
                      :class="{
                        'companies-tag-send': !ca.isReady && !ca.isConfirmed,
                        'companies-tag-wait': ca.isReady && !ca.isConfirmed,
                        'companies-tag-ok': ca.isReady && ca.isConfirmed,
                      }"
                    >
                      <template v-if="ca">
                        <p>{{ ca.company.companyName }}</p>
                        <p>{{ ca.project.projectName }}</p>
                        <p>{{ ca.object.objectName }}</p>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
          <!-- ABSENCES -->
          <div
            class="workers-calendar no-results-calendar"
            v-if="activeContentTab === 3"
          >
            <div class="workers-calendar-item">
              <div class="workers-calendar-item-header">
                <p>{{ date | formatDayYear }}</p>
              </div>
              <div
                class="workers-calendar-item-content"
                v-if="!absences.length"
              >
                <p class="no-results-calendar-p">{{ $t("no-results") }}</p>
              </div>
              <div class="absences-wrapper" v-if="absences.length">
                <p
                  class="absence-row"
                  v-for="a in absences"
                  :key="a._id"
                  @click="selectedAbsence = a._id"
                >
                  <span
                    class="absence-type"
                    :class="`absence-type--${a.type}`"
                    >{{ $t("absenceTypes")[a.type] }}</span
                  >
                  <span class="abscence-name">
                    <strong>{{ a.user.name }}</strong>
                  </span>
                  <span
                    >{{ a.from | formatDayYear }} -
                    {{ a.to | formatDayYear }}</span
                  >
                </p>
              </div>
            </div>
          </div>
          <div
            class="workers-calendar no-results-calendar"
            v-if="!calendars.length && activeContentTab !== 3"
          >
            <div class="workers-calendar-item">
              <div class="workers-calendar-item-header">
                <p>{{ $t("sites-for-the-day") }}</p>
              </div>
              <div class="workers-calendar-item-content"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <transition name="fade">
      <AddUsersModal
        v-if="selectedObject !== undefined"
        :incomingUsers="calendars[selectedObject].workers"
        :usersType="'workers'"
      />
    </transition>
    <transition name="fade">
      <AddCompaniesModal v-if="isAddCompaniesModalOpened" />
    </transition>
    <transition name="fade">
      <CopyMoveCalendarModal
        v-if="CopyMoveCalendarModalAction"
        :action="CopyMoveCalendarModalAction"
        :id="CopyMoveCalendarModalCalendar"
      />
    </transition>
    <transition name="fade">
      <AddMaterialsModal
        v-if="isAddMaterialsModalOpened"
        :isComingFromReport="true"
        :needsQuantity="true"
        :companyId="selectedIds.companyId"
        :projectId="selectedIds.projectId"
        :objectId="selectedIds.objectId"
        :incomingMaterials="selectedObjectMaterials"
        :selectedMaterials="calendars[selectedIds.objectIndex].materials || []"
      />
    </transition>
    <transition name="fade">
      <AddMechanizationsModal
        v-if="isAddMechanizationsModalOpened"
        :isComingFromReport="true"
        :needsQuantity="true"
        :companyId="selectedIds.companyId"
        :projectId="selectedIds.projectId"
        :objectId="selectedIds.objectId"
        :incomingMechanizations="selectedObjectMechanizations"
        :selectedMechanizations="
          calendars[selectedIds.objectIndex].mechanizations || []
        "
      />
    </transition>
    <transition name="fade">
      <AddActivitiesModal
        v-if="isAddActivitiesModalOpened"
        :isComingFromReport="true"
        :needsQuantity="true"
        :companyId="selectedIds.companyId"
        :projectId="selectedIds.projectId"
        :objectId="selectedIds.objectId"
        :incomingActivities="selectedObjectActivities"
        :selectedActivities="
          calendars[selectedIds.objectIndex].activities || []
        "
      />
    </transition>
    <transition name="fade">
      <AddQuantityModal v-if="addQuantityTo" :val="addQuantityTo[0]" />
    </transition>
    <transition name="fade">
      <AddAbsencesModal
        v-if="isAbsencesModalOpen"
        :selectedCalendarDate="date"
        @close="closeAbsencesModal"
      />
    </transition>
    <transition name="fade">
      <EditAbsenceModal
        v-if="selectedAbsence"
        :id="selectedAbsence"
        @close="closeEditAbscenceModal"
      />
    </transition>
  </div>
</template>

<script>
// import print from 'print-js';
import AddUsersModal from "./shared/AddUsersModal";
import AddCompaniesModal from "./shared/AddCompaniesModal";
import CopyMoveCalendarModal from "./shared/CopyMoveCalendarModal";
import AddMaterialsModal from "./shared/AddMaterialsModal";
import AddMechanizationsModal from "./shared/AddMechanizationsModal";
import AddActivitiesModal from "./shared/AddActivitiesModal";
import AddQuantityModal from "./shared/AddQuantityModal";
import AddAbsencesModal from "./shared/AddAbsencesModal.vue";
import EditAbsenceModal from "./shared/EditAbsenceModal";
import Datepicker from "vuejs-datepicker";
import { bg, en } from "vuejs-datepicker/dist/locale";
import { mapGetters } from "vuex";
import log from "./mixins/log.js";
export default {
  components: {
    Datepicker: Datepicker,
    AddUsersModal: AddUsersModal,
    AddCompaniesModal: AddCompaniesModal,
    CopyMoveCalendarModal: CopyMoveCalendarModal,
    AddMaterialsModal: AddMaterialsModal,
    AddMechanizationsModal: AddMechanizationsModal,
    AddQuantityModal: AddQuantityModal,
    AddActivitiesModal: AddActivitiesModal,
    AddAbsencesModal: AddAbsencesModal,
    EditAbsenceModal: EditAbsenceModal,
  },
  mixins: [log],
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      "getUser",
      // ...
    ]),
    normativeUrl() {
      return `${
        process.env.VUE_APP_BASE_URI
      }/normative/${this.date.toISOString()}`;
    },
  },
  data() {
    return {
      bg: bg,
      en: en,
      months: this.$t("months"),
      highlightedDates: [],
      highlighted: { to: "", from: "" },
      date: "",
      firstDay: undefined,
      lastDay: undefined,
      format: "MMMM yyyy",
      activePeriodTab: 0,
      activeContentTab: 0,
      selectedAbsence: "",
      calendars: [],
      absences: [],
      workers: [],
      allowExport: false,
      isAbsencesModalOpen: false,
      isAddCompaniesModalOpened: false,
      isAddActivitiesModalOpened: false,
      isAddMaterialsModalOpened: false,
      isAddMechanizationsModalOpened: false,
      selectedObject: undefined,
      CopyMoveCalendarModalAction: "",
      CopyMoveCalendarModalCalendar: undefined,
      showMobileDatepicker: false,
      selectedObjectActivities: [],
      selectedObjectMaterials: [],
      selectedObjectMechanizations: [],
      selectedIds: {
        companyId: null,
        projectId: null,
        objectId: null,
        objectIndex: null,
        type: null,
      },
      addQuantityTo: null,
      printable: null,
    };
  },
  watch: {
    // eslint-disable-next-line
    date(to, from) {
      this.showMobileDatepicker = false;
      // this.getCalendars();
      this.fetchData();
      this.firstDay = new Date(this.date).GetFirstDayOfWeek();
      this.lastDay = new Date(this.date).GetLastDayOfWeek();
      if (this.activeContentTab === 3) {
        this.highlighted = {
          dates: [],
        };
      } else {
        if (this.activePeriodTab === 1) {
          this.highlighted = { from: this.firstDay, to: this.lastDay };
        } else {
          this.highlighted = {
            dates: this.highlightedDates,
          };
        }
      }
    },
  },
  methods: {
    changePeriodTab(n) {
      this.showMobileDatepicker = false;
      this.firstDay = new Date(this.date).GetFirstDayOfWeek();
      this.lastDay = new Date(this.date).GetLastDayOfWeek();
      this.calendars = [];
      this.activePeriodTab = n;

      if (this.activeContentTab === 3) {
        this.highlighted = {
          dates: [],
        };
      } else {
        if (this.activePeriodTab === 1) {
          this.highlighted = { from: this.firstDay, to: this.lastDay };
        } else {
          this.highlighted = {
            dates: this.highlightedDates,
          };
        }
      }
      this.getCalendars();
    },
    changeContentTab(n) {
      this.activeContentTab = n;
      this.filter = null;
      this.fetchData();
    },
    fetchData() {
      this.calendars = [];
      this.absences = [];
      if (this.activeContentTab === 3) {
        this.getAbsences();
        this.exportPermission();
      } else {
        this.getCalendars();
      }
      this.getHighlighted();
    },
    exportPermission() {
      this.allowExport = false;
      let url = `${
        process.env.VUE_APP_BASE_URI
      }/normative/${this.date.toISOString()}?allow=true`;
      this.$http.get(url).then((response) => {
        this.allowExport = response.data;
      });
    },
    getAbsences() {
      let url = `${
        process.env.VUE_APP_BASE_URI
      }/absence/date/${this.date.toISOString()}`;

      this.$http.get(url).then((res) => {
        this.absences = res.data;
      });
    },
    openAddAbsencesModal() {
      if (this.activeContentTab === 3) {
        this.isAbsencesModalOpen = true;
      }
    },
    closeAbsencesModal(update) {
      if (update) {
        this.fetchData();
      }
      this.isAbsencesModalOpen = false;
    },
    closeEditAbscenceModal(update) {
      if (update) {
        this.fetchData();
      }
      this.selectedAbsence = "";
    },
    handleCompanies(c) {
      const url = `${
        process.env.VUE_APP_BASE_URI
      }/calendar/exists/${this.date.toISOString()}/${c.companyId}/${
        c.projectId
      }/${c.objectId}`;
      this.$http.get(url).then((response) => {
        if (!response.data.length) {
          const calendar = {
            date: this.date,
            createdAt: new Date(),
            createdBy: this.getUser._id,
            companyId: c.companyId,
            projectId: c.projectId,
            objectId: c.objectId,
            isReady: false,
            isConfirmed: false,
          };
          const url = `${process.env.VUE_APP_BASE_URI}/calendar`;
          this.$http
            .post(url, calendar)
            .then((a) => {
              if (a.data.errors) {
                console.log(a.data.errors);
                this.$snotify.error(this.$t("something-went-wrong"));
              } else {
                const dateString = `${this.date.getDate()} ${
                  this.months[this.date.getMonth()]
                } ${this.date.getFullYear()}`;
                this.$snotify.success(
                  `${this.$t("successfully-added-site-for")} ${dateString}`
                );
                this.getCalendars();
                this.closeAddCompaniesModal();
                if (calendar.objectId) {
                  let url = `${process.env.VUE_APP_BASE_URI}/company/object-name/${calendar.objectId}`;
                  return this.$http
                    .get(url)
                    .then((response) => {
                      if (response.data) {
                        this.newLog(
                          "newCalendar",
                          `${this.$t("site").toLowerCase()} ${
                            response.data[0].objectName
                          } - ${this.$t("date")} ${dateString}`
                        );
                      }
                    })
                    .catch((error) => console.log(error));
                }
              }
            })
            .catch((error) => {
              console.log(error.response);
              this.$snotify.error(this.$t("something-went-wrong"));
            });
        } else {
          this.$snotify.error(
            `${this.$t("already-added-site-for")} ${this.date.getDate()} ${
              this.months[this.date.getMonth()]
            } ${this.date.getFullYear()}`
          );
        }
      });
    },
    openWorkersModal(i, isReady) {
      if (
        !this.getUser.permissions.isSuperAdmin &&
        !this.getUser.permissions.isAdmin &&
        !this.getUser.permissions.isReporter
      )
        return;

      if (!isReady) {
        this.selectedObject = i;
      } else {
        this.$snotify.error(this.$t("already-sent-for-confirmation-workers"));
      }
    },
    // If we get the 'saveWorkersModal' event, we assign the workers we receive to the report
    handleWorkers(workers) {
      const workersArray = [];

      workers.forEach((w) => {
        workersArray.push({
          _id: w._id,
        });
      });

      const newCalendar = this.calendars[this.selectedObject];
      newCalendar.workers = workersArray;

      const url = `${process.env.VUE_APP_BASE_URI}/calendar/${newCalendar._id}`;

      this.$http
        .put(url, newCalendar)
        .then((a) => {
          if (a.data.errors) {
            console.log(a.data.errors);
            this.$snotify.error(this.$t("something-went-wrong"));
          } else {
            this.$snotify.success(this.$t("successfully-updated-workers"));
            this.getCalendars();
            this.closeAddWorkersModal();
          }
        })
        .catch((error) => {
          console.log(error.response);
          this.$snotify.error(this.$t("something-went-wrong"));
        });
    },
    openActivities(index, isReady) {
      if (
        !this.getUser.permissions.isSuperAdmin &&
        !this.getUser.permissions.isAdmin &&
        !this.getUser.permissions.isReporter
      )
        return;

      if (!isReady) {
        this.selectedIds = {
          companyId: this.calendars[index].company._id,
          projectId: this.calendars[index].project._id,
          objectId: this.calendars[index].object._id,
          objectIndex: index,
          type: "activities",
        };
        this.isAddActivitiesModalOpened = true;
        this.getActivities();
      } else {
        this.$snotify.error(this.$t("already-sent-for-confirmation-materials"));
      }
    },
    getActivities() {
      let url = `${process.env.VUE_APP_BASE_URI}/company/object/${this.selectedIds.objectId}`;
      return this.$http
        .get(url)
        .then((response) => {
          this.selectedObjectActivities = response.data[0].activities;
        })
        .catch((error) => console.log(error));
    },
    closeAddActivitiesModal() {
      this.resetSelectedIds();
      this.selectedObjectActivities = [];
      this.isAddActivitiesModalOpened = false;
    },
    openMaterials(index, isReady) {
      if (
        !this.getUser.permissions.isSuperAdmin &&
        !this.getUser.permissions.isAdmin &&
        !this.getUser.permissions.isReporter
      )
        return;

      if (!isReady) {
        this.selectedIds = {
          companyId: this.calendars[index].company._id,
          projectId: this.calendars[index].project._id,
          objectId: this.calendars[index].object._id,
          objectIndex: index,
          type: "materials",
        };
        this.isAddMaterialsModalOpened = true;
        this.getMaterials();
      } else {
        this.$snotify.error(this.$t("already-sent-for-confirmation-materials"));
      }
    },
    getMaterials() {
      let url = `${process.env.VUE_APP_BASE_URI}/company/object/${this.selectedIds.objectId}`;
      return this.$http
        .get(url)
        .then((response) => {
          this.selectedObjectMaterials = response.data[0].materials;
        })
        .catch((error) => console.log(error));
    },
    handleMaterial(m) {
      this.handleUpdate(m);
      // this.addQuantityTo = m;
    },
    closeAddMaterialsModal() {
      this.resetSelectedIds();
      this.selectedObjectMaterials = [];
      this.isAddMaterialsModalOpened = false;
    },
    openMechanizations(index, isReady) {
      if (
        !this.getUser.permissions.isSuperAdmin &&
        !this.getUser.permissions.isAdmin &&
        !this.getUser.permissions.isReporter
      )
        return;

      if (!isReady) {
        this.selectedIds = {
          companyId: this.calendars[index].company._id,
          projectId: this.calendars[index].project._id,
          objectId: this.calendars[index].object._id,
          objectIndex: index,
          type: "mechanizations",
        };
        this.isAddMechanizationsModalOpened = true;
        this.getMechanizations();
      } else {
        this.$snotify.error(
          this.$t("already-sent-for-confirmation-mechanizations")
        );
      }
    },
    getMechanizations() {
      let url = `${process.env.VUE_APP_BASE_URI}/company/object/${this.selectedIds.objectId}`;
      return this.$http
        .get(url)
        .then((response) => {
          this.selectedObjectMechanizations = response.data[0].mechanizations;
        })
        .catch((error) => console.log(error));
    },
    handleActivities(a) {
      this.handleUpdate(a);
      // this.addQuantityTo = a;
    },
    handleMechanization(m) {
      this.handleUpdate(m);
      // this.addQuantityTo = m;
    },
    closeAddMechanizationsModal() {
      this.resetSelectedIds();
      this.selectedObjectMechanizations = [];
      this.isAddMechanizationsModalOpened = false;
    },
    resetSelectedIds() {
      this.selectedIds = {
        companyId: null,
        projectId: null,
        objectId: null,
        objectIndex: null,
        type: null,
      };
    },
    handleUpdate(x) {
      const newCalendar = this.calendars[this.selectedIds.objectIndex];
      if (!newCalendar[this.selectedIds.type])
        newCalendar[this.selectedIds.type] = []; // create if its undefined

      // newCalendar[this.selectedIds.type].push(x.value);
      newCalendar[this.selectedIds.type].push(...x);
      const url = `${process.env.VUE_APP_BASE_URI}/calendar/${newCalendar._id}`;
      this.$http
        .put(url, newCalendar)
        .then((a) => {
          if (a.data.errors) {
            console.log(a.data.errors);
            this.$snotify.error(this.$t("something-went-wrong"));
          } else {
            this.$snotify.success(this.$t("successfully-added-mat-mech-act"));
            this.getCalendars();
            this.closeAddQuantityModal();
            this.resetSelectedIds();
            this.closeAddMaterialsModal();
            this.closeAddMechanizationsModal();
            this.closeAddActivitiesModal();
          }
        })
        .catch((error) => {
          console.log(error.response);
          this.$snotify.error(this.$t("something-went-wrong"));
        });
    },
    closeAddQuantityModal() {
      this.addQuantityTo = null;
    },
    // If we get the 'saveWorkersModal' event, we assign the workers we receive to the report
    deleteItem(selected, type, i) {
      const newCalendar = this.calendars[selected];
      newCalendar[type].splice(i, 1);

      const url = `${process.env.VUE_APP_BASE_URI}/calendar/${newCalendar._id}`;

      this.$http
        .put(url, newCalendar)
        .then((a) => {
          if (a.data.errors) {
            console.log(a.data.errors);
            this.$snotify.error(this.$t("something-went-wrong"));
          } else {
            this.$snotify.success(this.$t("successfully-deleted"));
            this.getCalendars();
            this.closeAddWorkersModal();
          }
        })
        .catch((error) => {
          console.log(error.response);
          this.$snotify.error(this.$t("something-went-wrong"));
        });
    },
    getCalendars() {
      this.$store.dispatch("startLoading");

      let type = "";

      // DAILY
      let firstDate = this.date;
      let secondDate = this.date;
      // WHOLE WEEK
      if (this.activePeriodTab === 1) {
        firstDate = new Date(this.date).GetFirstDayOfWeek();
        secondDate = new Date(this.date).GetLastDayOfWeek();
      }

      type = "day";

      let requestUrl = `${
        process.env.VUE_APP_BASE_URI
      }/calendar/${type}/${this.date.toISOString()}`;

      // BUILD THE REQUEST URL FOR ALL CALENDARS
      if (this.activePeriodTab === 0) {
        requestUrl = `${
          process.env.VUE_APP_BASE_URI
        }/calendar/${type}/${firstDate.toISOString()}`;
      }
      // WEEK
      else if (this.activePeriodTab === 1) {
        requestUrl = `${
          process.env.VUE_APP_BASE_URI
        }/calendar/${type}/${firstDate.toISOString()}/${secondDate.toISOString()}`;
      }

      this.$http
        .get(requestUrl)
        .then((response) => {
          this.$store.dispatch("stopLoading");
          this.calendars = response.data;
        })
        .catch((error) => console.log(error));
    },
    openCopyMoveCalendarModal(type, c) {
      this.CopyMoveCalendarModalAction = type;
      this.CopyMoveCalendarModalCalendar = c;
    },
    closeCopyMoveCalendarModal(e) {
      this.CopyMoveCalendarModalAction = "";
      this.CopyMoveCalendarModalCalendar = undefined;
      if (e.updated) {
        this.getCalendars();
        this.getHighlighted();
      }
    },
    removeCalendar(c) {
      if (c._id) {
        let url = `${process.env.VUE_APP_BASE_URI}/calendar/${c._id}`;
        this.$http
          .delete(url)
          .then((result) => {
            if (result.data.errors) {
              this.$snotify.error(this.$t("something-went-wrong"));
              console.log(result);
            } else {
              this.$snotify.success(this.$t("successfully-deleted"));
              this.getCalendars();
              if (result.data.calendar.objectId) {
                let url = `${process.env.VUE_APP_BASE_URI}/company/object-name/${result.data.calendar.objectId}`;
                return this.$http
                  .get(url)
                  .then((response) => {
                    if (response.data) {
                      let dateString = new Date(result.data.calendar.date);
                      dateString = `${dateString.getDate()} ${
                        this.months[dateString.getMonth()]
                      } ${dateString.getFullYear()}`;
                      this.newLog(
                        "deleteCalendar",
                        `${this.$t("site").toLowerCase()} ${
                          response.data[0].objectName
                        } - ${this.$t("date")} ${dateString}`
                      );
                    }
                  })
                  .catch((error) => console.log(error));
              }
            }
          })
          .catch((error) => {
            console.log(error);
            this.$snotify.error(this.$t("something-went-wrong"));
          });
      }
    },
    // Close the workers modal
    closeAddCompaniesModal() {
      this.isAddCompaniesModalOpened = false;
    },
    closeAddWorkersModal() {
      this.selectedObject = undefined;
    },
    print(i) {
      this.printable = i;
      this.$nextTick(() => {
        window.print();
      });
    },
    getInfo(c, short) {
      let output = `${this.$t("created-at")} ${
        short
          ? this.$options.filters.formatDate(c.createdAt)
          : this.$options.filters.formatFullDate(c.createdAt)
      } ${this.$t("by")} ${c.createdBy.name}${
        short ? "" : ` (${c.createdBy.username})`
      }.`;

      if (c.isReady) {
        output += ` ${this.$t("sent-for-confirmation-at")} ${
          short
            ? this.$options.filters.formatDate(c.readyAt)
            : this.$options.filters.formatFullDate(c.readyAt)
        } ${this.$t("by")} ${c.readyBy.name}${
          short ? "" : ` (${c.readyBy.username})`
        }.`;
      }

      if (c.isConfirmed) {
        output += ` ${this.$t("approved-at")} ${
          short
            ? this.$options.filters.formatDate(c.confirmedAt)
            : this.$options.filters.formatFullDate(c.confirmedAt)
        } ${this.$t("by")} ${c.confirmedBy.name}${
          short ? "" : ` (${c.confirmedBy.username})`
        }.`;
      }

      return output;
    },
    displayInfo(c) {
      this.$snotify.info(this.getInfo(c, true));
    },
    sendForConfirmation(c) {
      if (!c.isReady) {
        if (
          !c.materials.length &&
          !c.workers.length &&
          !c.mechanizations.length &&
          !c.activities.length
        ) {
          this.$snotify.error(this.$t("must-add-at-least-one"));
          return;
        }

        if (confirm(this.$t("confirm-send-for-confirmation"))) {
          const newCalendar = c;
          newCalendar.isReady = true;
          newCalendar.readyBy = this.getUser._id;
          newCalendar.readyAt = new Date();

          this.updateCalendar(
            newCalendar,
            this.$t("sent-for-confirmation"),
            "readyCalendar"
          );
        }
      }
    },
    sendConfirm(c) {
      if (c.isReady) {
        if (confirm(this.$t("confirm-confirmation"))) {
          const newCalendar = c;
          newCalendar.isConfirmed = true;
          newCalendar.confirmedBy = this.getUser._id;
          newCalendar.confirmedAt = new Date();

          this.updateCalendar(
            newCalendar,
            this.$t("request-confirmed"),
            "confirmCalendar"
          );
        }
      }
    },
    updateCalendar(c, message, log) {
      const url = `${process.env.VUE_APP_BASE_URI}/calendar/${c._id}`;
      this.$http
        .put(url, c)
        .then((a) => {
          if (a.data.errors) {
            console.log(a.data.errors);
            this.$snotify.error(this.$t("something-went-wrong"));
          } else {
            this.$snotify.success(message);
            const dateString = `${this.date.getDate()} ${
              this.months[this.date.getMonth()]
            } ${this.date.getFullYear()}`;
            this.newLog(
              log,
              `${this.$t("site").toLowerCase()} ${
                c.object.objectName
              } - ${this.$t("date")} ${dateString}`
            );
            this.getCalendars();
          }
        })
        .catch((error) => {
          console.log(error.response);
          this.$snotify.error(this.$t("something-went-wrong"));
        });
    },
    toggleExport(e) {
      if (!this.allowExport) {
        e.preventDefault();
        this.$snotify.warning(
          "За избрания месец няма създадени отчети или отсъствия"
        );
      }
    },
    getHighlighted() {
      const requestUrl = `${process.env.VUE_APP_BASE_URI}/calendar/highlighted`;
      this.highlightedDates = [];
      this.$http
        .get(requestUrl)
        .then((response) => {
          response.data.dates.map((d) => {
            this.highlightedDates.push(new Date(d));
          });
          if (this.activeContentTab === 3) {
            this.highlighted = {
              dates: [],
            };
          } else {
            if (this.activePeriodTab === 1) {
              this.highlighted = { from: this.firstDay, to: this.lastDay };
            } else {
              this.highlighted = {
                dates: this.highlightedDates,
              };
            }
          }
        })
        .catch((error) => console.log(error));
    },
    scrollH(e) {
      e = window.event || e;
      var delta = Math.max(-1, Math.min(1, e.wheelDelta || -e.detail));
      document.getElementById("rightPanel").scrollLeft -= delta * 40;
      e.preventDefault();
    },
  },
  mounted() {
    const todayCell = document.getElementsByClassName("today")[0];
    if (todayCell) {
      todayCell.click();
    } else {
      this.date = new Date();
    }

    this.fetchData();
    // this.getCalendars();
    // this.getHighlighted();

    if (this.$route.query.plan === "true") {
      this.isAddCompaniesModalOpened = true;
    }

    this.$on("mousewheel", this.scrollH, false);
    // Start listening for children's events
    this.$on("saveAddCompaniesModal", this.handleCompanies);
    this.$on("saveAddUsersModal", this.handleWorkers);
    this.$on("saveAddMaterialsModal", this.handleMaterial);
    this.$on("saveAddMechanizationsModal", this.handleMechanization);
    this.$on("saveAddActivitiesModal", this.handleActivities);
    this.$on("getMaterialsAgain", this.getMaterials);
    this.$on("getMechanizationsAgain", this.getMechanizations);
    this.$on("getActivitiesAgain", this.getActivities);
    this.$on("getMaterialsAgain", this.getMaterials);
    this.$on("getMechanizationsAgain", this.getMechanizations);
    // this.$on("addQuantity", this.handleUpdate);
    // Close listening for children's events
    this.$on("closeAddCompaniesModal", this.closeAddCompaniesModal);
    this.$on("closeAddUsersModal", this.closeAddWorkersModal);
    this.$on("closeAddMaterialsModal", this.closeAddMaterialsModal);
    this.$on("closeAddActivitiesModal", this.closeAddActivitiesModal);
    this.$on("closeAddMechanizationsModal", this.closeAddMechanizationsModal);
    this.$on("closeCopyMoveCalendarModal", this.closeCopyMoveCalendarModal);
    // this.$on("closeAddQuantityModal", this.closeAddQuantityModal);
  },
  destroyed() {
    // Stop listening
    this.$off("mousewheel");
    this.$off("saveAddCompaniesModal");
    this.$off("saveAddUsersModal");
    this.$off("saveAddMaterialsModal");
    this.$off("saveAddMechanizationsModal");
    this.$off("saveAddActivitiesModal");
    this.$off("getActivitiesAgain");
    this.$off("addQuantity");
    this.$off("getMaterialsAgain");
    this.$off("getMechanizationsAgain");
    this.$off("closeAddCompaniesModal");
    this.$off("closeAddUsersModal");
    this.$off("closeAddMaterialsModal");
    this.$off("closeAddActivitiesModal");
    this.$off("closeAddMechanizationsModal");
    this.$off("closeCopyMoveCalendarModal");
    this.$off("closeAddQuantityModal");
  },
};
</script>

<style scoped>
/* IT WILL BE A GOOD IDEA TO REFACTOR THE WHOLE CSS  */
label {
  color: #373737;
  font-size: 11px;
  font-weight: 700;
}
.week {
  background-color: #f0f0f7;
  border: 0;
  text-align: center;
  width: 300px;
}
.calendar-wrapper {
  display: flex;
}
.calendar {
  background-color: #fff;
  padding: 10px 40px;
  transition: all 0.3s;
  width: 100%;
}
.calendar-header {
  align-items: center;
  display: flex;
  height: 36px;
  justify-content: space-between;
}
.calendar-header h2 {
  color: #373737;
  font-size: 20px;
}
.calendar-header-left {
  align-items: center;
  display: flex;
  justify-content: space-between;
  /* width: 300px; */
}
.calendar-header-left label {
  margin-right: 10px;
  white-space: nowrap;
}
.calendar-content {
  background-color: #f3f7fd;
  border: 1px solid #f4eaea;
  border-radius: 4px;
  display: flex;
  margin-top: 10px;
  min-height: calc(100vh - 166px);
  /* max-height: calc(100vh - 166px); */
  padding: 12px 15px;
}
.left-panel {
  display: flex;
  border-right: 1px solid rgba(234, 240, 244, 0.5);
  flex-direction: column;
  margin-right: 10px;
  padding-right: 10px;
  min-width: 22vw;
  width: 22vw;
}
.left-panel-header {
  align-items: center;
  display: flex;
  height: 45px;
  min-height: 45px;
  justify-content: space-between;
  padding-bottom: 10px;
}
.views {
  display: flex;
  justify-content: center;
  width: 100%;
}

.view {
  background-color: #f5f5f5;
  border: 1px solid #d7dae2;
  color: #4d4f5c;
  cursor: pointer;
  font-size: 13px;
  font-weight: 700;
  height: 35px;
  min-width: 80px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  width: 33.33%;
}
.view:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}
.view:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}
.view-active {
  background-color: #0058bc;
  border: 1px solid #0058bc;
  color: #ffffff;
}
.view:hover {
  background-color: #f9f9f9;
}
.view-active:hover {
  background-color: #0058bc;
}
.new-content-data-item {
  border: 1px solid #f4eaea;
  height: 100%;
  overflow: auto;
  position: relative;
  /* max-height: 50vh; */
  width: 100%;
}
.new-content-data-item-toggle {
  background-color: #104d8033;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  font-size: 11px;
  height: 36px;
  min-height: 36px;
  margin-top: 10px;
  width: 100%;
  -webkit-transition: none;
  -moz-transition: none;
  -ms-transition: none;
  -o-transition: none;
  transition: none;
  z-index: 1;
}
.new-content-data-item-toggle:hover {
  background-color: #104d8033;
}
.new-content-data-item-toggle .new-content-data-item-toggle-circle {
  position: absolute;
  right: 10px;
  top: 6px;
}
.add-wrapper {
  width: 100%;
}
.add {
  align-items: center;
  background-color: #3b86ff;
  border: 0;
  border-radius: 4px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  font-size: 11px;
  height: 50px;
  justify-content: space-between;
  /* font-weight: 700; */
  padding: 0 10px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  width: 100%;
}
.add:hover {
  background-color: #5193fd;
}
.add-wrapper span.new-content-data-item-toggle-circle {
  background-color: #ffffff;
  font-size: 16px;
  color: #0078d7;
}
.add-wrapper .new-content-data-item-toggle-circle .tms2 {
  background-color: #ffffff;
  border-color: #ffffff;
}
.add-wrapper:hover .new-content-data-item-toggle-circle .tms2 {
  border-color: #3b86ff;
}
.right-panel {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  width: 100%;
}
.selected-date {
  font-size: 18px;
  padding: 7px 0;
}
.overtime {
  background-color: rgba(252, 252, 229, 0.63);
  border: 1px solid #eaf0f4;
  transform: rotate(270deg);
}
.normal {
  background-color: rgba(232, 245, 233, 0.37);
  border: 1px solid #eaf0f4;
  transform: rotate(270deg);
}
/* WORKERS */
.workers-calendar,
.objects-calendar,
.big-calendar {
  display: flex;
  height: 100%;
}
.workers-calendar-item,
.objects-calendar-item,
.big-calendar-item {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-right: 10px;
  min-width: 400px;
  width: 400px;
}
.workers-calendar-item-header,
.objects-calendar-item-header,
.date-calendar-item-header {
  align-items: center;
  background-color: #104d8033;
  border: 1px solid #f4eaea;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-bottom: 6px;
  max-height: 65px;
  min-height: 65px;
  padding: 10px 0;
  position: relative;
  width: 100%;
}
.workers-calendar-item-header {
  max-height: 35px;
  min-height: 35px;
}
.workers-calendar-item-header p,
.objects-calendar-item-header p,
.date-calendar-item-header p {
  color: #0058bc;
  font-size: 9px;
  font-weight: 400;
  text-align: center;
}
.workers-calendar-item-header p:nth-child(3),
.objects-calendar-item-header p:nth-child(3),
.date-calendar-item-header p:nth-child(3) {
  font-size: 12px;
  font-weight: 700;
}
.workers-calendar-item-content,
.objects-calendar-item-content,
.big-calendar-item-content {
  align-items: center;
  background-color: #fff;
  border: 1px solid #f4eaea;
  border-radius: 4px;
  flex-direction: column;
  display: flex;
  height: 100%;
  justify-content: center;
  overflow: hidden;
  padding: 5px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  position: relative;
  width: 400px;
}
/* .workers-calendar-item-content:hover,
.objects-calendar-item-content:hover,
.big-calendar-item-content:hover {
  background-color: #fff;
} */
.new-content-data-item-content {
  display: flex;
  flex-direction: column;
}
.new-content-data-item-content-no-results {
  height: 100%;
  justify-content: center;
  align-items: center;
}
.new-content-data-item-content p {
  color: #737373;
  font-size: 11px;
  padding: 5px;
}
.add-items-wrapper {
  display: flex;
  align-items: center;
}
.add-items-wrapper:last-child {
  width: 100%;
}
.workers-calendar-item-content-add,
.objects-calendar-item-content-add,
.big-calendar-item-content-add {
  align-items: center;
  display: flex;
  justify-content: space-between;
  height: 40px;
  /* padding: 4px; */
  width: 100%;
}

.objects-calendar-item-content-add-item {
  align-items: center;
  background-color: rgba(121, 119, 116, 0.15);
  border-radius: 4px;
  border: 1px solid transparent;
  display: flex;
  cursor: pointer;
  flex-direction: column;
  height: 40px;
  margin-right: 7px;
  justify-content: center;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  width: 40px;
}
.add-items-wrapper:last-child .objects-calendar-item-content-add-item {
  border: none;
  color: #fff;
  flex-direction: row;
  justify-content: space-between;
  margin-right: 0;
  padding: 0 6px;
  width: 100%;
}
.add-items-wrapper:last-child .objects-calendar-item-content-add-item:hover {
  border: none;
  opacity: 0.8;
}
.button-send {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#6191d8+0,066894+100 */
  background: rgb(97, 145, 216); /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgba(97, 145, 216, 1) 0%,
    rgba(6, 104, 148, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(97, 145, 216, 1) 0%,
    rgba(6, 104, 148, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(97, 145, 216, 1) 0%,
    rgba(6, 104, 148, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#6191d8', endColorstr='#066894',GradientType=0 ); /* IE6-9 */
}
.add-items-wrapper:last-child
  .objects-calendar-item-content-add-item.button-wait,
.add-items-wrapper:last-child
  .objects-calendar-item-content-add-item.button-wait:hover {
  background-color: #fdeada;
  cursor: default;
  justify-content: center;
  opacity: 1;
}
.button-wait span {
  color: #0058bc;
}
.add-items-wrapper:last-child
  .objects-calendar-item-content-add-item.button-confirmed,
.add-items-wrapper:last-child
  .objects-calendar-item-content-add-item.button-confirmed:hover {
  background-color: #e2efd9;
  cursor: default;
  justify-content: center;
  opacity: 1;
}
.button-confirmed span {
  color: #055514;
}
.button-ok {
  /* Permalink - use to edit and share this gradient: https://colorzilla.com/gradient-editor/#068d33+0,3c4a00+100 */
  background: rgb(6, 141, 51); /* Old browsers */
  background: -moz-linear-gradient(
    top,
    rgba(6, 141, 51, 1) 0%,
    rgba(60, 74, 0, 1) 100%
  ); /* FF3.6-15 */
  background: -webkit-linear-gradient(
    top,
    rgba(6, 141, 51, 1) 0%,
    rgba(60, 74, 0, 1) 100%
  ); /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(
    to bottom,
    rgba(6, 141, 51, 1) 0%,
    rgba(60, 74, 0, 1) 100%
  ); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#068d33', endColorstr='#3c4a00',GradientType=0 ); /* IE6-9 */
}
.objects-calendar-item-content-add-item:disabled {
  cursor: default;
  opacity: 0.5;
}
/* .objects-calendar-item-content-add-item:last-child {
  margin-right: 0;
} */
.objects-calendar-item-content-add-item-big {
  height: 100%;
  width: 100%;
}
.objects-calendar-item-content-add-item:hover:not(:disabled) {
  background-color: #fff;
  border-color: #104d8033;
}
.objects-calendar-item-content-add-item-big:hover {
  background: rgba(237, 241, 245, 0.8);
}
.workers-calendar-item-content-add-item .tms,
.workers-calendar-item-content-add-item .tms2,
.objects-calendar-item-content-add-item .tms,
.objects-calendar-item-content-add-item .tms2,
.big-calendar-item-content-add-item .tms,
.big-calendar-item-content-add-item .tms2 {
  align-items: center;
  border-radius: 50%;
  color: #fff;
  display: flex;
  font-size: 16px;
  flex-direction: column;
  height: 34px;
  justify-content: center;
  position: relative;
  width: 34px;
}
.workers-calendar-item-content-add-item .tms::after,
.workers-calendar-item-content-add-item .tms2::after,
.objects-calendar-item-content-add-item .tms::after,
.objects-calendar-item-content-add-item .tms2::after,
.big-calendar-item-content-add-item .tms::after,
.big-calendar-item-content-add-item .tms2::after {
  content: "";
  box-sizing: border-box;
  border: 1px solid transparent;
  border-radius: 50%;
  height: 30px;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  width: 30px;
}
.workers-calendar-item-content:hover .workers-calendar-item-content-add-hidden,
.big-calendar-item-content:hover .big-calendar-item-content-add-hidden {
  background-color: rgba(237, 241, 245, 0.8);
  opacity: 1;
}
.workers-calendar-item-content-add-item:hover .tms::after,
.workers-calendar-item-content-add-item:hover .tms2::after,
.objects-calendar-item-content-add-item:hover .tms::after,
.objects-calendar-item-content-add-item:hover .tms2::after,
.big-calendar-item-content-add-item:hover .tms::after,
.big-calendar-item-content-add-item:hover .tms2::after {
  border: 1px solid #fff;
}
.workers-calendar-item-content-add p,
.objects-calendar-item-content-add p,
.big-calendar-item-content-add p {
  font-size: 10px;
  text-align: center;
}
.no-results-calendar {
  width: 100%;
}
.no-results-calendar .workers-calendar-item {
  width: 100%;
}
.no-results-calendar .workers-calendar-item-content {
  background: #fff;
  cursor: default;
  width: 100%;
}
/* .no-results-calendar .workers-calendar-item-content:hover {
  background-color: transparent;
} */
.companies-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
  justify-content: center;
  width: 100%;
}
.companies-tag,
.personal-tag {
  align-items: center;
  background-color: #104d8033;
  display: flex;
  border-radius: 4px;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  padding: 5px;
  margin-bottom: 5px;
  min-height: 180px;
  width: 100%;
}
.companies-tag-send {
  background-color: #dbe5f1;
}
.companies-tag-wait {
  background-color: #fdeada;
}
.companies-tag-ok {
  background-color: #e2efd9;
}
.personal-tag {
  background-color: #bbdefb;
}
.companies-tag:last-child,
.personal-tag:last-child {
  margin-bottom: 0;
}
.companies-tag p,
.personal-tag p {
  font-size: 10px;
  line-height: 12px;
  opacity: 0.5;
  margin: 3px 0;
  text-align: center;
}
.companies-tag p:nth-child(3),
.personal-tag p:nth-child(3) {
  font-size: 16px;
  line-height: 20px;
  margin: 5px 0 0;
  text-align: center;
  opacity: 1;
}
.list-container,
.big-toggle-wrapper {
  background-color: #104d8033;
  border: 1px solid #f4eaea;
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  height: 33.33%;
  max-height: 25vh;
  margin-bottom: 4px;
  padding-bottom: 10px;
  position: relative;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  /* justify-content: center; */
  width: 100%;
}
.big-toggle-wrapper {
  align-items: center;
  background-color: transparent;
  border: none;
  cursor: default;
  flex-direction: row;
  justify-content: center;
  position: relative;
  padding: 0;
}
.list-container:hover {
  background-color: rgba(255, 255, 255, 0.8);
}
.big-toggle-workers {
  align-items: flex-end;
  padding-bottom: 10px;
}
.big-toggle-mechanizations {
  align-items: flex-start;
  padding-top: 10px;
}
.big-toggle {
  border-radius: 50%;
  background-color: #fff;
  cursor: pointer;
  height: 124px;
  border: 1px dashed #e0d0c1;
  padding: 3px;
  width: 124px;
}
.big-toggle-inner {
  background-color: #104d8033;
  border-radius: 50%;
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: center;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  width: 100%;
}

.big-toggle:hover .big-toggle-inner {
  background-color: #104e806b;
}

.big-toggle-workers .big-toggle-inner {
  background-color: #d9ecd8;
}

.big-toggle-workers .big-toggle:hover .big-toggle-inner {
  background-color: #9cd299;
}

.big-toggle-materials .big-toggle-inner {
  background-color: #d8ddec;
}
.big-toggle-materials .big-toggle:hover .big-toggle-inner {
  background-color: #9cabd6;
}

.big-toggle-mechanizations .big-toggle-inner {
  background-color: #ecddd8;
}

.big-toggle-mechanizations .big-toggle:hover .big-toggle-inner {
  background-color: #dcbbb0;
}

.big-toggle-inner .tms,
.big-toggle-inner .tms2 {
  align-items: center;
  background-color: #fff;
  border-radius: 50%;
  color: #0058bc;
  display: flex;
  font-size: 16px;
  height: 32px;
  justify-content: center;
  margin-bottom: 6px;
  width: 32px;
}
.big-toggle-inner p {
  color: #000;
  font-size: 11px;
  font-weight: 700;
  line-height: 12px;
  text-align: center;
  text-transform: capitalize;
}
.list-container header {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 5px 10px;
}
.list-container header p {
  color: #0058bc;
  font-size: 12px;
  font-weight: 700;
}
.list-container .toggle {
  align-items: center;
  background-color: #0058bc;
  border-radius: 50%;
  color: #fff;
  font-size: 16px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 34px;
  justify-content: center;
  position: absolute;
  right: 5px;
  top: 5px;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
  width: 34px;
}
.list-tag-wrapper {
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: auto;
}
.list-tag-wrapper::-webkit-scrollbar {
  width: 1px;
}

.list-tag-wrapper::-webkit-scrollbar-track {
  background: #ddd;
}

.list-tag-wrapper::-webkit-scrollbar-thumb {
  background: #0058bc;
}

.list-tag {
  align-items: center;
  color: #0058bc;
  font-size: 10px;
  display: flex;
  /* margin: 2px 0; */
  padding: 5px 10px;
  /* justify-content: center; */
  width: 100%;
}
.delete-item {
  cursor: pointer;
  font-size: 12px;
  margin-right: 4px;
}
.delete-item:hover {
  opacity: 0.8;
}
.date-calendar-item-header .date-calendar-objects-count {
  align-items: center;
  background-color: #0058bc;
  border-radius: 15px;
  color: #fff;
  opacity: 1;
  font-weight: 400;
  display: flex;
  justify-content: center;
  margin-top: 5px;
  padding: 7px;
  width: 90%;
}
.date-calendar-item-header .date-calendar-objects-count .tms2 {
  font-size: 14px;
  font-weight: 400;
  margin-right: 5px;
}
.big-calendar-count-toggle {
  align-items: center;
  background-color: #104d80;
  border-radius: 30px;
  color: #fff;
  display: flex;
  justify-content: center;
  margin-top: 10px;
  position: relative;
  padding: 5px 10px;
}
.big-calendar-count-toggle span {
  font-size: 14px;
}
.big-calendar-count-toggle .tms2 {
  font-size: 20px;
  margin-right: 5px;
}
.company {
  align-items: start;
  padding: 4px;
}
.btn {
  margin: 0;
}
.btns {
  display: flex;
}
.btn--help {
  background-color: #5690c9;
  margin-left: 5px;
  width: 30px;
}
.btn--help:hover {
  background-color: #6c9fd3;
}
/* ABSENCES */
.absences-wrapper {
  background-color: #fff;
  border: 1px solid #f4eaea;
  height: 100%;
  padding: 0 10px;
  width: 100%;
}
.absence-row {
  cursor: pointer;
  margin: 10px 0;
  transition: all 0.3s;
}
.absence-row:hover {
  opacity: 0.6;
}
.absence-type {
  align-items: center;
  border-radius: 4px;
  display: inline-flex;
  font-size: 12px;
  font-weight: 500;
  height: 22px;
  justify-content: center;
  line-height: 12px;
  width: 170px;
}
.absence-type--paid {
  background-color: #e1bee7;
  color: #4a148c;
}
.absence-type--unpaid {
  background-color: #cfd8dc;
  color: #263238;
}
.absence-type--illness {
  background-color: #bbdefb;
  color: #0d47a1;
}
.absence-type--compensation {
  background-color: #b2dfdb;
  color: #004d40;
}
.abscence-name {
  display: inline-block;
  margin: 0 20px;
}
/* OK */
.objects-calendar-item-send .objects-calendar-item-header {
  background-color: #b8cce4;
}
.objects-calendar-item-send .list-container {
  background-color: #dbe5f1;
}
.objects-calendar-item-send .objects-calendar-item-header p,
.objects-calendar-item-send .list-container .list-tag,
.objects-calendar-item-send .list-container header p {
  color: #1f497d;
}
.objects-calendar-item-send .list-container .tms.toggle,
.objects-calendar-item-send .list-container .tms2.toggle {
  background-color: #1f497d;
}
.objects-calendar-item-send .list-tag-wrapper::-webkit-scrollbar-thumb {
  background: #1f497d;
}

/* OK */
.objects-calendar-item-ok .objects-calendar-item-header {
  background-color: #c5e0b3;
}
.objects-calendar-item-ok .list-container {
  background-color: #e2efd9;
}
.objects-calendar-item-ok .objects-calendar-item-header p,
.objects-calendar-item-ok .list-container .list-tag,
.objects-calendar-item-ok .list-container header p {
  color: #055514;
}
.objects-calendar-item-ok .list-container .tms.toggle,
.objects-calendar-item-ok .list-container .tms2.toggle {
  background-color: #055514;
}
.objects-calendar-item-ok .list-tag-wrapper::-webkit-scrollbar-thumb {
  background: #055514;
}

/* WAIT */
.objects-calendar-item-wait .objects-calendar-item-header {
  background-color: #fbd5b5;
}
.objects-calendar-item-wait .list-container {
  background-color: #fdeada;
}
.objects-calendar-item-wait .objects-calendar-item-header p,
.objects-calendar-item-wait .list-container .list-tag,
.objects-calendar-item-wait .list-container header p {
  color: #0058bc;
}
.objects-calendar-item-wait .list-container .tms.toggle,
.objects-calendar-item-wait .list-container .tms2.toggle {
  background-color: #0058bc;
}
.objects-calendar-item-wait .list-tag-wrapper::-webkit-scrollbar-thumb {
  background: #0058bc;
}
/* * */
/* PERSONAL */
/* * */
.personal-calendar {
  display: block;
}
.personal-calendar .big-calendar-item {
  width: 100%;
}
.personal-calendar .big-calendar-item-content {
  width: 100%;
}
.personal-calendar .personal-tag {
  align-items: stretch;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  min-height: 150px;
  padding: 20px 10px;
}
.personal-calendar .personal-tag .left {
  border-right: 1px solid #104d80;
  margin-right: 50px;
  padding: 0 8px;
  position: relative;
  width: 50%;
}
.personal-calendar .personal-tag .left p {
  text-align: right;
}
.personal-calendar .personal-tag .right {
  border-left: 1px solid #104d80;
  margin-left: 50px;
  padding: 0 10px;
  position: relative;
  width: 50%;
}
.personal-calendar .personal-tag .right p {
  color: #104d80;
  font-size: 12px;
  line-height: 16px;
  margin: 0;
  opacity: 1;
  text-align: left;
}
.personal-calendar .personal-tag .left::before,
.personal-calendar .personal-tag .right::before {
  color: #104d80;
  content: "P";
  font-family: "tms2";
  font-size: 20px;
  position: absolute;
  top: 0;
}
.personal-calendar .personal-tag ::before {
  right: -30px;
}
.personal-calendar .personal-tag .right::before {
  content: "Q";
  left: -30px;
}
.mobile-inline {
  display: none;
}
.mobile-datepicker {
  display: none;
}
@media screen and (max-width: 1440px) {
  .left-panel {
    min-width: 25vw;
    width: 25vw;
  }
}
@media screen and (max-width: 1366px) {
  .left-panel {
    min-width: 28vw;
    width: 28vw;
  }
}
@media screen and (max-width: 1000px) {
  .desktop {
    display: none;
  }
  .mobile-inline {
    display: inline;
  }
  .calendar {
    padding: 10px 15px;
  }
  .calendar-header {
    position: relative;
  }
  .mobile-datepicker {
    display: inline-flex;
    position: relative;
    width: 100%;
  }
  .mobile-datepicker-toggle {
    align-items: center;
    background-color: #f3f7fd;
    border: 1px solid #d7dae2;
    border-radius: 4px;
    cursor: pointer;
    display: inline-flex;
    justify-content: space-between;
    height: 35px;
    padding: 0 10px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
    width: 100%;
  }
  .mobile-datepicker-toggle:hover {
    background-color: #f9f9f9;
  }
  .mobile-datepicker-toggle span {
    font-size: 12px;
  }
  .mobile-datepicker-toggle .tms {
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    -ms-transition: all 0.3s;
    -o-transition: all 0.3s;
    transition: all 0.3s;
  }
  .mobile-datepicker-toggle .rotate-arrow {
    transform: scale(-1);
  }
  .mobile-datepicker-wrapper {
    position: absolute;
    /* height: 100%; */
    /* left: 50%; */
    top: calc(100% + 10px);
    /* width: 100%; */
    z-index: 1;
  }
  .calendar-header-left {
    margin-left: 10px;
  }
  .no-results-calendar-p {
    font-size: 12px;
    text-align: center;
  }
}
@media screen and (max-width: 600px) {
  .mobile-datepicker {
    position: initial;
  }
  .view {
    min-width: 40px;
  }
  .personal-calendar .personal-tag {
    justify-content: center;
    flex-direction: column;
  }
  .personal-calendar .personal-tag .left,
  .personal-calendar .personal-tag .right {
    border-left: 1px solid #104d89;
    border-right: none;
    margin: 0 0 0 35px;
    width: calc(100% - 35px);
  }
  .personal-calendar .personal-tag .left {
    margin-bottom: 10px;
  }
  .personal-calendar .personal-tag .left p,
  .personal-calendar .personal-tag .right p {
    text-align: left;
  }
  .personal-calendar .personal-tag .left::before,
  .personal-calendar .personal-tag .right::before {
    right: initial;
    left: -30px;
  }
  .companies-tag p,
  .personal-tag p {
    font-size: 9px;
    margin: 0;
  }
  .companies-tag p:nth-child(3),
  .personal-tag p:nth-child(3) {
    font-size: 13px;
    margin: 0;
  }
  .personal-calendar .personal-tag .right p {
    font-size: 11px;
    line-height: 14px;
  }
  .no-results-calendar-p {
    font-size: 10px;
  }
}
@media screen and (max-width: 440px) {
  .mobile-datepicker-toggle span {
    font-size: 10px;
  }
  .personal-calendar .personal-tag .left::before,
  .personal-calendar .personal-tag .right::before {
    font-size: 15px;
  }
  .personal-calendar .personal-tag .left,
  .personal-calendar .personal-tag .right {
    width: calc(100% - 25px);
    margin-left: 25px;
  }
  .personal-calendar .personal-tag .left::before,
  .personal-calendar .personal-tag .right::before {
    left: -25px;
  }
}

@media print {
  .calendar-header,
  .left-panel,
  .objects-calendar-item-content-add,
  .big-toggle-wrapper,
  .objects-calendar-item:not(.objects-calendar-item-printable),
  .delete-item {
    display: none;
  }
  .calendar {
    padding: 0;
  }
  .calendar-content {
    align-items: flex-start;
    background: none;
    border: none;
    margin: 0;
    padding: 0;
  }
  .objects-calendar-item-content {
    border: none;
    padding: 0;
    width: 100%;
  }
  .list-container {
    height: initial;
    max-height: initial;
  }
  .objects-calendar-item-printable {
    margin: 0;
    width: 100%;
  }
}
</style>
