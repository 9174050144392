<template>
  <div class="own-reports">
    <div class="own-reports-header">
      <h2 v-if="!reports.length">{{ $t('my-reports') }}</h2>
      <h2 v-else>{{ $t('my-last-reports') }}</h2>
      <div class="btns">
        <button class="btn btn-new" @click="newReport()">
          <span class="tms">H</span>
          {{ $t('new-report') }}
        </button>
        <button class="btn btn-excel" v-if="reports.length" @click="exportFile()">
          <span class="tms">E</span>
          {{ $t('export-to-excel') }}
        </button>
      </div>
    </div>
    <div class="table-wrapper">
      <p v-if="!reports.length" class="no-reports">{{ $t('no-own-reports') }}</p>
      <table v-else class="table" ref="table">
        <thead>
          <tr>
            <th>{{ $t('date') | capitalize }}</th>
            <th>{{ $t('company') }}</th>
            <th>{{ $t('project') }}</th>
            <th>{{ $t('site') }}</th>
            <th>{{ $t('part') }}</th>
           <th class="center">{{ $t('reported-workers') }}</th>
            <!--  <th class="center">Общо отчетено време</th>-->
            <th class="center">{{ $t('reported') }} {{ $t('materials') }}</th>
            <th class="center">{{ $t('reported') }} {{ $t('activities') }}</th>
            <th class="center">{{ $t('reported') }} {{ $t('mechanizations') }}</th>
            <th v-if="getUser.permissions.isSuperAdmin || getUser.permissions.isAdmin"></th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="r in searchFilter()"
            :key="`findReport-${r._id}`"
            @click="viewReport(r._id)"
            :class="{ 'tr-invalid' : r.isInvalid }"
          >
            <td>
              <span class="no-wrap">{{r.date | formatDate}} г.</span>
            </td>
            <td class="td-company-name">
              <img
                class="td-invalid-flag"
                v-if="r.isInvalid"
                src="../assets/img/flag_yes.svg"
                alt="invalid"
              />
              {{r.company.companyName}}
            </td>
            <td>{{r.project.projectName}}</td>
            <td>{{r.object.objectName}}</td>
            <td>
              <span v-if="r.part">{{r.part.partName}}</span>
            </td>
            <td class="center">{{r.workers.length}}</td>
            <td class="center">{{r.materials.length}}</td>
            <td class="center">{{r.activities.length}}</td>
            <td class="center">{{r.mechanizations.length}}</td>
            <td v-if="getUser.permissions.isSuperAdmin || getUser.permissions.isAdmin">
              <img
                class="flag-indicator"
                v-if="r.priceStatus === 2"
                src="../assets/img/green.svg"
                :alt="$t('all-amounts-entered')"
                :title="$t('all-amounts-entered')"
              />
              <img
                class="flag-indicator"
                v-else-if="r.priceStatus === 1"
                src="../assets/img/yellow.svg"
                :alt="$t('missing-amounts-entered')"
                :title="$t('missing-amounts-entered')"
              />
              <img
                class="flag-indicator"
                v-else-if="r.priceStatus === 0"
                src="../assets/img/red.svg"
                :alt="$t('no-amounts-entered')"
                :title="$t('no-amounts-entered')"
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- BUTTONS FOR MOBILE -->
    <footer class="modal-footer mobile">
      <div
        class="new-content-data-item-toggle-circle mobile excel"
        v-if="this.reports.length"
        @click="exportFile()"
      >
        <span class="tms">E</span>
      </div>
      <div class="new-content-data-item-toggle-circle mobile" @click="newReport()">
        <span class="tms">L</span>
      </div>
    </footer>
  </div>
</template>

<script>
import hours from './mixins/hours.js';
import { mapGetters } from 'vuex';
import XLSX from 'xlsx';

export default {
  mixins: [hours],
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      'getUser'
      // ...
    ])
  },
  data() {
    return {
      reports: [],
      search: ''
    };
  },
  methods: {
    // Filter reports by date, companyName, projectName or objectName
    searchFilter() {
      return this.reports.filter(r => {
        return (
          r.date.toLowerCase().includes(this.search.toLowerCase()) ||
          r.company.companyName
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          r.project.projectName
            .toLowerCase()
            .includes(this.search.toLowerCase()) ||
          r.object.objectName.toLowerCase().includes(this.search.toLowerCase())
          // || r.part.partName.toLowerCase().includes(this.search.toLowerCase())
        );
      });
    },
    viewReport(id) {
      this.$router.push(`/reports/edit/${id}`);
    },
    getStatuses() {
      if (this.reports.length) {
        this.reports.forEach(r => {
          let status = 2;
          let total = 0;

          for (let tag of ['materials', 'activities', 'mechanizations']) {
            if (r[tag]) {
              r[tag].forEach(x => {
                total += +x.price;
                if (!x.price) {
                  status = 1;
                }
              });
            }
          }

          if (!total) {
            status = 0;
          }

          r.priceStatus = status;
        });
      }
    },
    getReports() {
      this.$store.dispatch('startLoading');
      let id = this.getUser._id;
      let url = `${process.env.VUE_APP_BASE_URI}/report/myreports/${id}`;
      this.$http
        .get(url)
        .then(response => {
          this.reports = response.data;
          this.$store.dispatch('stopLoading');
          if (
            this.getUser.permissions.isSuperAdmin ||
            this.getUser.permissions.isAdmin
          ) {
            this.getStatuses();
          }
        })
        .catch(error => console.log(error));
    },
    newReport() {
      this.$router.push('/reports/new');
    },
    // Export To Excel
    exportFile() {
      let worksheet = XLSX.utils.table_to_sheet(this.$refs.table);
      let new_workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(new_workbook, worksheet, `${this.$t('reports')}`);
      XLSX.writeFile(new_workbook, `${this.$t('report')}-${Date.now()}.xlsx`);
    }
  },
  mounted() {
    this.getReports();
  },
  updated() {
    // Handle search input
    this.$root.$on('sendSearchInput', data => {
      this.search = data;
      // console.log(this.search);
    });
  },
  destroyed() {
    this.$root.$off('sendSearchInput');
  }
};
</script>

<style scoped>
.own-reports {
  padding: 10px 40px;
}
.own-reports-header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}
.table-wrapper {
  overflow: auto;
}
.desktop {
  display: flex;
}
.mobile {
  display: none;
}
.no-reports {
  background-color: #fff;
  font-size: 18px;
  text-align: center;
  padding: 20px;
}
tr td:last-child {
  width: 1%;
}
/* Responsive */
@media screen and (max-width: 1000px) {
  .mobile {
    display: flex;
  }
  .table-wrapper {
    overflow: auto;
  }
  .own-reports {
    padding: 10px 15px;
  }
  .own-reports-header {
    display: none;
  }
  .btns.mobile {
    position: fixed;
    display: flex;
    float: left;
    flex-direction: column-reverse;
    justify-items: flex-end;
  }
  .new-content-data-item-toggle-circle {
    width: 50px;
    height: 50px;
  }
  .new-content-data-item-toggle-circle.mobile {
    bottom: 20px;
    cursor: pointer;
    position: fixed;
    right: 20px;
  }
  .new-content-data-item-toggle-circle.mobile.excel {
    background-color: green;
    bottom: 75px;
    cursor: pointer;
    right: 20px;
    position: fixed;
  }
  .new-content-data-item-toggle-circle .tms {
    border: 0px;
    font-size: 20px;
  }
  .modal-footer {
    background-color: #fff;
    height: 125px;
    padding: 0px;
  }
}
</style>
