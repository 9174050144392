<template>
  <label
    class="checkbox"
    @click.stop
    :class="{ 'checkbox--radio': radio }"
  >
    <input
      :class="{ checked: value }"
      type="checkbox"
      :checked="value"
      :disabled="disabled"
      @change="$emit('input', $event.target.checked)"
    />
    <span class="checkmark"></span>
    <!-- slot is used for icons -->
    <slot></slot> 
    <span class="label" v-if="label">{{ label }}</span>
  </label>
</template>

<script>
export default {
  props: ["value", "label", "disabled", "radio", "locked"],
};
</script>
<style lang="scss" scoped>
.checkbox {
  align-items: center;
  cursor: pointer;
  display: inline-flex;
  height: 20px;
  gap: 5px;
  position: relative;
  padding-left: 30px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &.checkbox--radio {
    .checkmark {
      border-radius: 50%;
    }
    input.checked ~ .checkmark:after {
      display: none;
    }
  }

  input {
    accent-color: #0058bc;
    cursor: pointer;
    height: 0;
    opacity: 0;
    position: absolute;
    width: 0;
    transition: 0.4s;
  }
  input.checked ~ .checkmark:after {
    display: block;
  }

  .checkmark {
    border: 1px solid #0058bc;
    border-radius: 4px;
    height: 20px;
    left: 0;
    position: absolute;
    top: 0;
    width: 20px;
    transition: 0.4s;

    &::after {
      content: "";
      display: none;
      position: absolute;
      border: solid #fff;
      border-width: 0 2px 2px 0;
      height: 10px;
      left: 6px;
      top: 2px;
      -webkit-transform: rotate(45deg);
      -ms-transform: rotate(45deg);
      transform: rotate(45deg);
      width: 5px;
    }
  }

  &:hover input ~ .checkmark {
    background-color: #0058bc1a;
  }
  input.checked ~ .checkmark {
    background-color: #0058bc;
  }
  input.checked ~ .checkmark-red {
    background-color: #bf392a;
  }
  .label{
    font-size: 12px;
  }
}
</style>
