<template>
  <div class="users-companies-wrapper">
    <!-- HEADER WITH SWITCH AND NEW USER BUTTON -->
    <header>
      <div class="user-types">
        <h2>{{ $t("assets") }}</h2>
      </div>

      <!-- NEW USER BUTTON -->
      <section class="add-new-users-wrapper">
        <button
          class="btn btn-new desktop"
          @click="isAssetModalOpen = !isAssetModalOpen"
        >
          {{ $t("add") }}
        </button>
      </section>
    </header>
    <div class="table-wrapper desktop">
      <table class="table">
        <thead>
          <tr>
            <th>ИМЕ</th>
            <th>НОМЕР</th>
            <th>ЛОКАЦИЯ</th>
            <th>ЗАСТРАХОВКА</th>
            <th>ГТП</th>
            <th>ВИНЕТКА</th>
            <th>КИЛОМЕТРИ</th>
            <th class="th--big">ЗАБЕЛЕЖКА</th>
            <th>СТАТУС</th>
          </tr>
        </thead>
        <tbody>
          <template v-if="assets.length">
            <tr
              v-for="asset of searchFilter()"
              :key="asset._id"
              @click="editAsset(asset)"
            >
              <td class="td-user-name">
                <span class="tms2">H</span>
                <span>
                  <strong>{{ asset.name }}</strong>
                </span>
              </td>
              <td>{{ asset.numberPlate }}</td>
              <td>{{ asset.location }}</td>
              <td>{{ asset.insurance | formatDate }}</td>
              <td>{{ asset.gtp | formatDate }}</td>
              <td>{{ asset.vignette | formatDate }}</td>
              <td>{{ asset.mileage }}</td>
              <td>{{ asset.note }}</td>
              <td class="td-widthless">
                <span
                  class="permission-indicator status-indicator"
                  :class="{ 'status-indicator--green': asset.isActive }"
                  >{{ asset.isActive ? $t("active-status") : $t("inactive-status") }}</span
                >
              </td>
            </tr>
          </template>
          <tr v-else>
            <td colspan="9" class="td--center">{{ $t("no-results") }}</td>
          </tr>
        </tbody>
      </table>
    </div>

    <footer class="modal-footer mobile">
      <div
        class="new-content-data-item-toggle-circle mobile"
        @click="isAssetModalOpen = !isAssetModalOpen"
      >
        <span class="tms">V</span>
      </div>
    </footer>
    <transition name="fade">
      <AssetModal
        v-if="isAssetModalOpen"
        :idToEdit="idToEdit"
        @close="closeAssetModal"
      />
    </transition>
  </div>
</template>

<script>
import AssetModal from "./shared/AssetModal";
import ClickOutside from "vue-click-outside";
import { mapGetters } from "vuex";
export default {
  components: {
    AssetModal,
  },
  data() {
    return {
      showActiveUsers: true,
      isAssetModalOpen: false,
      idToEdit: "",
      assets: [],
      search: "",
    };
  },
  directives: {
    ClickOutside,
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      "getUser",
      // ...
    ]),
  },
  methods: {
    // Filter assets for searching by name
    searchFilter() {
      return this.assets.filter((u) => {
        return u.name.toLowerCase().includes(this.search.toLowerCase());
      });
    },
    editAsset(u) {
      this.idToEdit = u._id;
      this.isAssetModalOpen = true;
    },
    getAssets() {
      this.$store.dispatch("startLoading");
      this.$http
        .get(`${process.env.VUE_APP_BASE_URI}/asset`)
        .then((response) => {
          this.assets = response.data;
          this.$store.dispatch("stopLoading");
        });
    },
    closeAssetModal(update) {
      this.isAssetModalOpen = false;
      this.idToEdit = "";
      if (update) this.getAssets();
    },
  },
  mounted() {
    this.getAssets();

    this.$on("closeAssetModal", (result) => {
      this.isAssetModalOpen = "";
      this.idToEdit = "";
      // if result.isEdited = true, this means something is changed and we get the users again
      if (result.isEdited) {
        this.assets = [];
        this.getAssets();
      }
    });
  },
  updated() {
    // Handle search input
    this.$root.$on("sendSearchInput", (data) => {
      this.search = data;
      // console.log(this.search);
    });
  },
  destroyed() {
    this.$off("closeAssetModal");
    this.$root.$off("sendSearchInput");
  },
};
</script>

<style scoped lang='scss'>
</style>
