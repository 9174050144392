import Holidays from "../../assets/holidays.json"
export default {
  data() {
    return {
      hours: [
        '00:00',
        '00:30',
        '01:00',
        '01:30',
        '02:00',
        '02:30',
        '03:00',
        '03:30',
        '04:00',
        '04:30',
        '05:00',
        '05:30',
        '06:00',
        '06:30',
        '07:00',
        '07:30',
        '08:00',
        '08:30',
        '09:00',
        '09:30',
        '10:00',
        '10:30',
        '11:00',
        '11:30',
        '12:00',
        '12:30',
        '13:00',
        '13:30',
        '14:00',
        '14:30',
        '15:00',
        '15:30',
        '16:00',
        '16:30',
        '17:00',
        '17:30',
        '18:00',
        '18:30',
        '19:00',
        '19:30',
        '20:00',
        '20:30',
        '21:00',
        '21:30',
        '22:00',
        '22:30',
        '23:00',
        '23:30',
        '24:00'
      ],
      holidays: Holidays
    }
  },
  methods: {
    calculateHours(day, startHour, endHour) {
      let normal = 0;
      let overtime = 0;
      let holiday = 0;
      let total = 0;
      const startIndex = this.hours.indexOf(startHour)
      const endIndex = this.hours.indexOf(endHour)

      if (day && startHour && endHour) {
        const date = new Date(day)
        // Determine if it's holiday from the predefined array of days
        const isHoliday = this.holidays.indexOf(`${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`) > -1;
        // Saturday or Sunday
        const isWeekend = date.getDay() === 0 || date.getDay() === 6;

        if (isHoliday) {
          for (let i = startIndex; i < endIndex; i++) {
            if (i !== 24 && i !== 25) {
              holiday += 0.5;
              total += 0.5;
            }
          }
        } else {
          if (isWeekend) {
            // loop trough hours from start to end
            for (let i = startIndex; i < endIndex; i++) {
              if (i !== 24 && i !== 25) {
                overtime += 0.5;
                total += 0.5;
              }
            }
          } else {
            // loop trough hours from start to end
            for (let i = startIndex; i < endIndex; i++) {
              // NORMAL WORKING TIME
              // index 16 = 08:00 (start from 08:00)
              // index 24 = 12:00
              // index 25 = 12:30 (start from 13:00)
              // index 34 = 17:00
              if (i > 15 && i < 34) {
                if (i !== 24 && i !== 25) {
                  normal += 0.5;
                }
              }
              // EVERYTHING ELSE IS OVERTIME
              else {
                overtime += 0.5;
              }

              if (i !== 24 && i !== 25) {
                total += 0.5;
              }
            }
          }
        }
      }

      return {
        normal: normal,
        overtime: overtime,
        holiday: holiday,
        total: total
      }
    }
  }
}