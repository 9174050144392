import {
  mapGetters
} from "vuex";
export default {
  data() {
    return {
      activity: {
        newCompany: this.$t('log-company-new'),
        editCompany: this.$t('log-company-edit'),
        newUser: this.$t('log-user-new'),
        editUser: this.$t('log-user-edit'),
        newReport: this.$t('log-report-new'),
        editReport: this.$t('log-report-edit'),
        newCalendar: this.$t('log-calendar-new'),
        deleteCalendar: this.$t('log-calendar-delete'),
        confirmCalendar: this.$t('log-calendar-confirm'),
        readyCalendar: this.$t('log-calendar-ready')
      }
    }
  },
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      "getUser"
      // ...
    ])
  },
  methods: {
    newLog(activity, additional) {
      const info = additional || '';
      if (this.activity[activity]) {
        let url = `${process.env.VUE_APP_BASE_URI}/log`;
        let log = {
          activity: `${this.activity[activity]} - ${info}`,
          createdBy: this.getUser._id
        }
        this.$http
          .post(url, log)
          .then(a => {
            if (a.data.errors) {
              console.log(a.data.errors)
            } else {
              console.log('Logged')
            }
          })
          .catch(error => {
            console.log(error.response);
          });
      }
    }
  }
}