<template>
  <div class="modal-wrapper">
    <div class="modal">
      <div class="modal-header">
        <p>
          {{ $t("edit-absence") }}
          <strong>{{ absence.user.name }}</strong>
        </p>
        <span class="tms modal-close" @click="close()">F</span>
      </div>
      <div class="modal-content">
        <template v-if="loaded">
          <div class="row">
            <p>{{ $t("absence-dates") }}:</p>
            <div class="dates-wrapper">
              <div class="date-input" @click="openSelectDateModal('from')">
                <p>
                  <span class="tms2">R</span>
                  {{ $t("start") }}:&nbsp;
                  <strong>{{ absence.from | formatDate }}</strong>
                </p>
                <span class="tms">c</span>
              </div>
              <div class="date-input" @click="openSelectDateModal('to')">
                <p>
                  <span class="tms2">R</span>
                  {{ $t("end") }}:&nbsp;
                  <strong>{{ absence.to | formatDate }}</strong>
                </p>
                <span class="tms">c</span>
              </div>
            </div>
          </div>
          <div class="row">
            <p>{{ $t("absence-type") }}:</p>
            <div class="type-toggle-wrapper">
              <div
                class="type-toggle type-toggle--paid"
                :class="{ 'type-toggle--active': absence.type === 'paid' }"
                @click="absence.type = 'paid'"
              >
                {{ $t("absence-paid") }}
              </div>
              <div
                class="type-toggle type-toggle--unpaid"
                :class="{ 'type-toggle--active': absence.type === 'unpaid' }"
                @click="absence.type = 'unpaid'"
              >
                {{ $t("absence-unpaid") }}
              </div>
              <div
                class="type-toggle type-toggle--illness"
                :class="{ 'type-toggle--active': absence.type === 'illness' }"
                @click="absence.type = 'illness'"
              >
                {{ $t("absence-illness") }}
              </div>
              <div
                class="type-toggle type-toggle--compensation"
                :class="{
                  'type-toggle--active': absence.type === 'compensation',
                }"
                @click="absence.type = 'compensation'"
              >
                {{ $t("absence-compensation") }}
              </div>
            </div>
          </div>
        </template>
        <p class="modal-content-no-results" v-else>{{ $t("loading") }}</p>
      </div>
      <div class="modal-footer">
        <button class="btn btn-delete2" @click="remove()" :disabled="loading">
          <span class="tms2">.</span>
          {{ $t("delete") }}
        </button>
        <button class="btn btn-new" @click="save()" :disabled="loading">
          <span class="tms">Y</span>
          {{ $t("save") }}
        </button>
      </div>
    </div>
    <transition name="fade">
      <SelectDateModal
        v-if="isSelectDateModalOpen"
        :title="selectedDateName === 'from' ? $t('start') : $t('end')"
        :selected="selectedDate"
        @save="selectedDateInModal"
        @close="closeSelectDateModal"
      />
    </transition>
  </div>
</template>

<script>
import SelectDateModal from "./SelectDateModal";
export default {
  props: ["id"],
  components: {
    SelectDateModal,
  },
  data() {
    return {
      isSelectDateModalOpen: false,
      selectedDateName: null,
      selectedDate: null,
      loaded: false,
      loading: false,
      valid: true,
      absence: {
        user: "",
        from: null,
        to: null,
        type: "",
      },
    };
  },
  methods: {
    getAbsence() {
      this.loaded = false;
      this.$http
        .get(`${process.env.VUE_APP_BASE_URI}/absence/${this.id}`)
        .then((response) => {
          this.absence = response.data;

          this.loaded = true;
        });
    },
    newDate(d) {
      let date = new Date();
      if (d) {
        date = new Date(d);
      }

      date.setHours(12, 0, 0, 0);
      return date;
    },
    openSelectDateModal(d) {
      this.selectedDateName = d;
      this.selectedDate = this.absence[d];
      this.isSelectDateModalOpen = true;
    },
    selectedDateInModal(d) {
      const date = this.newDate(d);
      if (this.selectedDateName === "from" && date > this.absence.to) {
        return this.$snotify.error(`${this.$T('start-error')}`);
      } else if (this.selectedDateName === "to" && date < this.absence.from) {
        return this.$snotify.error(
         `${this.$T('end-error')}`
        );
      }

      this.$set(this.absence, this.selectedDateName, d);

      this.validateAbsence();

      this.closeSelectDateModal();
    },
    closeSelectDateModal() {
      this.isSelectDateModalOpen = false;
      this.selectedDateName = null;
      this.selectedDate = null;
    },
    close(update) {
      this.$emit("close", update);
    },
    validateAbsence() {
      this.loading = true;
      const url = `${process.env.VUE_APP_BASE_URI}/absence/validate?ignore=${this.id}`;

      const abs = {
        user: this.absence.user._id,
        from: this.absence.from,
        to: this.absence.to,
      };

      this.$http
        .post(url, abs)
        .then((res) => {
          this.valid = res.data.valid;

          if (!res.data.valid) {
            this.$snotify.error(
              `${this.absence.user.name} ${this.$t('is-planned-or-reported')}`
            );
          }
        })
        .catch((error) => {
          console.log(error);
        })
        .then(() => (this.loading = false));
    },
    isValid() {
      if (!this.valid) {
        this.$snotify.error(`${this.$t('change-period-to-absence')}`);
        return false;
      }

      return true;
    },
    save() {
      if (!this.isValid()) {
        return;
      }

      const url = `${process.env.VUE_APP_BASE_URI}/absence`;
      this.$http
        .put(url, this.absence)
        .then(() => {
          this.$snotify.success(`${this.$t('successfully-edited-absence')}`);
          this.close(true);
        })
        .catch((error) => {
          console.log(error);
          this.$snotify.error(`${this.$t('something-went-wrong')}`);
        });
    },
    remove() {
      if (confirm(`${this.$t('confirm-absence-deletion')}`)) {
        const url = `${process.env.VUE_APP_BASE_URI}/absence/${this.id}`;
        this.$http
          .delete(url)
          .then(() => {
            this.$snotify.success(`${this.$t('successfully-deleted-absence')}`);
            this.close(true);
          })
          .catch((error) => {
            console.log(error);
            this.$snotify.error(`${this.$t('something-went-wrong')}`);
          });
      }
    },
    handleEvents(e) {
      if (e.code === "Enter") {
        // this.save();
      } else if (e.code === "Escape") {
        this.close();
      }
    },
  },
  mounted() {
    this.getAbsence();
    window.addEventListener("keydown", this.handleEvents);
  },
  destroyed() {
    window.removeEventListener("keydown", this.handleEvents);
  },
};
</script>

<style scoped>
.modal {
  width: 650px;
}
.modal-header {
  /* align-items: flex-start; */
  color: white;
  justify-content: space-between;
  padding: 20px;
  padding-right: 50px;
}
.modal-content {
  flex-direction: column;
  padding: 10px 20px 20px;
}
.row {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
}
.dates-wrapper {
  display: flex;
  margin-left: 20px;
}
.date-input {
  align-items: center;
  background-color: #f5f5f5;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  font-size: 14px;
  height: 47px;
  justify-content: space-between;
  padding: 10px;
  width: 212px;
}
.date-input:first-child {
  margin-right: 11px;
}
.date-input:hover {
  opacity: 0.8;
}
.date-input p {
  display: flex;
  align-items: center;
}
.date-input .tms2 {
  font-size: 16px;
  line-height: 16px;
  margin-right: 10px;
  vertical-align: middle;
}
.date-input .tms {
  color: #ec6607;
}
.type-toggle-wrapper {
  align-items: center;
  display: flex;
}
.type-toggle {
  align-items: center;
  background-color: rgba(143, 143, 143, 0.2);
  border-radius: 4px;
  color: #333;
  cursor: pointer;
  display: inline-flex;
  font-size: 12px;
  font-weight: 700;
  height: 39px;
  justify-content: center;
  line-height: 12px;
  margin-right: 5px;
  text-align: center;
  width: 105px;
  transition: all 0.3s;
}
.type-toggle:last-child {
  margin: 0;
}

.type-toggle--paid {
  background-color: rgba(225, 190, 231, 0.3);
}
.type-toggle--unpaid {
  background-color: rgba(177, 177, 177, 0.3);
}
.type-toggle--illness {
  background-color: rgba(187, 222, 251, 0.3);
}
.type-toggle--compensation {
  background-color: rgba(178, 223, 219, 0.3);
}

.type-toggle--active.type-toggle--paid,
.type-toggle--paid:hover {
  background-color: #e1bee7;
}
.type-toggle--active.type-toggle--unpaid,
.type-toggle--unpaid:hover {
  background-color: #b1b1b1;
}
.type-toggle--active.type-toggle--illness,
.type-toggle--illness:hover {
  background-color: #bbdefb;
}
.type-toggle--active.type-toggle--compensation,
.type-toggle--compensation:hover {
  background-color: #b2dfdb;
}
/* .type-toggle--active:hover {
  opacity: 0.8;
} */
.modal-footer {
  background: rgb(51, 51, 51, 0.2);
  justify-content: center;
}
</style>
