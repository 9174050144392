<template>
  <div class="user-modal-wrapper">
    <div
      class="user-modal"
      :class="{ 'user-modal-small': limitedFunctionality }"
    >
      <form>
        <!-- USERNAME -->
        <label v-if="userMode" for="userModalUsername" class="input-label">{{
          $t("username")
        }}</label>
        <div
          v-if="userMode"
          class="input-wrapper"
          :class="{ invalid: !isUsernameValid }"
        >
          <span class="tms">B</span>
          <input
            id="userModalUsername"
            type="text"
            :placeholder="$t('username')"
            v-model="user.username"
            @keyup="(isUsernameValid = true), formatUsername()"
            :disabled="limitedFunctionality"
            autocomplete="false"
          />
          <span v-if="limitedFunctionality" class="disabled">A</span>
        </div>
        <!-- PASSWORD -->
        <label v-if="userMode" for="userModalPassword" class="input-label">{{
          $t("password")
        }}</label>
        <div
          v-if="userMode"
          class="input-wrapper"
          :class="{ invalid: !isPasswordValid }"
        >
          <span class="tms">A</span>
          <input
            id="userModalPassword"
            type="password"
            :placeholder="$t('password')"
            v-model="user.password"
            @keyup="isPasswordValid = true"
            autocomplete="false"
          />
        </div>
        <!-- EMAIL -->
        <label for="userModalEmail" class="input-label">E-mail</label>
        <div class="input-wrapper" :class="{ invalid: !isEmailValid }">
          <span class="tms2">G</span>
          <input
            id="userModalEmail"
            placeholder="E-mail"
            v-model="user.email"
            @keyup="isEmailValid = true"
          />
        </div>
        <!-- PHONE -->
        <label for="userModalPhone" class="input-label">{{
          $t("phone")
        }}</label>
        <div class="input-wrapper">
          <span class="tms2">G</span>
          <input
            id="userModalPhone"
            :placeholder="$t('phone')"
            v-model="user.phone"
          />
        </div>
        <!-- NAME -->
        <label for="userModalName" class="input-label">{{ $t("name") }}</label>
        <div class="input-wrapper" :class="{ invalid: !isNameValid }">
          <span class="tms">B</span>
          <input
            id="userModalName"
            type="text"
            :placeholder="$t('name')"
            v-model="user.name"
            @keyup="(isNameValid = true), formatName()"
          />
        </div>
        <!-- <div class="new-input-wrapper desktop" v-if="user.group && !user.permissions.isWorker">
          <label class="input-label">{{ $t('group') }}</label>
          <div class="new-input">
            <span class="tms2">D</span>
            NOT SELECTED
            <input v-if="!user.group._id" type="text" :placeholder="$t('group-name')"
              @focus="openDropdown(1)" @blur="closeDropdown(1)" v-model="searchGroups" />
            SELECTED
            <input v-else type="text" :placeholder="$t('group-name')" @focus="openDropdown(1)"
              v-model="user.group.name" @blur="closeDropdown(1)" :disabled="user.group._id" />
            <span v-if="user.group._id" class="clear" @click="resetSelectedGroup()">F</span>
            <span v-else class="arrow" :class="{ 'arrow-reversed': openedDropdown[1] }">K</span>
          </div>
          DROP-DOWN COMPANIES
          <div class="new-input-dropdown" :class="{ 'new-input-dropdown-active': openedDropdown[1] }">
            <ul v-if="groups.length">
              <li v-for="g in filteredGroups" :key="g._id" @click="selectGroup(g)">{{ g.name }}</li>
              <li class="no-results" v-if="searchGroups && !filteredGroups.length">
                {{ $t('no-group') }}.
                <br />{{ $t('pre-added-groups') }}
              </li>
            </ul>
          </div>
        </div> -->
        <template v-if="!limitedFunctionality && userMode">
          <!-- POSITION -->
          <label for="userModalPosition" class="input-label">{{
            $t("position")
          }}</label>
          <div class="input-wrapper">
            <span class="tms2">Q</span>
            <input
              id="userModalPosition"
              type="text"
              :placeholder="$t('position')"
              v-model="user.position"
            />
          </div>
          <!-- COMPANY -->
          <!-- <div class="new-input-wrapper">
            <label class="input-label" for="userModalCompany">{{ $t('company') }}</label>
            <div class="new-input">
              <span class="tms2">P</span>
              <input
                v-if="!user.company"
                type="text"
                id="userModalCompany"
                v-model="user.company"
                :placeholder="$t('company')"
                @focus="selectCompany = true"
                @blur="selectCompany = false"
              />
              <input
                v-else
                type="text"
                id="userModalCompany"
                :value="user.company"
                :placeholder="$t('company')"
                disabled
              />
              <span v-if="user.company" class="clear" @click="clearCompany()">F</span>
            </div>
            <div
              class="new-input-dropdown"
              :class="{ 'new-input-dropdown-active' : selectCompany }"
            >
              <ul>
                IT'S IMPORTANT THAT COMPANIES ARE THE SAME AS THE ONES IN PORTAL.SIENIT.COM, BECAUSE THEY'RE COMPARED BY NAME
                THE COMPANIES SHOULD BE THE SAME REGARDLESS OF THE LANGUAGE
                <li
                  v-for="(c, i) in [
                  'Строителна Група АД',
                  'Инвест АД',
                  'Строителен Холдинг АД'
                  ]"
                  :key="`userModalCompany-${i}`"
                  @mousedown="chooseCompany(c)"
                >{{c}}</li>
              </ul>
            </div>
          </div> -->
          <!-- <div class="leave-wrapper">
            <label class="input-label">{{ $t('not-used-leave') }}</label>
            <div class="leave-list">
              <div class="leave" v-for="(l,y, i) in user.leave" :key="`userLeave-${i}`">
                LEAVE
                <label :for="`userLeave-${i}`" class="input-label">{{ y }}</label>
                <div class="input-wrapper">
                  <span class="tms2">F</span>
                  <input
                    :id="`userLeave-${i}`"
                    :placeholder="$t('how-many-days')"
                    v-model="user.leave[y]"
                  />
                </div>
              </div>
            </div>
          </div> -->
          <!-- PERMISSIONS -->
          <label class="input-label">{{ $t("permissions-tms") }}</label>
          <div
            class="user-modal-permissions"
            :class="{ invalid: !isPermissionsValid }"
          >
            <label
              class="checkbox-wrapper"
              v-if="getUser.permissions.isSuperAdmin"
            >
              {{ $t("users-superadmin") }}
              <input
                type="checkbox"
                v-model="user.permissions.isSuperAdmin"
                @change="
                  isNonWorkerChanged();
                  isPermissionsValid = true;
                "
              />
              <span class="checkmark"></span>
            </label>
            <label class="checkbox-wrapper">
              {{ $t("users-admin") }}
              <input
                type="checkbox"
                v-model="user.permissions.isAdmin"
                @change="
                  isNonWorkerChanged();
                  isPermissionsValid = true;
                "
              />
              <span class="checkmark"></span>
            </label>
            <label class="checkbox-wrapper">
              {{ $t("reporter") }}
              <input
                type="checkbox"
                v-model="user.permissions.isReporter"
                @change="
                  isNonWorkerChanged();
                  isPermissionsValid = true;
                "
              />
              <span class="checkmark"></span>
            </label>
            <label class="checkbox-wrapper">
              {{ $t("employee") }}
              <input
                type="checkbox"
                v-model="user.permissions.isEmployee"
                @change="
                  isNonWorkerChanged();
                  isPermissionsValid = true;
                "
              />
              <span class="checkmark"></span>
            </label>
          </div>
          <!-- <label class="input-label">{{ $t('permissions-eadmin') }}</label>
          <div class="user-modal-permissions">
            <label class="checkbox-wrapper">
              {{ $t('users-ceo-full') }}
              <input type="checkbox" v-model="user.permissions.isCEO" />
              <span class="checkmark"></span>
            </label>
            <label class="checkbox-wrapper">
              {{ $t('users-hr-full') }}
              <input type="checkbox" v-model="user.permissions.isHR" />
              <span class="checkmark"></span>
            </label>
          </div> -->
        </template>
        <div class="user-modal-actions">
          <div class="switch-container" v-if="!limitedFunctionality">
            <label class="switch">
              <input type="checkbox" v-model="user.isActive" />
              <span class="slider"></span>
            </label>
            <label v-if="user.isActive">{{ $t("active-single") }}</label>
            <label v-else>{{ $t("inactive-single") }}</label>
          </div>
          <div v-else>
            <!-- JUST AND EMPTY DIV FOR THE SPACE-BETWEEN RULE TO WORK -->
          </div>
          <div class="btns">
            <button
              v-if="!idToEdit"
              class="btn btn-new"
              type="submit"
              value="Submit"
              @click.prevent="register"
            >
              <span class="tms2">.</span>
              {{ $t("create") }}
            </button>
            <button
              v-else
              class="btn btn-new"
              type="submit"
              value="Submit"
              @click.prevent="edit"
            >
              <span class="tms2">.</span>
              {{ $t("edit") }}
            </button>
            <button class="btn btn-close" @click.prevent="closeModal(false)">
              <span class="tms">F</span>
              {{ $t("cancel") }}
            </button>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import log from "../mixins/log.js";
export default {
  mixins: [log],
  props: ["idToEdit", "limitedFunctionality", "userMode"],
  computed: {
    // mix the getters into computed with object spread operator
    ...mapGetters([
      "getUser",
      // ...
    ]),
    filteredGroups() {
      return this.groups.filter((g) => {
        return g.name.toLowerCase().includes(this.searchGroups.toLowerCase());
      });
    },
  },
  data() {
    return {
      openedDropdown: [false, false],
      selectCompany: false,
      isUsernameValid: true,
      isPasswordValid: true,
      isNameValid: true,
      isEmailValid: true,
      isPermissionsValid: true,
      searchGroups: "",
      groups: [],
      user: {
        username: "",
        password: "",
        email: "",
        name: "",
        position: "",
        phone: "",
        company: "",
        isActive: true,
        leave: {},
        group: { _id: "" },
        permissions: {
          isSuperAdmin: false,
          isAdmin: false,
          isReporter: true,
          isWorker: false,
          isCEO: false,
          isHR: false,
          // isTechLead: false
        },
      },
    };
  },
  methods: {
    initLeave() {
      const year = new Date().getFullYear();
      for (let i = 0; i < 4; i++) {
        this.$set(this.user.leave, year - i, 0);
      }
    },
    getSpecificUser(id) {
      this.$http
        .get(`${process.env.VUE_APP_BASE_URI}/auth/users/${id}`)
        .then((response) => {
          this.user = response.data;
          if (!this.user.leave) {
            this.user.leave = {};
            this.initLeave();
          }
        });
    },
    getGroups() {
      this.$http
        .get(`${process.env.VUE_APP_BASE_URI}/group?active=true`)
        .then((response) => {
          this.groups = response.data;
        });
    },
    selectGroup(g) {
      this.user.group = g;
    },
    // Open dropdown. Depends on index (hard coded in template)
    openDropdown(i) {
      this.$set(this.openedDropdown, i, true);
    },
    closeDropdown(i) {
      setTimeout(() => {
        this.$set(this.openedDropdown, i, false);

        if (this.searchGroups && !this.user.group._id) {
          this.$snotify.warning("Трябва да изберете бригадата");
        }

        this.searchGroups = "";
      }, 100);
    },
    resetSelectedGroup() {
      this.user.group = { _id: "" };
    },
    chooseCompany(c) {
      this.$set(this.user, "company", c);
    },
    clearCompany() {
      this.$set(this.user, "company", null);
    },
    validateEmail(email) {
      const re =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(String(email).toLowerCase());
    },
    validateUsername(username) {
      if (!username.length) {
        return false;
      }
      const re = /^[a-z0-9_.-]*$/;
      if (!username.match(re)) {
        return false;
      }
      return true;
    },
    // Reformat username - only lowercase latin letters, numbers, dot, underscore and dash
    formatUsername() {
      this.user.username = this.user.username
        .replace(/[^A-Za-z0-9-._]/g, "")
        .toLowerCase();
    },
    // Reformat username - only lowercase latin letters, numbers, dot, underscore and dash
    formatName() {
      if(this.$i18n.locale === 'bg') {
        this.user.name = this.user.name.replace(/[^А-Яа-я0-9 -._]/g, "");
      } else {
        this.user.name = this.user.name.replace(/[^A-Za-zА-Яа-я0-9 -._]/g, "");
      }
    },
    isNonWorkerChanged() {
      if (
        this.user.permissions.isSuperAdmin ||
        this.user.permissions.isAdmin ||
        this.user.permissions.isReporter
      ) {
        this.user.permissions.isWorker = false;
      }
    },
    isWorkerChanged() {
      if (this.user.permissions.isWorker) {
        this.user.permissions.isSuperAdmin = false;
        this.user.permissions.isAdmin = false;
        this.user.permissions.isReporter = false;

        this.username = "";
        this.password = "";
      }
    },
    checkForm() {
      if (!this.user.permissions.isWorker) {
        if (!this.validateUsername(this.user.username)) {
          this.isUsernameValid = false;
        }
        if (!this.user.password && !this.idToEdit) {
          this.isPasswordValid = false;
        }

        if (
          !this.user.permissions.isSuperAdmin &&
          !this.user.permissions.isAdmin &&
          !this.user.permissions.isReporter &&
          !this.user.permissions.isEmployee
        ) {
          this.isPermissionsValid = false;
        }
      }
      if (!this.user.name) {
        this.isNameValid = false;
      }
      if (this.user.email) {
        if (this.user.email.length && !this.validateEmail(this.user.email)) {
          this.isEmailValid = false;
        }
      }
      return (
        this.isUsernameValid &&
        this.isPasswordValid &&
        this.isNameValid &&
        this.isEmailValid &&
        this.isPermissionsValid
      );
    },
    register() {
      if (this.checkForm()) {
        let userForRequest = this.user;
        // if (!userForRequest.email.length) {
        //   delete userForRequest.email;
        // }
        this.$http
          .post(`${process.env.VUE_APP_BASE_URI}/auth/register`, userForRequest)
          .then(() => {
            this.$snotify.success(this.$t("success-new-user"));
            this.newLog("newUser", this.user.username);
            this.closeModal(true);
          })
          .catch((error) => {
            console.log(error.response);
            if (error.response.data.errorName)
              this.$snotify.error(this.$t(`${error.response.data.errorName}`));
            else this.$snotify.error(this.$t("something-went-wrong"));
          })
          .then(() => {});
      } else {
        // TODO: Do something
      }
    },
    edit() {
      if (this.checkForm()) {
        this.$http
          .put(
            `${process.env.VUE_APP_BASE_URI}/auth/users/${this.idToEdit}`,
            this.user
          )
          .then((res) => {
            this.$snotify.success(this.$t("success-changes"));
            // IF THE CURRENTLY LOGGED USER CHANGED HIS OWN ROLE IT LOGGES HIM OUT
            if (this.idToEdit === this.$store.state.user._id) {
              const oldPermissions = this.$store.state.user.permissions;
              const newPermissions = res.data.user.permissions;
              if (
                !(
                  oldPermissions.isSuperAdmin === newPermissions.isSuperAdmin &&
                  oldPermissions.isAdmin === newPermissions.isAdmin &&
                  oldPermissions.isReporter === newPermissions.isReporter &&
                  oldPermissions.isWorker === newPermissions.isWorker &&
                  this.$store.state.user.isActive === res.data.user.isActive
                )
              ) {
                this.$store
                  .dispatch("logout")
                  .then(() => this.$router.push("/login"))
                  .catch((err) => console.log(err));
              }
            }
            this.newLog("editUser", this.user.username);
            this.closeModal(true);
          })
          .catch((error) => {
            console.log(error.response);
            if (error.response.data.errorName)
              this.$snotify.error(this.$t(`${error.response.data.errorName}`));
            else this.$snotify.error(this.$t("something-went-wrong"));
          })
          .then(() => {});
      } else {
        // TODO: Do something
      }
    },
    closeModal(isEdited) {
      // isEdited = true => means something is changed
      // isEdited = false => just close the modal
      this.$parent.$emit("closeUserModal", { isEdited: isEdited });
    },
  },
  mounted() {
    this.initLeave();
    this.getGroups();

    if (this.idToEdit) {
      this.getSpecificUser(this.idToEdit);
    } else {
      if (this.userMode) {
        this.user.permissions = {
          isSuperAdmin: false,
          isAdmin: false,
          isReporter: true,
          isWorker: false,
        };
      } else {
        this.user.permissions = {
          isSuperAdmin: false,
          isAdmin: false,
          isReporter: false,
          isWorker: true,
        };
      }
    }

    if (this.idToEdit === this.$store.state.user._id) {
      if (this.limitedFunctionality) {
        this.$snotify.warning(this.$t("logout-warning-1"));
      } else {
        this.$snotify.warning(this.$t("logout-warning-2"));
      }
    }

    this.$eventBus.$on("closeModalFromSpeechRecognition", () => {
      this.closeModal();
    });
  },
  destroyed() {
    this.$eventBus.$off("closeModalFromSpeechRecognition");
  },
};
</script>

<style scoped>
</style>
