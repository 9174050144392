<template>
  <label
    class="select-hour"
    :class="{
      'select-hour--reported': reported,
      'select-hour--reported-elsewhere': reportedElsewhere,
      'select-hour--reported-nonplanned': reportedNonplanned,
    }"
    :for="`select-${this._uid}`"
  >
    <span class="tms">R</span>
    <select
      :id="`select-${this._uid}`"
      :value="value"
      @change="$emit('input', $event.target.value), $emit('change')"
    >
      <option v-if="!value" :value="null">-</option>
      <option v-for="h in filteredHours" :key="`h-${h}`" :value="h">
        {{ h }}
      </option>
    </select>
  </label>
</template>

<script>
import hours from "../mixins/hours.js";
export default {
  mixins: [hours],
  props: [
    "value",
    "start",
    "end",
    "reported",
    "reportedElsewhere",
    "reportedNonplanned",
  ],
  computed: {
    filteredHours() {
      // TODO: refactor this, no time now
      // tbh the whole app needs to be refactored, but that prob won't happen
      if (this.start || this.end) {
        const index = this.hours.indexOf(this.start || this.end);

        if (this.start) {
          return this.hours.filter((o, i) => {
            return i > index;
          });
        } else {
          return this.hours.filter((o, i) => {
            return i < index;
          });
        }
      }

      return this.hours;
    },
  },
};
</script>

<style scoped>
.select-hour {
  align-items: center;
  background-color: #e8e8e8;
  border-radius: 4px;
  cursor: pointer;
  display: inline-flex;
  height: 20px;
  justify-content: center;
  margin: 1px 2px;
  padding: 0 10px;
  -webkit-transition: background-color 0.3s;
  -moz-transition: background-color 0.3s;
  -ms-transition: background-color 0.3s;
  -o-transition: background-color 0.3s;
  transition: background-color 0.3s;
  white-space: nowrap;
}
.select-hour:hover {
  background-color: #dbdada;
}
.select-hour select {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: transparent;
  border: 0;
  color: #737373;
  cursor: pointer;
}
.select-hour .tms {
  color: #3b86ff;
  margin-right: 10px;
}
.select-hour--reported {
  background-color: #a9d2a7;
}
.select-hour--reported select {
  color: #1d1d1d;
}
.select-hour--reported .tms {
  color: #005a9e;
}
.select-hour--reported-nonplanned {
  background-color: #9fd3e3;
}
.select-hour--reported-nonplanned select {
  color: #1d1d1d;
}
.select-hour--reported-nonplanned .tms {
  color: #005a9e;
}
.select-hour--reported-elsewhere {
  background-color: #ecc98c;
}
.select-hour--reported-elsewhere select {
  color: #7a5100;
}
.select-hour--reported-elsewhere .tms {
  color: #7a5100;
}
</style>